export const PhysicianData = {
  title: 'User Form Fields',
  weight: 0,
  default: true,
  components: {
    mspNumber: {
      title: 'MSP Billing Number',
      key: 'mspNumber',
      schema: {
        label: 'MSP Billing Number',
        type: 'textfield',
        key: 'mspNumber',
        input: true
      },
    },
    cpsbc: {
      title: 'CPSBC',
      key: 'cpsbc',
      schema: {
        label: 'CPSBC',
        type: 'textfield',
        key: 'cpsbc',
        input: true
      }
    },
    physicianFirstName: {
      title: 'First Name',
      key: 'physicianFirstName',
      schema: {
        label: 'User First Name',
        type: 'textfield',
        key: 'physicianFirstName',
        input: true
      },
    },
    physicianLastName: {
      title: 'Last Name',
      key: 'physicianLastName',
      schema: {
        label: 'User Last Name',
        type: 'textfield',
        key: 'physicianLastName',
        input: true
      }
    },
    physicianName: {
      title: 'Full Name',
      key: 'physicianName',
      schema: {
        label: 'User Full Name',
        type: 'textfield',
        key: 'physicianName',
        input: true
      }
    },
  }
};
