<ngx-loading [show]="loading || !invoice"></ngx-loading>
<div class="grey-seven-background">
  <ng-container *ngFor="let error of errors">
    <app-error *ngFor="let fieldError of error.fieldErrors" [message]="fieldError"></app-error>
  </ng-container>

  <!-- Total Due, Credit Ballance, ePoints -->
  <div class="p-4 d-flex flex-column align-items-center">
    <button
      *ngIf="
        paymentType !== PaymentType.Regular &&
        paymentType !== PaymentType.Refund &&
        paymentType !== PaymentType.Recurring
      "
      type="button"
      class="btn-clean btn-back navy-font"
      (click)="adminFee = 0; backToReturn()"
    >
      <i class="far fa-long-arrow-left"></i>
    </button>
    <h6 class="mt-0 mb-4 navy-font">
      {{
        paymentType === PaymentType.Regular
          ? editTransactionMode
            ? 'EDIT TRANSACTIONS'
            : 'MAKE A PAYMENT'
          : paymentType === PaymentType.RefundCredit
          ? 'REFUND CREDITS'
          : paymentType === PaymentType.Recurring
          ? 'RECURRING PAYMENT'
          : 'REFUND'
      }}
    </h6>
    <div class="payment">
      <div *ngIf="paymentType === PaymentType.Regular || paymentType === PaymentType.Recurring">
        <div class="payment-row px-4 mb-3 font-weight-bold">
          <div>SUBTOTAL:</div>
          <div>{{ invoice?.subTotalAmount | currency }}</div>
        </div>
        <div *ngFor="let invoiceLineItemTax of invoiceLineItemTaxKeys" class="payment-row px-4 mb-3 font-weight-bold">
          <div>{{ invoiceLineItemTax }}:</div>
          <div>{{ invoiceLineItemTaxValuesObject['sum' + invoiceLineItemTax] | currency }}</div>
        </div>
      </div>
      <div class="payment-row p-4 font-weight-bold white-font navy-background">
        <div>TOTAL DUE</div>
        <div>{{ invoice?.totalAmount | currency }}</div>
      </div>
      <div
        *ngIf="paymentType === PaymentType.Refund"
        class="payment-row p-4 font-weight-bold navy-font grey-six-background"
      >
        <div>TOTAL PAID</div>
        <div>{{ invoice?.totalAmount - outstandingAmount | currency }}</div>
      </div>
      <div class="payment-row px-4 py-2 white-font teal-background">
        <div>PATIENT CREDITS:</div>
        <div
          [attr.data-test-id]="'invoice-payment-modal-patient-credit-amount'"
          [attr.data-test-value]="patientAvailableCredit | currency"
        >
          {{ patientAvailableCredit | currency }}
        </div>
      </div>
      <div *ngIf="stateOfCredits != 0" class="payment-row px-4 py-2 white-font teal-background">
        <div>CREDITS {{ stateOfCredits > 0 ? 'REFUND' : 'SPEND' }}:</div>
        <div>{{ stateOfCredits | currency }}</div>
      </div>

      <div class="payment-row payment-row--points px-4 py-2 white-font teal-background">
        <div>PATIENT ePOINTS:</div>
        <div>
          {{ patientAvailableLoyaltyPoints?.value }} / {{ patientAvailableLoyaltyPoints?.dollarValue | currency }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="p-4 d-flex flex-column align-items-center">
  <!-- Admin Fee and Balance Owed or Square Payment-->
  <div *ngIf="clinic.paymentProcessorType != PaymentProcessorType.Square; else squareCard" class="payment mb-2">
    <div
      [formGroup]="paymentForm"
      class="admin-fee-row px-4 pt-3 navy-font font-weight-bold grey-three-background align-items-center"
      [hidden]="
        paymentType === PaymentType.Regular ||
        paymentType === PaymentType.RefundAdminFee ||
        paymentType === PaymentType.Recurring
      "
    >
      <div>ADMIN FEE:</div>
      <mat-slide-toggle
        formControlName="adminFeeToggle"
        kendoTooltip
        tooltipClass="em-tooltip"
        title="Charge an optional admin fee"
        position="top"
        class="mx-1"
        #hasAdminFeeToggle
        (change)="adminFeeChanged(null)"
        [disabled]="isAdminFeePaid"
      ></mat-slide-toggle>
      <div class="w-100"></div>
      <div class="w-100" *ngIf="!hasAdminFeeToggle.checked"></div>
      <input
        formControlName="adminFee"
        [value]="adminFee | currency"
        (change)="adminFeeChanged($event)"
        mat-input
        numericType="number"
        [hidden]="!hasAdminFeeToggle.checked"
        placeholder="Fee ($)"
        class="w-100 ml-2"
        autocomplete="off"
      />
      <div class="w-100 pl-4">
        <button
          [hidden]="!hasAdminFeeToggle.checked"
          kendoTooltip
          tooltipClass="em-tooltip"
          position="top"
          type="button"
          class="btn btn-sm em-btn btn-circle em-btn-green btn-apply"
          (click)="onChargeAdminFee()"
          [title]="paymentType === PaymentType.Regular ? 'Charge Payment' : 'Refund Payment'"
          [disabled]="isAdminFeePayButtonDisabled()"
        >
          APPLY
        </button>
      </div>
    </div>
    <div
      class="balance-owed-row px-4 py-3 navy-font font-weight-bold grey-three-background align-items-center"
      [ngClass]="{
        'pb-3': paymentType === PaymentType.Regular || paymentType === PaymentType.RefundCredit
      }"
    >
      <div>BALANCE OWED:</div>
      <div>{{ outstandingAmount | currency }}</div>
      <ng-container *ngIf="paymentType !== PaymentType.RefundPaidCancellation">
        <button
          [hidden]="!outstandingAmount"
          kendoTooltip
          tooltipClass="em-tooltip"
          position="top"
          type="button"
          class="btn btn-sm em-btn em-btn-green ml-2"
          style="justify-self: center"
          (click)="addToPaymentTotal(outstandingAmount)"
          title="Add balance to payment"
          data-test-id="add-balance-to-total"
          [disabled]="isAddBalanceButtonDisabled()"
        >
          <i class="far fa-arrow-circle-down"></i>
        </button>
      </ng-container>
    </div>
  </div>

  <ng-template #squareCard>
    <app-square-card
      #squareCardSelection
      *ngIf="isSquareLinked"
      class="px-4 py-3 font-weight-bold grey-three-background align-items-center"
      [patient]="patient"
      (cardSelectedEvent)="onSquareCardSelected($event)"
    ></app-square-card>
    <div *ngIf="!isSquareLinked" class="alert alert-warning" role="alert">
      A Square account is required to purchase recurring memberships. Please link a Square account in the clinic
      settings.
    </div>
    <button
      kendoTooltip
      tooltipClass="em-tooltip"
      position="top"
      type="button"
      class="btn em-btn btn-circle em-btn-green my-2 btn-membership-pay"
      (click)="onChargePayment()"
      [title]="'Charge Payment'"
      [disabled]="isPayButtonDisabled()"
    >
      PAY FOR MEMBERSHIP
    </button>
  </ng-template>

  <!-- Close Invoice Button -->
  <div *ngIf="paymentType === PaymentType.Regular && showCloseInvoiceButton" class="text-center w-100 pb-4 pt-3">
    <button class="btn em-btn em-btn-green ml-2" (click)="closeInvoiceHandler()">Close Invoice</button>
  </div>

  <!-- Payment Form -->
  <form [formGroup]="paymentForm" class="w-100 payment-form">
    <div class="grey-four-border-bottom field-wrapper mb-3"></div>
    <div *ngIf="paymentType === PaymentType.RefundProduct" class="d-flex">
      <mat-slide-toggle formControlName="addBackToInventory">Return to Inventory: </mat-slide-toggle>
    </div>
    <div
      *ngIf="paymentType === PaymentType.RefundProduct"
      class="pb-3 grey-four-border-bottom field-wrapper mb-3"
    ></div>
    <ng-container
      *ngIf="
        paymentType !== PaymentType.RefundPaidCancellation &&
        clinic.paymentProcessorType !== PaymentProcessorType.Square
      "
    >
      <!-- Payment methods -->
      <div
        class="d-flex justify-content-start flex-nowrap w-100 align-items-center"
        kendoTooltip
        tooltipClass="em-tooltip"
        position="top"
      >
        <i
          *ngIf="editTransactionMode"
          class="far fa-info-circle pr-2"
          kendoTooltip
          tooltipClass="em-tooltip"
          title="Negative amounts will be treated as refunds when editing transactions"
        ></i>
        <h6 class="my-0 navy-font pr-2">
          {{ paymentType === PaymentType.Regular ? 'Add Payment Method' : 'Add Refund Method' }}
        </h6>
        <button
          type="button"
          class="btn btn-sm em-btn em-btn-green pull-right"
          (click)="addPaymentMethodRow()"
          [title]="paymentType === PaymentType.Regular ? 'Add Payment Method' : 'Add Refund Method'"
          [disabled]="isAddNewPaymentRowDisabled()"
          [hidden]="isAddNewPaymentRowHidden()"
        >
          <i class="far fa-plus-circle"></i>
        </button>
      </div>

      <div *ngIf="paymentForm.get('formPaymentMethods')" class="pb-3 grey-four-border-bottom field-wrapper mb-3">
        <div
          formArrayName="formPaymentMethods"
          *ngFor="let paymentMethodControls of paymentForm.get('formPaymentMethods')['controls']; let i = index"
          [attr.data-test-id]="'form-payment-methods'"
          [attr.data-test-value]="i"
        >
          <div [formGroupName]="i" class="w-100 payment-form-row">
            <button
              kendoTooltip
              tooltipClass="em-tooltip"
              position="top"
              type="button"
              [attr.data-test-id]="'payment-method-delete-row'"
              [attr.data-test-value]="i"
              class="btn btn-sm em-btn btn-circle em-btn-green mt-3 ml-2"
              (click)="removePaymentMethodRow(i)"
              [title]="paymentType === PaymentType.Regular ? 'Remove Payment Method' : 'Remove Refund Method'"
              [disabled]="
                isCharged(paymentMethodControls) ||
                paymentMethodControls.controls.isMerchantDeviceTransaction.value ||
                clinic.paymentProcessorType === PaymentProcessorType.EmilyPay
              "
            >
              <i class="far fa-minus-circle"></i>
            </button>

            <div class="navy-font d-flex align-items-center font-weight-bold form-field">
              Method:
              <div class="flex-grow-1 ml-2">
                <mat-form-field [floatLabel]="'never'">
                  <mat-select
                    formControlName="method"
                    [attr.data-test-id]="'payment-method-drop-down'"
                    [attr.data-test-value]="i"
                    (valueChange)="onFormChange($event, i, true)"
                    [disabled]="
                      (isMerchantDevice(paymentMethodControls) && editTransactionMode) ||
                      isCharged(paymentMethodControls)
                    "
                  >
                    <mat-option
                      [attr.data-test-id]="'payment-method-option'"
                      [attr.data-test-value]="paymentMethod.name"
                      *ngFor="let paymentMethod of merchantDevicePaymentMethods"
                      [value]="paymentMethod"
                      [disabled]="
                        (paymentType === PaymentType.Regular &&
                          paymentMethod.name === 'Credits' &&
                          patientAvailableCredit === 0 &&
                          !editTransactionMode) ||
                        (paymentType === PaymentType.RefundCredit && paymentMethod.name === 'Credits') ||
                        (creditFieldExists && paymentMethod.id === creditsPaymentMethod.id) ||
                        editTransactionMode
                      "
                    >
                      {{ paymentMethod.name }}
                    </mat-option>

                    <mat-option
                      [attr.data-test-id]="'payment-method-option'"
                      [attr.data-test-value]="paymentMethod.name"
                      *ngFor="let paymentMethod of paymentMethods"
                      [value]="paymentMethod"
                      [disabled]="
                        (paymentType === PaymentType.Regular &&
                          paymentMethod.name === 'Credits' &&
                          patientAvailableCredit === 0 &&
                          !editTransactionMode) ||
                        (paymentType === PaymentType.RefundCredit && paymentMethod.name === 'Credits') ||
                        (creditFieldExists && paymentMethod.id === creditsPaymentMethod.id) ||
                        (paymentType === PaymentType.Recurring && paymentMethod.merchantDeviceId == 0)
                      "
                    >
                      {{ paymentMethod.name }}
                    </mat-option>
                    <ng-container *ngIf="editTransactionMode">
                      <mat-option
                        *ngFor="let paymentMethod of deviceCapablePaymentMethods"
                        [value]="paymentMethod"
                        [disabled]="true"
                      >
                        {{ paymentMethod.name }}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="navy-font d-flex align-items-center font-weight-bold form-field">
              Amount($):
              <div class="flex-grow-1 ml-2">
                <mat-form-field [floatLabel]="'never'">
                  <input
                    matInput
                    [attr.data-test-id]="'payment-method-amount-input'"
                    [attr.data-test-value]="i"
                    formControlName="amount"
                    [allowNegative]="editTransactionMode"
                    (change)="
                      onFormChange($any($event.target).value, i, false);
                      formatCurrencyForControl($any($event.target).value, paymentMethodControls)
                    "
                    onlyNumber
                    numericType="decimal"
                    autocomplete="off"
                    [attr.disabled]="
                      isCharged(paymentMethodControls) ||
                      (editTransactionMode && paymentMethodControls.controls.isMerchantDeviceTransaction.value)
                    "
                  />
                </mat-form-field>
              </div>
            </div>

            <button
              [hidden]="editTransactionMode"
              kendoTooltip
              tooltipClass="em-tooltip"
              position="top"
              type="button"
              [attr.data-test-id]="'payment-method-charge-row'"
              [attr.data-test-value]="i"
              class="btn btn-sm em-btn btn-circle em-btn-green mt-3 btn-pay"
              (click)="onChargePayment(i)"
              [title]="paymentType === PaymentType.Regular ? 'Charge Payment' : 'Refund Payment'"
              [disabled]="isCharged(paymentMethodControls) || isPaymentControlsDisabled(paymentMethodControls)"
            >
              PAY
            </button>
            <div class="mt-2 ml-1" *ngIf="!editTransactionMode">
              <label [hidden]="!isMerchantDevice(paymentMethodControls)" class="d-block mb-0 teal-font font-weight-bold"
                >Manual</label
              >
              <label
                [hidden]="!isMerchantDevice(paymentMethodControls)"
                class="switch align-text-top"
                kendoTooltip
                tooltipClass="em-tooltip"
                title="Manually enter credit card information."
              >
                <input
                  type="checkbox"
                  formControlName="isManualEntry"
                  (click)="toggleManualEntry(paymentMethodControls)"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <i
              *ngIf="paymentMethodControls.controls.isMerchantDeviceTransaction.value && editTransactionMode"
              class="far fa-question-circle pr-2 pt-4"
              kendoTooltip
              tooltipClass="em-tooltip"
              title="Merchant Transaction cannot be edited"
            ></i>
          </div>
        </div>
        <div class="alert-danger px-1" *ngIf="checkInvalidPaymentAmount()">Not a valid amount.</div>
        <div
          class="alert-danger px-1"
          *ngIf="paymentType === PaymentType.Regular ? patientAvailableCredit + stateOfCredits < 0 : false"
        >
          Your credit balance has been exceeded.
        </div>
        <!-- <div class="alert-danger px-1" *ngIf="this.sum !== 0 && adminFee !== 0 && outstandingAmount !== 0">
        Partial refund cannot have an admin fee.
      </div> -->
      </div>
    </ng-container>
    <ng-container *ngIf="paymentType == PaymentType.RefundPaidCancellation">
      <div
        class="d-flex justify-content-start flex-nowrap w-100 align-items-center"
        kendoTooltip
        tooltipClass="em-tooltip"
        position="top"
      >
        <h6 class="my-0 navy-font pr-2 mb-3">Issue Refund</h6>
      </div>
      <div class="pb-3 grey-four-border-bottom field-wrapper mb-3 ng-star-inserted">
        <div class="w-100 refund-form-row">
          <div class="navy-font d-flex align-items-center font-weight-bold">Credit Card:</div>
          <div class="navy-font d-flex align-items-center font-weight-bold">
            <i
              class="fa-2x fab"
              [class]="
                refundSquareCard
                  ? PaymentHelper.getCreditCardIcon(refundSquareCard?.card_brand)
                  : refundConvergeCard
                  ? PaymentHelper.getCreditCardIcon(refundConvergeCard.brand)
                  : 'fa-credit-card'
              "
            ></i>
          </div>
          <div class="navy-font d-flex align-items-center font-weight-bold">
            <div class="flex-grow-1 ml-2 uppercase">
              {{
                refundSquareCard
                  ? PaymentHelper.getCreditCardString(refundSquareCard?.card_brand)
                  : refundConvergeCard
                  ? PaymentHelper.getCreditCardString(refundConvergeCard.brand)
                  : 'Credit Card'
              }}
            </div>
          </div>

          <div class="navy-font d-flex align-items-center font-weight-bold">
            <div *ngIf="refundSquareCard" class="flex-grow-1 ml-2">************{{ refundSquareCard?.last_4 }}</div>
            <div *ngIf="refundConvergeCard" class="flex-grow-1 ml-2">{{ refundConvergeCard?.maskedDigits }}</div>
          </div>
          <div class="navy-font d-flex align-items-center font-weight-bold">
            <div *ngIf="refundSquareCard" class="flex-grow-1 ml-2">
              {{ refundSquareCard?.exp_month }}/{{ refundSquareCard?.exp_year }}
            </div>
            <div *ngIf="refundConvergeCard" class="flex-grow-1 ml-2">{{ refundConvergeCard?.expiry }}</div>
          </div>
          <button
            kendoTooltip
            tooltipClass="em-tooltip"
            position="top"
            type="button"
            class="btn btn-sm em-btn btn-circle em-btn-green mt-1 btn-pay"
            ng-reflect-tooltip-class="em-tooltip"
            ng-reflect-position="top"
            title="Refund Payment"
            data-title=""
            (click)="onChargePayment()"
            [disabled]="isPayButtonDisabled()"
          >
            PAY
          </button>
        </div>
      </div>
    </ng-container>
    <div class="d-flex justify-content-between align-items-center footer-buttons" *ngIf="!editTransactionMode">
      <button
        type="button"
        class="schedNewCommButton center em-btn em-btn-green py-2 px-3 mb-0"
        (click)="onExit()"
        [disabled]="buttonsDisabled()"
        data-test-id="pay-button"
      >
        DONE
      </button>
      <app-generic-send-email-popover
        buttonClass="schedNewCommButton center em-btn em-btn-green py-2 px-3 mb-0"
        buttonText="EMAIL"
        [initialEmailAddress]="patient?.email"
        [disabled]="buttonsDisabled()"
        [sendEmailObservable]="getEmailInvoiceObservable"
      ></app-generic-send-email-popover>
      <button
        type="button"
        class="schedNewCommButton center em-btn em-btn-green py-2 px-3 mb-0"
        [disabled]="buttonsDisabled()"
        (click)="onPrintInvoice()"
      >
        PRINT
      </button>
    </div>
    <div
      class="d-flex justify-content-center align-items-center"
      *ngIf="editTransactionMode"
      [ngClass]="{ 'return-payment': paymentType !== PaymentType.Regular }"
    >
      <button
        data-test-id="save-transaction-button"
        type="button"
        class="schedNewCommButton center em-btn em-btn-green py-2 px-3 mb-0"
        (click)="saveEditedTransactions()"
        [disabled]="
          !isPaymentFormValid() ||
          (paymentType === PaymentType.Regular ? patientAvailableCredit + stateOfCredits < 0 : false) ||
          outstandingAmount !== 0
        "
      >
        SAVE TRANSACTIONS
      </button>
    </div>
  </form>
</div>
<div style="opacity: 0; position: absolute; width: 0; height: 0">
  <ng-template #exportTemplate></ng-template>
</div>
