<div class="teal-font smaller">Add New Service</div>
<mat-form-field 
  class="apply-text-field w-100"
  [floatLabel]="'never'">
    <mat-label>Select</mat-label>
    <mat-select [(ngModel)]="selectedServiceTemplate">
      <mat-option *ngFor="let serviceTemplate of serviceTemplates" [value]="serviceTemplate">
        {{serviceTemplate.serviceName}}
      </mat-option>
    </mat-select>
</mat-form-field>
<div class="text-right">
  <button 
    type="button" 
    class="btn em-btn-green white-font"
    [disabled]="!selectedServiceTemplate"
    (click)="applyServiceTemplate()">
      APPLY
  </button>
  <button 
    type="button" 
    class="btn em-btn-green white-font ml-2"
    (click)="closeServiceTemplate.next()">
      CLOSE
  </button>
</div>