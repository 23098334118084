import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Lab } from '@models/lab';
import { LabsService } from '@services/labs.service';

@Component({
  selector: 'app-quick-add-lab',
  templateUrl: './quick-add-lab.component.html',
  styleUrls: ['./quick-add-lab.component.less']
})
export class QuickAddLabComponent implements OnInit, OnDestroy {
  labForm: FormGroup;
  unsub = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<QuickAddLabComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private labsService: LabsService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.labForm = this.formBuilder.group({
      labName: [null, Validators.required]
    });
  }

  labFormSubmit() {
    const lab: Lab = this.initLab(this.labForm.value.name);
    this.labsService.addLab(lab)
      .pipe(takeUntil(this.unsub))
      .subscribe((lab) => {
        this.dialogRef.close(lab);
      });
  }

  initLab(name: string): Lab {
    const address = {
      address1: '',
      address2: '',
      city: '',
      country: '',
      postalCode: '',
      province: ''
    };
    const lab = {
      labId: 0,
      name: name,
      address: address,
      phoneNumber1: '',
      phoneNumber2: '',
      phoneNumber3: '',
      faxNumber: '',
      email: '',
      website: '',
      hoursOfOperation: null,
      labType: ''
    };
    return lab;
  }

  closeModal() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
