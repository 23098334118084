export interface IEditorOptions {
  ImageName: string;
  ImageUrl?: string;
  ImageType?: string;
  File?: File;
  AspectRatios?: Array<RatioType>;
}

export type RatioType = '16:9' | '4:3' | '1:1' | '2:3' | 'Default';

export class EditorOptions implements IEditorOptions {
  ImageName: string;
  ImageUrl?: string;
  ImageType?: string;
  File?: File;
  AspectRatios?: Array<RatioType>;
}

export interface NgxAspectRatio {
  value: number;
  text: RatioType;
}

export const NGX_DEFAULT_RATIOS: Array<NgxAspectRatio> = [
  {
    value: 16 / 9, text: '16:9'
  },
  {
    value: 4 / 3, text: '4:3'
  },
  {
    value: 1 / 1, text: '1:1'
  },
  {
    value: 2 / 3, text: '2:3'
  },
  {
    value: 0 / 0, text: 'Default'
  }
];
