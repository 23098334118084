<ngx-loading [show]="loading"></ngx-loading>
<div class="visits-container position-absolute d-block z-index-3 white-background">
  <div class="re-links-container">
    <div (click)="closePanel()" class="re-links pointer">
      <a class="close-panel">
        <i class="far fa-times" aria-hidden="true"></i>
      </a>
    </div>
  </div>

  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0">
    <div class="row mx-0 teal-background">
      <div class="col px-2">
        <h6 class="white-font uppercase p-2 mb-0">{{ addOrEdit }} Schedule</h6>
      </div>
    </div>
    <div *ngIf="scheduleType === 'Staff Schedule'" class="row mx-0 pt-2">
      <div class="col px-2">
        <mat-form-field [floatLabel]="'always'" class="w-100">
          <mat-select placeholder="Schedule Type" [(ngModel)]="scheduleType" name="schedType">
            <mat-option *ngFor="let type of scheduleTypes" [value]="type">
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="scheduleType === 'Staff Schedule'" class="row mx-0">
      <div class="col px-2">
        <mat-form-field [floatLabel]="'always'" class="w-100">
          <mat-select placeholder="Staff Member" [(ngModel)]="selectedStaff" [compareWith]="compareStaff" name="staff">
            <mat-option *ngFor="let staff of serviceProviders" [value]="staff">
              {{ staff.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="scheduleType !== 'Staff Schedule'" class="row mx-0 pt-2">
      <div class="col px-2">
        <mat-form-field [floatLabel]="'always'" class="w-100">
          <mat-select
            id="selectedStaffMembersFormField"
            placeholder="Applies To"
            [(ngModel)]="schedStaffSelectedStatus"
            (change)="updateSelectedStaffMembers($event)"
            name="selectedStaffMembers"
            multiple
            [disabled]="addOrEdit === 'Update'"
          >
            <mat-option *ngFor="let staff of serviceProviders" [value]="staff.id">
              {{ staff.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <hr />
    <div class="row mx-0">
      <div class="col px-2">
        <label for="startTime" class="teal-font custom-label d-block mb-0">Start Time</label>
        <kendo-timepicker
          name="startTime"
          [min]="minTime"
          [max]="maxTime"
          [steps]="{ minute: minimumDuration }"
          [(value)]="startTime"
          (valueChange)="onStartTimeChange($event)"
        ></kendo-timepicker>
      </div>
    </div>
    <div class="row pt-3 mx-0">
      <div class="col px-2">
        <label for="endTime" class="teal-font custom-label d-block mb-0">End Time</label>
        <kendo-timepicker
          name="endTime"
          [min]="minEndTime"
          [max]="maxTime"
          [steps]="{ minute: minimumDuration }"
          [(value)]="endTime"
        ></kendo-timepicker>
      </div>
    </div>
    <hr />
    <div class="row mx-0">
      <div class="col px-2">
        <form>
          <mat-form-field class="full" [floatLabel]="'always'" class="w-100">
            <mat-select
              *ngIf="selectedStaffSchedule"
              name="shiftRepeat"
              placeholder="Repeat"
              [disabled]="isRepeatDisabled"
              (ngModelChange)="recurrenceChanged($event)"
              [(ngModel)]="selectedStaffSchedule.recurrence"
            >
              <mat-option [value]="0">No Repeat</mat-option>
              <mat-option [value]="1">Every Week</mat-option>
              <mat-option [value]="2">Every 2 Weeks</mat-option>
              <mat-option [value]="3">Every 3 Weeks</mat-option>
              <mat-option [value]="4">Every 4 Weeks</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </div>

    <div class="row mx-0" *ngIf="selectedStaffSchedule.recurrence !== 0">
      <div class="col px-2">
        <label for="repeatDates" class="teal-font d-block mb-0" style="font-size: 11px">Days To Repeat</label>
        <div class="d-flex mb-2">
          <div name="repeatDates">
            <mat-checkbox
              *ngFor="let day of weekdays"
              [disabled]="day == todayDayString || addOrEdit == 'Update'"
              (change)="dayChecked($event, day)"
              class="pr-2"
              [value]="day"
              [checked]="recurrenceDays[day]"
            >
              {{ day | slice : 0 : 2 }}
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0" *ngIf="selectedStaffSchedule && selectedStaffSchedule.recurrence !== 0">
      <div class="col px-2">
        <label for="repeatUntil" class="teal-font custom-label d-block mb-0">End Date</label>
        <kendo-datepicker [min]="minDate" [(value)]="repeatUntil" name="repeatUntil"> </kendo-datepicker>
      </div>
    </div>
    <hr />
    <div class="row mx-0">
      <div class="col px-2">
        <label class="teal-font custom-label d-block mb-0">Notes</label>
        <p-editor
          placeholder="Notes"
          [(ngModel)]="selectedStaffSchedule.notes"
          [ngClass]="{ 'blocked-schedule': scheduleType == 'Blocked Schedule' }"
        >
          <p-header>
            <span class="ql-formats">
              <button class="ql-bold" aria-label="Bold"></button>
              <button class="ql-italic" aria-label="Italic"></button>
              <button class="ql-underline" aria-label="Underline"></button>
              <button class="ql-strike" aria-label="Strike"></button>
            </span>
            <!--
            <span class="ql-formats">
              <select title="Text Color" class="ql-color">
                <option value="rgb(255,255,255)" label="rgb(255,255,255)"></option>
                <option value="rgb(0,0,0)" label="rgb(0,0,0)"></option>
                <option value="rgb(255,0,0)" label="rgb(255,0,0)"></option>
                <option value="rgb(0,255,0)" label="rgb(0,255,0)"></option>
                <option value="rgb(0,0,255)" label="rgb(0,0,255)"></option>
                <option value="rgb(128,128,128)" label="rgb(128,128,128)"></option>
                <option value="rgb(255,140,0)" label="rgb(255,140,0)"></option>
              </select>
              <span class="ql-format-separator"></span>
              <select title="Background Color" class="ql-background" defaultValue="rgb(37, 47, 56)">
                <option value="rgb(37, 47, 56)" label="rgb(37, 47, 56)"></option>
                <option value="rgb(255,0,0)" label="rgb(255,0,0)"></option>
                <option value="rgb(0,255,0)" label="rgb(0,255,0)"></option>
                <option value="rgb(0,0,255)" label="rgb(0,0,255)"></option>
                <option value="rgb(128,128,128)" label="rgb(128,128,128)"></option>
                <option value="rgb(0,128,128)" label="rgb(0,128,128)"></option>
                <option value="rgb(255,140,0)" label="rgb(255,140,0)"></option>
              </select>
            </span>
            -->
            <span class="ql-formats">
              <button class="ql-list" value="ordered"></button>
              <button class="ql-list" value="bullet"></button>
            </span>
            <span class="ql-formats">
              <button aria-label="Link" class="ql-link"></button>
            </span>
          </p-header>
        </p-editor>
        <br />
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <button class="btn em-btn em-btn-green mr-1" (click)="addUpdateAndCloseSchedule()">
          {{ addOrEdit == 'Add' ? 'Add' : 'Update' }} &amp; Close
        </button>
        <button class="btn em-btn em-btn-green mr-1" (click)="confirmAddUpdateSchedule()">
          {{ addOrEdit == 'Add' ? 'Add' : 'Update' }}
        </button>
        <button *ngIf="addOrEdit === 'Update'" class="btn em-btn em-btn-green mr-1" (click)="deleteSchedule()">
          Delete
        </button>
        <button class="btn em-btn em-btn-green" (click)="closePanel()">Close</button>
      </div>
    </div>
  </perfect-scrollbar>
</div>
