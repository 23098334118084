import { PhotoEventType } from './../../../../models/photo/photo-event-type';
import { PhotoEditingService } from './../../../../services/photo-editing.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddPhotoComponent } from './../../patient-photos-tab/add-photo/add-photo.component';
import { GenericDialogComponent } from './../../../../management/dialogs/generic-confirm/generic-confirm.component';
import { Patient } from './../../../../models/patient';
import { PatientService } from '@services/patient.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteDialogComponent } from './../../../../management/dialogs/confirm-delete/confirm-delete.component';
import { ImageService } from '@services/image.service';
import { TagType } from '@models/tag/tag-type';
import { PhotoConsentType, PhotoConsentTitle } from '@models/photo/photo-consent-type';
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { takeUntil, map, debounceTime, last, delay, catchError } from 'rxjs/operators';
import { Subject, of, throwError } from 'rxjs';

import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/public_api';
import { TagService } from '@services/tag.service';
import { PhotoSeriesService } from '@services/photo-series.service';
import { SeriesType } from '@models/photo/series-type';
import { Tag } from '@models/tag/tag';
import { PhotoMetaData } from '@models/photo/photo-meta-data';

@Component({
  selector: 'app-patient-toolbar',
  templateUrl: './patient-toolbar.component.html',
  styleUrls: ['./patient-toolbar.component.less'],
})
export class PatientToolbarComponent implements OnInit {
  @Input() adminMode: boolean = false;
  @Input() isGallery: boolean;
  @Input() seriesMode: boolean;
  @Output() changeSeriesState: EventEmitter<any> = new EventEmitter();
  @Output() photoSeriesSave: EventEmitter<any> = new EventEmitter();
  @Output() newSearchTerm: EventEmitter<any> = new EventEmitter();
  @Input() patient: Patient = null;
  PhotoTypeTag = TagType.photoType;
  @ViewChild('imageNameInput') imageNameInput: ElementRef;
  @Output() filterToggled: EventEmitter<any> = new EventEmitter();

  @Input() isFilterMode: boolean = false;
  private _currentPhoto: PhotoMetaData = null;
  isAvatar: boolean = false;
  set currentPhoto(currentPhoto) {
    if (this._currentPhoto && currentPhoto && currentPhoto.id != this._currentPhoto.id && this.imageNameInput) {
      this.imageNameInput.nativeElement.focus();
    }
    if (currentPhoto) {
      this._currentPhoto = new PhotoMetaData(currentPhoto);
      this.photoDate =
        this._currentPhoto && this._currentPhoto.dateTaken
          ? this._currentPhoto.dateTaken.toDate
            ? this._currentPhoto.dateTaken.toDate()
            : new Date(this._currentPhoto.dateTaken.toString())
          : null;
      this.isAvatar =
        this.patient &&
        this.patient.avatar &&
        this.patient.avatar.replace('/originals', '') == this._currentPhoto.filePath; //filePathOriginal corrupted in some cases so i left this as is
      // this._currentPhoto.addConsentTags();
      // if (this.isAvatar) this._currentPhoto.addAvatarTag();
    } else {
      this._currentPhoto = null;
      this.photoDate = null;
      this.isAvatar = false;
    }
  }

  get currentPhoto() {
    return this._currentPhoto;
  }
  photoDate: Date = null;
  CONSENT_TYPES = PhotoConsentType;
  CONSENT_TITLES = PhotoConsentType;
  consentMap;

  @Input()
  set clearToolbarSearch(clearToolbarSearch: boolean) {
    this._clearToolbarSearch = clearToolbarSearch;
    if (clearToolbarSearch) {
      this.selected = '';
    }
  }

  get clearToolbarSearch() {
    return this._clearToolbarSearch;
  }
  loading: boolean = false;
  _clearToolbarSearch: boolean;
  searchResults: any;
  tagList: Tag[];
  get filteredTagList() {
    if (this.currentPhoto && this.currentPhoto.tags && this.tagList) {
      return this.tagList.filter((tag) => this.currentPhoto.tags.findIndex((t) => t.tagId == tag.tagId) == -1);
    } else return [];
  }
  selected: string;
  unsub: Subject<void> = new Subject<void>();
  photoSeriesList: PhotoMetaData[] = [];
  editingImageTags = false;
  pillColours = this.tagService.pillColours;
  showEmailMessage = false;

  constructor(
    private tagService: TagService,
    private photoSeriesService: PhotoSeriesService,
    private photoEditingService: PhotoEditingService,
    private confirmDialog: MatDialog,
    private imageService: ImageService,
    private patientService: PatientService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.consentMap = Object.keys(PhotoConsentTitle).map((e) => ({
      title: PhotoConsentTitle[e],
      value: PhotoConsentType[e],
    }));
    this.tagService.getAllPhotoTags().subscribe((tags) => {
      this.tagList = tags;
    });
    this.photoSeriesService.photoSeriesSource$.pipe(takeUntil(this.unsub)).subscribe((res) => {
      this.photoSeriesList = res;
    });

    const extionsMap = {
      [PhotoEventType.LoadPhoto]: (photoEvent) => {
        this.currentPhoto = photoEvent.photo ? Object.assign({}, photoEvent.photo) : photoEvent.photo;
        if (this.currentPhoto && this.currentPhoto.isSeries) {
          this.photoSeriesList = this.currentPhoto.seriesPhotos ? this.currentPhoto.seriesPhotos : [];
        }
      },
      [PhotoEventType.ClearPhoto]: (photoEvent) => {
        this.currentPhoto = null;
      },
    };

    this.photoEditingService
      .getPhotoSource()
      .pipe(takeUntil(this.unsub), delay(0))
      .subscribe((photoEvent) => extionsMap[photoEvent.event](photoEvent));
  }

  public trackEmptyState() {
    if (this.selected === '') {
      this.newSearchTerm.emit(this.selected);
      this.editingImageTags = false;
    }
  }

  public removeSearchTerm() {
    this.selected = '';
    this.newSearchTerm.emit(this.selected);
    this.editingImageTags = false;
  }

  public tagSelected(event: TypeaheadMatch) {
    this.selected = '';
    this.newSearchTerm.emit(event.value);
    this.editingImageTags = false;
  }

  public uploadPhoto() {
    this.modalService.open(AddPhotoComponent, { windowClass: 'add-photo-modal', centered: true });
  }

  public createPhotoSeries() {
    this.changeSeriesState.emit({ state: true, type: SeriesType.Multi });
    this.editingImageTags = false;
  }

  public createBeforeAfter() {
    this.changeSeriesState.emit({ state: true, type: SeriesType.BeforeAfter });
    this.editingImageTags = false;
  }

  public onSave() {
    this.photoSeriesSave.emit(true);
    this.editingImageTags = false;
  }

  public onCancel() {
    this.changeSeriesState.emit({ state: false, type: SeriesType.BeforeAfter });
    this.editingImageTags = false;
  }

  public getCurrentPhotoTags(tags: Tag[]) {
    return tags.map((t) => t.title).join(', ');
  }

  public getSidenavWidth() {
    //hacky fix for now until we decide on a final layout
    return document.getElementById('photo-gallery-area').getBoundingClientRect().width;
  }

  resetUpdate: Subject<any> = new Subject<any>();
  public updateMetaData(debouncedTime: number = 750) {
    var currentPhoto = Object.assign({}, this.currentPhoto);
    if (!currentPhoto) return of();
    else if (!currentPhoto.tags) currentPhoto.tags = [];
    else {
      currentPhoto.tags = currentPhoto.tags
        .filter((tag) => tag.tagId.indexOf('Consent-') == -1)
        .filter((tag) => tag.tagId.indexOf('Avatar-') == -1);
    }

    if (currentPhoto.isSeries) {
      return this.photoSeriesService.updatePhotoSeries(currentPhoto).pipe(
        takeUntil(this.resetUpdate),
        debounceTime(debouncedTime),
        map((photo) => {
          this.loading = false;
          currentPhoto = new PhotoMetaData(currentPhoto);
          currentPhoto.tags = photo.tags;
          // currentPhoto.addConsentTags();
          this.currentPhoto = currentPhoto;
          return photo;
        })
      );
    } else {
      return this.imageService.uploadPhotoMetaData(currentPhoto).pipe(
        takeUntil(this.resetUpdate),
        debounceTime(debouncedTime),
        map((photo) => {
          this.loading = false;
          currentPhoto = new PhotoMetaData(currentPhoto);
          currentPhoto.tags = photo.tags;
          // currentPhoto.addConsentTags();
          this.currentPhoto = currentPhoto;
          return photo;
        })
      );
    }
  }

  public changedData() {
    this.resetUpdate.next();
    this.updateMetaData(1500).subscribe((photo) => {
      // console.log(photo)
    });
  }

  public photoDateChanged(event) {
    this.photoDate = event;
    this.currentPhoto.dateTaken = moment(this.photoDate);
    this.loading = true;
    this.updateMetaData().subscribe((photo) => {
      this.loading = false;
    });
  }

  async emailPhoto(currentPhoto: PhotoMetaData, email: string) {
    this.loading = true;
    var filename = !currentPhoto.isSeries
      ? currentPhoto.imageName
        ? currentPhoto.imageName
        : new Date().toISOString() + '_' + currentPhoto.patientId
      : (currentPhoto.seriesType == SeriesType.BeforeAfter ? 'Before-After_' : 'Multi-Image_') +
        (currentPhoto.imageName ? currentPhoto.imageName : currentPhoto.patientId);
    this.imageService.emailPhoto(email, currentPhoto.id, filename, currentPhoto.patientId).subscribe(() => {
      //email complete = we dont need to wait for it
    });

    this.showEmailMessage = true;
    setTimeout(() => {
      this.showEmailMessage = false;
    }, 3000);
    this.loading = false;
    // if (currentPhoto && currentPhoto.isSeries){
    // this.photoSeriesService.downloadPhotoSeries(currentPhoto)
    // .pipe(catchError(err => {
    //   console.log(err);
    //   this.loading = false;
    //   return throwError(null);
    // }))
    // .subscribe(async (dataUri: string) => {
    //   let blob: Blob = await fetch(dataUri).then(res => res.blob())
    //   var filename = (currentPhoto.seriesType == SeriesType.BeforeAfter ? "Before-After_" : "Multi-Image_" )+ (currentPhoto.imageName ? currentPhoto.imageName : currentPhoto.patientId) + '.png';

    // });
    // }
    // else{
    //   this.imageService.downloadSingleImage(currentPhoto)
    //   .pipe(catchError(err => {
    //     console.log(err);
    //     this.loading = false;
    //     return throwError(null);
    //   }))
    //   .subscribe(async (resp) => {
    //     let blob  = <Blob>resp.body;
    //     var filename = resp.headers.get('Filename-Emily');
    //     await this.imageService.emailPhoto(email, blob, filename).toPromise();
    //     this.loading = false;
    //   });
    // }
  }

  downloadPhoto(currentPhoto: PhotoMetaData) {
    if (currentPhoto && currentPhoto.isSeries) {
      this.downloadSeries(currentPhoto);
    } else if (currentPhoto) {
      this.loading = true;
      this.imageService.downloadSingleImage(currentPhoto).subscribe((resp) => {
        let blob = <Blob>resp.body;
        this.imageService.downloadImageLine(resp.headers.get('Filename-Emily'), URL.createObjectURL(blob));
        this.loading = false;
      });
    }
  }

  downloadSeries(series: PhotoMetaData) {
    this.loading = true;
    this.photoSeriesService
      .downloadPhotoSeries(series)
      .pipe(
        catchError((err) => {
          console.log(err);
          return throwError(null);
        })
      )
      .subscribe((dataUri: string) => {
        if (dataUri) {
          this.imageService.downloadImageLine(
            (series.seriesType == SeriesType.BeforeAfter ? 'Before-After_' : 'Multi-Image_') +
              (series.imageName ? series.imageName : series.patientId ? series.patientId : '') +
              '.png',
            dataUri
          );
          this.loading = false;
        }
      });
  }

  deletePhoto(photo: PhotoMetaData) {
    let dialogOpts = this.isAvatar
      ? {
          width: '250px',
          data: {
            extraMessage: "Caution: You are removing the patient's current avatar.",
          },
        }
      : {
          width: '250px',
        };
    const dialogRef = this.confirmDialog.open(ConfirmDeleteDialogComponent, dialogOpts);

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'delete') {
          let fxn = photo.isSeries
            ? this.photoSeriesService.deletePhotoSeries(photo.id, photo.patientId)
            : this.imageService.deletePhoto(photo);

          fxn.subscribe(
            () => {
              // const indexToUpdate = this.patient.photos.findIndex(p => p.id === photo.id);
              // this.patient.photos.splice(indexToUpdate, 1);
              this.photoEditingService.clearSelectedPhoto();

              if (this.isAvatar) {
                this.patientService.removePatientAvatar(photo.patientId).subscribe(() => {});
              }
            },
            (error) => {
              if (error && error.error) {
                const dialogRef = this.confirmDialog.open(GenericDialogComponent, {
                  width: '250px',
                  data: {
                    title: 'Warning',
                    content: `${error.error}`,
                    confirmButtonText: 'Ok',
                    showCancel: false,
                  },
                });

                dialogRef
                  .afterClosed()
                  .pipe(takeUntil(this.unsub))
                  .subscribe(() => {});
              }
            }
          );
        }
      });
  }

  favouriteToggle() {
    this.currentPhoto.isFavourite = !this.currentPhoto.isFavourite;
    this.imageService.setPhotoFavouriteStatus(this.currentPhoto, this.currentPhoto.isFavourite).subscribe((p) => {
      // console.log(p);
    });
  }
}
