<ngx-loading [show]="loading"></ngx-loading>
<div class="container p-0">
  <div *ngIf="errors?.length > 0" class="errors-wrapper">
    <ng-container *ngFor="let error of errors">
      <app-error *ngFor="let fieldError of error.fieldErrors" [message]="fieldError"></app-error>
    </ng-container>
  </div>
  <div class="search-container navy-background justify-content-center campaign-recipient-header-container">
    <h6 class="modal-title text-uppercase white-font text-center">{{ titleAndRecipients }}</h6>
  </div>

  <div class="campaign-recipient-container">
    <app-campaign-recipients-grid
      [skip]="skip"
      [details]="campaignRecipients"
    ></app-campaign-recipients-grid>
  </div>

  <!-- <div class="save-changes-container text-center">
    <button class="btn em-btn em-btn-green ml-2" (click)="closePanel.next()">Close</button>
  </div> -->
</div>
