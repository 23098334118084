<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>

<div [ngStyle]="{'height': 'calc(100dvh - 75px)'}">
  <kendo-grid
    [data]="gridView"
    [pageSize]="gridState.take"
    [skip]="gridState.skip"
    [sort]="gridState.sort"
    [sortable]="true"
    [selectable]="true"
    [style.height.%]="100"
    (add)="onAddClick($event)"
    (cancel)="cancelHandler($event)"
    (save)="saveHandler($event)"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)">
    <ng-template kendoGridToolbarTemplate>
      <div class="d-flex">
        <span class="pl-4 search-box border border-radius">
          <span class="fa fa-search"></span>
          <mat-form-field class="w-75 p-3">
            <input matInput placeholder="Search..." aria-label="Search Packages" [(ngModel)]="searchValue"
              [formControl]="searchCtrl">
            <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="searchValue=''">
              <mat-icon><i class="fa fa-times-circle"></i></mat-icon>
            </button>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option id="searchDropdown" *ngFor="let package of filteredPackages | async" [value]="package.name">
                <span>{{package.name}}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </span>
        <button kendoGridAddCommand class="btn kendo-btn"><i class="fal fa-plus-circle"></i></button>
      </div>
    </ng-template>
  
    <kendo-grid-column field="name" title="Name" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="totalOfIndividualPrices" title="Total Price" [width]="125">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{dataItem.totalOfIndividualPrices | currency}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="retailPrice" title="Retail Price" [width]="125">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{dataItem.retailPrice | currency}}</span>
      </ng-template>
    </kendo-grid-column>
  
    <kendo-grid-column field="packageProductsString" title="Product(s)/Service(s)" [width]="125"></kendo-grid-column>
    <kendo-grid-command-column title="Actions" min-width="125px" [width]="125">
      <ng-template kendoGridCellTemplate let-isNew="isNew">
        <button kendoGridEditCommand class="btn kendo-btn"><i class="fas fa-edit"></i></button>
        <button kendoGridRemoveCommand class="btn kendo-btn"><i class="fas fa-minus-circle"></i></button>
        <button kendoGridSaveCommand [disabled]="formGroup?.invalid">{{ isNew ? 'Add' : 'Update' }}</button>
        <button kendoGridCancelCommand>{{ isNew ? 'Discard changes' : 'Cancel' }}</button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>

<app-actionpanel></app-actionpanel>
