import { TargetType } from './target-type';
import { TransactionType } from './transaction-type';
import { ServiceNote } from '../service/service-note';
import { User } from '../user';

export class PatientTransaction {
  constructor(
    public id: number,
    public patientId: number,
    public targetId: number,
    public targetTypeId: number,
    public targetType: TargetType,
    public transactionTypeId: number,
    public transactionType: TransactionType,
    public description: string,
    public createDate: Date,
    public createdById: string,
    public createdBy: User
  ) {}
}

export class PatientTransactionServiceNote {
  PatientTransactionId: number;
  serviceNoteId: number;
  entryDate: Date;
  enteredBy: string;
  entryText: string;
  constructor() {}

  static getPtServiceNote(ptId: number, serviceNote: ServiceNote) {
    const ptServiceNote = new PatientTransactionServiceNote();

    ptServiceNote.PatientTransactionId = ptId;
    ptServiceNote.serviceNoteId = serviceNote.serviceNoteId;
    ptServiceNote.enteredBy = serviceNote.enteredBy;
    ptServiceNote.entryDate = serviceNote.entryDate;
    ptServiceNote.entryText = serviceNote.entryText;

    return ptServiceNote;

  }

  static getServiceNote(ptServniceNote: PatientTransactionServiceNote) {
    const serviceNote = new ServiceNote ({
      serviceNoteId: ptServniceNote.serviceNoteId,
      enteredBy: ptServniceNote.enteredBy,
      entryDate: ptServniceNote.entryDate,
      entryText: ptServniceNote.entryText
    });

    return serviceNote;
  }
}
