<ngx-loading [show]="loading"></ngx-loading>
<div *ngIf="errors?.length > 0" class="errors-wrapper">
  <ng-container *ngFor="let error of errors">
    <app-error *ngFor="let fieldError of error.fieldErrors" [message]="fieldError"></app-error>
  </ng-container>
</div>

<div class="row h-100 w-100 mx-0 px-2 py-4">
  <div class="h-100 col-xl-3 col-6 d-flex flex-column px-2">
    <div class="d-flex flex-column grey-three-border mb-3">
      <div class="d-flex account-balance">
        <div class="account-balance-icon d-flex align-items-center justify-content-center flex-shrink-0">
          <i class="far fa-donate"></i>
        </div>
        <div class="d-flex align-items-center justify-space-between w-100 px-3 py-3">
          ePOINTS:
          <span class="account-balance-value font-weight-bold ml-auto">
            {{ patientAvailableLoyaltyPoints?.value }} / {{ patientAvailableLoyaltyPoints?.dollarValue | currency }}
          </span>
        </div>
      </div>
      <div class="d-flex account-balance">
        <div class="account-balance-icon d-flex align-items-center justify-content-center flex-shrink-0">
          <i class="fas fa-dollar-sign"></i>
        </div>
        <div class="d-flex align-items-center justify-space-between uppercase w-100 px-3 py-3">
          Credits:<span class="account-balance-value font-weight-bold ml-auto">{{
            patientAvailableCredit | currency
          }}</span>
        </div>
      </div>
      <div class="d-flex flex-wrap p-3">
        <button
          type="button"
          class="schedNewCommButton t-transform-none d-flex align-items-center justify-content-center w-100 em-btn em-btn-green"
          (click)="convertLoyaltyPointsToCredit()"
          [disabled]="!(patientAvailableLoyaltyPoints?.value > 0)"
        >
          CONVERT ePOINTS TO CREDIT
        </button>
        <button
          type="button"
          class="schedNewCommButton d-flex align-items-center justify-content-center w-100 em-btn em-btn-green"
          (click)="openReturnModal()"
        >
          Refund
        </button>
        <button
          type="button"
          class="schedNewCommButton d-flex align-items-center justify-content-center w-100 em-btn em-btn-green"
          (click)="addNewInvoice(true)"
        >
          Add Credit
        </button>
        <button
          type="button"
          class="schedNewCommButton d-flex align-items-center justify-content-center w-100 em-btn em-btn-green"
          (click)="openMembershipInvoice()"
        >
          Add Membership
        </button>
        <button
          type="button"
          class="schedNewCommButton d-flex align-items-center justify-content-center w-100 em-btn em-btn-green"
          (click)="addNewInvoice(false)"
        >
          Create Invoice
        </button>
      </div>
    </div>

    <div class="d-flex flex-column flex-grow-1 minh-0 grey-three-border">
      <app-patient-membership-overview
        class="h-100"
        [patientId]="patientId"
        [allowCancellation]="true"
      ></app-patient-membership-overview>
    </div>
  </div>

  <div class="h-100 col-xl-6 col-12 main-column d-flex flex-column px-2">
    <div class="h-100 d-flex flex-column grey-three-border">
      <app-patient-invoice-history #history class="h-100 d-flex flex-column"></app-patient-invoice-history>
    </div>
  </div>

  <div class="h-100 col-xl-3 col-6 d-flex flex-column px-2">
    <div class="h-100 d-flex flex-column grey-three-border">
      <app-patient-transactions-list #transactions class="h-100"></app-patient-transactions-list>
    </div>
  </div>
</div>

<ng-template #returnModal let-dialogRef="dialogRef">
  <app-panel [dialogRef]="dialogRef" [isMedium]="true">
    <app-return-payment (invoicePay)="dialogRef.close()"></app-return-payment>
  </app-panel>
</ng-template>

<ng-template #creditUpdateModal let-dialogRef="dialogRef">
  <app-panel [dialogRef]="dialogRef">
    <app-update-credit
      (closePanel)="dialogRef.close()"
      [patientId]="patientId"
      [patientAvailableCredit]="patientAvailableCredit"
      (creditUpdated)="patientCreditUpdated($event); dialogRef.close()"
    ></app-update-credit>
  </app-panel>
</ng-template>
