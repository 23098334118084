import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.less'],
})
export class PanelComponent implements OnInit {
  @Input() dialogRef: MatDialogRef<any>;
  @Input() isHuge: boolean;
  @Input() isMedium: boolean;
  @Output() isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }
}
