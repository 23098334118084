<div class="edit-container position-absolute d-block z-index-3 white-background">
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0;">
    <div class="row mx-0 teal-background">
      <div class="col px-2">
        <h6 class="white-font uppercase p-2 mb-0">
          {{addOrEdit}} Package
        </h6>
      </div>
    </div>
    <div class="row mx-0 mt-2">
      <div class="col px-2">
        <mat-form-field>
          <input required matInput placeholder="Package Name" class="capitalized" aria-label="Package Name"
            (change)="formatterService.textCapitalize(name)" [(ngModel)]="package.name" [formControl]="name">
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input type="number" matInput disabled placeholder="Total of Prices (including Taxes)" aria-label="Total of Prices"
            [(ngModel)]="package.totalOfIndividualPrices">
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input type="number" matInput placeholder="Retail Price" aria-label="Retail Price" [(ngModel)]="package.retailPrice"
            [formControl]="retailPrice">
        </mat-form-field>
      </div>
      <div class="col px-2">
        <kendo-multiselect placeholder="Taxes" aria-label="Taxes"
          [data]="taxes" [valuePrimitive]="false" [(ngModel)]="selectedTaxes" textField="name" valueField="value"
          (valueChange)="updateTotalOfPrices()">
        </kendo-multiselect>
      </div>
    </div>

    <div class="row mx-0 mt-2 teal-background">
      <div class="col px-2">
        <h6 class="white-font uppercase p-2 mb-0">
          Products
        </h6>
      </div>
    </div>
    <div class="row mt-2 mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input id="productsInput" type="text" matInput [formControl]="selectedProduct"
            placeholder="Search for a product" [matAutocomplete]="products" />
        </mat-form-field>
        <mat-autocomplete #products="matAutocomplete" [displayWith]="productDisplayFn">
          <mat-option *ngFor="let product of filteredProducts | async" [value]="product">
            {{ product.displayName }}
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="col px-2">
        <button class="btn em-btn em-btn-green" (click)="addProductToPackage()">Add Product</button>
      </div>
    </div>
    <div *ngFor="let product of selectedProducts" class="row mx-0">
      <div class="col px-2">
        {{product.clinicProduct ? product.clinicProduct.displayName : ""}}
      </div>
      <div class="col px-2">
        ${{product.clinicProduct ? product.clinicProduct.retailPrice : ""}}
      </div>
      <div class="col px-2">
        <input class="no-spin" type="number" [(ngModel)]="product.productQuantity"
          (change)="updatePackageProductQuantity(product)">
      </div>
      <div class="col px-2">
        <button class="btn p-0" (click)="removeProductFromPackage(product.clinicProduct)"><i class="fa fa-times-circle"></i></button>
      </div>
    </div>
    <div class="row mx-0 mt-2 teal-background">
      <div class="col px-2">
        <h6 class="white-font uppercase p-2 mb-0">
          Services
        </h6>
      </div>
    </div>
    <div class="row mt-2 mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input id="servicesInput" type="text" matInput [formControl]="selectedService"
            placeholder="Search for a service" [matAutocomplete]="services" />
        </mat-form-field>
        <mat-autocomplete #services="matAutocomplete" [displayWith]="serviceDisplayFn">
          <mat-option *ngFor="let service of filteredServices | async" [value]="service">
            {{ service.serviceName }}
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="col px-2">
        <button class="btn em-btn em-btn-green" (click)="addServiceToPackage()">Add Service</button>
      </div>
    </div>
    <div *ngFor="let packageService of selectedServices" class="row mx-0">
      <div class="col px-2">
        {{packageService.service.serviceName}}
      </div>
      <div class="col px-2">
        ${{packageService.service.defaultPrice}}
      </div>
      <div class="col px-2">
        <input
          class="no-spin"
          type="number"
          [(ngModel)]="packageService.serviceQuantity"
          (change)="updatePackageServiceQuantity(packageService)">
      </div>
      <div class="col px-2">
        <button class="btn p-0" (click)="removeServiceFromPackage(packageService.service)">
          <i class="fa fa-times-circle"></i>
        </button>
      </div>
    </div>
    <div class="row mx-0 pt-2">
      <div class="col text-center">
        <button class="btn em-btn em-btn-green" [disabled]="package.name === ''"
          (click)="updatePackage()">{{addOrEdit == 'Add' ? 'Add' : 'Update'}}
          Package</button>
      </div>
      <div class="col text-center">
        <button class="btn em-btn em-btn-green" (click)="cancelUpdate()">Cancel</button>
      </div>
    </div>
  </perfect-scrollbar>
</div>
