import { PhotoConsentType } from '@models/photo/photo-consent-type';
import { Component, OnInit, Input } from '@angular/core';
import { PhotoEditingService } from '@app/services/photo-editing.service';
import { PhotoMetaData } from '@app/models/photo/photo-meta-data';
import * as moment from 'moment';
import { SeriesType } from '@models/photo/series-type';

@Component({
  selector: 'app-admin-gallery',
  templateUrl: './admin-gallery.component.html',
  styleUrls: ['./admin-gallery.component.less']
})
export class AdminGalleryComponent implements OnInit {
  SeriesType = SeriesType;
  _adminPhotos: any[];
  selectedPhoto: PhotoMetaData;
  photoConsentType = PhotoConsentType;
  _showStockImages: boolean = false;
  @Input() set showStockImages(showStockImages: boolean) {
    this._showStockImages = showStockImages;  
  }
  get showStockImages(){
    return this._showStockImages;
  }
  @Input()
  set adminPhotos(adminPhotos) {
    this._adminPhotos = adminPhotos;
    if (this._adminPhotos.length) {
      if (adminPhotos.length > 0) {
        let photo = this._adminPhotos.find(p => this.showStockImages ? true : !p.isStockPhoto)
        this.updateSelectedPhoto(photo); 
      } 
    } else {
      this.updateSelectedPhoto(null);
    }
  }

  get adminPhotos() {
    return this._adminPhotos;
  }

  constructor(
    private photoEditingService: PhotoEditingService,
  ) { }

  ngOnInit() {
    
  }

  public imageClicked(item: PhotoMetaData) {
    this.selectedPhoto = item;
    this.updateSelectedPhoto(item);
  }

  private updateSelectedPhoto(item?: PhotoMetaData) {
    this.photoEditingService.loadPhoto(item);
    this.selectedPhoto = item;
  }

  public getUploadDatestr(photo: PhotoMetaData) {
    return moment(photo.uploadDate).format('MMMM DD, YYYY');
  }
}
