import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { Subject, zip } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isNullOrUndefined } from '@app/shared/helpers';

import { PatientService } from '@services/patient.service';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { TreatmentPlanFormService } from '@services/treatment-planning/treatment-plan-form.service';
import { CoolsculptingFormService } from '@services/service-detail/coolsculpting-form.service';
import { ObservationService } from '@services/observation.service';
import { ObservationListItemsService } from '@services/observation-list-items.service';
import { PlottingEventService } from '@services/plotting-event.service';
import { CurrentDataService } from '@services/currentData.service';

import { TreatmentPlan } from '@models/treatment-planning/treatment-plan';
import { Patient } from '@models/patient';
import { Observation, ObservationListItem, ObservationListStatic } from '@models/observation/observation';
import { PlannedTreatment } from '@models/treatment-planning/planned-treatment';
import { PaymentStatus } from '@models/appointments/payment-status';

@Component({
  selector: 'app-assessment-feed',
  templateUrl: './assessment-feed.component.html',
  styleUrls: ['./assessment-feed.component.less'],
})
export class AssessmentFeedComponent implements OnInit, OnDestroy {
  @Input() serviceTemplateId: number;
  @Input() serviceId: number;
  @Input() treatmentPlanning: boolean;

  private treatmentPlan: TreatmentPlan;
  private patient: Patient;
  private unsub: Subject<void>;
  // pt.id -> (obrGroup -> (obr.id, obr))
  public assessmentMap: Map<number, Map<string, Map<number, Observation>>>;
  // pt.id -> pt
  public plannedTreatmentMap: Map<number, PlannedTreatment>;
  // obr.id -> pt.id
  private obrToPlannedTreatmentMap: Map<number, number>;
  // obr.id -> obr
  private obrsToSave: Map<number, Observation>;
  public loading: boolean;
  private obrOriginalMap: Map<number, Observation>;
  maxScrollHeight: number = 0;
  @Input() associatedPlannedTreatment: PlannedTreatment;

  PaymentStatus = PaymentStatus;

  constructor(
    private patientService: PatientService,
    private treatmentPlanService: TreatmentPlanService,
    private treatmentPlanFormService: TreatmentPlanFormService,
    public coolsculptingFormService: CoolsculptingFormService,
    private observationService: ObservationService,
    private observationListItemsService: ObservationListItemsService,
    private plottingEventService: PlottingEventService,
    private currentDataService: CurrentDataService
  ) {
    this.unsub = new Subject();
    this.patient = this.patientService.patientPanelPatient;
    this.assessmentMap = new Map();
    this.plannedTreatmentMap = new Map();
    this.obrToPlannedTreatmentMap = new Map();
    this.obrsToSave = new Map();
    this.obrOriginalMap = new Map();
    this.loading = false;
  }

  ngOnInit() {
    this.onFilterPlannedTreatments();

    this.currentDataService.serviceDetailSectionHeightUpdate$.subscribe((height) => {
      this.maxScrollHeight = height / 3;
    });
    this.patientService.thePatientUpdated$.subscribe((patient) => {
      this.patient = patient;

      this.treatmentPlanService.getTreatmentPlanByPatientId(this.patient.patientId).subscribe((tp: TreatmentPlan) => {
        this.treatmentPlan = Object.assign({}, tp);
        this.onFilterPlannedTreatments();
      });
    });

    this.treatmentPlanService.getTreatmentPlanByPatientId(this.patient.patientId).subscribe((tp: TreatmentPlan) => {
      this.treatmentPlan = Object.assign({}, tp);
      this.onFilterPlannedTreatments();
    });

    this.treatmentPlanService.treatmentPlanUpdated$.pipe(takeUntil(this.unsub)).subscribe((tp) => {
      tp = Object.assign({}, tp);
      this.treatmentPlan = this.treatmentPlanFormService.onCleanTreatmentPlan(tp);
      this.onFilterPlannedTreatments();
    });

    this.coolsculptingFormService.treatmentSaved
      .pipe(takeUntil(this.unsub))
      .subscribe(() => this.onSaveAssessmentChanges());
    this.coolsculptingFormService.treatmentRemoved
      .pipe(takeUntil(this.unsub))
      .subscribe((obrId: number) => this.onTreatmentRemoved(obrId));
  }

  private onFilterPlannedTreatments() {
    if (!isNullOrUndefined(this.serviceTemplateId) && !isNullOrUndefined(this.treatmentPlan)) {
      this.treatmentPlan.plannedTreatments = this.treatmentPlan.plannedTreatments.filter((pt) => {
        return (
          pt.service.templateId === this.serviceTemplateId &&
          (this.associatedPlannedTreatment ? pt.id == this.associatedPlannedTreatment.id : true)
        );
      });
      this.onInitAssessmentMap();
    }
  }

  private onInitAssessmentMap() {
    this.loading = true;
    this.assessmentMap.clear();

    this.treatmentPlan.plannedTreatments.forEach((pt) => {
      this.plannedTreatmentMap.set(pt.id, pt);
      this.assessmentMap.set(pt.id, new Map());
      pt.service.observations.forEach((obr) => {
        this.obrToPlannedTreatmentMap.set(obr.id, pt.id);
        this.obrOriginalMap.set(obr.id, JSON.parse(JSON.stringify(obr)));

        const mapKey = this.coolsculptingFormService.onGetMapKey(obr);
        if (isNullOrUndefined(this.assessmentMap.get(pt.id).get(mapKey))) {
          this.assessmentMap.get(pt.id).set(mapKey, new Map());
        }
        this.assessmentMap.get(pt.id).get(mapKey).set(obr.id, obr);
      });
    });
    this.loading = false;
  }

  public onGetCycles(plannedTreatmentId: number, treatmentKey: string): number {
    return this.coolsculptingFormService.onGetCycles(
      Array.from(this.assessmentMap.get(plannedTreatmentId).get(treatmentKey).values())
    );
  }

  public onGetAvaliableCycles(plannedTreatmentId: number, treatmentKey: string): number {
    let avaliableCycles = 0;
    this.assessmentMap
      .get(plannedTreatmentId)
      .get(treatmentKey)
      .forEach((obr: Observation) => {
        avaliableCycles += obr.details.avaliable;
      });

    return avaliableCycles;
  }

  onGetCircleColor(plannedTreatmentId: number, treatmentKey: string) {
    try {
      return Array.from(this.assessmentMap.get(plannedTreatmentId).get(treatmentKey).values())[0].details.plotDetails
        .pointColor;
    } catch (e) {
      return 'orange';
    }
  }

  public onApplyTreatment(plannedTreatmentId: number, treatmentKey: string) {
    // Grab the first observation that has avaliable cycles and remove an avaliable cycle
    let plotDetails: any;
    let completed = false;
    // First we need to find the plot details for the treatment
    this.assessmentMap
      .get(plannedTreatmentId)
      .get(treatmentKey)
      .forEach((obr: Observation) => {
        if (!completed) {
          if (obr.details.plotDetails.leafletId > 0) {
            plotDetails = JSON.parse(JSON.stringify(obr.details.plotDetails));
            plotDetails.leafletId = 0;
            completed = true;
          }
        }
      });
    completed = false;

    this.assessmentMap
      .get(plannedTreatmentId)
      .get(treatmentKey)
      .forEach((obr: Observation) => {
        if (!completed) {
          // Grab the first observation that has avaliable cycles and remove a cycle
          if (obr.details.avaliable > 0) {
            obr.details.avaliable -= 1;
            this.obrsToSave.set(obr.id, obr);

            const newObr = JSON.parse(JSON.stringify(obr));
            newObr.id = 0;
            newObr.details.avaliable = 0;
            newObr.value = 1;
            newObr.serviceId = this.serviceId;
            newObr.details.plotDetails = plotDetails;
            newObr.details.treatmentReference = obr.id;

            this.coolsculptingFormService.droppingPins = true;
            this.observationListItemsService.assignPriceToObservation(newObr);
            this.plottingEventService.startPinDrop(newObr);

            completed = true;
          }
        }
      });
  }

  private onSaveAssessmentChanges() {
    const obrs = JSON.parse(JSON.stringify(Array.from(this.obrsToSave.values())));
    this.observationService.addObservations(obrs, null).subscribe(() => {
      this.obrsToSave.clear();
      // Update the current patient's observations and the treatment plan
      zip(
        this.observationService.getAllObservationsByPatientId(this.patient.patientId),
        this.treatmentPlanService.getTreatmentPlanByPatientId(this.patient.patientId)
      ).subscribe(([observations]) => {
        this.patient.observations = observations;
        this.patientService.thePatientHasBeenUpdated(this.patient);
      });
    });
  }

  private onTreatmentRemoved(obrId: number) {
    // When a treatment is removed we need to update the treatment plan
    const plannedTreatmentId = this.obrToPlannedTreatmentMap.get(obrId);
    let originalObr: Observation = this.obrOriginalMap.get(obrId);
    const mapKey = this.coolsculptingFormService.onGetMapKey(originalObr);
    console.log(this.coolsculptingFormService.onGetCycles([originalObr]));
    originalObr.details.avaliable = this.coolsculptingFormService.onGetCycles([originalObr]);

    this.obrsToSave.set(obrId, originalObr);
    this.assessmentMap
      .get(plannedTreatmentId)
      .get(mapKey)
      .set(obrId, JSON.parse(JSON.stringify(originalObr)));
  }

  public onGetAreaDisplayName(mapKey: string): string {
    const displayName = new ObservationListStatic(this.coolsculptingFormService.onGetArea(mapKey)).name;
    return displayName;
  }

  public onGetApplicatorDisplayName(mapKey: string): string {
    const displayName = new ObservationListItem(this.coolsculptingFormService.onGetApplicator(mapKey)).displayName;

    return displayName;
  }

  onGetObservations(plannedTreatmentId: number, mapKey: string) {
    const obrs = Array.from(this.assessmentMap.get(plannedTreatmentId).get(mapKey).values());
    return obrs;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
