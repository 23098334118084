<ngx-loading [show]="loading"></ngx-loading>
<div class="row justify-content-center"  #contain *ngIf="photo">
  <div class="col text-center h-100 pr-4">
    <ng-container *ngIf="editMode && imageURL; else previewMode">
      <!-- <custom-ngx-image-editor
        [undoEvent]="undoPhotoEdit.asObservable()"
        [config]="photoEditorConfig"
        (file)="getEditedFile($event)"
        (cropped)="imageCropped()">
      </custom-ngx-image-editor> -->
      <div class="h-100">
        <div >
          <button class="btn em-btn py-0 px-2 em-btn-green" (click)="rotateLeft()">
            <i class="fas fa-undo"></i>
          </button>
          <button class="btn em-btn py-0 px-2 em-btn-green ml-2" (click)="rotateRight()">
            <i class="fas fa-redo"></i>
          </button>
          <app-photo-rotation
            [imageBase64]="imageURL"
            [maintainAspectRatio]="false"
            [containWithinAspectRatio]="containWithinAspectRatio"
            [aspectRatio]="1 / 1"
            [cropperMinWidth]="128"
            [onlyScaleDown]="true"
            [roundCropper]="false"
            [canvasRotation]="canvasRotation"
            [transform]="transform"
            [style.display]="showCropper ? null : 'none'"
            format="png"
            [disabled]="true"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady($event)"
            (loadImageFailed)="loadImageFailed()"
          ></app-photo-rotation>
         
        </div>
      </div>
    </ng-container>
    <ng-template #previewMode>
      <div class="d-flex align-items-center h-100 justify-content-center">
        <img  
        appDetermineMaxScrollHeight [itemsToWatch]="[photo]"
        (load)="loading=false"
          class="mw-100 h-auto"
          style="cursor: pointer;"
          src={{photo.filePath}}
          alt="{{photo?.imageName}}"
          (click)="photoClicked()">

          <!-- <div    (click)="photoClicked()"   style="background-repeat: no-repeat; background-position: bottom; background-size: contain; max-width:100%; height:auto" [ngStyle]="{'background-image': 'url(' + photo?.filePath + ')'}"> </div> -->
       
      </div>
    </ng-template>
  </div>
</div>