import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { isNullOrUndefined } from '@app/shared/helpers';
import { EmailNotificationsSettings } from '@models/communications/email-notifications-settings.model';
import { MailjetEmailTemplate } from '@models/communications/mailjet-email-template.model';
import { CommunicationSettingType } from '@models/communications/notifications-settings-base.model';
import { SmsNotificationsSettings } from '@models/communications/sms-notifications-settings.model';
import { TestEmail } from '@models/communications/test-email.model';
import { TestSms } from '@models/communications/test-sms.model';
import { CommunicationsService } from '@services/communications.service';
import { UsersService } from '@services/users.service';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-send-test-visit-reminder',
  templateUrl: './send-test-visit-reminder.component.html',
  styleUrls: ['./send-test-visit-reminder.component.less'],
})
export class SendTestVisitReminderComponent implements OnInit, OnDestroy {
  private unsub: Subject<void> = new Subject<void>();
  loading: boolean;
  type: string;
  reminderSettingsForm: FormGroup;
  emailTemplates: MailjetEmailTemplate[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private communicationsService: CommunicationsService,
    private userService: UsersService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      this.type = params['type'];
      this.initForm(this.type);
      this.populateCommunicationSettingsValue(this.type);
    });
    this.reminderSettingsForm.controls['emailRecipientName'].setValue(
      this.userService.loggedInUser.firstName + ' ' + this.userService.loggedInUser.lastName
    );
    this.reminderSettingsForm.controls['emailRecipientEmail'].setValue(this.userService.loggedInUser.email);
    this.reminderSettingsForm.controls['recipientNumber'].setValue(this.userService.loggedInUser.phoneNumber);
  }

  initForm(type: string) {
    this.reminderSettingsForm = new FormGroup({
      emailTemplate: new FormControl(
        '',
        this.conditionalValidator(() => type === 'email', Validators.required)
      ),
      emailSenderName: new FormControl(
        '',
        this.conditionalValidator(() => type === 'email', Validators.required)
      ),
      emailSenderEmail: new FormControl('', [
        Validators.email,
        this.conditionalValidator(() => type === 'email', Validators.required),
      ]),
      emailRecipientName: new FormControl(
        '',
        this.conditionalValidator(() => type === 'email', Validators.required)
      ),
      emailRecipientEmail: new FormControl('', [
        Validators.email,
        this.conditionalValidator(() => type === 'email', Validators.required),
      ]),
      smsMessage: new FormControl(
        '',
        this.conditionalValidator(() => type === 'sms', Validators.required)
      ),
      recipientNumber: new FormControl(
        '',
        this.conditionalValidator(() => type === 'sms', Validators.required)
      ),
    });
  }

  conditionalValidator(condition: () => boolean, validator: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!condition()) {
        return null;
      }
      return validator(control);
    };
  }

  populateCommunicationSettingsValue(type: string) {
    this.loading = true;
    if (type === 'email') {
      forkJoin([
        this.communicationsService.getEmailTemplates(),
        this.communicationsService.getEmailNotificationsSettings(),
      ])
        .pipe(takeUntil(this.unsub))
        .subscribe(([templates, settings]) => {
          let reminderSettings = settings.find(
            (es) => es.communicationSettingType === CommunicationSettingType.Reminder
          );
          this.populateEmailSettingsValues(templates, reminderSettings);
          this.loading = false;
        });
    } else if (type === 'sms') {
      this.communicationsService
        .getSmsNotificationsSettings()
        .pipe(takeUntil(this.unsub))
        .subscribe((settings) => {
          this.populateSmsSettings(settings);
          this.loading = false;
        });
    }
  }

  populateEmailSettingsValues(templates: MailjetEmailTemplate[], settings: EmailNotificationsSettings) {
    this.emailTemplates = templates;
    let selectedTemplate = this.emailTemplates.find((_) => _.id === settings.templateReferenceId);
    if (isNullOrUndefined(selectedTemplate) && templates.length > 0) {
      selectedTemplate = templates[0];
    }
    this.reminderSettingsForm.controls['emailTemplate'].setValue(selectedTemplate);
    this.reminderSettingsForm.controls['emailSenderName'].setValue(settings.senderName);
    this.reminderSettingsForm.controls['emailSenderEmail'].setValue(settings.senderEmail);
  }

  populateSmsSettings(settings: SmsNotificationsSettings) {
    var userPhoneNumber = this.userService.loggedInUser.phoneNumber;
    this.reminderSettingsForm.controls['smsMessage'].setValue(settings.message);
    this.reminderSettingsForm.controls['recipientNumber'].setValue(userPhoneNumber);
  }

  emailFormData() {
    const data = new TestEmail();
    data.templateReferenceId = (this.reminderSettingsForm.controls['emailTemplate'].value as MailjetEmailTemplate).id;
    data.senderName = this.reminderSettingsForm.controls['emailSenderName'].value;
    data.senderEmail = this.reminderSettingsForm.controls['emailSenderEmail'].value;
    data.recipientName = this.reminderSettingsForm.controls['emailRecipientName'].value;
    data.recipientEmail = this.reminderSettingsForm.controls['emailRecipientEmail'].value;
    return data;
  }

  private smsFormData() {
    const data: TestSms = {
      userId: this.userService.loggedInUser.id,
      message: this.reminderSettingsForm.controls['smsMessage'].value,
      recipientNumber: this.reminderSettingsForm.controls['recipientNumber'].value,
    };
    return data;
  }

  cancelUpdate() {
    this.communicationsService.refreshRequired = false;
    this.communicationsService.communicationsSettingsUpdated.next();
    this.router.navigate(['/management/communication/appointments/', { outlets: { 'action-panel': null } }]);
  }

  sendTest() {
    const typeString = this.type == 'sms' ? 'SMS' : 'email';
    const successDialogData = {
      width: '250px',
      data: {
        showCancel: false,
        title: 'Complete',
        content: `Test ${typeString} sent successfully...`,
        confirmButtonText: 'Ok',
      },
    };
    const failureDialogData = {
      width: '250px',
      data: {
        showCancel: false,
        title: 'Error',
        content: `Test ${typeString} was not sent. Please report this issue.`,
        confirmButtonText: 'Ok',
      },
    };

    if (this.type === 'email') {
      this.communicationsService.sendTestEmail(this.emailFormData()).subscribe(
        () => {
          this.dialog.open(GenericDialogComponent, successDialogData);
        },
        () => {
          this.dialog.open(GenericDialogComponent, failureDialogData);
        }
      );
    } else if (this.type === 'sms') {
      this.communicationsService.sendTestSms(this.smsFormData()).subscribe(
        () => {
          this.dialog.open(GenericDialogComponent, successDialogData);
        },
        () => {
          this.dialog.open(GenericDialogComponent, failureDialogData);
        }
      );
    }
    this.cancelUpdate();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
