import { globalIncludes } from './global-includes';
import { PhysicianData } from './../management/clinic-documents/eform-builder/eform-custom-data/physician-data';
import { ClinicData } from './../management/clinic-documents/eform-builder/eform-custom-data/clinic-data';
import { PatientData } from './../management/clinic-documents/eform-builder/eform-custom-data/patient-data';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http'; 
import { map, switchMap, catchError } from 'rxjs/operators';
import { environment } from '@environments/environment';

import { Subject, combineLatest, zip, of, Observable } from 'rxjs';
import { ClinicDocument } from '@models/document/clinic-document';
import { BlobService } from './blob.service';
import { removeURIQueryString } from '../lib/fun';
import { Tag } from '@models/tag/tag';
import * as moment from 'moment';
/**
 * Currently in this app as proof of concept ONLY - must be removed for production:
 * - (getHtmlPdfFromStorage) The shadow document that is created for downloading/printing must be moved to package or backend process
 * - (uploadHtmlPdfToStorage) The editing of style in the shadow document to manipulate checkboxes being placed too low
 */
@Injectable({
  providedIn: 'root'
})
export class ClinicDocumentsService {
  docsLoaded = new Subject<ClinicDocument[]>();
  docSelected = new Subject<ClinicDocument>();
  docsChanged = new Subject<ClinicDocument[]>();

  docChangeCancelled = new Subject();

  clinicDocs: ClinicDocument[];
  defaultId = 0;

  constructor(
    private http: HttpClient,
    private blobService: BlobService ) { }

  getDocuments() {
    return combineLatest(
      this.blobService.getReadOnlySASObservable(),
      this.http.get<ClinicDocument[]>(environment.baseUrl + 'api/ClinicDocuments')
    ).pipe(
      map(([readOnlySAS, docs]) => {
        // add the SAS to the document URI
        docs = docs.map(doc => {
          doc = new ClinicDocument(doc);
          doc.filePath =( doc.filePath ?  doc.filePath.trim() : '') + readOnlySAS;
          if (doc.pdfToHtmlUrl) {
            doc.pdfToHtmlUrl =( removeURIQueryString(doc.pdfToHtmlUrl) ?  removeURIQueryString(doc.pdfToHtmlUrl).trim() : '') + readOnlySAS;
          }
          if (doc && doc.modifiedDate && typeof(doc.modifiedDate) == 'string'){
            doc.modifiedDate = new Date(doc.modifiedDate);
          }
          if (doc && doc.uploadDate && typeof(doc.uploadDate) == 'string'){
            doc.uploadDate = new Date(doc.uploadDate);
          }
        

          return doc;
        });

        this.clinicDocs = docs.sort(this.sortByName);
        return docs;
      })
    );
  }

  getDocument(docId: number) {
    return combineLatest(
      this.blobService.getReadOnlySASObservable(),
      this.http.get<ClinicDocument>(environment.baseUrl + 'api/ClinicDocuments/' + docId)
    ).pipe(
      map(([readOnlySAS, doc]) => {
        doc.filePath =( doc.filePath ?  doc.filePath.trim() : '') + readOnlySAS;
        if (doc.pdfToHtmlUrl) {
          doc.pdfToHtmlUrl =( removeURIQueryString(doc.pdfToHtmlUrl) ?  removeURIQueryString(doc.pdfToHtmlUrl).trim() : '') + readOnlySAS;
        }
        if (doc && doc.modifiedDate && typeof(doc.modifiedDate) == 'string'){
          doc.modifiedDate = new Date(doc.modifiedDate);
        }
        if (doc && doc.uploadDate && typeof(doc.uploadDate) == 'string'){
          doc.uploadDate = new Date(doc.uploadDate);
        }
        return doc;
      })
    );
  }

  getLoadedDocument(id: number): ClinicDocument {
    if (this.clinicDocs) {
      const itemIndex = this.clinicDocs.findIndex(doc => doc.id === id);
      return this.clinicDocs[itemIndex];
    }
    return null;
  }

  restoreTags(id: number, tags: Tag[]) {
    const itemIndex = this.clinicDocs.findIndex(doc => doc.id === id);
    const doc = this.clinicDocs[itemIndex];
    if (doc) {
      doc.tags = tags;
    }
  }

  updateDocument(clinicDocument: ClinicDocument) {
    clinicDocument.filePath = removeURIQueryString(clinicDocument.filePath);
    if (clinicDocument.pdfToHtmlUrl) {
      clinicDocument.pdfToHtmlUrl = removeURIQueryString(clinicDocument.pdfToHtmlUrl);
    }
    return combineLatest(
      this.blobService.getReadOnlySASObservable(),
      this.http
        .put<ClinicDocument>(environment.baseUrl + 'api/ClinicDocuments/' + clinicDocument.id, clinicDocument)
    ).pipe(
      map(([readOnlySAS, updatedDoc]) => {
        if (this.clinicDocs) {
          const itemIndex = this.clinicDocs.findIndex(doc => doc.id === clinicDocument.id);
          if (itemIndex !== -1) {
            updatedDoc.filePath =( updatedDoc.filePath ?  updatedDoc.filePath.trim() : '') + readOnlySAS;
            if (updatedDoc.pdfToHtmlUrl) {
              updatedDoc.pdfToHtmlUrl =( removeURIQueryString(updatedDoc.pdfToHtmlUrl) ?  removeURIQueryString(updatedDoc.pdfToHtmlUrl).trim() : '') + readOnlySAS;
            }
            this.clinicDocs[itemIndex] = updatedDoc;
            this.docsChanged.next(this.clinicDocs.sort(this.sortByName).slice());
          }
        }
        return updatedDoc;
      })
    );
  }
 
  //Each of script and styles are applied to the <head> of the html generated from the document pdfs from global-includes
  /**
   * <meta name="emily-custom" content="start"> IS THE START TAG
   * 1. jspdf import: for converting base64 png of generated document into a PDF
   * 2. html2canvas import: for converting the generated PDF plus user submission into a canvas element and exported as PNG
   * 3. print styling: extra custom style that allows print to occur nicely
   * 4. iframe message hooks: custom hooks for printing the window, and for initiating a document generation+download as pdf
   * 5. downloadMe: is the funciton called by aformentioned hook tasked with creating the pdf
   * 6. Font family: arial
   * 7. Font size is fixed except for checkboxes which require a larger font
   * 8. Text area line height is fixed to 1x
   * 9. Checkbox location fixed
   * <meta name="emily-custom" content="end"> IS ALWAYS THE END TAG BUT IT IS ADDED BY THE SCRIPT OR THE FRONT_END AFTER GENERATING CUSTOM STYLES FOR CUSTOM COMPONENTS
   */
  uploadHtmlPdfToStorage(doc: ClinicDocument) {
    let keys = [];
    Object.keys(PatientData.components).forEach(key =>  keys.push(PatientData.components[key].schema.label));
    Object.keys(PhysicianData.components).forEach(key =>  keys.push(PhysicianData.components[key].schema.label));
    Object.keys(ClinicData.components).forEach(key =>  keys.push(ClinicData.components[key].schema.label));


    let include = '<style>';
    keys.forEach((key, index) => {
      include += "div[customLabel='" + key + "']{visibility:hidden!important;}div[customLabel='" + key + "']:before{content:'" + key + "';visibility:visible!important;background-color:#ffe4b5;width:100%;position:absolute;left:0;height:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}";
    });

    let includeForScript = include; //for pausing on breakpoint and adding to az-storage-script-update
    keys = ['Full Physician Name', 'Physician First Name', 'Physician Last Name', 'Physician Full Name' , 'Physician Name']
    keys.forEach((key, index) => {
      includeForScript +=  "div[customLabel='" + key + "']{visibility:hidden!important;}div[customLabel='" + key + "']:before{content:'" + key + "';visibility:visible!important;background-color:#ffe4b5;width:100%;position:absolute;left:0;height:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}";
    });


    includeForScript += '</style>';
    include += '</style>';
    let completeInclude = globalIncludes + include + '<meta name="emily-custom" content="end">';
    return this.http.get(doc.pdfToHtmlUrl,{responseType: 'text'})
    .pipe(map((response: string) =>  response.replace(/<meta name=\"emily-custom\" content=\"start\">.*?<meta name=\"emily-custom\" content=\"end\">/g, '')))
    .pipe(map(response =>  [response.slice(0,response.indexOf('</head>') ), completeInclude, response.slice(response.indexOf('</head>'))].join('')))
    .pipe(map(response => response.replace('this.wrapper.style.height="".concat(this.wrapperRect.height,"px")','Array.from(this.wrapper.firstChild.classList).indexOf("drag-resize-content-textarea") != -1 || Array.from(this.wrapper.firstChild.classList).indexOf("drag-resize-content-signature") != -1 ? (this.wrapper.style.height = "".concat( this.wrapperRect.height, "px" )) : undefined;')))
    .pipe(map(response => response.replace('class:"drag-resize-content drag-resize-content-".concat(this.component.type)})','customLabel: this.component.label,class:"drag-resize-content drag-resize-content-".concat(this.component.type)})')))
    .pipe(map(response => new Blob([response], { type : 'text/html' })))
      .pipe(
        switchMap((file: File) => {
          return this.blobService.uploadFileToBlobStorage(file, `clinic/document/${doc.file.name}.${Date.now()}.html`, {
            progress: progress => {
              // TODO: Do something with percent if you want
              const percent = progress.loadedBytes / doc.file.size;
            }, blobHTTPHeaders: { blobContentType: 'text/html; charset=utf-8'}
        });
      }));
  }

  addDocument(docToAdd: ClinicDocument) {
    docToAdd.id = this.defaultId;

    return this.blobService
      .uploadFileToBlobStorage(docToAdd.file, `clinic/document/${docToAdd.file.name}`, {
        // This options object is purely here to allow you to have a progress function that may update the UI otherwise you can remove it
        progress: progress => {
          // TODO: Do something with percent if you want
          const percent = progress.loadedBytes / docToAdd.file.size;
        }
      })
      .pipe(
        switchMap(blobURI => {
          // Update the photo meta data with the blobs uri
          docToAdd.filePath = removeURIQueryString(blobURI);
          if (docToAdd.pdfToHtmlUrl) {
            docToAdd.pdfToHtmlUrl = removeURIQueryString(docToAdd.pdfToHtmlUrl);
          }
          docToAdd.file = null;
          return zip(
            this.blobService.getReadOnlySASObservable(),
            this.http.post<ClinicDocument>(environment.baseUrl + 'api/ClinicDocuments', docToAdd)
          );
        }),
        map(([readOnlySAS, addedDoc]) => {
          addedDoc = new ClinicDocument(addedDoc);
          addedDoc.filePath =( addedDoc.filePath ?  addedDoc.filePath.trim() : '') + readOnlySAS;
          if (addedDoc.pdfToHtmlUrl) {
            addedDoc.pdfToHtmlUrl =( removeURIQueryString(addedDoc.pdfToHtmlUrl) ?  removeURIQueryString(addedDoc.pdfToHtmlUrl).trim() : '') + readOnlySAS;
          }
          this.clinicDocs.push(addedDoc);
          this.docsChanged.next(this.clinicDocs.sort(this.sortByName).slice());
          this.docSelected.next(addedDoc);
          return addedDoc;
        })
      );
  }

  sortByName(doc1: ClinicDocument, doc2: ClinicDocument) {
    if (doc1.name < doc2.name) {
      return -1;
    }
    if (doc1.name > doc2.name) {
      return 1;
    }
    return 0;
  }

  deleteDocument(docId: number) {
    return this.http.delete<ClinicDocument>(`${environment.baseUrl}api/ClinicDocuments/${docId}`)
    .pipe(
      switchMap(doc => {
        if (doc.pdfToHtmlUrl) {
          return zip(
            this.blobService.removeFileFromBlobStorage(doc.filePath),
            this.blobService.removeFileFromBlobStorage(doc.pdfToHtmlUrl)).map(() => doc);
        } else {
          return this.blobService.removeFileFromBlobStorage(doc.filePath).map(() => doc);
        }
      }),
      map(deletedDoc => {

        const itemIndex = this.clinicDocs.findIndex(doc => doc.id === docId);

        this.clinicDocs.splice(itemIndex, 1);
        this.docsChanged.next(this.clinicDocs);

        if (this.clinicDocs.length > 0) {
          this.docSelected.next(this.clinicDocs[0]);
        }

        return this.clinicDocs.slice();
      })
    );
  }


  drawPatientResultsToCanvas( eFormDefinition: any = {}, eFormData: {[k: string]: any} = {} ) : string{
    let outerParent = document.createElement('div');
    outerParent.style.background = "blue";
    outerParent.className ="outerParent";
    let parent = document.createElement('div');
    parent.className ="parent";
    parent.style.position =  'absolute';
    outerParent.appendChild(parent);
    let pages = [];
    for (let i in eFormDefinition.components){
      let component = eFormDefinition.components[+i];
      if (component['key'] != "submit"){
        let overlay = component['overlay'];
        let width = overlay.width;
        let height = overlay.height;
        let left = overlay.left;
        let top = overlay.top;
        if (pages.indexOf(overlay.page) == -1) pages.push(overlay.page);
        let isImg = typeof(eFormData[component['key']]) == 'string' && eFormData[component['key']].indexOf('data:image') != -1 ;
        let isDate = component['type'] == 'datetime' ;
        let isCheckbox = component['type'] == 'checkbox' ;
        let div = isImg ?  document.createElement('img') : !isCheckbox ? document.createElement('span') : document.createElement('input');
        div.className = component['key'];
        div.style.width = width + 'px';
        div.style.height = height + 'px';
        div.style.left = left + 'px';
        div.style.top = top + 'px';
        div.style.position = 'absolute';
        div.style.zIndex = '2';
        if (isImg ){
          (<HTMLImageElement>div).src = eFormData[component['key']];
        }
        else if (isDate){

          let format = component['format'] ? component['format'].toUpperCase() : 'YYYY-MM-DD';
          div.innerText = moment(eFormData[component['key']]).format(format);
        }
        else if (isCheckbox){
          let check: HTMLInputElement = (<HTMLInputElement>div);
          check.type = 'checkbox';
          check.checked = eFormData[component['key']];
          check.readOnly = true;

        }
        else{

          div.innerText = eFormData[component['key']];
        }
        parent.appendChild(div);
      }
    }

    return parent.outerHTML;

  }



}
