import { ColourVariables } from '@models/constants/colour-variables';
import { TagType } from '@models/tag/tag-type';
import { Tag } from '@models/tag/tag';
import { TagService } from '@services/tag.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms'; 
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators'; 
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';

@Component({
  selector: 'app-edit-tag',
  templateUrl: './edit-tag.component.html',
  styleUrls: ['./edit-tag.component.less']
})
export class EditTagComponent implements OnInit, OnDestroy {
  unsub: Subject<void> = new Subject<void>();
  tagId: string;
  tagsForm: FormGroup;
  actionType: String;
  isNewTag: boolean;
  tagTypes = [TagType.bodyPart, TagType.service]; //only allow   body part for now

  serviceColours = new ColourVariables().textMappings;
  colorMappings = new ColourVariables().colourMappings;
  colourVariables = new ColourVariables();

  private _defaultTag: Tag = {
    tagId: '',
    title: '', 
    type: TagType.bodyPart,
    refId: '',
    hexColour: "#00BAFF",
    isActive: true
  }

  constructor(
    public tagService: TagService,
    private catalogueUpdatesService: CatalogueUpdatesService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() { 
    this.route.params.pipe(takeUntil(this.unsub)).subscribe(params => {
      const id = params['id'];
      if (id && id === '_') {
        this.actionType = 'Add';
        this.isNewTag = true;
        this.tagId = null;
      } else {
        this.tagId = id;
        this.actionType = 'Update';
        this.isNewTag = false;
      }
      this.initForm();
    });
  }

  private initForm() { 
    this.tagsForm = new FormGroup({
      title: new FormControl('', Validators.required),
      hexColour: new FormControl("#00BAFF"),
      type: new FormControl(TagType.bodyPart, Validators.required),
      tagId: new FormControl(this.tagId )
    });

    if (!this.isNewTag) {
      this.tagService.getTagByTagId(this.tagId)
        .pipe(takeUntil(this.unsub))
        .subscribe((tagX: Tag) => {
          var tag = {...this._defaultTag, ...tagX}; //overwrites default with custom data https://stackoverflow.com/a/60202550
          this.tagsForm.controls['title'].setValue(tag.title);
          this.tagsForm.controls['hexColour'].setValue(tag.hexColour);
          this.tagsForm.controls['type'].setValue(tag.type);
          this.tagsForm.controls['tagId'].setValue(tag.tagId);
        });
    }
  }

  onSubmit() {
    if (this.isNewTag) {
      this.tagService.addCustomTag(this.tagsForm.value as Tag).subscribe(() => {
        this.catalogueUpdatesService.refreshRequired = true;
        this.catalogueUpdatesService.catalogueUpdateComplete();
        this.router.navigate([
          '/management/catalogue/custom-tags',
          { outlets: { 'action-panel': null } }
        ]);
      });
    } else {
      this.tagService.updateCustomTag(this.tagsForm.value as Tag).subscribe(() => {
        this.catalogueUpdatesService.refreshRequired = true;
        this.catalogueUpdatesService.catalogueUpdateComplete();
        this.router.navigate([
          '/management/catalogue/custom-tags',
          { outlets: { 'action-panel': null } }
        ]);
      });
    }
  }

  cancelUpdate() {
    this.catalogueUpdatesService.refreshRequired = true;
    this.catalogueUpdatesService.catalogueUpdateComplete();
    this.router.navigate([
      '/management/catalogue/custom-tags',
      { outlets: { 'action-panel': null } }
    ]);
  }

  getSelectedOptionStyles() {
    const value = {
      'background-color': this.colorMappings.get(this.tagsForm.controls['hexColour'].value),
      'border-left': '10px solid ' + this.tagsForm.controls['hexColour'].value,
      'color': this.colourVariables.black_custom,
      'padding': '5px 0 5px 5px'
    };

    return value;
  }


  getOptionStyles(color: any) {
    return {
      'background-color': this.colorMappings.get(color.key),
      'border-left': '10px solid ' + color.key,
      'color': this.colourVariables.black_custom
    };
  }


  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
