import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';

import { isNullOrUndefined } from '@app/shared/helpers';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map, startWith } from 'rxjs/operators';

import { SpecialsService } from '@services/specials.service';
import { ClinicsService } from '@services/clinics.service';
import { TaxService } from '@services/tax.service';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { FormatterService } from '@services/formatter.service';
import { Tax } from '@models/tax';
import { Special, SpecialTax } from '@models/special';
import { ClinicProduct, SpecialProduct } from '@models/clinic-product';

@Component({
  selector: 'app-edit-special',
  templateUrl: './edit-special.component.html',
  styleUrls: ['./edit-special.component.less'],
})
export class EditSpecialComponent implements OnInit, OnDestroy {
  name: FormControl;
  code: FormControl;
  totalOfIndividualPrices: FormControl;
  retailPrice: FormControl;
  products: FormControl;
  services: FormControl;
  selectedProduct: FormControl = new FormControl();

  public addOrEdit = 'Add';
  public taxes: Tax[];
  public selectedTaxes: Tax[] = [];
  public filteredProducts: Observable<ClinicProduct[]>;
  public selectedProducts: SpecialProduct[] = [];

  public special: Special = {
    specialId: 0,
    name: '',
    code: null,
    totalOfIndividualPrices: '$0.00',
    retailPrice: 0.0,
    products: [],
    productsString: '',
    services: [],
    specialTaxes: [],
  };

  private allAvailableProducts: ClinicProduct[] = [];
  private isNew = true;
  private specialIdParam = '0';

  private unsub: Subject<void> = new Subject<void>();

  constructor(
    private specialsService: SpecialsService,
    private clinicService: ClinicsService,
    private taxService: TaxService,
    private catalogueUpdatesService: CatalogueUpdatesService,
    private route: ActivatedRoute,
    private router: Router,
    public formatterService: FormatterService
  ) {
    this.name = new FormControl();
    this.code = new FormControl();
    this.totalOfIndividualPrices = new FormControl();
    this.retailPrice = new FormControl();
    this.products = new FormControl();
    this.services = new FormControl();
  }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      const id = params['specid'];

      if (id && id !== '_') {
        // TODO: add this to the condition: && isNumber(id) && id > 0
        this.specialIdParam = id;
        this.specialsService.getSpecialForEdit(id).subscribe(
          (dto) => {
            if (dto.special) {
              this.special = dto.special;
              this.allAvailableProducts = dto.products;
              // this.categories = dto.services;
              // this.taxes = dto.taxes;
              this.taxes = this.getClinicTaxes(0);

              dto.special.products.forEach((sp) => {
                this.selectedProducts.push(sp);
              });
              dto.special.specialTaxes.forEach((pt) => {
                this.selectedTaxes.push(pt.tax);
              });

              this.isNew = false;
              this.addOrEdit = 'Edit';
            } else {
              // TODO: decide what to do if there is no more the package in the database
            }
          },
          (err) => {
            // TODO: decide what to do with err
          }
        );
      } else {
        this.specialsService.getListsForNewSpecial().subscribe(
          (dto) => {
            this.allAvailableProducts = dto.products;
            // this.categories = dto.services;
            // this.taxes = dto.taxes;
            this.taxes = this.getClinicTaxes(0);
          },
          (err) => {
            // TODO: decide what to do with err
          }
        );
      }
    });
    this.filteredProducts = this.selectedProduct.valueChanges.pipe(
      startWith(''),
      map((val) => this.filter(val))
    );
  }

  private filter(val: any): ClinicProduct[] {
    if (!val) return [];
    if (val.name) {
      return this.allAvailableProducts.filter((option) =>
        option.displayName.toLowerCase().includes(val.name.toLowerCase())
      );
    } else {
      return this.allAvailableProducts.filter((option) => option.displayName.toLowerCase().includes(val.toLowerCase()));
    }
  }

  public productDisplayFn(user?: ClinicProduct): string | undefined {
    return user ? user.displayName : undefined;
  }

  public addProductToSpecial() {
    let matchFound = false;
    let productToAdd: ClinicProduct = null;
    if (typeof this.selectedProduct.value === 'object') {
      productToAdd = this.selectedProduct.value;
    }
    if (!isNullOrUndefined(productToAdd)) {
      this.selectedProducts.forEach((selectedProduct) => {
        if (selectedProduct.clinicProductId === productToAdd.id) {
          selectedProduct.productQuantity += 1;
          this.updateSpecialProductQuantity(selectedProduct);
          matchFound = true;
        }
      });
      if (!matchFound) {
        const packageProduct: SpecialProduct = {
          clinicProductId: productToAdd.id,
          clinicProduct: productToAdd,
          productQuantity: 1,
          specialId: Number(this.specialIdParam),
          special: null,
        };
        const packageProductDB: SpecialProduct = {
          clinicProductId: productToAdd.id,
          clinicProduct: null,
          productQuantity: 1,
          specialId: Number(this.specialIdParam),
          special: null,
        };
        this.selectedProducts.push(packageProduct);
        this.special.products.push(packageProductDB);
        this.updateTotalOfPrices();
        this.selectedProduct.reset();
      }
    }
  }

  public updateSpecialProductQuantity(product: SpecialProduct) {
    this.special.products.forEach((pp) => {
      if (pp.clinicProductId === product.clinicProductId) {
        pp.productQuantity = product.productQuantity;
      }
    });
    this.selectedProducts.forEach((sp) => {
      if (sp.clinicProductId === product.clinicProductId) {
        sp.productQuantity = product.productQuantity;
      }
    });
    this.updateTotalOfPrices();
  }

  public removeProductFromSpecial(productToRemove: SpecialProduct) {
    let productIndex = this.special.products.indexOf(
      this.special.products.find((pp) => pp.clinicProductId === productToRemove.clinicProductId)
    );
    this.special.products.splice(productIndex, 1);

    productIndex = this.selectedProducts.indexOf(
      this.selectedProducts.find((sp) => sp.clinicProductId === productToRemove.clinicProductId)
    );
    this.selectedProducts.splice(productIndex, 1);

    this.updateTotalOfPrices();
  }

  private getClinicTaxes(clinicId: number): Tax[] {
    const clinicTaxes: Tax[] = [];
    this.clinicService.getClinics().subscribe((c) => {
      if (!isNullOrUndefined(c[0].clinicTaxes)) {
        if (c[0].clinicTaxes.length > 0) {
          c[0].clinicTaxes.forEach((ct) => {
            this.taxService.getTaxes().subscribe((taxes) => {
              taxes.forEach((t) => {
                if (t.taxId === ct.taxId) {
                  clinicTaxes.push(t);
                }
              });
              return clinicTaxes;
            });
          });
        } else {
          return clinicTaxes;
        }
      } else {
        return clinicTaxes;
      }
    });
    return clinicTaxes;
  }

  public updateTotalOfPrices() {
    let totalPrices = 0;
    this.selectedProducts.forEach((sp) => {
      totalPrices = Number(totalPrices) + Number(sp.clinicProduct.retailPrice) * Number(sp.productQuantity);
    });
    // apply the taxes
    let thetaxtotal = 0;
    this.selectedTaxes.forEach((tax) => {
      thetaxtotal = thetaxtotal + totalPrices * tax.value;
    });
    totalPrices = totalPrices + thetaxtotal;
    this.special.totalOfIndividualPrices = totalPrices.toFixed(2);
  }

  public updateSpecial() {
    const id = this.special.specialId;
    const selectedTaxes = this.selectedTaxes;
    const specialTaxes: SpecialTax[] = [];

    selectedTaxes.forEach((t) => {
      specialTaxes.push({
        specialId: id,
        special: null,
        taxId: t.taxId,
        tax: null,
      });
    });

    this.special.specialTaxes = specialTaxes;

    if (this.isNew) {
      this.specialsService.addSpecial(this.special).subscribe(() => {
        this.catalogueUpdatesService.refreshRequired = true;
        this.catalogueUpdatesService.catalogueUpdateComplete();
        this.router.navigate(['/management/catalogue/specials', { outlets: { 'action-panel': null } }]);
      });
    } else {
      this.specialsService.updateSpecial(this.special).subscribe(() => {
        this.catalogueUpdatesService.refreshRequired = true;
        this.catalogueUpdatesService.catalogueUpdateComplete();
        this.router.navigate(['/management/catalogue/specials', { outlets: { 'action-panel': null } }]);
      });
    }
  }

  public cancelUpdate() {
    this.catalogueUpdatesService.refreshRequired = false;
    this.catalogueUpdatesService.catalogueUpdateComplete();
    this.router.navigate(['/management/catalogue/specials', { outlets: { 'action-panel': null } }]);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
