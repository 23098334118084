export class ConvergeCard {
  patientTokenId: number;
  brand: string;
  maskedDigits: string;
  expiry: string;

  constructor(init?: Partial<ConvergeCard>) {
    Object.assign(this, init);
  }
}
