import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { PatientTypeheadComponent } from '@app/patients/patient-typehead/patient-typehead.component';
import { PatientSearchModalComponent } from '@app/shared/components/patient-search-modal/patient-search-modal.component';
import { Patient } from '@models/patient';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterOverlayService } from '@services/actionpanel.service';
import { BlobService } from '@services/blob.service';
import { PatientSignalrService } from '@services/patient-signalr.service';
import { PatientService } from '@services/patient.service';
import { TwilioConversationsService } from '@services/twilio-conversations.service';
import { Conversation, Message } from '@twilio/conversations';
import * as moment from 'moment';
import { NgScrollbar } from 'ngx-scrollbar';
import { merge, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-conversations-sidebar',
  templateUrl: './conversations-sidebar.component.html',
  styleUrls: ['./conversations-sidebar.component.less'],
})
export class ConversationsSidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('scrollbar') scrollbar: NgScrollbar;
  @ViewChild('patientSearch') patientSearch: PatientTypeheadComponent;
  @ViewChild('conversationSelectionList') selectionList: MatSelectionList;
  @ViewChild('paginator') paginator: MatPaginator;

  searchFormControl = new FormControl('');
  conversations: Conversation[] = [];
  filteredConversations: Conversation[] = [];
  pageConversations: Conversation[] = [];
  conversationsPatient: Map<string, Patient> = new Map();
  conversationsUnreadCount: Map<string, Observable<number>> = new Map();
  conversationsLastMessage: Map<string, Observable<string>> = new Map();
  loading = false;
  removePolicy = Policies.patientMessagingRemove;
  sendPolicy = Policies.patientMessagingSend;
  private unsub = new Subject<void>();

  private _selectedConversation: Conversation = null;
  get selectedConversation() {
    return this._selectedConversation;
  }
  set selectedConversation(conversation: Conversation) {
    this._selectedConversation = conversation;
    const patient = conversation ? this.conversationsPatient.get(conversation.sid) : null;
    this.twilioService.setSelectedConversationAndPatient(conversation, patient);
  }

  get readOnlySAS() {
    return this.blobService.getReadOnlySAS();
  }

  constructor(
    private twilioService: TwilioConversationsService,
    private blobService: BlobService,
    private modalService: NgbModal,
    private patientService: PatientService,
    private patientSignalRService: PatientSignalrService,
    private masterOverlayService: MasterOverlayService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loading = true;

    this.twilioService.conversations$.pipe(takeUntil(this.unsub)).subscribe(async (result) => {
      this.conversations = result.filter((c) => !this.twilioService.getConversationArchiveStatus(c));
      this.filteredConversations = this.searchFilter('');
      await this.setPageConversations();
      if (this.selectedConversation) {
        await this.scrollToConversation(this.selectedConversation);
      }
      this.loading = false;
    });

    this.searchFormControl.valueChanges.pipe(takeUntil(this.unsub)).subscribe(async (searchString) => {
      this.filteredConversations = this.searchFilter(searchString);
      await this.setPageConversations();
      this.selectedConversation = null;
    });

    this.patientSignalRService.patientUpdated$.pipe(takeUntil(this.unsub)).subscribe(async (updatedPatientId) => {
      for (const conversation of this.conversations) {
        const patientId = this.twilioService.parsePatientId(conversation);
        if (patientId === updatedPatientId) {
          const patient = await this.patientService.getPatientById(patientId).toPromise();
          this.conversationsPatient.set(conversation.sid, patient);
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.setPageConversations();
  }

  private async setPageConversations() {
    if (this.paginator) {
      this.loading = true;
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      const endIndex = startIndex + this.paginator.pageSize;
      this.pageConversations = this.filteredConversations.slice(startIndex, endIndex);
      await this.setConversationMaps(this.pageConversations);
      this.loading = false;
    }
  }

  private async setConversationMaps(conversations: Conversation[]) {
    const patientsRequests = [];
    for (const conversation of conversations) {
      const patientId = this.twilioService.parsePatientId(conversation);
      if (!this.conversationsPatient.has(conversation.sid)) {
        const patientsRequest = this.patientService
          .getPatientById(patientId)
          .toPromise()
          .then((patient) => {
            this.conversationsPatient.set(conversation.sid, patient);
          });
        patientsRequests.push(patientsRequest);
      }
      if (!this.conversationsUnreadCount.has(conversation.sid))
        this.conversationsUnreadCount.set(conversation.sid, this.unreadCountObserver(conversation));
      if (!this.conversationsLastMessage.has(conversation.sid))
        this.conversationsLastMessage.set(conversation.sid, this.latestMessageObserver(conversation));
    }
    await Promise.all(patientsRequests);
  }

  private unreadCountObserver(conversation: Conversation): Observable<number> {
    const initialGet = conversation.getUnreadMessagesCount();

    const messageAdded = this.twilioService.messageAdded$.pipe(
      takeUntil(this.unsub),
      filter((message) => message.conversation.sid === conversation.sid),
      map((message) => message.conversation),
      switchMap((con) => con.getUnreadMessagesCount())
    );

    const conversationUpdated = this.twilioService.conversationUpdated$.pipe(
      takeUntil(this.unsub),
      filter((con) => con.sid === conversation.sid),
      switchMap((con) => con.getUnreadMessagesCount())
    );

    return merge(initialGet, messageAdded, conversationUpdated).pipe(takeUntil(this.unsub));
  }

  private latestMessageObserver(conversation: Conversation): Observable<string> {
    const mapLastRead = (message: Message) => {
      const fromName = message.author.includes('clinic') ? 'Clinic' : 'Patient';
      return `${fromName}: ${message.body}`;
    };

    const currentLastMessage = conversation.getMessages(1).then((page) => {
      return page.items[0];
    });

    const messageAdded = this.twilioService.messageAdded$.pipe(
      filter((message) => message.conversation.sid === conversation.sid)
    );

    return merge(currentLastMessage, messageAdded).pipe(map(mapLastRead), takeUntil(this.unsub));
  }

  private searchFilter(searchString: string) {
    const searchTerms = searchString.split(' ').map((term) => term.trim().toLowerCase());
    return this.conversations.filter((conversation) =>
      searchTerms.every((term) => conversation.friendlyName.toLowerCase().includes(term))
    );
  }

  async onSelectionChange(change: MatSelectionListChange) {
    this.selectedConversation = change.options[0]?.value;
  }

  async onPageChange() {
    await this.setPageConversations();
    this.selectedConversation = null;
  }

  addConversation() {
    const modalRef = this.modalService.open(PatientSearchModalComponent, { centered: true });
    modalRef.result.then(async (patient: Patient) => {
      if (patient) {
        this.loading = true;
        try {
          const fullPatient = await this.patientService.getPatientById(patient.patientId).toPromise();
          this.selectedConversation = await this.twilioService.getPatientConversation(fullPatient);
        } catch (error) {
          if (error.message) {
            this.dialog.open(GenericDialogComponent, {
              width: '350px',
              data: {
                title: 'Can not create a new conversation',
                content: error.message ?? 'An error occurred while trying to open the patient chat.',
                confirmButtonText: 'OK',
                showCancel: false,
              },
            });
          }
          throw error;
        } finally {
          this.loading = false;
        }
      }
    });
  }

  // For internal use. Will permanently delete to conversation. Button commented out in template.
  async deleteConversation(conversation: Conversation) {
    await conversation.delete();
    if (conversation.sid === this.selectedConversation?.sid) {
      this.selectedConversation = null;
    }
  }

  async removeConversation(conversation: Conversation) {
    await this.twilioService.archiveConversation(conversation);
    if (conversation.sid === this.selectedConversation?.sid) {
      this.selectedConversation = null;
    }
  }

  goToPatientProfile(patient: Patient, event: MouseEvent) {
    event.stopPropagation();
    if (!patient) return;
    this.patientService.patientPanelPatient = patient;
    this.masterOverlayService.masterOverlayState(true);
    this.router.navigate([
      '/patient-messaging',
      { outlets: { 'action-panel': ['patient', patient.patientId + '_' + 'patientprofiletab'] } },
    ]);
    this.selectedConversation = null;
  }

  getAge(date: Date) {
    return moment(new Date(Date.now())).diff(moment(date), 'years');
  }

  private async scrollToConversation(conversation: Conversation) {
    if (conversation) {
      const element = document.getElementById(conversation.uniqueName);
      if (element) {
        await this.scrollbar.scrollToElement(element);
      }
    }
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }
}
