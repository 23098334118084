<div *ngIf="appts.length > 0">
  <h1 mat-dialog-title>Select Appointment</h1>
  <div mat-dialog-content>
    <ul>
      <li *ngFor="let appt of appts">
        <div>
          <mat-radio-button (click)="apptClicked(appt)">
            {{appt.service.serviceName}} - {{getAppointmentTime(appt)}}</mat-radio-button>
        </div>
      </li>
    </ul>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" [mat-dialog-close]="data">Cancel</button>
    <button mat-button (click)="onSelectClick()" [mat-dialog-close]="data" cdkFocusInitial>Select</button>
  </div>
</div>
<div *ngIf="appts.length === 0">
  <h1 mat-dialog-title>No Appointments Found for Today</h1>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" [mat-dialog-close]="data">Ok</button>
  </div>
</div>