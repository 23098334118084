<div class="p-2">
    <form *ngIf="editPhotoDetails" class="metadata-wrapper px-3 pt-3" [formGroup]="editPhotoDetails" kendoTooltip tooltipClass="em-tooltip" position="top"> 
      <div class="row py-2">
        <div class="col">
          <input
            type="text"
            class="form-control photo-details-control lg-control navy-font"
            [ngStyle]="{ 'edit-mode': editMode }"
            formControlName="imageName"
            placeholder="Add Image Name"/>
        </div>
      </div>

      <div class="row">
        <div class="col default-cursor d-flex align-items-baseline">
          <p class="mr-3"><i class="far fa-calendar-alt" title="Created Date"></i></p>
          <kendo-datepicker [(value)]="uplodaDateTime"></kendo-datepicker>
          <kendo-timepicker [(value)]="uplodaDateTime"></kendo-timepicker>
        </div>
      </div>

      <div class="row" *ngIf="editMode" >
        <div class="col-3 d-flex align-items-baseline">
          <p class="mr-3"><i class="far fa-book" title="Notes"></i></p>
          <input type="text" class="form-control photo-details-control" [ngStyle]="{ 'edit-mode': editMode }"
            formControlName="notes" placeholder="Add notes" />
        </div>
      </div>
    
      <ng-container>
        <div class="row">
          <div class="col d-flex align-items-baseline">
            <p class="mr-3"><i class="far fa-tags" title="Tags"></i></p>
            <tag-input [formControlName]="'tags'" name="tags" [inputClass]="'tag-input'" #input [displayBy]="'title'" [onlyFromAutocomplete]="true">
              <ng-template let-item="item" let-index="index">
                <div class="tag-pill" [ngStyle]="{ 'border-color': pillColours[item.type] }">
                  <ng-container *ngIf="item.title; else text">
                    {{ item.title }}
                  </ng-container>
                  <ng-template #text>
                    {{ item.display }}
                  </ng-template>
                  <delete-icon (click)="input.removeItem(item, index)"></delete-icon>
                </div>
              </ng-template>
              <tag-input-dropdown [focusFirstElement]="true" [autocompleteItems]="tagList" [zIndex]="99999"
                [displayBy]="'title'" [identifyBy]="'tagId'" [showDropdownIfEmpty]="true" [keepOpen]="false">
              </tag-input-dropdown>
            </tag-input>
          </div>
        </div>
      </ng-container>

      <div class="row">
        <div class="col d-flex mt-2 mb-2 align-items-baseline">
          <label for="consentToShare">
            Consent to Share Photo(s)
          </label>
          <select id="photoConsentSelect"
            class="w-25 ml-2 form-control border-top-0 border-right-0 border-left-0"
            (change)="setPhotoConsent()"
            formControlName="photoConsentTypeId">
            <option *ngFor="let option of photoConsentOptions" [value]="option.value">{{option.title}}</option>
          </select>
        </div>
      </div>

      <div class="row" *ngIf="editMode && isGallery">
        <div class="col-3 d-flex align-items-center">
          <p class="mr-3"><i class="far fa-star" title="Globally Shared"></i></p>
          <p>Global Shared Image</p>
          <label class="switch align-text-top ml-2 mb-3">
            <input
              type="checkbox"
              [checked]='photo.isGlobalSharedImage'
              (change)="changeValue($event)"
              formControlName="isGlobalSharedImage">
            <span class="slider round"></span>
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button class="btn em-btn em-btn-green" (click)="saveChanges()">Save & Close</button>
          <button class="btn em-btn em-btn-green ml-2" (click)="cancelChanges()">Cancel</button>
          <!-- <button
            class="btn em-btn em-btn-green ml-2"
            *ngIf="photoCropped"
            (click)="undoImageChanges()">Undo Photo Edit</button> -->
        </div>
      </div>
    </form>

  <div class="metadata-wrapper px-3 pt-3" *ngIf="!editMode && photo" kendoTooltip tooltipClass="em-tooltip" position="top">
    <h6 class="mb-4">{{ photo?.imageName }}</h6>
    <div class="row">
      <div class="col default-cursor d-flex">
        <p class="mr-3"><i class="far fa-calendar-alt" title="Created Date"></i></p>
        <p>{{ photo?.uploadDate.toDate()| date: 'yyyy-MM-dd - h:mm a' }}</p>
      </div>
    </div>

    <div class="row" *ngIf="photo?.notes">
      <div class="col d-flex">
        <p class="mr-3"><i class="far fa-book" title="Notes"></i></p>
        <p>{{ photo?.notes }}</p>
      </div>
    </div>

    <div class="row" *ngIf="photo?.tags?.length">
        <div class="col d-flex align-items-baseline" >
          <p class="mr-3"><i class="far fa-tags" title="Tags"></i></p>
          <div class="pill-container">
            <div class="tag tag-pill d-inline" *ngFor="let tag of photo.tags"
              [ngStyle]="{ 'border-color': pillColours[tag.type] }">
              {{ tag.title }}
            </div>
          </div>
        </div>
      </div>

    <div class="row">
      <ng-container *ngIf="photo?.photoConsentTypeId !== 1; else noShare">
        <div class="col default-cursor d-flex" >
          <p class="mr-3"><i class="far fa-user-check" title="Consent Given"></i></p>
          <p>Consent to share photo: 
            {{photoConsentOptions[photo?.photoConsentTypeId - 1].title}}
          </p>
        </div>
      </ng-container>
      <ng-template #noShare>
        <div class="col default-cursor d-flex" >
          <p class="mr-3"><i class="far fa-user-times" title="Consent not Given"></i></p>
          <p>No consent given to share photo</p>
        </div>
      </ng-template>
    </div>

    <div class="row" *ngIf="isGallery">
      <div class="col d-flex">
        <p class="mr-3"><i class="far fa-star" title="Globally Shared"></i></p>
        <p>Global Shared Image {{ photo?.isGlobalSharedImage ? 'ON' : 'OFF' }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <button class="btn em-btn em-btn-green"  (click)="editPhoto()">
          Edit
        </button>
        <button 
          [class.d-none]="photo?.isOriginal || photo?.isSeries"
          class="btn em-btn em-btn-green ml-2"
          (click)="revertPhoto()">
            Revert to Original
        </button>
        <button *ngIf="showDelete " class="btn em-btn em-btn-green ml-2" (click)="deletePhoto()">
          Delete
        </button>
      </div>
    </div>
  </div>
</div>