import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';
import * as Sentry from '@sentry/angular-ivy';

Sentry.init({
  dsn: 'https://ebfe5bda5d25d21ac76422fd9333b028@o4507625676537856.ingest.us.sentry.io/4507626627727360',
  integrations: [
    Sentry.browserTracingIntegration(),
    // Sentry replay has been disabled because it was causing triggering change detection, causing performance issues.
    // https://github.com/getsentry/sentry-javascript/issues/12443
    // Look at reintroducing in Angular 18 without zone.js
    // Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
  ],

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^((?!maizal).)*\.emilyemr\./],

  // Filter URLs for error capturing
  allowUrls: [/^((?!maizal).)*\.emilyemr\./],
  // denyUrls: [/localhost/, /maizal.emilyemr.ca/],

  // Session Replay
  replaysSessionSampleRate: 0, //environment.production ? 0.1 : 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: environment.production ? 1.0 : 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  environment: environment.production ? 'production' : 'development',
});

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  const m: any = module;
  const isHot = !!m['hot'];

  if (isHot) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
  }
} else {
  document.addEventListener('DOMContentLoaded', () => {
    bootstrap()
      .then(() => console.log(`Emily EMR is running.`))
      .catch((err) => console.error(err));
  });
}
