import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { Clinic } from '@models/clinic';
import { Invoice } from '@models/invoice/invoice';
import { InvoiceType } from '@models/invoice/invoice-type';
import { Patient } from '@models/patient';
import { BlobService } from '@services/blob.service';
import { ClinicsService } from '@services/clinics.service';
import { InvoicesService } from '@services/invoices.service';
import { PatientService } from '@services/patient.service';
import * as moment from 'moment';
import { Observable, Subject, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { PaymentType } from '../invoice-payment/invoice-payment.component';
import { StandardInvoiceComponent } from '../standard-invoice/standard-invoice.component';

@Component({
  selector: 'app-patient-invoice-history',
  templateUrl: './patient-invoice-history.component.html',
  styleUrls: ['./patient-invoice-history.component.less'],
})
export class PatientInvoiceHistoryComponent implements OnInit, OnDestroy {
  @Input() patientId: number;
  @Input() email: string;
  @ViewChild(MatAccordion) invoiceAccordion: MatAccordion;
  @ViewChild('messagecontainer', { read: ViewContainerRef, static: true }) entry: ViewContainerRef;
  @ViewChild('paymentModal') paymentModal: TemplateRef<any>;
  unsub: Subject<void> = new Subject<void>();
  invoices: Invoice[];
  selectedInvoice: Invoice;
  isOpenAll = false;
  componentRef: any;
  showFutureInvoices = false;
  loading = false;
  nFutureInvoices: number = 0;
  InvoiceTypeEnum = InvoiceType;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private invoicesService: InvoicesService,
    private resolver: ComponentFactoryResolver,
    private patientService: PatientService,
    private clinicsService: ClinicsService,
    private blobService: BlobService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getClinicInfo().subscribe((clinic) => {
      this.getInvoices();
      this.detectInvoiceListUpdate();
    });

    // Default the email to be the current patient's email, if they have one.
    if (this.patientService.patientPanelPatient?.email && this.patientService.patientPanelPatient?.email !== '')
      this.email = this.patientService.patientPanelPatient.email;

    this.patientService.thePatientUpdated$.subscribe((patient: Patient) => {
      this.patientId = patient.patientId;
      this.email = patient.email;
    });
  }

  clinic: Clinic;

  getClinicInfo(): Observable<Clinic> {
    // TODO Update this to use the appropriate Clinic -mmm
    this.loading = true;
    if (this.clinic) {
      this.loading = false;
      return of(this.clinic);
    }

    if (this.clinicsService.clinic) {
      this.clinic = this.clinicsService.clinic;
      return of(this.clinic);
    }

    return this.clinicsService.getClinicById(localStorage.clinicId).pipe(
      map((clinic: Clinic) => {
        if (clinic.logoUrl) {
          clinic.logoUrl = clinic.logoUrl.trim() + this.blobService.getReadOnlySAS();
        }
        this.clinic = clinic;
        this.loading = false;
        return this.clinic;
      })
    );
  }

  createInvoicePDF(invoice: Invoice) {
    this.loading = true;
    const factory = this.resolver.resolveComponentFactory(StandardInvoiceComponent);
    this.componentRef = this.entry.createComponent(factory);
    (<StandardInvoiceComponent>this.componentRef.instance).externalInvoiceId = invoice.id;
    (<StandardInvoiceComponent>this.componentRef.instance).clinic = this.clinic;
    (<StandardInvoiceComponent>this.componentRef.instance).invoice = invoice;
    (<StandardInvoiceComponent>this.componentRef.instance).invoiceReadyForPrint.subscribe((ready) => {
      if (ready) {
        setTimeout(() => {
          (<StandardInvoiceComponent>this.componentRef.instance).exportToPDF();
          this.componentRef.destroy();
          this.entry.clear();
          this.loading = false;
        }, 150);
      }
    });
  }

  emailInvoice(invoice: Invoice) {
    const emailAsObservable = (emailAddress: string) => {
      const factory = this.resolver.resolveComponentFactory(StandardInvoiceComponent);
      this.componentRef = this.entry.createComponent(factory);
      (<StandardInvoiceComponent>this.componentRef.instance).externalInvoiceId = invoice.id;
      (<StandardInvoiceComponent>this.componentRef.instance).clinic = this.clinic;
      (<StandardInvoiceComponent>this.componentRef.instance).invoice = invoice;
      return (<StandardInvoiceComponent>this.componentRef.instance).invoiceReadyForPrint.pipe(
        map(() => {
          (<StandardInvoiceComponent>this.componentRef.instance).emailInvoice(emailAddress);
          this.componentRef.destroy();
          this.entry.clear();
        })
      );
    };
    return emailAsObservable;
  }

  detectInvoiceListUpdate() {
    this.invoicesService.invoicesListUpdated$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.getInvoices();
    });
  }

  getInvoicesToShow(invoices: Invoice[]) {
    if (!invoices || invoices.length == 0) return [];
    else if (this.showFutureInvoices) return invoices;
    else {
      return invoices.filter((i) => !this.isFutureInvoice(i));
    }
  }

  getInvoices() {
    this.loading = true;
    if (!this.patientId) {
      this.patientId = this.route.snapshot.params.patId.split('_')[0];
    }
    this.invoicesService
      .getInvoicesByPatientId(this.patientId)
      .pipe(
        takeUntil(this.unsub),
        map((invoices) =>
          invoices.map((invoice) => {
            invoice['isExpanded'] = !invoice.isPaid;
            return invoice;
          })
        )
      )
      .subscribe((invoices: Invoice[]) => {
        this.invoices = invoices;
        this.nFutureInvoices = invoices.filter((i) => this.isFutureInvoice(i)).length;
        this.loading = false;
      });
  }

  detailInvoice(invoiceId: number) {
    if (this.route.snapshot.params.patId) {
      const url = this.router.url.includes('overview') ? '../invoice' : '../../invoice';
      this.router.navigate([url, invoiceId], { relativeTo: this.route });
    } else {
      this.router.navigateByUrl(
        `/schedule/(action-panel:patient/${this.patientId}_patientprofiletab/patienttabs/patientaccounttab/invoice/${invoiceId})`
      );
    }
  }

  openPayModal(invoice: Invoice) {
    this.selectedInvoice = invoice;
    this.dialog.open(this.paymentModal, { panelClass: 'custom-dialog-container' });
  }

  onInvoicePay() {
    this.selectedInvoice = null;
  }

  getInvoiceType(invoice: Invoice) {
    return this.invoicesService.getInvoiceType(invoice);
  }

  getPaymentType() {
    return this.selectedInvoice?.invoiceTypeId === InvoiceType.Refund
      ? PaymentType.Refund
      : this.selectedInvoice?.invoiceTypeId === InvoiceType.Recurring
      ? PaymentType.Recurring
      : PaymentType.Regular;
  }

  trackByFunction(index: number, item: Invoice) {
    return index;
  }

  getFutureInvoiceCount(invoices: Invoice[]) {
    return invoices.filter((i) => this.isFutureInvoice(i)).length;
  }

  isFutureInvoice(invoice: Invoice) {
    if (!invoice || !invoice.invoiceDate) return false;
    return moment().diff(moment(invoice.invoiceDate)) < 0;
  }

  toggleAll() {
    this.loading = true;
    if (this.isOpenAll) {
      this.invoiceAccordion.closeAll();
      this.isOpenAll = false;
    } else {
      this.openAll();
    }
    this.loading = false;
  }

  openAll() {
    this.invoiceAccordion.openAll();
    this.isOpenAll = true;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
