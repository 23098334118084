<ngx-loading
  [show]="loading"
  [attr.data-test-id]="'invoice-is-loaded'"
  [attr.data-test-value]="!loading && !transactionsLoading && initialized"
></ngx-loading>

<div class="row w-100 mx-0 px-2 py-4 h-100">
  <div class="col-xl-3 col-12 d-flex flex-column px-2 h-100">
    <div class="d-flex flex-column grey-three-border h-100" #sidePanelContainer>
      <div class="w-100 navy-background px-3 py-4" #sidePanelHeader>
        <button type="button" class="white-font btn-clean" (click)="backToRoute()">
          <i class="fal fa-arrow-left mr-2"></i> ACCOUNT
        </button>
      </div>

      <div class="p-3">
        <div class="d-flex flex-column grey-three-border mb-3">
          <app-service-products
            [serviceProducts]="recommendedProducts"
            (selectProduct)="addRecommendedProductToInvoice($event)"
            [invoice]="invoice"
            [panelHeight]="panelHeight$.value / 3"
          >
          </app-service-products>
        </div>

        <div class="d-flex flex-column grey-three-border mb-3">
          <app-treatment-plan-overview
            [showProducts]="true"
            [currentInvoice]="invoice && !invoice?.isPaid ? invoice : null"
            [showDetailButton]="false"
            (selectedItem)="selectItem($event)"
            [typeOfTab]="TabType.Invoice"
            [panelHeight]="panelHeight$.value / 3"
          >
          </app-treatment-plan-overview>
        </div>

        <div class="d-flex flex-column grey-three-border">
          <app-product-purschase-history
            [isInvoice]="!invoice?.isPaid"
            (selectProduct)="addRecommendedProductToInvoice($event)"
            [panelHeight]="panelHeight$.value / 3"
          ></app-product-purschase-history>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-9 col-12 px-0 main-column d-flex flex-column px-2 invoice-actions">
    <ng-container *ngFor="let error of updateLineItemsErrors">
      <app-error *ngFor="let fieldError of error.fieldErrors" [message]="fieldError"></app-error>
    </ng-container>

    <div class="d-flex py-2 grey-four-border-bottom">
      <div class="column-header mr-auto">{{ getInvoiceType(invoice) }}</div>
      <ng-container *ngIf="!invoice?.isPaid">
        <button
          *ngIf="showDeleteButton"
          (click)="onDeleteInvoice(invoice?.id)"
          type="button"
          class="schedNewCommButton em-btn em-btn-green py-1 mr-4 mb-0"
          [disabled]="!buttonsAvailable"
        >
          Delete
        </button>
        <button
          *ngIf="!creditLineItemExists && invoice?.invoiceTypeId !== InvoiceType.Recurring"
          (click)="addCreditToInvoice()"
          type="button"
          class="schedNewCommButton center em-btn em-btn-green py-1 mr-4 mb-0"
          [disabled]="!buttonsAvailable"
        >
          Add Credit
        </button>
        <button
          *ngIf="invoice?.invoiceTypeId !== InvoiceType.Recurring"
          (click)="selectProductsToAdd()"
          type="button"
          class="schedNewCommButton center em-btn em-btn-green py-1 mr-4 mb-0"
          [disabled]="!buttonsAvailable"
        >
          Add Product
        </button>
        <button
          *ngIf="invoice?.invoiceTypeId === InvoiceType.Recurring"
          (click)="addMembershipToInvoice()"
          type="button"
          class="schedNewCommButton center em-btn em-btn-green py-1 mr-4 mb-0"
          [disabled]="!buttonsAvailable"
        >
          Add Membership
        </button>
        <button
          (click)="applyDiscountToInvoice()"
          type="button"
          class="schedNewCommButton center em-btn em-btn-green py-1 mr-4 mb-0"
          [disabled]="!buttonsAvailable"
        >
          Discount Invoice
        </button>
        <button
          *ngIf="!showCloseInvoiceButton"
          type="button"
          class="schedNewCommButton center em-btn em-btn-green py-1 mr-4 mb-0"
          (click)="openPayModal()"
          [disabled]="!buttonsAvailable"
          data-test-id="pay-invoice"
        >
          {{ invoice?.invoiceTypeId === InvoiceType.Refund ? 'Refund' : 'Pay' }}
        </button>
      </ng-container>
      <ng-container *appAuthGuard="billingPolicy">
        <button
          *ngIf="invoice?.isPaid"
          class="btn em-btn em-btn-green mr-4 rounded"
          (click)="invoicePaidStatusHandler()"
          kendoTooltip
          tooltipClass="em-tooltip"
          position="top"
          title="Unlock Invoice"
          data-test-id="unlock-invoice"
        >
          <i class="fas fa-lock"></i>
        </button>
      </ng-container>
      <button
        *ngIf="!invoice?.isPaid && showCloseInvoiceButton"
        class="schedNewCommButton center em-btn em-btn-green py-1 mr-4 mb-0"
        [disabled]="!buttonsAvailable"
        (click)="closeInvoiceHandler()"
      >
        Close Invoice
      </button>
      <app-generic-send-email-popover
        class="d-flex"
        buttonClass="panel-box-button-icon mr-4"
        iconClass="far fa-envelope navy-font fs-20 pointer"
        toolTipTitle="Email"
        [initialEmailAddress]="currentPatient?.email"
        [sendEmailObservable]="getEmailInvoiceAsObservable"
      ></app-generic-send-email-popover>

      <!--A more reliable way of getting multi-page PDFs for an invoice. Does not currently show clinic's logo-->
      <button class="panel-box-button-icon mr-4" type="button" (click)="exportToPDF()">
        <i class="far fa-print" title="Print"></i>
      </button>
    </div>

    <ng-scrollbar
      id="detail-invoice-scroll"
      [autoHeightDisabled]="false"
      appDetermineMaxScrollHeight
      [itemsToWatch]="[updateLineItemsErrors, invoiceLineItemTaxKeys]"
    >
      <kendo-pdf-export #pdf [paperSize]="'A4'" [scale]="0.6" [landscape]="false" margin="1cm" class="invoice-export">
        <!-- Clinic Info Header -->
        <div class="row p-4 pb-0 clinic-info" #clinicInfo kendoTooltip tooltipClass="em-tooltip" position="top">
          <div class="col-6">
            <div class="d-flex flex-row">
              <img
                class="logo img-fluid"
                [src]="clinic?.logoUrl ? clinic?.logoUrl : '../../../assets/Avatars/clinic-logo.png'"
                alt="clinic-logo"
              />
              <div class="pl-3">
                <div *ngIf="clinic?.phoneNumber" class="d-flex align-items-center font-weight-bolder mb-1 text-nowrap">
                  <i class="far fa-phone mr-2" title="Phone Number"></i>
                  {{ clinic?.phoneNumber }}
                </div>
                <div *ngIf="clinic?.email" class="d-flex align-items-center font-weight-bolder mb-1 text-nowrap">
                  <i class="far fa-envelope mr-2" title="Email Address"></i>
                  {{ clinic?.email }}
                </div>
                <div *ngIf="clinic?.website" class="d-flex align-items-center font-weight-bolder mb-1 text-nowrap">
                  <i class="fal fa-globe mr-2" title="Address"></i>
                  {{ clinic?.website }}
                </div>
                <div *ngIf="clinic?.address" class="d-flex align-items-center font-weight-bolder">
                  <i class="far fa-map-marker-alt mr-2"></i>
                  {{ clinic?.address?.address1 + ', ' + clinic?.address?.postalCode }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-3">
            <div id="paid" *ngIf="invoice?.isPaid && showPaidStamp">
              <p id="paid-text" [attr.data-test-id]="'invoice-paid-overlay-text'">PAID</p>
            </div>
          </div>

          <div class="col-3 invoice-info">
            <div class="row">
              <h3 class="col-md-12">{{ getInvoiceType(invoice) }}</h3>
            </div>

            <div class="row">
              <div class="col-auto">
                <div class="font-weight-bolder text-nowrap">
                  {{ getInvoiceType(invoice) }} TO: <br />
                  {{ currentPatient?.firstName }} {{ currentPatient?.lastName }} <br />
                  {{ currentPatient?.mobileNumber }}
                </div>
              </div>

              <div class="col-auto">
                <div class="font-weight-bolder">
                  DATE:
                  <span class="font-weight-normal ml-1">{{ getLocalFormattedDate(invoice?.invoiceDate) }}</span>
                </div>
                <div
                  class="font-weight-bolder"
                  *ngIf="invoice?.invoiceNumber"
                  [attr.data-test-id]="'invoice-id'"
                  [attr.data-test-value]="invoice.invoiceNumber"
                >
                  {{ getInvoiceType(invoice) }} #:<span class="font-weight-normal ml-1">{{
                    invoice?.invoiceNumber
                  }}</span>
                </div>
              </div>
              <div class="col-auto px-0">
                <button
                  *ngIf="!invoice?.isPaid && !loading"
                  title="Edit Date"
                  (click)="editInvoiceDate(invoice?.id, invoice?.invoiceDate)"
                  class="btn btn-sm em-btn em-btn-green edit-button"
                  style="height: fit-content"
                >
                  <i class="far fa-pencil"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--Products & Services-->
        <div class="row">
          <div class="col">
            <div class="invoice-table py-0 grey-four-border-bottom">
              <ng-template #tooltipTemplate let-anchor>
                <div *ngIf="currentTooltips && currentTooltips.length > 0" class="container">
                  <div class="row" *ngFor="let currentTooltip of currentTooltips">
                    <span>{{ currentTooltip }}</span>
                  </div>
                </div>
              </ng-template>
              <div
                kendoTooltip
                tooltipClass="em-tooltip"
                position="top"
                showOn="hover"
                [tooltipTemplate]="tooltipTemplate"
                filter="i.info-icon"
                (mouseover)="showTooltip($event)"
                (mouseout)="currentTooltips = []"
              >
                <kendo-grid
                  [navigable]="true"
                  *ngIf="invoice?.invoiceLineItems"
                  [data]="invoiceLineItems"
                  (cellClick)="cellClickHandler($event)"
                  #grid="kendoGrid"
                  id="invoiceLineItemsGrid"
                  [loading]="invoiceLineItemsLoading"
                  [resizable]="true"
                  [sortable]="false"
                  [kendoGridExpandDetailsBy]="expandDetailsBy"
                  [expandedDetailKeys]="expandedDetailKeys"
                >
                  >
                  <kendo-grid-command-column
                    title=""
                    [width]="45"
                    *ngIf="invoiceHasUnpaidRemovableLineItems(invoice)"
                    class="action-column"
                    [footerClass]="{ 'text-right': true, 'font-weight-normal': true, 'text-white': true }"
                    [style]="{ padding: '4px 0', 'text-align': 'center' }"
                  >
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                      <button
                        *ngIf="
                          (dataItem.isManual || dataItem.relatedInvoiceLineItemId) &&
                          !dataItem.isPaid &&
                          dataItem.serviceType
                        "
                        kendoTooltip
                        [attr.data-test-id]="'delete-line-item'"
                        [attr.data-test-value]="dataItem.Id"
                        tooltipClass="em-tooltip"
                        position="top"
                        [disabled]="!!formGroup"
                        class="btn btn-sm em-btn em-btn-green"
                        (click)="onDeleteLineItem(dataItem)"
                      >
                        <i class="far fa-minus-circle" title="Delete"></i>
                      </button>
                    </ng-template>
                  </kendo-grid-command-column>
                  <kendo-grid-column
                    field="serviceType"
                    title="Item Description"
                    [footerClass]="{ 'text-right': false, 'font-weight-normal': true, 'text-white': true }"
                    [width]="150"
                  >
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      <div class="font-weight-bold" attr.data-test-id="regular-line-item">
                        {{ dataItem.serviceType }}
                        <i
                          [attr.sectionvalue]="dataItem.serviceId"
                          *ngIf="
                            dataItem?.isManual === true &&
                            ((!dataItem.serviceTypeDescription &&
                              dataItem.serviceTemplateId === serviceDetailTemplate.Injections) ||
                              dataItem.serviceTemplateId === serviceDetailTemplate.Coolsculpting)
                          "
                          class="info-icon ml-1 far fa-info-circle"
                        ></i>
                      </div>
                      <div *ngIf="dataItem?.serviceTypeDescription">{{ dataItem?.serviceTypeDescription }}</div>
                    </ng-template>
                    <ng-template kendoGridFooterTemplate>Totals: </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="note" title="Notes" [width]="90">
                    <ng-template kendoGridFooterTemplate> </ng-template>
                    <ng-template
                      *ngIf="invoice.isPaid"
                      kendoGridCellTemplate
                      let-dataItem="dataItem"
                      let-rowIndex="rowIndex"
                    >
                      <div [ngClass]="{ 'grey-five-font': !dataItem?.note }">
                        {{ dataItem?.note ? dataItem?.note : 'Add a note' }}
                      </div>
                    </ng-template>
                    <ng-template
                      *ngIf="!invoice.isPaid"
                      kendoGridCellTemplate
                      let-dataItem="dataItem"
                      let-rowIndex="rowIndex"
                    >
                      <div class="p-1 border min-box-size box-radius">{{ dataItem?.note }}</div>
                    </ng-template>
                    <ng-template
                      kendoGridEditTemplate
                      kendoGridFocusable
                      let-dataItem="dataItem"
                      let-column="column"
                      let-formGroup="formGroup"
                    >
                      <textarea
                        class="k-textbox notesbox"
                        [formControl]="formGroup.get(column.field)"
                        cdkTextareaAutosize
                      ></textarea>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="attributedToUser" title="Attributed To" [width]="110">
                    <ng-template
                      *ngIf="invoice.isPaid"
                      kendoGridCellTemplate
                      let-dataItem="dataItem"
                      let-rowIndex="rowIndex"
                    >
                      <div [ngClass]="{ 'grey-five-font': !dataItem?.attributedToUser }">
                        {{
                          dataItem?.attributedToUser
                            ? dataItem?.attributedToUser.firstName + ' ' + dataItem?.attributedToUser.lastName
                            : 'Select staff member'
                        }}
                      </div>
                    </ng-template>
                    <ng-template
                      *ngIf="!invoice.isPaid"
                      kendoGridCellTemplate
                      let-dataItem="dataItem"
                      let-rowIndex="rowIndex"
                    >
                      <mat-select
                        placeholder="
                      {{
                          dataItem?.attributedToUser != null && dataItem?.attributedToUser.firstName != null
                            ? dataItem?.attributedToUser.firstName
                            : ''
                        }}
                      {{
                          dataItem?.attributedToUser != null && dataItem?.attributedToUser.lastName != null
                            ? dataItem?.attributedToUser.lastName
                            : ''
                        }}"
                        [disabled]="true"
                      >
                      </mat-select>
                    </ng-template>
                    <ng-template
                      kendoGridEditTemplate
                      kendoGridFocusable
                      let-dataItem="dataItem"
                      let-column="column"
                      let-formGroup="formGroup"
                    >
                      <mat-select
                        class="grey-four-border-bottom"
                        name="StaffMember"
                        [value]="dataItem.attributedToUser"
                        [formControl]="formGroup.get('attributedToUser')"
                      >
                        <mat-option *ngFor="let user of users" [value]="user">
                          {{ user.firstName }} {{ user.lastName }}
                        </mat-option>
                      </mat-select>
                    </ng-template>
                    <ng-template kendoGridFooterTemplate> </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column
                    field="unitPrice"
                    title="Price"
                    [width]="75"
                    [class]="{ 'text-right': true }"
                    [headerClass]="{ 'text-right': true }"
                    [footerClass]="{ 'text-right': true, 'font-weight-normal': true, 'text-white': true }"
                    editor="numeric"
                  >
                    <ng-template kendoGridHeaderTemplate> Price </ng-template>
                    <ng-template kendoGridFooterTemplate>
                      {{ getAggregate('unitPrice') | currency }}
                    </ng-template>
                    <ng-template
                      *ngIf="invoice.isPaid"
                      kendoGridCellTemplate
                      let-dataItem="dataItem"
                      let-rowIndex="rowIndex"
                      let-column="column"
                    >
                      <div [ngClass]="{ 'grey-five-font': !dataItem?.unitPrice }">
                        {{ dataItem?.unitPrice != null ? (dataItem?.unitPrice | currency) : '$-' }}
                      </div>
                    </ng-template>
                    <ng-template
                      *ngIf="!invoice.isPaid"
                      kendoGridCellTemplate
                      let-dataItem="dataItem"
                      let-rowIndex="rowIndex"
                      let-column="column"
                    >
                      <div
                        *ngIf="dataItem.serviceType !== 'Credit'"
                        [ngClass]="{ 'grey-five-font': !dataItem?.unitPrice }"
                      >
                        {{ dataItem?.unitPrice != null ? (dataItem?.unitPrice | currency) : '$-' }}
                      </div>

                      <kendo-numerictextbox
                        class="t-right"
                        *ngIf="dataItem.serviceType === 'Credit'"
                        [format]="'n2'"
                        placeholder="{{ dataItem?.unitPrice != null ? (dataItem?.unitPrice | currency) : '$-' }}"
                        [disabled]="true"
                      >
                      </kendo-numerictextbox>
                    </ng-template>

                    <ng-template
                      kendoGridFocusable
                      kendoGridEditTemplate
                      let-dataItem="dataItem"
                      let-rowIndex="rowIndex"
                      let-column="column"
                    >
                      <div
                        *ngIf="dataItem.serviceType !== 'Credit'"
                        [ngClass]="{ 'grey-five-font': !dataItem?.unitPrice }"
                      >
                        {{ dataItem?.unitPrice != null ? (dataItem?.unitPrice | currency) : '$-' }}
                      </div>
                      <kendo-numerictextbox
                        class="t-right"
                        *ngIf="dataItem.serviceType === 'Credit'"
                        [format]="'n2'"
                        [formControl]="$any(formGroup.get(column.field))"
                      >
                      </kendo-numerictextbox>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column
                    field="quantity"
                    title="Qty"
                    [width]="50"
                    [class]="{ 'text-right': true }"
                    [headerClass]="{ 'text-right': true }"
                    [footerClass]="{ 'text-right': true, 'font-weight-normal': true, 'text-white': true }"
                    editor="numeric"
                  >
                    <ng-template kendoGridHeaderTemplate> Qty </ng-template>
                    <ng-template kendoGridFooterTemplate>
                      {{ getAggregate('quantity') }}
                    </ng-template>
                    <ng-template
                      *ngIf="invoice.isPaid"
                      kendoGridCellTemplate
                      let-dataItem="dataItem"
                      let-rowIndex="rowIndex"
                    >
                      <div [ngClass]="{ 'grey-five-font': !dataItem?.quantity }">
                        {{ dataItem?.quantity ? dataItem?.quantity : '-' }}
                      </div>
                    </ng-template>
                    <ng-template
                      *ngIf="!invoice.isPaid"
                      kendoGridCellTemplate
                      let-dataItem="dataItem"
                      let-rowIndex="rowIndex"
                    >
                      <div
                        *ngIf="dataItem?.clinicProductId == null"
                        class="non-product-qty"
                        [ngClass]="{ 'grey-five-font': !dataItem?.quantity }"
                      >
                        {{ dataItem?.quantity ? dataItem?.quantity : '-' }}
                      </div>
                      <div *ngIf="dataItem?.clinicProductId != null">
                        <kendo-numerictextbox
                          class="t-right quantity-width"
                          [format]="'n0'"
                          placeholder="{{ dataItem?.quantity ? dataItem?.quantity : '-' }}"
                          [disabled]="true"
                        ></kendo-numerictextbox>
                      </div>
                    </ng-template>
                    <ng-template
                      kendoGridFocusable
                      kendoGridEditTemplate
                      let-column="column"
                      let-formGroup="formGroup"
                      let-dataItem="dataItem"
                    >
                      <div
                        *ngIf="dataItem?.clinicProductId == null"
                        [ngClass]="{ 'grey-five-font': !dataItem?.quantity }"
                      >
                        {{ dataItem?.quantity ? dataItem?.quantity : '-' }}
                      </div>
                      <div *ngIf="dataItem?.clinicProductId != null">
                        <kendo-numerictextbox
                          class="t-right quantity-width"
                          kendoGridFocusable
                          [format]="'n0'"
                          #quantityTextBox="popupAnchor"
                          popupAnchor
                          [formControl]="formGroup.get(column.field)"
                          (valueChange)="onQuantityChange($event)"
                        ></kendo-numerictextbox>
                        <kendo-popup
                          [anchor]="quantityTextBox.element"
                          *ngIf="formGroup.get(column.field).invalid && !formGroup.get(column.field).untouched"
                          popupClass="k-widget k-tooltip k-tooltip-validation k-invalid-msg"
                        >
                          <i class="fas fa-exclamation-circle"></i>
                          Quantity must be > 0
                        </kendo-popup>
                      </div>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column
                    field="discountPrice"
                    title="Discount"
                    class="calendar-red-font text-right"
                    [class]="{ 'text-right': true }"
                    [headerClass]="{ 'text-right': true }"
                    [footerClass]="{ 'text-right': true, 'font-weight-normal': true, 'text-white': true }"
                    [width]="75"
                  >
                    <ng-template kendoGridHeaderTemplate>
                      <span> Discount </span>
                      <i
                        class="medium far fa-question-circle ml-1"
                        kendoTooltip
                        tooltipClass="em-tooltip"
                        title="Enter a % symbol at the end of the discount value to discount by percentage."
                      ></i>
                    </ng-template>
                    <ng-template kendoGridFooterTemplate>
                      {{ getAggregate('discountPrice') | currency }}
                    </ng-template>
                    <ng-template
                      *ngIf="invoice.isPaid"
                      kendoGridCellTemplate
                      let-dataItem="dataItem"
                      let-rowIndex="rowIndex"
                    >
                      <div [ngClass]="{ 'grey-five-font': !dataItem?.discountPrice }">
                        {{
                          dataItem?.discountPrice != null
                            ? dataItem?.isPercentDiscount
                              ? dataItem?.discountPrice + '%'
                              : (dataItem?.discountPrice | currency)
                            : '-'
                        }}
                      </div>
                    </ng-template>
                    <ng-template
                      *ngIf="!invoice.isPaid"
                      kendoGridCellTemplate
                      let-dataItem="dataItem"
                      let-rowIndex="rowIndex"
                    >
                      <div *ngIf="dataItem.serviceType === 'Credit'" class="grey-five-font">$-</div>
                      <div *ngIf="dataItem.serviceType !== 'Credit' && dataItem.patientMembershipId === null">
                        <kendo-numerictextbox
                          class="t-right discount-width"
                          [placeholder]="
                            dataItem?.discountPrice != null
                              ? dataItem?.isPercentDiscount
                                ? dataItem?.discountPrice + '%'
                                : (dataItem?.discountPrice | currency)
                              : '-'
                          "
                          [disabled]="true"
                        ></kendo-numerictextbox>
                      </div>
                    </ng-template>
                    <ng-template
                      kendoGridEditTemplate
                      kendoGridFocusable
                      let-column="column"
                      let-formGroup="formGroup"
                      let-dataItem="dataItem"
                    >
                      <div *ngIf="dataItem.serviceType === 'Credit'" class="grey-five-font">$-</div>
                      <div *ngIf="dataItem.serviceType !== 'Credit' && dataItem.patientMembershipId === null">
                        <kendo-textbox
                          class="t-right discount-width"
                          kendoGridFocusable
                          popupAnchor
                          [formControl]="formGroup.get(column.field)"
                          (valueChange)="onItemDiscountChange($event)"
                          #anchor
                        ></kendo-textbox>
                        <kendo-popup
                          [anchor]="anchorRef"
                          *ngIf="formGroup.get(column.field).invalid && !formGroup.get(column.field).untouched"
                          popupClass="k-widget k-tooltip k-tooltip-validation k-invalid-msg width-max-content"
                        >
                          <i class="fas fa-exclamation-circle"></i>
                          Discount must be a dollar or percentage value.
                        </kendo-popup>
                      </div>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column
                    title="Subtotal"
                    [class]="{ 'text-right': true }"
                    [headerClass]="{ 'text-right': true }"
                    [footerClass]="{ 'text-right': true, 'font-weight-normal': true, 'text-white': true }"
                    [width]="75"
                  >
                    <ng-template kendoGridHeaderTemplate> Subtotal </ng-template>
                    <ng-template kendoGridFooterTemplate>
                      {{ getAggregate('subtotal') | currency }}
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      <div
                        [ngClass]="{
                          'calendar-red-font': dataItem?.discountPrice,
                          'grey-five-font': !dataItem?.unitPrice
                        }"
                      >
                        {{ dataItem?.total != null ? (dataItem.total | currency) : '$-' }}
                      </div>
                    </ng-template>
                  </kendo-grid-column>
                  <ng-container *ngIf="invoiceLineItemTaxKeys && invoiceLineItemTaxKeys.length > 0">
                    <kendo-grid-column
                      *ngFor="let item of invoiceLineItemTaxKeys"
                      [width]="invoiceLineItemTaxKeys.length == 1 ? 75 : invoiceLineItemTaxKeys.length == 2 ? 50 : 45"
                      [title]="item"
                      [class]="{ 'text-right': true }"
                      [headerClass]="{ 'text-right': true }"
                      [footerClass]="{ 'text-right': true, 'font-weight-normal': true, 'text-white': true }"
                    >
                      <ng-template kendoGridHeaderTemplate>
                        {{ item }}
                      </ng-template>
                      <ng-template kendoGridFooterTemplate let-column>
                        {{ getAggregate(column.title, invoiceLineItemTaxValuesObject) | currency }}
                      </ng-template>
                      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        {{
                          dataItem?.id && invoiceLineItemTaxValuesObject[dataItem?.id] !== undefined
                            ? (invoiceLineItemTaxValuesObject[dataItem?.id][item] | currency)
                            : ''
                        }}
                      </ng-template>
                    </kendo-grid-column>
                  </ng-container>
                  <kendo-grid-column
                    field="finalTotal"
                    title="Total"
                    [width]="75"
                    [headerClass]="{ 'text-right': true }"
                    [footerClass]="{ 'text-right': true, 'font-weight-normal': true, 'text-white': true }"
                    [editable]="false"
                  >
                    <ng-template kendoGridFooterTemplate>
                      <div [attr.data-test-id]="'invoice-final-total'">{{ getAggregate('finalTotal') | currency }}</div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      <div [ngClass]="{ 'grey-five-font': !dataItem?.finalTotal }">
                        {{ dataItem?.finalTotal != null ? (dataItem?.finalTotal | currency) : '$-' }}
                      </div>
                    </ng-template>
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      <div [ngClass]="{ 'grey-five-font': !dataItem?.finalTotal }">
                        {{ dataItem?.finalTotal != null ? (dataItem?.finalTotal | currency) : '$-' }}
                      </div>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-pdf [landscape]="false" [fileName]="getFileName()" paperSize="A4" [scale]="0.6">
                    <kendo-grid-pdf-margin top="4cm" left="1cm" right="1cm" bottom="5cm"> </kendo-grid-pdf-margin>
                    <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
                      <div class="pdf-template">
                        <div class="header">
                          <div
                            class="px-4 pt-4 pb-1 clinic-info d-flex justify-content-between"
                            [innerHTML]="clinicInfo.innerHTML | keepHtml"
                          ></div>
                        </div>
                        <div class="footer mt-4 grey-four-border-top">
                          <div class="row py-2 mx-0 font-navy" [innerHTML]="totalSection.innerHTML | keepHtml"></div>
                        </div>
                        <div class="mt-2 grey-four-border-top" *ngIf="invoice?.invoiceTransactions?.length > 0">
                          <div class="column-header mr-auto mt-2">TRANSACTIONS</div>
                          <div class="py-2 mx-0 px-0 font-navy" [innerHTML]="transGrid.innerHTML | keepHtml"></div>
                          <div class="row w-100 px-0 py-2 mx-0 grey-four-border-bottom font-navy justify-content-end">
                            <div class="col-xl-4 col-5 p-0 mr-2">
                              <div [innerHTML]="creditsTotalSection.innerHTML | keepHtml"></div>
                            </div>
                            <div class="col-xl-4 col-5 p-0">
                              <div [innerHTML]="paymentsTotalSection.innerHTML | keepHtml"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </kendo-grid-pdf>
                </kendo-grid>
              </div>
            </div>
          </div>
        </div>

        <!--Transactions-->
        <div class="d-flex px-2 mt-4">
          <div class="column-header mr-auto">
            TRANSACTIONS
            <ng-container *appAuthGuard="patientAccountTransactionsPolicy">
              <button
                data-test-id="edit-transactions-button"
                *ngIf="
                  !invoice?.isPaid && outstandingBalance == 0 && !loading && invoice?.invoiceTransactions?.length > 0
                "
                title="Edit Transactions"
                (click)="editTransactionsInPayModal()"
                class="btn btn-sm em-btn em-btn-green edit-button"
                style="height: fit-content"
              >
                <i class="far fa-pencil"></i>
              </button>
            </ng-container>
          </div>
        </div>
        <div>
          <div class="row">
            <div class="col" #transGrid>
              <div class="invoice-table py-0 grey-four-border-bottom transactions">
                <kendo-grid
                  *ngIf="invoice?.invoiceTransactions"
                  [data]="invoice.invoiceTransactions"
                  (cellClick)="transactionsGridCellClickHandler($event)"
                  #transactionsGrid="kendoGrid"
                  [loading]="transactionsLoading"
                  id="transactionsGrid"
                >
                  <kendo-grid-command-column
                    title=""
                    [width]="70"
                    class="action-column"
                    [footerClass]="{ 'text-right': true, 'font-weight-normal': true, 'text-white': true }"
                    [style]="{ padding: '4px 0', 'text-align': 'center' }"
                    *ngIf="invoiceHasValidVoidTransactions(invoice)"
                  >
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                      <button
                        kendoTooltip
                        tooltipClass="em-tooltip"
                        position="top"
                        class="btn btn-sm em-btn em-btn-green"
                        (click)="onVoidTransaction(dataItem)"
                        *ngIf="
                          !isVoidTransaction(dataItem) && !isOldTransaction(dataItem) && isApprovedTransaction(dataItem)
                        "
                      >
                        <i class="fas fa-ban" title="Void Transaction"></i>
                      </button>
                    </ng-template>
                  </kendo-grid-command-column>
                  <kendo-grid-column field="transactionDate" title="Transaction Date">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      {{ getLocalFormattedDateTime(dataItem?.transactionDate) }}
                    </ng-template>
                  </kendo-grid-column>

                  <kendo-grid-column field="financeTransactionTypeId" title="Transaction Type">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      {{ financeTransactionTypes[dataItem?.financeTransactionTypeId] }}
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="paymentMethod" title="Payment Method" [width]="200">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      {{ getPaymentMethodName(dataItem?.paymentMethodId) }}
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="description" title="Notes">
                    <ng-template kendoGridFooterTemplate> </ng-template>
                    <ng-template
                      *ngIf="invoice.isPaid"
                      kendoGridCellTemplate
                      let-dataItem="dataItem"
                      let-rowIndex="rowIndex"
                    >
                      <div [ngClass]="{ 'grey-five-font': !dataItem?.description }">
                        {{ dataItem?.description ? dataItem?.description : 'Add a note' }}
                      </div>
                    </ng-template>
                    <ng-template
                      *ngIf="!invoice.isPaid"
                      kendoGridCellTemplate
                      let-dataItem="dataItem"
                      let-rowIndex="rowIndex"
                    >
                      <div class="p-1 border min-box-size box-radius">{{ dataItem?.description }}</div>
                    </ng-template>
                    <ng-template
                      kendoGridEditTemplate
                      kendoGridFocusable
                      let-dataItem="dataItem"
                      let-column="column"
                      let-formGroup="formGroup"
                    >
                      <textarea
                        class="k-textbox notesbox"
                        [formControl]="formGroup.get(column.field)"
                        cdkTextareaAutosize
                      ></textarea>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="createdBy" title="Created By" editor="numeric">
                    <ng-template
                      *ngIf="invoice.isPaid"
                      kendoGridCellTemplate
                      let-dataItem="dataItem"
                      let-rowIndex="rowIndex"
                    >
                      {{ dataItem?.createdBy.firstName + ' ' + dataItem?.createdBy.lastName }}
                    </ng-template>
                    <ng-template
                      *ngIf="!invoice.isPaid"
                      kendoGridCellTemplate
                      let-dataItem="dataItem"
                      let-rowIndex="rowIndex"
                    >
                      <mat-select
                        placeholder="
                    {{
                          dataItem?.createdBy != null && dataItem?.createdBy.firstName != null
                            ? dataItem?.createdBy.firstName
                            : ''
                        }}
                    {{
                          dataItem?.createdBy != null && dataItem?.createdBy.lastName != null
                            ? dataItem?.createdBy.lastName
                            : ''
                        }}"
                        [disabled]="true"
                      >
                      </mat-select>
                    </ng-template>

                    <ng-template
                      kendoGridEditTemplate
                      let-dataItem="dataItem"
                      let-column="column"
                      let-formGroup="formGroup"
                    >
                      <mat-select
                        data-test="created-by-dropdown"
                        class="grey-four-border-bottom"
                        name="StaffMember"
                        [value]="dataItem.createdBy"
                        [formControl]="formGroup.get(column.field)"
                      >
                        <mat-option *ngFor="let user of users" [value]="user">
                          {{ user.firstName }} {{ user.lastName }}
                        </mat-option>
                      </mat-select>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="amount" title="Amount">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      {{ dataItem?.amount | currency }}
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-pdf [landscape]="false" [fileName]="getFileName()" paperSize="A4" [scale]="0.6">
                    <kendo-grid-pdf-margin top="6cm" left="1cm" right="1cm" bottom="6cm"> </kendo-grid-pdf-margin>
                  </kendo-grid-pdf>
                </kendo-grid>
              </div>
            </div>
          </div>

          <div class="row w-100 px-0 py-2 mx-0 grey-four-border-bottom font-navy justify-content-end">
            <div #creditsTotalSection class="col-xl-4 col-5 p-0 mr-2">
              <div
                *ngIf="totalCredits > 0"
                class="d-flex align-items-center justify-content-between navy-background white-font total-paid-section"
              >
                <span class="font-weight-bolder">CREDITS USED: </span><span> {{ totalCredits | currency }}</span>
              </div>
            </div>
            <div #paymentsTotalSection class="col-xl-4 col-5 p-0">
              <div
                class="d-flex align-items-center justify-content-between navy-background white-font total-paid-section"
              >
                <span class="font-weight-bolder">TOTAL PAID: </span><span> {{ totalPaid | currency }}</span>
              </div>
            </div>
          </div>
          <div *ngIf="hasMerchantTransactions">
            <div class="column-header mr-auto">RECEIPT</div>
            <div
              *ngFor="let invoiceTransaction of getEmvTransactions()"
              class="row w-100 px-0 py-2 mx-0 grey-four-border-bottom font-navy justify-content-left"
            >
              <div class="col-xl-4 p-0">
                <div>
                  <span>TYPE:&nbsp;</span><span> {{ invoiceTransaction.emvData?.transactionType }}</span>
                </div>
                <div>
                  <span>ENTRY METHOD:&nbsp;</span><span> {{ invoiceTransaction.emvData?.entryMethod }}</span>
                </div>
                <div>
                  <span>*******{{ invoiceTransaction.emvData?.responseText }}*******</span>
                </div>
                <div>
                  <span>{{ invoiceTransaction.emvData?.cardBrand }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <span>{{ invoiceTransaction.emvData?.maskedPan }}</span>
                </div>
                <div>
                  <span>TENDER:&nbsp;</span><span> {{ invoiceTransaction.emvData?.tender | currency }}</span>
                </div>
              </div>
              <div class="col-xl-4 p-0">
                <div>
                  <span>TC:&nbsp;</span><span> {{ invoiceTransaction.emvData?.applicationCryptogram }}</span>
                </div>
                <div>
                  <span>TVR:&nbsp;</span><span> {{ invoiceTransaction.emvData?.terminalVerificationResults }}</span>
                </div>
                <div>
                  <span>AID:&nbsp;</span><span> {{ invoiceTransaction.emvData?.terminalApplicationId }}</span>
                </div>
                <div>
                  <span>IAD:&nbsp;</span><span> {{ invoiceTransaction.emvData?.issuerApplicationData }}</span>
                </div>
              </div>
              <div class="col-xl-4 p-0">
                <div>
                  <span>APPROVAL #:&nbsp;</span><span> {{ invoiceTransaction.emvData?.authorizationCode }}</span>
                </div>
                <div>
                  <span>APPLICATION LABEL:&nbsp;</span><span> {{ invoiceTransaction.emvData?.applicationLabel }}</span>
                </div>
                <div>
                  <span>TERMINAL #:&nbsp;</span><span> {{ invoiceTransaction.emvData?.terminalId }}</span>
                </div>
                <div>
                  <span>DATE:&nbsp;</span
                  ><span>{{ getLocalFormattedDateTime(invoiceTransaction.transactionDate) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Credits & Custom Message-->
        <div class="row px-0 py-3 mx-auto grey-four-border-bottom font-navy total-section" #totalSection>
          <div class="col-12 d-flex flex-column px-0">
            <div class="row d-flex justify-content-center">
              <div class="mb-3 mt-auto credit-section">
                <span class="font-weight-bolder mr-2">YOUR REMAINING CREDITS:</span
                ><span data-test-id="remaining-credits">
                  {{
                    invoice?.isPaid && invoice?.patientCreditsAtTimePaid
                      ? invoice?.patientCreditsAtTimePaid
                      : (patientAvailableCredit | currency)
                  }}
                </span>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div
                class="py-0 mb-0 custom-invoice-text"
                [innerHTML]="
                  (invoice?.isPaid && invoice?.clinicInvoiceTextAtTimePaid
                    ? invoice?.clinicInvoiceTextAtTimePaid
                    : clinic?.customInvoiceText
                  ) | keepHtml
                "
              ></div>
            </div>
          </div>
        </div>
      </kendo-pdf-export>
    </ng-scrollbar>
  </div>
</div>

<ng-template #paymentModal let-dialogRef="dialogRef">
  <app-panel [isMedium]="true" [dialogRef]="dialogRef" (isVisibleChange)="panelVisibilityChange($event)">
    <app-invoice-payment
      [parentClosed]="payModalClosed"
      [patientId]="currentPatient.patientId"
      [invoice]="selectedInvoice"
      [editTransactionMode]="isEditTransactionMode"
      [paymentType]="
        invoice?.invoiceTypeId === InvoiceType.Refund
          ? PaymentType.Refund
          : invoice?.invoiceTypeId === InvoiceType.Recurring
          ? PaymentType.Recurring
          : PaymentType.Regular
      "
      (paymentComplete)="onInvoicePay(); dialogRef.close()"
    ></app-invoice-payment>
  </app-panel>
</ng-template>
