import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ActivatedRoute, Router } from '@angular/router';
import { ObservationTypes } from '@models/observation/observation';
import { Service } from '@models/service/service';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { ServiceListType } from '@models/service/service-list-type';
import { ServiceNote } from '@models/service/service-note';
import { PlannedTreatment } from '@models/treatment-planning/planned-treatment';
import { TreatmentState } from '@models/treatment-planning/treatment-state';
import { isNullOrUndefined } from '@app/shared/helpers';
import { PaymentStatus } from '@models/appointments/payment-status';

export const DATE_FORMAT = {
  parse: {
    dateInput: 'yyyy-MM-dd',
  },
  display: {
    dateInput: 'yyyy-MM-dd',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-planned-treatments',
  templateUrl: './planned-treatments.component.html',
  styleUrls: ['./planned-treatments.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})
export class PlannedTreatmentsComponent implements OnInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() plannedTreatments: PlannedTreatment[];
  @Input() isPlannedTreatmentMultiple: boolean;
  plannedTreatmentsForm: FormGroup;
  treatmentState = TreatmentState;
  loading = false;
  formLoaded = false;
  serviceDetailTemplate = ServiceDetailTemplate;
  observationTypes = ObservationTypes;
  serviceList: Service[];
  serviceListType = ServiceListType;

  PaymentStatus = PaymentStatus;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.plannedTreatmentsForm = this.fb.group({
      plannedTreatments: this.fb.array([]),
    });
    this.initFormPlannedTreatments();
    this.onFormChange();
  }

  private initFormPlannedTreatments() {
    this.loading = true;
    this.serviceList = [];
    const formArray = <FormArray>this.plannedTreatmentsForm.get('plannedTreatments');
    if (!isNullOrUndefined(this.plannedTreatments)) {
      this.plannedTreatments.forEach((pt) => {
        this.serviceList.push(pt.service);
        formArray.push(
          this.fb.group({
            id: [pt.id],
            rank: [pt.rank, Validators.required],
            service: this.fb.group(pt.service),
            concern: [pt.concern],
            notes: [pt.notes],
            createdDate: [pt.createdDate],
            updatedDate: [pt.updatedDate],
            plannedDate: [pt.plannedDate],
            scheduledServiceId: [pt.scheduledServiceId],
            dueDate: [pt.dueDate],
            treatmentState: [pt.treatmentState],
            price: [
              pt.service.governmentBilling
                ? 0
                : pt.service.chargeAmount
                ? pt.service.chargeAmount
                : pt.service.defaultPrice
                ? pt.service.defaultPrice
                : 0,
              Validators.required,
            ],
            paymentStatus: [pt.paymentStatus],
            plannedTreatmentMultipleId: [pt.plannedTreatmentMultipleId],
          })
        );
      });
    }
    this.loading = false;
  }

  private onFormChange() {
    this.plannedTreatmentsForm.valueChanges.subscribe(() => {
      const plannedTreatments: PlannedTreatment[] = [];
      this.getPlannedTreatmentControls().forEach((pt, idx) => {
        const plannedTreatment = new PlannedTreatment(pt.value);
        plannedTreatment.service = this.serviceList[idx];
        if (plannedTreatment.service.serviceDetailTemplateId === this.serviceDetailTemplate.None) {
          plannedTreatment.service.defaultPrice = pt.value.price;
        }
        if (isNullOrUndefined(pt.value.price)) {
          pt.value.price = 0;
        }
        plannedTreatments.push(new PlannedTreatment(plannedTreatment));
      });
    });
  }

  public getPlannedTreatmentControls() {
    return (<FormArray>this.plannedTreatmentsForm.get('plannedTreatments')).controls;
  }

  public onDeleteService(index: number, plannedTreatment: PlannedTreatment) {
    if (plannedTreatment.id > 0) {
      // The service has been saved in the database so we must remove it from the database as well
      //this.treatmentPlanFormService.treatmentPlanDirty = true;
      //this.plannedTreatmentsToDelete.push(plannedTreatment.id);
    }

    (<FormArray>this.plannedTreatmentsForm.get('plannedTreatments')).removeAt(index);
    this.serviceList.splice(index, 1);
  }

  public onEditService(index: number, plannedTreatment: PlannedTreatment) {
    if (plannedTreatment.id > 0) {
      // TODO
    }
  }

  onGetServiceNotes(index: number): ServiceNote[] {
    return this.serviceList[index].serviceNotes ? this.serviceList[index].serviceNotes : [];
  }
  onViewServiceDetail(pt: PlannedTreatment) {
    const service = pt.service;
    const treatmentFromToday = true;
    const treatmentPlanning = true;

    this.router.navigate(
      [
        'detail',
        service.serviceId,
        treatmentFromToday,
        treatmentPlanning,
        service.isLocked || pt.paymentStatus == PaymentStatus.Paid,
      ],
      {
        relativeTo: this.route.parent,
      }
    );
  }
}
