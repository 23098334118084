<ngx-loading [show]="loading"></ngx-loading>
<kendo-grid
  class="emr-kendo-grid"
  [data]="gridView"
  [style.maxHeight.%]="100"
  [pageSize]="gridState.take"
  [pageable]="true"
  [skip]="gridState.skip"
  [sort]="gridState.sort"
  [sortable]="{
    allowUnsort: false,
    mode: 'single'
  }"
  (pageChange)="pageChange($event)"
  (sortChange)="sortChange($event)"
  (excelExport)="onExcelExport($event)"
  #grid="kendoGrid"
>
  <kendo-grid-column field="physicianName" title="Physician">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="referralCount" title="Total Referrals">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-excel fileName="referrals_report.xlsx" [fetchData]="allData"></kendo-grid-excel>
</kendo-grid>
