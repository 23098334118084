import { Injectable } from '@angular/core';
import { TempEvent } from '@models/appointments/temp-event';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';

export enum ScheduleView {
  Appointments = 'Appointments',
  NoShowAppointments = 'NoShowAppointments',
  StaffSchedules = 'StaffSchedules',
}

export enum ScheduleMode {
  DayView = 'resourceTimeGridDay',
  WeekView = 'resourceTimeGridWeek',
}

@Injectable()
export class EventsService {
  scheduleView = ScheduleView.Appointments;
  scheduleMode = ScheduleMode.DayView;
  movingAppointment: boolean;
  selectedPref: string;
  preferredAppoints: any[] = [];
  blockedScheduleMode = false;
  selectedProviderId: string;

  // Observable string sources
  private componentMethodCallSource = new Subject<any>();
  private closePanelCallSource = new Subject<any>();
  private updateCreateVisitTimeProviderSource = new BehaviorSubject<TempEvent>(null);
  private removeAppointmentCallSource = new Subject<any>();
  private scheduleViewCallSource = new Subject<any>();
  private scheduleModeChangedSource = new Subject<ScheduleMode>();

  appointmentUpdated = new Subject<any>();
  appointmentAdded = new Subject<any>();
  appointmentRemoved = new Subject<any>();
  closeCreateVisitPanel = new Subject<any>();
  visitPanelOpen = new Subject<boolean>();

  appointmentUpdatedListener = this.appointmentUpdated.asObservable();
  appointmentAddedListener = this.appointmentAdded.asObservable();
  appointmentRemovedListener = this.appointmentRemoved.asObservable();
  closeCreateVisitPanelListener = this.closeCreateVisitPanel.asObservable();
  visitPanelOpenListener = this.visitPanelOpen.asObservable();
  scheduleModeChangedListener = this.scheduleModeChangedSource.asObservable();

  private patientListUpdatedSource = new Subject<any>();

  // Observable string streams
  closeSidePanel$ = this.closePanelCallSource.asObservable();
  updateCreateVisitTimeProvider$ = this.updateCreateVisitTimeProviderSource.asObservable();
  removeAppointment$ = this.removeAppointmentCallSource.asObservable();
  patientListChanged$ = this.patientListUpdatedSource.asObservable();
  scheduleViewChanged$ = this.scheduleViewCallSource.asObservable();

  now = moment();
  selectedDate = new BehaviorSubject(new Date(Date.now()));
  currentDate = this.selectedDate.asObservable();
  tempEvent: TempEvent = {
    start: moment().startOf('day').toDate(),
    end: moment().startOf('day').toDate(),
    provider: null,
    isSelection: false,
  };

  constructor() {}

  setTempEvent(event: TempEvent) {
    this.tempEvent = event;
    this.updateCreateVisitTimeAndProvider(event);
  }

  getTempEvent(): TempEvent {
    return this.tempEvent;
  }

  setSelectedDate(date: Date) {
    this.selectedDate.next(date);
  }

  patientListUpdated() {
    this.patientListUpdatedSource.next();
  }
  // Service message commands
  callAppointmentListenerMethod() {
    this.componentMethodCallSource.next();
  }
  closePanel(event?: any) {
    this.closePanelCallSource.next(event);
  }
  updateCreateVisitTimeAndProvider(event: TempEvent) {
    this.updateCreateVisitTimeProviderSource.next(event);
  }
  callRemoveAppointmentMethod(currentAppointmentId) {
    this.removeAppointmentCallSource.next(currentAppointmentId);
  }

  scheduleViewChanged(scheduleView) {
    this.scheduleViewCallSource.next(scheduleView);
  }
  setScheduleMode(scheduleMode: ScheduleMode) {
    this.scheduleMode = scheduleMode;
    this.scheduleModeChangedSource.next(scheduleMode);
  }
}
