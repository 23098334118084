import { Tag } from '../tag/tag';
import { DocumentType } from './document-type';

export class ClinicDocument {
  public id: number;
  public name: string;
  public filePath: string;
  public notes: string;
  public file: File;
  public documentTypeId: number;
  public documentType: DocumentType;
  public tags: Tag[];
  public uploadDate: Date;
  public modifiedDate: Date;
  public pdfToHtmlUrl: string;
  public eFormDefinition: string;
  public isDeleted: boolean;

  public constructor(init?: Partial<ClinicDocument>) {
    Object.assign(this, init);
    if (this.modifiedDate && typeof(this.modifiedDate) == 'string') this.modifiedDate = new Date(this.modifiedDate);
    if (this.modifiedDate && !this.uploadDate) this.uploadDate = this.modifiedDate;
    else if (this.uploadDate && typeof(this.uploadDate) == 'string') this.uploadDate = new Date(this.uploadDate);
  }
}
