<ngx-loading [show]="loading"></ngx-loading>

<div class="modal-header border-0 p-0">
  <button
    type="button"
    class="btn white-font p-0 ml-auto mr-2"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')">
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="container p-0 pb-3">
  <div class="search-container navy-background justify-content-center clinic-doc-container">
    <h6 class="modal-title text-uppercase white-font text-center mb-4">Add eForm</h6>
    <div class="input-group pb-2">
      <div class="input-group-prepend">
        <div class="input-group-text border-radius grey-three-background border-0">
          <i class="fa fa-search"></i>
        </div>
      </div>
      <input
        [formControl]="searchFormControl"
        type="text"
        name="docSearch"
        class="form-control border-0 grey-three-background"
        placeholder="Search">
    </div>

    <div *ngIf="filterType" class="d-flex align-items-center justify-content-center">

        <button (click)="resetFilter()" class="btn em-btn filter-button ml-2">
            <i class="fa fa-times"></i>
            {{filterType.name}}
        </button>
    </div>
  </div>

  <div class="clinic-doc-container">
    <div
      *ngFor="let item of clinicDocuments"
      class="d-flex align-items-center navy-font clinic-item" >
      <div class="d-flex p-2">{{item.name}}</div>
      <div class="d-flex flex-grow-1 justify-content-end">
        <button class="btn em-btn em-btn-green btn-small" (click)="assignClinicDocument(item)">Assign</button>
      </div>
    </div>
  </div>

  <div class="save-changes-container my-3 text-center">
    <button
      class="btn em-btn em-btn-green ml-2"
      (click)="activeModal.dismiss('Cross click')">Cancel</button>
  </div>
</div>
