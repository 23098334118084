import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from '@app/shared/helpers';
import { eTreatmentForm } from '@models/etreatment-forms/etreatment-form';
import { eTreatmentFormCategory } from '@models/etreatment-forms/etreatment-form-category';
import { eTreatmentFormCategoryService } from '@services/etreatment-form-category.service';
import { eTreatmentFormService } from '@services/etreatment-form.service';
import { FormatterService } from '@services/formatter.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-etreatment-form',
  templateUrl: './edit-etreatment-form.component.html',
  styleUrls: ['./edit-etreatment-form.component.less'],
})
export class EditEtreatmentFormComponent implements OnInit {
  loading = false;
  disableGrid = true;
  unsub: Subject<void> = new Subject<void>();
  eTreatmentFormId: number;

  form: FormGroup;
  addOrUpdate: String;
  isNew = false;

  eTreatmentFormCategories: eTreatmentFormCategory[] = [];

  constructor(
    private fb: FormBuilder,
    private eTreatmentFormCategoryService: eTreatmentFormCategoryService,
    private eTreatmentFormService: eTreatmentFormService,
    public formatterService: FormatterService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      if (+params['id'] === this.eTreatmentFormService.defaultId) {
        this.isNew = true;
        this.addOrUpdate = 'Create';
        this.eTreatmentFormId = 0;
      } else {
        this.eTreatmentFormId = +params['id'];
        this.isNew = false;
        this.addOrUpdate = 'Update';
      }

      this.geteTreatmentFormCategories();
      this.initForm();

      this.eTreatmentFormService.geteTreatmentFormById(this.eTreatmentFormId).subscribe((form) => {
        if (!isNullOrUndefined(form)) {
          this.setFormValues(form);
        }
      });
    });
  }

  geteTreatmentFormCategories() {
    this.eTreatmentFormCategories = [];
    this.eTreatmentFormCategoryService.geteTreatmentFormCategories().subscribe((res) => {
      this.eTreatmentFormCategories = res;
    });
  }

  private initForm() {
    this.form = this.fb.group({
      id: new FormControl(this.eTreatmentFormId, [Validators.required]),
      eTreatmentFormCategoryId: new FormControl(0, [Validators.required]),
      name: new FormControl('', [Validators.required]),
      formDefinition: new FormControl(''),
    });
  }

  private setFormValues(eTreatmentForm: eTreatmentForm) {
    this.form.controls['id'].setValue(eTreatmentForm.id);
    this.form.controls['eTreatmentFormCategoryId'].setValue(eTreatmentForm.eTreatmentFormCategoryId);
    this.form.controls['name'].setValue(eTreatmentForm.name);
    this.form.controls['formDefinition'].setValue(eTreatmentForm.formDefinition);
  }

  isFormDisabled(): boolean {
    return !this.form.valid;
  }

  onSubmit() {
    this.loading = true;

    try {
      if (this.isNew) {
        this.eTreatmentFormService.addeTreatmentForm(this.form.value).subscribe(() => {
          this.router.navigate(['/management/forms/etreatment-forms', { outlets: { 'action-panel': null } }]);
        });
      } else {
        this.eTreatmentFormService.updateeTreatmentForm(this.form.value).subscribe(() => {
          this.router.navigate(['/management/forms/etreatment-forms', { outlets: { 'action-panel': null } }]);
        });
      }
    } catch {
      this.loading = false;
    }
  }

  cancelUpdate() {
    this.eTreatmentFormService.eTreatmentFormChangeCancelled.next();
    this.router.navigate(['/management/forms/etreatment-forms', { outlets: { 'action-panel': null } }]);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
