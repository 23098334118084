<ngx-loading [show]="loading"></ngx-loading>
<ng-scrollbar class="h-100" *ngIf="clinicFormGroup">
  <div class="row mx-0 mb-2">
    <div class="col px-0 mx-2">
      <ng-container *ngIf="formDisabled; else editing">
        <button class="btn em-btn em-btn-green" (click)="onClinicFormEdit()"><i class="fal fa-edit"></i> Edit</button>
        <button
          *appAuthGuard="developerPolicy"
          [disabled]="!this.canAddClinic"
          class="btn em-btn em-btn-green ml-2"
          (click)="onAddClinic()"
        >
          <i class="fal fa-plus-circle"></i> Add Clinic
        </button>
      </ng-container>
      <ng-template #editing>
        <button
          class="btn em-btn em-btn-green"
          [disabled]="!clinicFormGroup.dirty && !clinicFormGroup.touched"
          (click)="onClinicFormSubmit()"
        >
          <i class="fal fa-save"></i> {{ isNewClinic ? 'Create' : 'Update' }}
        </button>
        <button class="btn em-btn em-btn-green ml-2" (click)="onClinicFormCancel()">
          <i class="fal fa-ban"></i> Cancel
        </button>
      </ng-template>
    </div>
  </div>
  <ng-container [formGroup]="clinicFormGroup">
    <div class="row mx-0 mb-2">
      <!--Main-->
      <div class="col d-flex flex-column px-0 mx-2">
        <div class="section-header uppercase">Main</div>
        <div class="border flex-grow-1">
          <div class="row mx-0">
            <div class="col">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Clinic Name"
                  class="capitalized"
                  aria-label="Clinic Name"
                  formControlName="name"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col">
              <mat-form-field>
                <input matInput placeholder="Website" aria-label="Website" formControlName="website" />
                <mat-error *ngIf="!clinicFormGroup.controls.website.valid">Not a Valid Website URL</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col">
              <mat-form-field>
                <input matInput placeholder="Email" aria-label="Email" formControlName="email" />
                <mat-error *ngIf="!clinicFormGroup.controls.email.valid">Not a Valid Email</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col">
              <mat-form-field>
                <input matInput placeholder="Phone Number" aria-label="Phone Number" formControlName="phoneNumber" />
                <mat-error>{{ 'Format: ' + getExampleNumber() + ' or Int.' }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col">
              <mat-form-field>
                <input
                  matInput
                  placeholder="Fax Number"
                  aria-label="Fax Number"
                  name="faxNumber"
                  formControlName="faxNumber"
                />
                <mat-error>{{ 'Format: ' + getExampleNumber() + ' or Int.' }}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <!--Address-->
      <div class="col d-flex flex-column px-0 mx-2">
        <div class="section-header">ADDRESS</div>
        <ng-container formGroupName="address">
          <div class="border flex-grow-1">
            <div class="row mx-0">
              <div class="col">
                <mat-form-field>
                  <mat-select name="usStates" placeholder="Country" formControlName="country">
                    <mat-option *ngFor="let country of countries" [value]="country">
                      {{ country.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col">
                <mat-form-field>
                  <mat-select name="usStates" formControlName="province">
                    <mat-option *ngFor="let region of selectedCountryRegions" [value]="region">
                      {{ region.name }}
                    </mat-option>
                  </mat-select>
                  <mat-placeholder class="placeholder">
                    {{
                      clinicFormGroup.get('address.country').value?.slug == 'CA'
                        ? 'Province / Territory'
                        : clinicFormGroup.get('address.country').value?.slug == 'US'
                        ? 'State'
                        : clinicFormGroup.get('address.country').value?.slug == 'AU'
                        ? 'State / Territory'
                        : 'Region'
                    }}
                  </mat-placeholder>
                </mat-form-field>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="City"
                    aria-label="City"
                    (change)="capitalizeValue('address.city')"
                    formControlName="city"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col">
                <mat-form-field>
                  <input matInput placeholder="Address 1" aria-label="Address1" formControlName="address1" />
                </mat-form-field>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col">
                <mat-form-field>
                  <input matInput placeholder="Address 2" aria-label="Address2" formControlName="address2" />
                </mat-form-field>
              </div>
            </div>

            <div class="row mx-0">
              <div class="col">
                <mat-form-field>
                  <input matInput aria-label="Postal Code" formControlName="postalCode" />
                  <mat-placeholder class="placeholder">
                    {{ clinicFormGroup.get('address.country').value == 'United States' ? 'Zip Code' : 'Postal Code' }}
                  </mat-placeholder>
                  <mat-error>
                    {{
                      clinicFormGroup.get('address.country').value?.slug == 'US'
                        ? 'Not a Valid ZIP Code'
                        : 'Not a Valid Postal Code'
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <!--Time-->
      <div class="col d-flex flex-column px-0 mx-2">
        <div class="section-header">TIME</div>
        <div class="border flex-grow-1">
          <div class="row mx-0">
            <div class="col">
              <mat-form-field class="w-100">
                <mat-select placeholder="Timezone" name="timezone" formControlName="timezone">
                  <mat-option *ngFor="let timeZone of timezonesOfTheWorld" [value]="timeZone.id">
                    {{ timeZone.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row mx-0">
            <div class="col">
              <mat-form-field>
                <mat-select
                  placeholder="Minimum Appointment Duration"
                  name="minimumDuration"
                  formControlName="minimumDuration"
                >
                  <mat-option *ngFor="let durOpt of durationOptions" [value]="durOpt">
                    {{ durOpt }}
                  </mat-option>
                </mat-select>
                <span matSuffix>mins</span>
              </mat-form-field>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col">
              Hours of Operation: <br />
              <ng-container *ngFor="let day of clinic.hoursOfOperation?.hoursOfOperationDays">
                <div class="d-flex justify-content-between">
                  <span>{{ day.dayofweek }}</span>
                  <span>{{ formatDayHours(day) }}</span>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col">
              <button *ngIf="!formDisabled" class="btn em-btn em-btn-green my-2" (click)="openHoursOfOperationDialog()">
                EDIT
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--SRFax-->
      <div class="col d-flex flex-column px-0 mx-2">
        <div class="section-header">SRFAX</div>
        <div class="border flex-grow-1">
          <div class="row mx-0">
            <div class="col">
              <mat-form-field>
                <input matInput placeholder="SRFax ID" aria-label="SRFax ID" formControlName="srFaxId" />
                <mat-error *ngIf="!clinicFormGroup.controls.srFaxId.valid">Not a Valid SRFax Id</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col">
              <mat-form-field>
                <input
                  matInput
                  placeholder="SRFax Password"
                  aria-label="SRFax Password"
                  formControlName="srFaxPassword"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <!--Billing-->
      <div class="col d-flex flex-column px-0 mx-2">
        <div class="section-header">BILLING</div>
        <div class="border flex-grow-1">
          <div class="row mx-0">
            <div class="col">
              <mat-form-field>
                <mat-select
                  placeholder="Taxes"
                  name="taxes"
                  multiple
                  formControlName="clinicTaxes"
                  [compareWith]="compareClinicTaxObjects"
                >
                  <mat-option *ngFor="let clinicTax of possibleClinicTaxes" [value]="clinicTax">
                    {{ clinicTax.tax.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col">
              <mat-form-field>
                <input
                  matInput
                  aria-label="ePoints"
                  placeholder="ePoints"
                  formControlName="loyaltyPointsToDollarsRate"
                />
                <span matSuffix>ePoints/$</span>
              </mat-form-field>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col">
              <mat-form-field>
                <input
                  matInput
                  aria-label="Staff Booking Cancellation Price"
                  placeholder="Staff Booking Cancellation Price"
                  formControlName="staffBookingCancellationPrice"
                  mask="separator.2"
                  thousandSeparator=","
                  prefix="$"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <!--PatientPortal-->
      <div class="col d-flex flex-column px-0 mx-2">
        <div class="section-header">Patient Portal</div>
        <div class="border flex-grow-1">
          <div class="row mx-0">
            <div class="col py-2">
              <mat-checkbox
                class="mr-2"
                type="checkbox"
                formControlName="sendPatientPortalEmail"
                name="sendPatientPortalEmail"
                aria-label="Send Patient Portal Emails"
                id="sendPatientPortalEmail"
              ></mat-checkbox>
              <span class="teal-font">Send Patient Portal Invites Automatically</span>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col">
              <mat-form-field>
                <mat-select
                  placeholder="Default Photo Section"
                  name="defaultPortalPhotoTagId"
                  formControlName="defaultPortalPhotoTagId"
                >
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let photosection of allPhotoSection" [value]="photosection.tagId">
                    {{ photosection.title }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Developer Section -->
    <ng-container *appAuthGuard="developerPolicy">
      <h6 class="section-header mx-2 mb-2">Developer Section</h6>
      <div class="row mx-0 mb-2">
        <!-- Twilio -->
        <div class="col-2 d-flex flex-column px-0 mx-2">
          <div class="section-header">Twilio</div>
          <div class="border flex-grow-1">
            <div class="row mx-0">
              <div class="col">
                <mat-form-field>
                  <input
                    matInput
                    autocomplete="off"
                    placeholder="Account SID"
                    aria-label="Account SID"
                    formControlName="twilioAccountSid"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col">
                <mat-form-field>
                  <input
                    matInput
                    autocomplete="off"
                    placeholder="Auth Token"
                    aria-label="Auth Token"
                    formControlName="twilioAuthToken"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col">
                <mat-form-field>
                  <input
                    matInput
                    autocomplete="off"
                    placeholder="Conversations Service SID"
                    aria-label="Conversations Service SID"
                    formControlName="twilioConversationServiceSid"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col">
                <mat-form-field>
                  <input
                    matInput
                    autocomplete="off"
                    placeholder="API Key"
                    aria-label="API Key"
                    formControlName="twilioApiKey"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col">
                <mat-form-field>
                  <input
                    matInput
                    autocomplete="off"
                    placeholder="API Secret"
                    aria-label="API Secret"
                    formControlName="twilioApiSecret"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col">
                <mat-form-field>
                  <input
                    matInput
                    autocomplete="off"
                    placeholder="Phone #"
                    aria-label="Phone #"
                    formControlName="twilioFromNumber"
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <!-- Azure -->
        <div class="col-2 d-flex flex-column px-0 mx-2">
          <div class="section-header">Azure</div>
          <div class="border flex-grow-1">
            <div class="row mx-0">
              <div class="col">
                <mat-form-field>
                  <input
                    matInput
                    aria-label="Blob Storage Container"
                    placeholder="Blob Storage Container"
                    formControlName="blobContainerName"
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col">
                <mat-form-field>
                  <input
                    matInput
                    aria-label="Clinic Blob Folder"
                    placeholder="Clinic Blob Folder"
                    formControlName="url"
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <!-- Payment Processor -->
        <div class="col-2 d-flex flex-column px-0 mx-2">
          <div class="section-header">Payment Processor</div>
          <div class="border flex-grow-1">
            <div class="row mx-0">
              <div class="col">
                <mat-form-field>
                  <mat-select
                    placeholder="Payment Processor"
                    name="paymentProcessorType"
                    formControlName="paymentProcessorType"
                  >
                    <mat-option *ngFor="let item of paymentProcessorTypes" [value]="item.value">
                      {{ item.title }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- <div class="row mx-0 pt-1">
          <div class="col px-2 d-flex justify-content-start align-items-center">
            <p class="teal-font uppercase font-weight-bold mb-0 pr-2">Google Place Settings</p> <button class="btn btn-sm em-btn-green btn-clean" style="pointer-events:all" (click)="lookupPlaceId()" *ngIf="clinicFormGroup.controls['googlePlaceId'].value == ''">Lookup Google Place Id Using Address</button>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col px-2">
            <mat-form-field class="w-100"  [hidden]="lookingUpPlaceId">
              <input matInput placeholder="Google Place Id" aria-label="Google Place Id" formControlName="googlePlaceId">

              <mat-error *ngIf="!clinicFormGroup.controls.googlePlaceId.valid">Not a Valid Google Place Id. Retrieve this ID from Google My Business dashboard</mat-error>
            </mat-form-field>
            <i *ngIf="lookingUpPlaceId" class="fas fa-clock"></i>
          </div>
        </div> -->
  </ng-container>

  <h6 class="section-header mx-2 mb-0">Clinic Logo</h6>
  <div class="border mx-2 mb-2 p-2">
    <app-clinic-logo [selectedClinic]="this.clinic"></app-clinic-logo>
  </div>
</ng-scrollbar>
