export const ClinicData = {
  title: 'Clinic Data Fields',
  weight: 0,
  default: true,
  components: {
    clinicAddress1: {
      title: 'Address 1',
      key: 'clinicAddress1',
      schema: {
        label: 'Clinic Address 1',
        type: 'textfield',
        key: 'clinicAddress1',
        input: true
      }
    },
    clinicAddress2: {
      title: 'Address 2',
      key: 'clinicAddress2',
      schema: {
        label: 'Clinic Address 2',
        type: 'textfield',
        key: 'clinicAddress2',
        input: true
      }
    },
    clinicCity: {
      title: 'City',
      key: 'clinicCity',
      schema: {
        label: 'Clinic City',
        type: 'textfield',
        key: 'clinicCity',
        input: true
      }
    },
    clinicCountry: {
      title: 'Country',
      key: 'clinicCountry',
      schema: {
        label: 'Clinic Country',
        type: 'textfield',
        key: 'clinicCountry',
        input: true
      }
    },
    clinicPostalCode: {
      title: 'Postal Code',
      key: 'clinicPostalCode',
      schema: {
        label: 'Clinic Postal Code',
        type: 'textfield',
        key: 'clinicPostalCode',
        input: true
      }
    },
    clinicProvince: {
      title: 'Province',
      key: 'clinicProvince',
      schema: {
        label: 'Clinic Province',
        type: 'textfield',
        key: 'clinicProvince',
        input: true
      }
    },
    clinicPhoneNumber: {
      title: 'Phone Number',
      key: 'clinicPhoneNumber',
      schema: {
        label: 'Clinic Phone Number',
        type: 'textfield',
        key: 'clinicPhoneNumber',
        input: true
      }
    },
    clinicFaxNumber: {
      title: 'Fax Number',
      key: 'clinicFaxNumber',
      schema: {
        label: 'Clinic Fax Number',
        type: 'textfield',
        key: 'clinicFaxNumber',
        input: true
      }
    },
    clinicEmail: {
      title: 'Clinic Email',
      key: 'clinicEmail',
      schema: {
        label: 'Clinic Email',
        type: 'textfield',
        key: 'clinicEmail',
        input: true
      }
    }
  }
}
