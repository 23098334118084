import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { IReportComponent } from '@models/reports/ireport-component';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { DataSourceRequestState, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Subject, Observable } from 'rxjs';
import { ReportsService } from '@services/reports.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-referral-reports',
  templateUrl: './referral-reports.component.html',
  styleUrls: ['./referral-reports.component.less']
})
export class ReferralReportsComponent implements OnInit, OnDestroy, IReportComponent {
  @ViewChild('grid') grid;
  gridView: GridDataResult;
  gridState: DataSourceRequestState;
  loading: boolean = false;
  unsub: Subject<void> = new Subject<void>();

  constructor(
    private reportsService: ReportsService
  ) { }

  ngOnInit() {
    this.initDefaultValue();
    this.getReportData();
    this.allData = this.allData.bind(this);
  }

  initDefaultValue() {
    this.gridState = {
      skip: 0,
      take: 10,
      // set default state of sorting icon
      sort: [{field: 'physicianName', dir: 'asc'}]
    };

    this.gridView = {
      data: [],
      total: 0
    };
  }

  getReportData() {
    this.loading = true;

    this.reportsService.getReferralsReportEntities(this.gridState)
      .pipe(takeUntil(this.unsub))
      .subscribe((referralReports: any) => {
        this.gridState.sort = [{field: 'physicianName', dir: 'asc'}];
        this.gridView = {
          data: referralReports.data,
          total: referralReports.total || referralReports.data.length
        };
        this.loading = false;
    });
  }

  exportToExcel() {
    this.grid.saveAsExcel();
  }

  onExcelExport(e: any) {
    this.reportsService.onExcelExport(e, 'Referrals Report');
  }

  pageChange(event: PageChangeEvent) {
    this.gridState.skip = event.skip;
    this.getReportData();
  }

  sortChange(sort: SortDescriptor[]) {
    this.gridState.sort = sort;
    this.gridView.data = orderBy(this.gridView.data, this.gridState.sort);
  }

  sortIconClass(column: any) {
    return this.gridState.sort.length && (this.gridState.sort[0].field === column.field && this.gridState.sort[0].dir === 'asc') ? 'fad fa-sort-up'
           : this.gridState.sort.length && (this.gridState.sort[0].field === column.field && this.gridState.sort[0].dir === 'desc') ? 'fad fa-sort-down'
           : 'fas fa-sort';
  }

  allData = (): Observable<any> => {
    const gridState: DataSourceRequestState = {};
    return this.reportsService.getReferralsReportEntities(gridState);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
