import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormioComponent } from '@formio/angular';
import { PatientService } from '@services/patient.service';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.less'],
})
export class SignatureComponent implements OnInit {
  @ViewChild('formView') formView: FormioComponent;
  public form: any = {};
  public submission: any = {};
  public signature: any = {};

  constructor(public activeModal: NgbActiveModal, private patientService: PatientService) {}

  ngOnInit() {
    const patient = this.patientService.patientPanelPatient;
    this.initForm();

    if (patient && patient.signature) {
      this.submission = { data: { signature: patient.signature } };
    }
  }

  private initForm() {
    this.form = {
      display: 'form',
      components: [
        {
          type: 'signature',
          key: 'signature',
          label: 'Please sign below:',
          input: true,
        },
      ],
    };
  }

  onSubmit(value: any) {
    this.formView.submit.next({ data: { signature: this.signature } });
  }

  onFormChange(value: any) {
    this.signature = value.data.signature;
  }

  onFormSubmit(value: any) {
    this.patientService.patientPanelPatient.signature = value.data.signature;
    this.patientService.updatePatient(this.patientService.patientPanelPatient).subscribe();
    this.activeModal.close('signed');
  }
}
