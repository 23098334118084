import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmCancelWithReasonDialogComponent } from '@app/management/dialogs/confirm-cancel-with-reason/confirm-cancel-with-reason.component';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { ServiceProviderSelectorModalComponent } from '@app/management/dialogs/service-provider-selector-modal/service-provider-selector-modal.component';
import {
  SelectProviderDialogComponent,
  SelectProviderDialogData,
} from '@app/patients/patient-tabs/patient-chart-tab/chart-overview/modals/select-provider/select-provider.component';
import { isNullOrUndefined } from '@app/shared/helpers';
import { environment } from '@environments/environment';
import { Appointment, AppointmentType, UpdateMethod } from '@models/appointments/appointment';
import { PaymentStatus } from '@models/appointments/payment-status';
import { ChargeableAppointment } from '@models/billing/chargeable-appointment';
import { ConfirmCancelData } from '@models/billing/confirm-cancel-data';
import { ColourVariables } from '@models/constants/colour-variables';
import { AppointmenteTreatmentForm } from '@models/etreatment-forms/appointment-etreatment-form';
import { ServiceeTreatmentForm } from '@models/etreatment-forms/service-etreatment-form';
import { FinanceTransactionType } from '@models/finance/finance-transaction-type';
import { InvoicePayment } from '@models/finance/invoice-payment';
import { PatientForm } from '@models/forms/patient-form';
import { ServiceForm } from '@models/forms/service-form';
import { Invoice } from '@models/invoice/invoice';
import { InvoiceLineItemTax } from '@models/invoice/invoice-line-item-tax';
import { CancellationType } from '@models/payments/cancellation-type.enum';
import { DrawingData } from '@models/photo/photo-drawing';
import { Resource } from '@models/resource';
import { ResourceType } from '@models/resource-type';
import { ChartAppointment } from '@models/service-chart/chart-appointment';
import { ChartEntry } from '@models/service-chart/chart-entry';
import { ChartNote } from '@models/service-chart/chart-note';
import { ServiceProvider } from '@models/service-provider';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { Service } from '@models/service/service';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { PreviousTreatment } from '@models/treatment-planning/previous-treatment';
import { User } from '@models/user';
import { Visit } from '@models/visit';
import { VisitConfirmedStatus } from '@models/visit-confirm-status';
import { staffBlockedScheduleCacheBuster$ } from '@services/service-provider.service';
import * as moment from 'moment';
import { Cacheable } from 'ngx-cacheable';
import { EMPTY, Observable, ReplaySubject, Subject, Subscription, forkJoin, from, of, pipe, timer } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, takeUntil, tap, toArray } from 'rxjs/operators';
import { AppointmentReservation } from '../models/appointments/appointment-reservation';
import { Patient } from '../models/patient';
import { AppointmentSignalrService } from './appointment-signalr.service';
import { BlobService } from './blob.service';
import { ClinicsService } from './clinics.service';
import { EventsService, ScheduleMode, ScheduleView } from './events.service';
import { FinanceService } from './finance.service';
import { UrlHelper } from './helpers/url-helper';
import { InvoicesService } from './invoices.service';
import { PatientFormService } from './patient-form.service';
import { PatientService } from './patient.service';
import { ResourcesService } from './resources.service';
import { ServiceProviderService } from './service-provider.service';
import { ServicesService } from './services.service';
import { TreatmentPlanService } from './treatment-planning/treatment-plan.service';
import { UsersService } from './users.service';
import { VisitService } from './visit.service';

@Injectable()
export class AppointmentService implements OnDestroy {
  activeScheduleAppointments: Appointment[] = [];
  private cancelAppointmentReq = new Subject<void>();

  private appointmentLoading = new Subject<{ appointmentId: number; loading: boolean }>();
  appointmentLoading$ = this.appointmentLoading.asObservable();

  private closePanelOnChangeOfClinic = new ReplaySubject(1, 100);
  closePanelOnChangeOfClinic$ = this.closePanelOnChangeOfClinic.asObservable();

  private scheduleApptsUpdated = new Subject<any>();
  scheduleApptsUpdated$ = this.scheduleApptsUpdated.asObservable();

  private apptsAdded = new Subject<any>();
  apptsAdded$ = this.apptsAdded.asObservable();

  private serviceTemplateIdSource = new Subject<number>();
  serviceTemplateIdSource$ = this.serviceTemplateIdSource.asObservable();

  private appointmentUpdated = new Subject<Appointment>();
  appointmentUpdated$ = this.appointmentUpdated.asObservable();

  static colourVariables = new ColourVariables();
  static staffUnavailabilityBackgroundColor = AppointmentService.colourVariables.grey_five;
  static blockedUnavailabilityBackgroundColor = AppointmentService.colourVariables.grey_five;
  static staffUnavailabilityColor = AppointmentService.colourVariables.calendar_blue;
  static blockedUnavailabilityColor = AppointmentService.colourVariables.calendar_red;

  apptsSelected: Map<number, number> = new Map();
  apptsMarkedForMove: Set<number> = new Set();

  startOfWeek: Date = moment(new Date()).startOf('week').toDate();
  selectedDate: Date;

  unsub = new Subject<any>();

  constructor(
    private http: HttpClient,
    private eventsService: EventsService,
    private dialog: MatDialog,
    private providerService: ServiceProviderService,
    private appointmentSignalrService: AppointmentSignalrService,
    private blobService: BlobService,
    private resourcesService: ResourcesService,
    private invoicesService: InvoicesService,
    private financeService: FinanceService,
    private usersService: UsersService,
    private visitService: VisitService,
    private treatmentPlanService: TreatmentPlanService,
    private patientService: PatientService,
    private serviceProviderService: ServiceProviderService,
    private patientFormService: PatientFormService,
    private clinicsService: ClinicsService,
    private servicesService: ServicesService
  ) {
    this.eventsService.currentDate.pipe(takeUntil(this.unsub)).subscribe((value) => {
      this.startOfWeek = moment(new Date(value)).startOf('week').toDate();
      this.selectedDate = value;
    });

    this.eventsService.closeSidePanel$.subscribe(() => {
      this.apptsSelected.clear();
    });

    this.closePanelOnChangeOfClinic$.pipe(takeUntil(this.unsub)).subscribe((action) => {
      this.visitService.closePanel();
    });

    this.clinicsService.clinicIdSelected$.pipe(takeUntil(this.unsub)).subscribe((clinicId) => {
      this.setBlockedAppointmentDummy();
    });
  }

  private async setBlockedAppointmentDummy() {
    const blockedVisit = await this.visitService
      .getBlockedScheduleVisit()
      .pipe(
        catchError((err) => {
          throw err;
        })
      )
      .toPromise();
    const blockedService = await this.servicesService
      .getBlockedScheduleService()
      .pipe(
        catchError((err) => {
          throw err;
        })
      )
      .toPromise();
  }

  addAppointment(appointment: Appointment) {
    appointment.startTime = moment.duration(appointment.startTime);
    appointment.endTime = moment.duration(appointment.endTime);

    delete appointment.service;
    delete appointment.colourVariables;
    return this.http.post<Appointment>(environment.baseUrl + 'api/Appointments', appointment).pipe(
      map((a: Appointment) => {
        this.updateAllAppointmentsOnSchedule().subscribe();
        this.onApptsAdded();
        this.invalidateStaffScheduleCache(a.appointmentType);
        this.appointmentSignalrService.addAppointment(a);
        return a;
      })
    );
  }

  async createAppointmentFromTemplate(
    serviceTemplate: ClinicServiceTemplate,
    patient: Patient,
    plannedTreatmentId: number = null
  ): Promise<Appointment> {
    if (!(await this.checkPatientRushNotes(this.patientService.patientPanelPatient).toPromise())) {
      return;
    }

    const serviceProviders = await this.serviceProviderService.getServiceProviderByDate(new Date()).toPromise();
    const selectedServiceProvider = await this.selectServiceProviderForService(
      serviceTemplate.id,
      serviceProviders,
      serviceTemplate.governmentBilling
    ).toPromise();
    if (!selectedServiceProvider) {
      return;
    }

    const startDateTime = new Date();
    let mins = startDateTime.getMinutes();
    let hours = startDateTime.getHours();
    let days = startDateTime.getDate();
    mins = Math.floor(mins / 15) * 15 + 15;
    hours += Math.floor(mins / 60);
    days += Math.floor(hours / 24);
    startDateTime.setMinutes(mins % 60);
    startDateTime.setHours(hours);
    startDateTime.setDate(days);
    const dt = this.getStartEndTime(startDateTime, serviceTemplate.defaultDurationMinutes);
    const proceed = await this.checkWithinClinicHours(dt.date, dt.startTime);
    if (!proceed) {
      return;
    }

    let service = this.servicesService.mapServiceFromTemplate(serviceTemplate, patient);
    if (service.serviceDetailTemplateId === ServiceDetailTemplate.TreatmentPlan) {
      service.subType = '';
    }
    service = await this.servicesService.addService(service).toPromise();

    const visitIdString = patient.patientId.toString() + new Date().toDateString();
    let visit = await this.visitService.getVisitByEvent(visitIdString).toPromise();
    if (!visit || visit.cancelled) {
      let newVisit: Visit = this.visitService.initEmptyVisit(
        patient.patientId,
        visitIdString,
        [],
        new Date(),
        this.usersService.loggedInUser.firstName + ' ' + this.usersService.loggedInUser.lastName
      );
      visit = await this.visitService.addVisit(newVisit).toPromise();
    }

    const appointment = new Appointment();
    appointment.appointmentType = AppointmentType.Regular;
    appointment.title = patient.firstName + ' ' + service.serviceName;
    appointment.date = this.stripDateOnlyAsUtcDate(new Date());
    appointment.dateTime = new Date();
    appointment.startTime = dt.startTime;
    appointment.endTime = dt.endTime;
    appointment.endDate = this.stripDateOnlyAsUtcDate(new Date());
    appointment.serviceId = service.serviceId;
    appointment.resourceId = selectedServiceProvider.id;
    appointment.visitIdString = visit.visitIdString;
    appointment.visitId = visit.visitId;
    appointment.color = serviceTemplate.serviceIDColour;
    appointment.patientId = patient.patientId;
    appointment.staffId = selectedServiceProvider.id;
    appointment.plannedTreatmentId = plannedTreatmentId;

    appointment.appointmentForms = serviceTemplate.serviceForms
      .filter((sf) => sf.isSelectedByDefault)
      .map((serviceForm: ServiceForm) => {
        return PatientForm.fromForm(patient.patientId, serviceForm.clinicForm);
      });

    appointment.appointmenteTreatmentForms = serviceTemplate.clinicServiceTemplateeTreatmentForms
      .filter((etf) => etf.isSelectedByDefault)
      .map(
        (selectedeTreatmentForm: ServiceeTreatmentForm) =>
          new AppointmenteTreatmentForm({
            eTreatmentFormId: selectedeTreatmentForm.eTreatmentFormId,
            formDefinition: selectedeTreatmentForm.eTreatmentForm.formDefinition,
          })
      );

    return await this.addAppointment(appointment)
      .pipe(
        tap(async (appointment: Appointment) => {
          if (appointment.appointmentForms.length > 0) {
            this.patientFormService.patientForms$.next(
              this.patientFormService.patientForms$.getValue().concat(appointment.appointmentForms)
            );
          }
        })
      )
      .toPromise();
  }

  private async checkWithinClinicHours(startDate: Date, startDuration: moment.Duration): Promise<boolean> {
    const startTime = startDuration.asMilliseconds();
    const dayOfWeek = moment(startDate).day();
    const openTime =
      this.clinicsService.clinic?.hoursOfOperation.hoursOfOperationDays[dayOfWeek].openTime.asMilliseconds();
    const closeTime =
      this.clinicsService.clinic?.hoursOfOperation.hoursOfOperationDays[dayOfWeek].closeTime.asMilliseconds();
    if (startTime < openTime || startTime > closeTime) {
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        width: '300px',
        data: {
          title: 'Warning',
          content: 'You are about to add an appointment outside of working hours. Do you want to proceed?',
          confirmButtonText: 'Ok',
          showCancel: true,
        },
      });
      return await dialogRef
        .afterClosed()
        .map((result) => result === 'confirm')
        .toPromise();
    }
    return true;
  }

  addVisitedAppointment(appointment: Appointment): Observable<Appointment> {
    return this.addAppointment(appointment).pipe(
      map((appt) => {
        if (this.activeScheduleAppointments.length > 0) {
          const defaultSelectedApp: Appointment = this.activeScheduleAppointments.find((app) => {
            return this.apptsSelected.has(app.appointmentId);
          });
          if (defaultSelectedApp && appointment.visitId === defaultSelectedApp.visitId) {
            this.apptsSelected.set(appointment.appointmentId, appointment.appointmentId);
          }
        }
        this.updateAllAppointmentsOnSchedule().subscribe();
        this.onApptsAdded();
        return appt;
      })
    );
  }

  updateAppointment(appointment: Appointment, updateMethod: UpdateMethod = UpdateMethod.Single) {
    appointment.startTime = moment.duration(appointment.startTime);
    appointment.endTime = moment.duration(appointment.endTime);

    const url = environment.baseUrl + 'api/Appointments/' + appointment.appointmentId + '?updateMethod=' + updateMethod;
    return this.http.put<Appointment>(url, appointment).pipe(
      map((a) => {
        a.color = a.service.serviceTemplate.serviceIDColour;
        this.parseDurations(a);
        this.invalidateStaffScheduleCache(a.appointmentType);
        this.appointmentUpdated.next(a);
        this.appointmentSignalrService.updateAppointment(a);
        return a;
      }),
      switchMap((a) => this.updateAppointmentOnSchedule(a))
    );
  }

  removeAppointment(appointmentId: number, updateMethod: UpdateMethod = UpdateMethod.Single) {
    return this.http
      .delete(environment.baseUrl + 'api/Appointments/' + appointmentId + '?updateMethod=' + updateMethod)
      .pipe(switchMap(() => this.updateAllAppointmentsOnSchedule()));
  }

  private updateAppointmentOnSchedule(appointment: Appointment): Observable<void> {
    appointment = this.convertToEvents([appointment])[0];
    return this.updateActiveAppointmentAndVisit(appointment);
  }

  updateAllAppointmentsOnSchedule(startOfWeek?: Date, providerId?: string) {
    let getReq: Observable<void>;
    if (this.eventsService.scheduleMode === ScheduleMode.DayView) {
      if (startOfWeek) {
        if (!providerId)
          throw Error(
            'If startOfWeek is provided, then provider id must also be provided when updating active appointments in day view.'
          );
        getReq = this.updateActiveAppointments(startOfWeek, null, null, providerId);
      } else {
        getReq = this.updateActiveAppointments(null, this.selectedDate, this.selectedDate);
      }
    } else if (this.eventsService.scheduleMode === ScheduleMode.WeekView) {
      providerId ??= this.eventsService.selectedProviderId;
      if (!providerId)
        throw Error('Provider id could not be determined when updating active appointments in week view.');
      const startOfWeek = moment(this.selectedDate).startOf('week').toDate();
      const endOfWeek = moment(this.selectedDate).endOf('week').toDate();
      getReq = this.updateActiveAppointments(startOfWeek, null, endOfWeek, providerId);
    }
    return getReq;
  }

  //Deal with being in one clinic with the action panel open for an appointment and then changing clinic
  //prior to adding, closing or canceling on the action-panel
  closeActionPanel() {
    this.closePanelOnChangeOfClinic.next(true);
  }

  onApptsAdded() {
    this.apptsAdded.next();
  }

  appointmentAdded(appt: Appointment) {
    switch (this.eventsService.scheduleView) {
      case ScheduleView.Appointments:
        if (appt.appointmentType === AppointmentType.Staff) {
          appt.rendering = 'inverse-background';
        }
        if (appt.appointmentType === AppointmentType.Blocked) {
          appt.rendering = 'background';
        }
        break;
      case ScheduleView.StaffSchedules:
        if (appt.appointmentType === AppointmentType.Staff || appt.appointmentType === AppointmentType.Blocked) {
          appt.rendering = null;
        }
        break;
    }
  }

  updateActiveAppointments(
    startOfWeek?: Date,
    startDate?: Date,
    endDate?: Date,
    providerId: string = ''
  ): Observable<void> {
    const startOfWeekParam = startOfWeek?.toDateString() ?? '';
    const startDateParam = startDate?.toDateString() ?? '';
    const endDateParam = endDate?.toDateString() ?? '';
    this.cancelAppointmentReq.next();

    return this.http
      .get<Appointment[]>(
        environment.baseUrl +
          'api/Appointments?startOfWeek=' +
          startOfWeekParam +
          '&startDate=' +
          startDateParam +
          '&endDate=' +
          endDateParam +
          '&providerId=' +
          providerId
      )
      .pipe(this.updateActiveAppointmentsPipe());
  }

  updateActiveNoShowAppointments(): Observable<void> {
    this.cancelAppointmentReq.next();
    return this.getCancelledAndNoShowAppointments(
      this.stripDateFromUtcDate(this.selectedDate),
      this.stripDateFromUtcDate(this.selectedDate)
    ).pipe(this.updateActiveAppointmentsPipe());
  }

  private updateActiveAppointmentsPipe() {
    return pipe(
      takeUntil(this.cancelAppointmentReq),
      map<Appointment[], Appointment[]>((appointments) =>
        appointments.map((appointment) => this.parseDurations(appointment))
      ),
      map<Appointment[], Appointment[]>((appointments) => this.convertToEvents(appointments)),
      mergeMap((activeAppointments) => this.setActiveAppointmentsAndVisits(activeAppointments))
    );
  }

  private convertToEvents(allAppts: Appointment[]): Appointment[] {
    switch (this.eventsService.scheduleView) {
      case ScheduleView.Appointments:
        const regularAppointments = allAppts
          .filter((a) => !a.cancelled)
          .map((a) => {
            if (a.appointmentType === AppointmentType.Staff) {
              a.rendering = 'inverse-background';
            }
            if (a.appointmentType === AppointmentType.Blocked) {
              a.rendering = null;
            }
            if (a.visitIdString === '_BlockedScheduleVisit' && !this.eventsService.blockedScheduleMode) {
              a.editable = false;
            } else {
              a.editable = true;
            }
            a.roomName = this.getRoomName(a);
            return a;
          });
        return regularAppointments;

      case ScheduleView.NoShowAppointments:
        const noShowAppointments = allAppts.map((e) => {
          e.editable = false;
          return e;
        });
        return noShowAppointments;
      case ScheduleView.StaffSchedules:
        const appointments = allAppts
          .filter((a) => a.appointmentType === AppointmentType.Staff || a.appointmentType === AppointmentType.Blocked)
          .map((a) => {
            a.rendering = null;
            return a;
          });
        return appointments;
      default:
        const breakAppointments = allAppts
          .filter((a) => !a.cancelled)
          .map((a) => {
            if (a.appointmentType === AppointmentType.Staff) {
              a.rendering = 'inverse-background';
            }
            if (a.appointmentType === AppointmentType.Blocked) {
              a.rendering = 'background';
            }
            return a;
          });
        return breakAppointments;
    }
  }

  private setActiveAppointmentsAndVisits(activeAppointments: Appointment[]): Observable<void> {
    const distinctVisitIds = [...new Set(activeAppointments.map((a) => a.visitId))];
    return this.visitService
      .setActiveScheduleVisits(distinctVisitIds)
      .pipe(tap(() => (this.activeScheduleAppointments = activeAppointments)));
  }

  private updateActiveAppointmentAndVisit(appointment: Appointment): Observable<void> {
    const existingIndex = this.activeScheduleAppointments.findIndex(
      (a) => a.appointmentId === appointment.appointmentId
    );
    if (existingIndex === -1) return EMPTY;
    this.activeScheduleAppointments[existingIndex] = appointment;
    return this.visitService.getVisitById(appointment.visitId).pipe(
      map((visit) => {
        this.visitService.activeScheduleVisits.set(visit.visitId, visit);
        // trigger change detection
        this.activeScheduleAppointments = [...this.activeScheduleAppointments]
      })
    );
  }

  setScheduleBackgroundColor(color: string) {
    const bgElements = document.getElementsByClassName('fc-slats') as HTMLCollectionOf<HTMLElement>;

    if (bgElements.length !== 0) {
      bgElements[0].style.backgroundColor = color;
    }
  }

  getStartEndTime(start: Date, minutes: number) {
    const date = this.stripDateOnlyAsUtcDate(start);
    const startTime = this.stripTimeAsDuration(start);
    const endTime = moment.duration(startTime).add(this.durationFromMinutes(minutes));
    return { date, startTime, endTime };
  }

  stripDateOnlyAsUtcDate(dateTime: Date): Date {
    const d = new Date(dateTime);
    const year = d.getFullYear();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return moment.utc([year, month, day].join('-')).toDate();
  }

  stripDateFromUtcDate(dateTime: Date): Date {
    return new Date(dateTime.getUTCFullYear(), dateTime.getUTCMonth(), dateTime.getUTCDate());
  }

  stripTimeAsDuration(dateTime: Date): moment.Duration {
    const startStr = dateTime.getHours() + ':' + dateTime.getMinutes() + ':00';
    return moment.duration(startStr);
  }

  durationFromMinutes(minutes: number): moment.Duration {
    const durationStr = '00:' + minutes + ':00';
    return moment.duration(durationStr);
  }

  async validateAppointment(startTime: Date, duration: number, staffId: string) {
    const dt = this.getStartEndTime(startTime, duration);
    const startDate = this.stripDateFromUtcDate(dt.date);
    let available: Boolean = false;

    // Check if the selected Staff Member is available during this time based on their StaffSchedule
    const allStaffScheduleAppointments = await this.getStaffScheduleAppointmentsByDate(startDate, null).toPromise();
    const staffScheduleAppointments = allStaffScheduleAppointments.filter((a) => a.staffId === staffId);
    staffScheduleAppointments.forEach((ss) => {
      if (ss.startTime <= dt.startTime && ss.endTime >= dt.endTime) {
        available = true;
      }
    });

    if (!available) {
      const message = 'Schedule Conflict Detected.<br>The appointment time is outside the staff schedule.';
      const allow = await this.confirmAppointmentConflict(message);
      if (!allow) {
        return false;
      }
    }

    // check if appointment time falls in staff's break period
    const allStaffScheduleBlocks = await this.getBlockedScheduleAppointmentsByDate(startDate).toPromise();
    const staffScheduleBlocks = allStaffScheduleBlocks.filter((a) => a.staffId === staffId);
    staffScheduleBlocks.forEach((bs) => {
      if (
        (dt.startTime <= bs.startTime && dt.endTime >= bs.endTime) ||
        (dt.startTime >= bs.startTime && dt.startTime < bs.endTime) ||
        (dt.endTime > bs.startTime && dt.endTime <= bs.endTime)
      ) {
        available = false;
      }
    });

    if (!available) {
      const message = "Schedule Conflict Detected.<br>The appointment time falls in staff's break period.";
      const allow = await this.confirmAppointmentConflict(message);
      if (!allow) {
        return false;
      }
    }
    return true;
  }

  async confirmResourceConflict(message: string) {
    return await this.confirmAppointmentConflict(message);
  }

  validateResourcesAndCreateOrUpdateAppointment(
    selectedResources: Resource[],
    staff: ServiceProvider,
    startTime: Date,
    dt: {
      date: Date;
      startTime: moment.Duration;
      endTime: moment.Duration;
    },
    confirmCallback: () => void,
    cancelCallback: () => void,
    appointmentId: number = null
  ) {
    // Check resources allocated for the Appointment
    this.resourcesService
      .getResourcesAllocated(undefined, dt.date, dt.startTime, dt.endTime, appointmentId)
      .subscribe((resources) => {
        let selectedResourceIds = selectedResources?.map((b) => b.resourceId);
        let matchingResources = resources.filter((r) => selectedResourceIds.find((ar) => ar == r.resourceId));
        if (matchingResources.length > 0) {
          const resourceMessage = this.getResourceConflictMessage(matchingResources);
          const allow = this.confirmResourceConflict(resourceMessage);
          if (!allow) {
            cancelCallback();
            return;
          }
        }
        const durationMinutes = dt.endTime.asMinutes() - dt.startTime.asMinutes();
        const valid = this.validateAppointment(startTime, durationMinutes, staff.id);
        if (valid) {
          confirmCallback();
        }
      });
  }

  getResourceConflictMessage(matchingResources: Resource[]) {
    let resourceMessage = `There is a resource conflict with: `;
    let equipmentResources = matchingResources.filter((r) => r.resourceType == ResourceType.Equipment);
    if (equipmentResources.length > 0) {
      resourceMessage += `<br>Equipment: `;
      equipmentResources.forEach((resource) => {
        if (resource.resourceType == ResourceType.Equipment) {
          resourceMessage += `<b>` + resource.name + `</b> `;
        }
      });
    }
    let roomResources = matchingResources.filter((r) => r.resourceType == ResourceType.Room);
    if (roomResources.length > 0) {
      resourceMessage += `<br>Room: `;
      roomResources.forEach((resource) => {
        if (resource.resourceType == ResourceType.Room) {
          resourceMessage += `<b>` + resource.name + `</b> `;
        }
      });
    }
    return resourceMessage;
  }

  private async confirmAppointmentConflict(message: string) {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '300px',
      data: {
        title: 'Confirm Appointment?',
        content: message,
        confirmButtonText: 'Confirm',
        showCancel: true,
        panelClass: 'confirm-appointment-dialog-box',
      },
    });
    const result = await dialogRef.afterClosed().toPromise();
    return result == 'confirm';
  }

  checkPatientRushNotes(patient: Patient): Observable<boolean> {
    if (patient && patient.rushPatientNote && patient.notesAndAlerts && patient.notesAndAlerts.length > 0) {
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        width: '300px',
        data: {
          title: 'Warning - Do You Wish To Continue?',
          content: patient.notesAndAlerts,
          confirmButtonText: 'Yes',
          showCancel: true,
        },
      });

      dialogRef.addPanelClass('patient-warning-box'); //this version of angular-material doesnt work specifying this in the constructor despite what docs say
      return dialogRef.afterClosed().pipe(
        map((data) => {
          return data === 'confirm';
        })
      );
    } else return of(true);
  }

  selectServiceProviderForService(
    serviceTemplateId: number,
    serviceProviders: ServiceProvider[],
    isGovernmentBilling: boolean
  ): Observable<ServiceProvider> {
    const dialogData: SelectProviderDialogData = {
      serviceTemplateId: serviceTemplateId,
      serviceProviders: JSON.parse(JSON.stringify(serviceProviders)),
      isGovernmentBilling: isGovernmentBilling,
    };
    const dialogRef: MatDialogRef<SelectProviderDialogComponent, ServiceProvider | string> = this.dialog.open(
      SelectProviderDialogComponent,
      {
        data: dialogData,
      }
    );
    return dialogRef.afterClosed().pipe(map((result) => (typeof result == 'string' ? null : result)));
  }

  getAppointmentById(appointmentId: number): Observable<Appointment> {
    const appointment = this.activeScheduleAppointments.find((a) => a.appointmentId === appointmentId);
    if (appointment) {
      return Observable.of(appointment);
    }
    return this.http
      .get<Appointment>(environment.baseUrl + 'api/Appointments/' + appointmentId)
      .pipe(map((a) => this.parseDurations(a)));
  }

  getAppointmentsByParentId(parentId: number): Observable<Appointment[]> {
    return this.http
      .get<Appointment[]>(environment.baseUrl + 'api/Appointments/GetRecurringAppointmentsByParentId/' + parentId)
      .pipe(map((as) => as.map((a) => this.parseDurations(a))));
  }

  getAppointmentsByVisitId(visitId: number): Observable<Appointment[]> {
    return this.http
      .get<Appointment[]>(environment.baseUrl + 'api/Appointments/byVisitId?visitId=' + visitId)
      .pipe(map((aa) => aa.map((a) => this.parseDurations(a))));
  }

  getAppointmentByServiceId(serviceId: number): Observable<Appointment> {
    return this.http
      .get<Appointment>(environment.baseUrl + 'api/Appointments/byServiceId?serviceId=' + serviceId)
      .pipe(map((a) => this.parseDurations(a)));
  }

  getCancelledAndNoShowAppointments(startDate?: Date, endDate?: Date): Observable<Appointment[]> {
    if (isNullOrUndefined(startDate) || isNullOrUndefined(endDate)) {
      return this.http
        .get<Appointment[]>(environment.baseUrl + 'api/Appointments/NoShow')
        .pipe(map((aa) => aa.map((a) => this.parseDurations(a))));
    } else {
      let url = environment.baseUrl + 'api/Appointments/NoShow';
      url = UrlHelper.addStartEndDateParameters(url, startDate, endDate);
      return this.http.get<Appointment[]>(url).pipe(map((aa) => aa.map((a) => this.parseDurations(a))));
    }
  }

  getRegularScheduleAppointmentsByDate(startDate?: Date, endDate?: Date) {
    let url = environment.baseUrl + 'api/Appointments/RegularByDate';
    url = UrlHelper.addStartEndDateParameters(url, startDate, endDate);
    return this.http.get<Appointment[]>(url).pipe(map((aa) => aa.map((a) => this.parseDurations(a))));
  }

  getAppointmentsByPatientId(patientId: number): Observable<Appointment[]> {
    return this.http
      .get<Appointment[]>(environment.baseUrl + 'api/Appointments/byPatientId?patientId=' + patientId)
      .pipe(map((aa) => aa.map((a) => this.parseDurations(a))));
  }

  getAppointmentsByPatientIdAndDate(patientId: number, startDate: Date): Observable<Appointment[]> {
    if (!isNullOrUndefined(startDate)) {
      return this.http
        .get<Appointment[]>(
          environment.baseUrl + 'api/Appointments/byPatientId?patientId=' + patientId + '&startDate=' + startDate
        )
        .pipe(map((aa) => aa.map((a) => this.parseDurations(a))));
    } else {
      return this.http
        .get<Appointment[]>(environment.baseUrl + 'api/Appointments/byPatientId?patientId=' + patientId)
        .pipe(map((aa) => aa.map((a) => this.parseDurations(a))));
    }
  }

  @Cacheable({
    cacheBusterObserver: staffBlockedScheduleCacheBuster$,
  })
  getStaffScheduleAppointmentsByDate(startDate?: Date, endDate?: Date) {
    let url = environment.baseUrl + 'api/Appointments/StaffbyDate';
    url = UrlHelper.addStartEndDateParameters(url, startDate, endDate);
    return this.http.get<Appointment[]>(url).pipe(map((aa) => aa.map((a) => this.parseDurations(a))));
  }

  @Cacheable({
    cacheBusterObserver: staffBlockedScheduleCacheBuster$,
  })
  getBlockedScheduleAppointmentsByDate(startDate?: Date, endDate?: Date) {
    let url = environment.baseUrl + 'api/Appointments/BlockedbyDate';
    url = UrlHelper.addStartEndDateParameters(url, startDate, endDate);
    return this.http.get<Appointment[]>(url).pipe(map((aa) => aa.map((a) => this.parseDurations(a))));
  }

  getTreatmentAppointmentsInDateRange(
    patientId: number,
    serviceTemplateId?: number,
    startDate: Date = new Date(1900, 1, 1),
    endDate: Date = new Date(2200, 1, 1),
    maxResults?: number,
    shouldIncludeCancelledAppointment?: boolean
  ): Observable<PreviousTreatment[]> {
    const paramsObj = {};
    paramsObj['patientId'] = patientId;

    if (serviceTemplateId) {
      paramsObj['serviceTemplateId'] = serviceTemplateId;
    }

    if (startDate) {
      paramsObj['startDate'] = startDate.toDateString();
    }

    if (endDate) {
      paramsObj['endDate'] = endDate.toDateString();
    }

    if (maxResults) {
      paramsObj['maxResults'] = maxResults;
    }

    if (!isNullOrUndefined(shouldIncludeCancelledAppointment)) {
      paramsObj['shouldIncludeCancelledAppointment'] = shouldIncludeCancelledAppointment;
    }

    const params = new HttpParams({ fromObject: paramsObj });

    return this.http.get<PreviousTreatment[]>(environment.baseUrl + 'api/Appointments/GetTreatmentAppointmentsByDate', {
      params,
    });
  }

  getPatientChartEntries(patientId: number): Observable<ChartEntry[]> {
    return this.http.get<ChartEntry[]>(
      environment.baseUrl + 'api/Appointments/GetPatientChartEntries?patientId=' + patientId
    );
  }

  getChartNote(noteId: number): Observable<ChartNote> {
    return this.http
      .get<ChartNote>(environment.baseUrl + 'api/Appointments/GetChartNote?noteId=' + noteId)
      .pipe(map((chartNote) => this.appendChartEntrySAS(chartNote) as ChartNote));
  }

  getChartAppointment(appointmentId: number): Observable<ChartAppointment> {
    return this.http
      .get<ChartAppointment>(
        environment.baseUrl + 'api/Appointments/GetChartAppointment?appointmentId=' + appointmentId
      )
      .pipe(map((chartAppointment) => this.appendChartEntrySAS(chartAppointment) as ChartAppointment));
  }

  private appendChartEntrySAS(entry: ChartNote | ChartAppointment) {
    const readOnlySAS = this.blobService.getReadOnlySAS();
    for (const drawing of entry.photoDrawings) {
      drawing.photo.filePath += readOnlySAS;
      drawing.photo.filePathOriginal += readOnlySAS;
      drawing.photo.filePathThumb += readOnlySAS;
      if (drawing.drawingData) {
        const drawingData = JSON.parse(drawing.drawingData) as DrawingData;
        let src = drawingData.canvasData.backgroundImage['src'] as string;
        src = src.split('?')[0];
        src += readOnlySAS;
        drawingData.canvasData.backgroundImage['src'] = src;
        drawing.drawingData = JSON.stringify(drawingData);
      }
    }
    return entry as ChartNote | ChartAppointment;
  }

  postDxCodeByAppointmentId(appointmentId: number, diagnosticCode: string) {
    const params = {
      appointmentId: String(appointmentId),
      diagnosticCode,
    };
    return this.http.post(`${environment.baseUrl}api/Services/UpdateDiagnosticCode/`, null, { params: params }).pipe(
      map((a) => {
        let appointment = new Appointment();
        appointment.appointmentId = appointmentId;
        this.appointmentSignalrService.updateAppointment(appointment);
        return a;
      })
    );
  }

  postBillingCodeByAppointmentId(appointmentId: number, serviceBillingCodes) {
    return this.http.post(`${environment.baseUrl}api/Services/UpdateBillingCodes`, serviceBillingCodes).pipe(
      map((a) => {
        let appointment = new Appointment();
        appointment.appointmentId = appointmentId;
        this.appointmentSignalrService.updateAppointment(appointment);
        return a;
      })
    );
  }

  treatmentIsFromToday(appts: Appointment[], serviceId: number): boolean {
    let treatmentDate: moment.Moment;
    const appt = appts.find((s) => s.serviceId === serviceId);
    if (!isNullOrUndefined(appt)) {
      treatmentDate = moment(appt.date).add(appt.startTime);
    } else {
      treatmentDate = moment.utc();
    }
    return (
      moment().utc()['_d'].getFullYear() === treatmentDate['_d'].getFullYear() &&
      moment().utc()['_d'].getMonth() === treatmentDate['_d'].getMonth() &&
      moment().utc()['_d'].getDate() === treatmentDate['_d'].getDate()
    );
  }

  isAppointmentCancellationChargeable(apppointmentId: number): Observable<ChargeableAppointment> {
    return this.http.get<ChargeableAppointment>(
      `${environment.baseUrl}api/Appointments/IsAppointmentCancellationChargeable/${apppointmentId}`
    );
  }

  chargeAppointmentCancellation(
    cancellationType: CancellationType,
    visitId?: number,
    appointmentId?: number
  ): Observable<Invoice> {
    if (cancellationType == CancellationType.Appointment)
      return this.http.post<Invoice>(
        `${environment.baseUrl}api/Appointments/ChargeAppointmentCancellation/${visitId}/${appointmentId}`,
        {}
      );
    if (cancellationType == CancellationType.Visit)
      return this.http.post<Invoice>(`${environment.baseUrl}api/Visits/ChargeVisitCancellation/${visitId}`, {});
  }

  appointmentCancellationNotCharged(
    cancellationType: CancellationType,
    visitId: number,
    appointmentId: number,
    reason: string
  ): Observable<void> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');

    if (cancellationType == CancellationType.Appointment)
      return this.http.post<void>(
        `${environment.baseUrl}api/Appointments/AppointmentCancellationNotCharged/${appointmentId}`,
        JSON.stringify(reason),
        { headers: headers }
      );
    if (cancellationType == CancellationType.Visit)
      return this.http.post<void>(
        `${environment.baseUrl}api/Visits/VisitCancellationNotCharged/${visitId}`,
        JSON.stringify(reason),
        { headers: headers }
      );
  }

  private parseDurations(a: Appointment): Appointment {
    if (a) {
      a.startTime = moment.duration(a.startTime);
      a.endTime = moment.duration(a.endTime);
      a.start = moment(a.date).add(a.startTime).toDate();
      a.end = moment(a.date).add(a.endTime).toDate();
    }
    return a;
  }

  public shareServiceTemplateId(serviceTemplateId: number) {
    this.serviceTemplateIdSource.next(serviceTemplateId);
  }

  private invalidateStaffScheduleCache(type: AppointmentType) {
    // If we have added/updated a staff or blocked schedule we want to invalidate the ngx-cache object
    if (type === AppointmentType.Blocked || type === AppointmentType.Staff) {
      staffBlockedScheduleCacheBuster$.next();
    }
  }

  recalculatePatientOpenInvoices(patientId: number) {
    return this.http.get<any>(environment.baseUrl + 'api/Appointments/RecalculateInvoices/' + patientId);
  }

  getRoomName(appointment: Appointment): string {
    // Get the RoomName for Schedule Appointments
    var roomName = '';
    if (
      appointment.service.serviceResources !== null &&
      appointment.service.serviceResources !== undefined &&
      appointment.service.serviceResources.filter((sr) => sr.resource.resourceType === ResourceType.Room).length > 0
    ) {
      roomName = appointment.service.serviceResources.filter((sr) => sr.resource.resourceType === ResourceType.Room)[0]
        .resource.name;
    }
    return roomName;
  }

  reservationTimers: Map<string, Subscription> = new Map<string, Subscription>();
  startReservationTimer(appointment: Appointment, clinicReservationDurationMinutes: number) {
    if (this.reservationTimers.has(appointment.appointmentId.toString())) return;

    const created = moment.utc(appointment.createdDate);
    const now = moment(Date.now());
    const diff = now.diff(created, 'seconds');
    const diffInSeconds = Math.max(clinicReservationDurationMinutes * 60 - diff, 0);

    const sub = timer(0, 1000)
      .pipe(takeUntil(this.unsub))
      .pipe(take(diffInSeconds))
      .map((i) => {
        return diffInSeconds - i;
      })
      .subscribe((val) => {
        let timerString = `${Math.floor(val / 60).toString()}:${(val % 60).toString().padStart(2, '0')}`;
        if (val <= 0) {
          timerString = '';
        }
        const element = document.getElementById(appointment.appointmentId + '-reservation-time');
        if (element) element.innerHTML = timerString;
      });
    this.reservationTimers.set(appointment.appointmentId.toString(), sub);
  }

  setAppointmentTileLoading(appointmentId: number, loading: boolean) {
    this.appointmentLoading.next({ appointmentId, loading });
    const element = document.getElementById(appointmentId + '-tile-loading');
    if (element) {
      if (loading) {
        element.classList.remove('d-none');
      } else {
        element.classList.add('d-none');
      }
    }
  }

  addAppointmentReservation(visit: Visit, selectedStaff: ServiceProvider) {
    if (this.visitService.reservation == null) {
      if (selectedStaff) {
        this.addAppointmentReservations(visit, selectedStaff).subscribe({
          next: (a) => {
            this.visitService.reservation = a;
            this.eventsService.appointmentAdded.next();
          },
          error: (err) => {
            // May need to delete dangling reservation on failure
            console.error(err);
          },
        });
      }
    }
  }

  async deleteAppointmentReservation() {
    if (this.visitService.reservation != null) {
      await this.deleteAppointmentReservations([this.visitService.reservation]).toPromise();
      this.visitService.reservation = null;
      this.eventsService.appointmentRemoved.next();
    }
  }

  moveAppointmentReservations(selectedStaff: ServiceProvider) {
    if (!isNullOrUndefined(this.visitService.lastVisit)) {
      this.moveAppointmentReservation(
        this.visitService.lastVisit,
        selectedStaff,
        this.visitService.reservation
      ).subscribe((a) => {
        this.visitService.reservation = a;
      });
    }
  }

  private addAppointmentReservations(visit: Visit, selectedStaff: ServiceProvider): Observable<Appointment> {
    if (selectedStaff === null || selectedStaff === undefined) return;

    let tempEvent = this.eventsService.getTempEvent();
    if (tempEvent.isSelection === false) {
      return EMPTY;
    }
    // //Calculate default start and end time
    var calculatedDuration = Math.abs(moment(tempEvent.start).diff(moment(tempEvent.end), 'minutes'));

    var start = tempEvent.start;
    var dt = this.getStartEndTime(start, calculatedDuration);
    var date = this.stripDateOnlyAsUtcDate(visit.date);

    var reservation: AppointmentReservation = {
      startTime: dt.startTime,
      endTime: dt.endTime,
      date: date,
      visit: visit,
      selectedStaff: selectedStaff,
    };
    return this.http.put<Appointment>(environment.baseUrl + 'api/Appointments/AddReservation', reservation).pipe(
      map((a: Appointment) => {
        this.invalidateStaffScheduleCache(a.appointmentType);
        return a;
      })
    );
  }

  private deleteAppointmentReservations(reservations: Appointment[]): Observable<void> {
    var resId: number[] = [];
    reservations.forEach((a) => resId.push(a.appointmentId));
    return this.http.put<void>(environment.baseUrl + 'api/Appointments/DeleteReservations', resId);
  }

  private moveAppointmentReservation(
    visit: Visit,
    selectedStaff: ServiceProvider,
    reservation: Appointment
  ): Observable<Appointment> {
    // //Calculate default start and end time
    var calculatedDuration = Math.abs(
      moment(this.eventsService.getTempEvent().start).diff(moment(this.eventsService.getTempEvent().end), 'minutes')
    );
    var start = this.eventsService.getTempEvent().start;
    var dt = this.getStartEndTime(start, calculatedDuration);
    var date = this.stripDateOnlyAsUtcDate(visit.date);

    var reservationData: AppointmentReservation = {
      startTime: dt.startTime,
      endTime: dt.endTime,
      date: date,
      visit: visit,
      selectedStaff: selectedStaff,
    };
    return this.http
      .put<Appointment>(
        environment.baseUrl + 'api/Appointments/MoveReservation/' + reservation.appointmentId,
        reservationData
      )
      .pipe(
        map((a: Appointment) => {
          this.invalidateStaffScheduleCache(a.appointmentType);
          return a;
        })
      );
  }

  async moveAppointment(
    targetDate: Date,
    fromVisitId: number,
    toVisitId: number,
    appointmentId: number,
    workingProviders: ServiceProvider[] = null
  ): Promise<Boolean> {
    if (workingProviders == null) {
      workingProviders = await this.providerService.getServiceProviderByDate(targetDate).toPromise();
    }
    const appointment = await this.getAppointmentById(appointmentId).toPromise();
    if (!this.verifyProviderIsScheduled(appointment, workingProviders)) {
      var content =
        '<strong>' +
        appointment.staff.fullName +
        '</strong>' +
        ' is not scheduled to perform the <strong>' +
        appointment.service.serviceName +
        '</strong> service at <strong>' +
        this.getStartTime(appointment) +
        '</strong>';

      var eligibleProviders = workingProviders.filter((provider) =>
        this.verifyMovedAppointmentForProvider(appointment, provider)
      );
      let providerToUse = await this.getUserSelectedProvider(appointment, content, eligibleProviders).toPromise();
      if (!providerToUse) {
        return false;
      }
      appointment.staffId = providerToUse.id;
    }

    const duration = Math.abs(moment(appointment.start).diff(moment(appointment.end), 'minutes'));
    const { date, startTime, endTime } = this.getStartEndTime(targetDate, duration);

    const updateApp = { ...appointment } as Appointment;
    updateApp.visitId = toVisitId;
    updateApp.date = date;
    updateApp.dateTime = targetDate;
    updateApp.startTime = startTime;
    updateApp.endTime = endTime;
    updateApp.endDate = date;
    updateApp.appointmentForms = appointment.appointmentForms.map((form) => {
      form.id = 0;
      return form;
    });
    updateApp.appointmenteTreatmentForms = appointment.appointmenteTreatmentForms.map((form) => {
      form.id = 0;
      return form;
    });

    const appointmentResourceIds = appointment.service.serviceResources?.map((b) => b.resourceId);
    const allocatedResources: Resource[] = await this.resourcesService
      .getResourcesAllocated(undefined, updateApp.date, startTime, endTime)
      .toPromise();
    const matchingResources = allocatedResources.filter((r) => appointmentResourceIds.some((ar) => ar == r.resourceId));

    if (matchingResources.length > 0) {
      let resourceMessage = this.getResourceConflictMessage(matchingResources);

      const dialogRef = this.dialog.open(GenericDialogComponent, {
        width: '300px',
        data: {
          title: 'Confirm Appointment Move?',
          content: resourceMessage,
          confirmButtonText: 'Confirm Move',
          showCancel: true,
          panelClass: 'confirm-appointment-dialog-box',
        },
      });

      const dialogResult = await dialogRef.afterClosed().toPromise();
      if (dialogResult === 'cancel') {
        this.eventsService.movingAppointment = false;
        this.apptsMarkedForMove.clear();

        return false;
      }
    }

    await this.updateAppointment(updateApp).toPromise();
    await this.transferVisitProducts(fromVisitId, toVisitId);

    this.apptsMarkedForMove.clear();
    this.apptsSelected.clear();
    return true;
  }

  async moveMarkedAppointments(
    toVisit: Visit,
    fromVisitId: number,
    targetDate: moment.Moment,
    staffId: string,
    allProviders: ServiceProvider[],
    workingProviders: ServiceProvider[]
  ): Promise<Boolean> {
    const appointmentsToMove = await from(this.apptsMarkedForMove.values())
      .pipe(
        mergeMap((appointmentId) => this.getAppointmentById(appointmentId)),
        toArray()
      )
      .toPromise();

    appointmentsToMove.sort((a, b) => (a.startTime > b.startTime ? 1 : a.startTime < b.startTime ? -1 : 0));

    // Verify Providers are available to do the appointment
    for (const appointment of appointmentsToMove) {
      if (!this.verifyProviderIsScheduled(appointment, workingProviders)) {
        var unscheduledStaff = allProviders.filter((s) => s.id === appointment.staffId);
        var content =
          '<strong>' +
          unscheduledStaff[0].title +
          '</strong>' +
          ' is not scheduled to perform the <strong>' +
          appointment.service.serviceName +
          '</strong> service at <strong>' +
          this.getStartTime(appointment) +
          '</strong>';

        // Get providers who can do the service
        var eligibleProviders: ServiceProvider[] = [];
        workingProviders.forEach((provider) => {
          if (this.verifyMovedAppointmentForProvider(appointment, provider)) {
            eligibleProviders.push(provider);
          }
        });

        let providerToUse = await this.getUserSelectedProvider(appointment, content, eligibleProviders).toPromise();

        // If the dialog is cancelled, we won't update the providers and return
        if (!providerToUse) {
          this.eventsService.movingAppointment = false;
          this.apptsMarkedForMove.clear();

          return false;
        }

        appointment.staffId = providerToUse.id;
      }
    }

    const resourceConflictCalls: Map<Appointment, Observable<Resource[]>> = new Map<
      Appointment,
      Observable<Resource[]>
    >();
    const updateAppointmentCalls: Observable<void>[] = [];

    var appTimeDiff = 0;
    appointmentsToMove.forEach((appointment: Appointment, index) => {
      const targetDateTime = targetDate.toDate();
      if (!index) {
        appTimeDiff =
          moment.duration(moment(targetDate).format('HH:mm')).asMilliseconds() -
          moment.duration(appointment.startTime).asMilliseconds();
      }

      var startTime = moment.duration(appTimeDiff + moment.duration(appointment.startTime).asMilliseconds());
      var endTime = moment.duration(appTimeDiff + moment.duration(appointment.endTime).asMilliseconds());

      const updateApp = { ...appointment } as Appointment;
      updateApp.visitId = toVisit.visitId;
      updateApp.visitIdString = toVisit.visitIdString;
      updateApp.date = this.stripDateOnlyAsUtcDate(targetDateTime);
      updateApp.dateTime = targetDateTime;
      updateApp.startTime = startTime;
      updateApp.endTime = endTime;
      updateApp.endDate = this.stripDateOnlyAsUtcDate(targetDateTime);

      // Only the first appointment provider can change to the selected provider
      if (index === 0 && updateApp.staffId !== staffId) {
        updateApp.staffId = staffId;
        updateApp.resourceId = staffId;
      } else {
        updateApp.staffId = appointment.staffId;
        updateApp.resourceId = appointment.staffId;
      }

      updateAppointmentCalls.push(this.updateAppointment(updateApp));
      resourceConflictCalls.set(
        appointment,
        this.resourcesService.getResourcesAllocated(undefined, updateApp.date, startTime, endTime)
      );
    });

    // Check for resource conflicts
    for (let [appointment, resourceConflictCall] of resourceConflictCalls) {
      const appointmentResourceIds = appointment.service.serviceResources?.map((b) => b.resourceId);
      const allocatedResources: Resource[] = await resourceConflictCall.toPromise();

      const matchingResources = allocatedResources.filter((r) =>
        appointmentResourceIds.some((ar) => ar == r.resourceId)
      );

      if (matchingResources.length > 0) {
        let resourceMessage = this.getResourceConflictMessage(matchingResources);

        const dialogRef = this.dialog.open(GenericDialogComponent, {
          width: '300px',
          data: {
            title: 'Confirm Appointment Move?',
            content: resourceMessage,
            confirmButtonText: 'Confirm Move',
            showCancel: true,
            panelClass: 'confirm-appointment-dialog-box',
          },
        });

        const dialogResult = await dialogRef.afterClosed().toPromise();
        if (dialogResult === 'cancel') {
          this.eventsService.movingAppointment = false;
          this.apptsMarkedForMove.clear();

          return false;
        }
      }
    }

    await forkJoin(updateAppointmentCalls).toPromise();
    await this.transferVisitProducts(fromVisitId, toVisit.visitId);

    this.apptsMarkedForMove.clear();
    this.apptsSelected.clear();
    return true;
  }

  async isWithinStaffSchedule(staffId: string, start: Date, end: Date): Promise<boolean> {
    const appointments = await this.getStaffScheduleAppointmentsByDate(
      this.stripDateFromUtcDate(start),
      null
    ).toPromise();
    const staffSchedules: Appointment[] = appointments.filter((a) => a.staffId === staffId);
    return staffSchedules.some((ss) => ss.start <= start && ss.end >= end);
  }

  getUserSelectedProvider(
    appointment: Appointment,
    content: string,
    workingProviders: ServiceProvider[]
  ): Observable<ServiceProvider> {
    var dialogRef;
    dialogRef = this.dialog.open(ServiceProviderSelectorModalComponent, {
      height: 'auto',
      data: {
        appointment: appointment,
        providers: workingProviders,
        content: content,
      },
    });

    return dialogRef.afterClosed().pipe(
      takeUntil(this.unsub),
      map((result: ServiceProvider) => {
        return result;
      })
    );
  }

  verifyProviderIsScheduled(
    apptMoving: Appointment,
    workingProviders: ServiceProvider[],
    toMoveProvider: ServiceProvider = null
  ) {
    // We don't worry about non-regular appointments
    if (apptMoving.appointmentType !== AppointmentType.Regular) return true;
    // Check that the provider is scheduled
    else {
      // If a ServiceProvider is provided, check if they are scheduled
      if (toMoveProvider) {
        return workingProviders.some((p) => p.id === toMoveProvider.id);
      } else {
        return workingProviders.some((p) => p.id === apptMoving.staffId);
      }
    }
  }

  verifyMovedAppointmentForProvider(apptMoving: Appointment, apptToMoveProvider: ServiceProvider) {
    if (apptMoving.appointmentType === AppointmentType.Regular && apptMoving.staffId === apptToMoveProvider.id)
      return true;
    else if (
      apptMoving.appointmentType === AppointmentType.Regular &&
      apptMoving.staffId !== apptToMoveProvider.id &&
      apptToMoveProvider.authorizedServiceIds.some((authServiceId) => authServiceId === apptMoving.service.templateId)
    ) {
      return true;
    }
    return false;
  }

  private async transferVisitProducts(fromVisitId: number, toVisitId: number) {
    if (fromVisitId === toVisitId) return;
    const existingInvoice = await this.invoicesService.getInvoiceByVisitId(fromVisitId).toPromise();
    const toTransfer = existingInvoice?.invoiceLineItems?.filter(
      (ili) => ili.clinicProductId != null && ili.isRecommendedProduct == false
    );
    const toRemove = existingInvoice?.invoiceLineItems?.filter(
      (ili) => ili.clinicProductId != null && ili.isRecommendedProduct == true
    );

    if (toTransfer?.length > 0) {
      const newInvoice = await this.invoicesService.getInvoiceByVisitId(toVisitId).toPromise();
      const newLineItems = newInvoice.invoiceLineItems;
      toTransfer.forEach((productLineItem) => {
        productLineItem.invoiceId = newInvoice.id;
        newLineItems.push(productLineItem);
      });
      await this.invoicesService.updateInvoiceLineItems(newLineItems).toPromise();
    }

    if (toRemove?.length > 0) {
      toRemove.forEach((productLineItem) => {
        productLineItem.isDeleted = true;
      });
      await this.invoicesService.updateInvoiceLineItems(toRemove).toPromise();
    }
  }

  public getStartTime(appt: Appointment): string {
    if (!isNullOrUndefined(appt.startTime)) {
      return moment(appt.date).add(appt.startTime).format('H:mm A');
    } else {
      return 'N/A';
    }
  }

  async handleCancellation(appointment: Appointment): Promise<boolean> {
    const service = appointment.service;
    const isPaid = appointment.paymentStatus === PaymentStatus.Paid;
    const canCancelNonTxPlanPrepayment = isPaid && !service.isLocked;
    let continueCancel = true;
    let chargeTotal = new Service(service).getChargeAmount();

    if (isPaid) {
      let visitInvoice = await this.invoicesService.getInvoiceByVisitId(appointment.visitId).toPromise();
      if (visitInvoice) {
        // Treatment Multiples don't have Invoices
        let serviceLineItem = visitInvoice.invoiceLineItems.find((ili) => ili.serviceId === service.serviceId);
        if (serviceLineItem && serviceLineItem.total) {
          chargeTotal = serviceLineItem.total;
        }
      }
    }

    if ((chargeTotal && chargeTotal > 0 && !service.isPrepaid && isPaid) || service.isLocked) {
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        width: '330px',
        data: {
          title: canCancelNonTxPlanPrepayment ? 'Refund Payment To Patient Credit' : 'Cannot Cancel Appointment',
          content: canCancelNonTxPlanPrepayment
            ? 'This service has already been paid in a patient invoice. Refund $' +
              chargeTotal +
              ' + tax to patient credit?'
            : 'This service has been locked from the patient chart',
          confirmButtonText: 'OK',
          showCancel: canCancelNonTxPlanPrepayment,
        },
      });

      continueCancel = await dialogRef
        .afterClosed()
        .map((result) => result == 'confirm' && canCancelNonTxPlanPrepayment)
        .toPromise();

      if (!continueCancel) {
        return false;
      }
    }

    const dialogRef = this.dialog.open(ConfirmCancelWithReasonDialogComponent, {
      width: '400px',
      data: {
        result: '',
        selectedCancelReason: '',
        customCancelReason: '',
        appointmentId: appointment.appointmentId,
      },
    });

    let confirmResultData: ConfirmCancelData = null;
    const result = await dialogRef.afterClosed().toPromise();
    if (result.event === 'confirm') {
      confirmResultData = result.data;
    } else {
      return false;
    }

    const cancelResult = await this.handleCancellationCharge(
      confirmResultData,
      CancellationType.Appointment,
      appointment.visitId,
      appointment.appointmentId
    );

    if (!cancelResult) {
      // There was an error so don't continue any further
      return false;
    }

    // Record the no charge for cancellation reason
    if (confirmResultData.isCancelChargeable) {
      if (!confirmResultData.chargeCancelAppointment) {
        appointment.reasonCancellationNotCharged = confirmResultData.reasonCancellationNotCharged;
      }
    }

    // Cancel Appointment
    appointment.cancelledByUserId = this.usersService.loggedInUser.id;
    appointment.cancellationDate = new Date();
    appointment.cancelled = true;
    appointment.cancellationReason = result.data?.selectedCancelReason ?? '';
    appointment.cancellationMessage = result.data?.customCancelReason ?? '';
    appointment.source = null;
    appointment.className = '';

    const visit = await this.visitService.getVisitById(appointment.visitId).toPromise();
    //If last appointment in visit delete the visit
    if (visit.appointments.length == 1) {
      visit.cancelledByUserId = this.usersService.loggedInUser.id;
      visit.cancellationDate = new Date();
      visit.cancelled = true;
      visit.cancellationReason = appointment.cancellationReason;
      visit.cancellationMessage = appointment.cancellationMessage;

      // Record the cancellation reasons in the Appointment too
      visit.appointments[0].cancelledByUserId = visit.cancelledByUserId;
      visit.appointments[0].cancellationDate = visit.cancellationDate;
      visit.appointments[0].cancelled = visit.cancelled;
      visit.appointments[0].cancellationReason = visit.cancellationReason;
      visit.appointments[0].cancellationMessage = visit.cancellationMessage;

      // Check for cancellation charge
      if (confirmResultData.isCancelChargeable) {
        // Record the reason cancellation wasn't charged
        if (!confirmResultData.chargeCancelAppointment) {
          visit.appointments[0].reasonCancellationNotCharged = confirmResultData.reasonCancellationNotCharged;
        }
      }
      await this.visitService.updateVisit(visit).toPromise();
    }

    if (isPaid && !service.isPrepaid) {
      let taxes: Array<{ serviceId: number; amount: number; iliTaxes: InvoiceLineItemTax[] }> =
        await this.invoicesService
          .calculateLineItemTax([{ serviceId: service.serviceId, productId: null, amount: chargeTotal }])
          .toPromise();
      chargeTotal = taxes && taxes.length > 0 ? taxes[0].amount : chargeTotal;
      let invoiceReturn = await this.invoicesService.refundPaidService(service.serviceId).toPromise();
      const invoicePayment: InvoicePayment = this.invoicesService.getReturnPayment(
        appointment.patientId,
        invoiceReturn.id,
        FinanceTransactionType.Refund,
        chargeTotal,
        'Refunding cancelled appointment to credit'
      );
      await this.financeService.addTransactions(invoicePayment).toPromise();
    }

    await this.updateAppointment(appointment).toPromise();
    if (appointment.plannedTreatmentId) this.treatmentPlanService.plannedTreatmentCancelled$.next();
    return true;
  }

  async handleCancellationCharge(
    confirmCancelData: ConfirmCancelData,
    cancellationType: CancellationType,
    visitId?: number,
    appointmentId?: number
  ) {
    var response: boolean = true;
    if (confirmCancelData.isCancelChargeable) {
      // Charge the CC for cancellation
      if (confirmCancelData.chargeCancelAppointment) {
        await this.chargeAppointmentCancellation(cancellationType, visitId, appointmentId)
          .toPromise()
          .catch(async (errorResponse) => {
            console.error(errorResponse);
            var errorMessage;
            if (errorResponse.error.errors) errorMessage = errorResponse.error.errors[0].fieldErrors[0];
            else if (errorResponse.message) errorMessage = errorResponse.message;
            else errorMessage = errorResponse.error;

            var errorContent = '<span class="text-danger">' + errorMessage + '</span>';
            const dialogRef = this.dialog.open(GenericDialogComponent, {
              width: '300px',
              data: {
                title: 'Credit Card Payment Error',
                content:
                  'The cancellation invoice was created but there was a problem processing the credit card payment. ' +
                  errorContent +
                  '<br /><strong>Do you want to cancel the appointment anyway?</strong>',
                confirmButtonText: 'Yes',
                showCancel: true,
                cancelButtonText: 'No',
                panelClass: 'confirm-appointment-dialog-box',
              },
            });
            var errorResponse = await dialogRef.afterClosed().toPromise();
            if (errorResponse == 'confirm') response = true;
            else response = false;
          });
      } else {
        // Record the credit card cancellation no charge reason
        await this.appointmentCancellationNotCharged(
          cancellationType,
          visitId,
          appointmentId,
          confirmCancelData.reasonCancellationNotCharged
        )
          .toPromise()
          .catch(async (errorResponse) => {
            console.error(errorResponse);
            var errorMessage;
            if (errorResponse.error.errors) errorMessage = errorResponse.error.errors[0].fieldErrors[0];
            else if (errorResponse.message) errorMessage = errorResponse.message;
            else errorMessage = errorResponse.error;

            var errorContent = '<span class="text-danger">' + errorMessage + '</span>';

            const dialogRef = this.dialog.open(GenericDialogComponent, {
              width: '300px',
              data: {
                title: 'Credit Card Payment Error',
                content:
                  'There was a problem processing the credit card payment. <strong>Do you want to continue cancelling the appointment?</strong>\n' +
                  errorContent,
                confirmButtonText: 'Yes',
                showCancel: true,
                cancelButtonText: 'No',
                panelClass: 'confirm-appointment-dialog-box',
              },
            });
            var errorResponse = await dialogRef.afterClosed().toPromise();
            if (errorResponse == 'confirm') response = true;
            else response = false;
          });
      }
    }
    return response;
  }

  getConfirmationStatus(visit: Visit, appointment: Appointment) {
    if (visit == null || appointment == null) return 'Unconfirmed';
    if (visit.confirmedStatus) {
      let confirmTime = visit.confirmedTime;
      switch (visit.confirmedStatus) {
        case VisitConfirmedStatus.StaffConfirmed: {
          let confirmedBy = visit.manuallyConfirmedByUser ? new User(visit.manuallyConfirmedByUser) : null;
          if (confirmedBy == null) {
            return (
              'Scheduled by: ' +
              appointment.createdBy.firstName +
              ' ' +
              appointment.createdBy.lastName +
              ', ' +
              moment(confirmTime).format('YYYY-MM-DD h:mm A')
            );
          }
          return (
            'Confirmed' +
            (confirmedBy ? ' by ' + confirmedBy.fullName : '') +
            ': ' +
            moment(confirmTime).format('YYYY-MM-DD h:mm A')
          );
        }
        case VisitConfirmedStatus.EmailConfirmed: {
          return 'Confirmed via Email: ' + moment(confirmTime).format('YYYY-MM-DD h:mm A');
        }
        case VisitConfirmedStatus.SMSConfirmed: {
          return 'Confirmed via SMS: ' + moment(confirmTime).format('YYYY-MM-DD h:mm A');
        }
        default:
          return 'Unconfirmed';
      }
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
