import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil, take, switchMap } from 'rxjs/operators';
import { isNullOrUndefined } from '@app/shared/helpers';

import { Tag } from '@models/tag/tag';
import { TagType } from '@models/tag/tag-type';
import { ClinicDocumentsService } from '@services/clinic-documents.service';
import { FormatterService } from '@services/formatter.service';
import { TagService } from '@services/tag.service';
import { FormIoApiService } from '@services/formio-api.service';
import { ClinicDocument } from '@models/document/clinic-document';

@Component({
  selector: 'app-clinic-eform-edit',
  templateUrl: './clinic-eform-edit.component.html',
  styleUrls: ['../../management.component.less'],
})
export class ClinicEformEditComponent implements OnInit, OnDestroy {
  loading = false;
  disableGrid = true;
  unsub: Subject<void> = new Subject<void>();
  docId: number;

  form: FormGroup;
  createOrEdit: String;
  isNew = false;

  pillColours: String[];
  tags: Tag[] = [];
  prevTags: Tag[] = [];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private fb: FormBuilder,
    private clinicDocService: ClinicDocumentsService,
    private tagService: TagService,
    public formatterService: FormatterService,
    private route: ActivatedRoute,
    private router: Router,
    private formIoApiService: FormIoApiService
  ) {}

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      if (+params['id'] === this.clinicDocService.defaultId) {
        this.isNew = true;
        this.createOrEdit = 'Create';
      } else {
        this.docId = +params['id'];
        this.isNew = false;
        this.createOrEdit = 'Edit';
      }

      this.initForm();
      this.pillColours = this.tagService.getPillColors();

      this.tagService.getDocumentTags().subscribe((tags) => {
        this.tags = tags;

        const clinicDoc = this.clinicDocService.getLoadedDocument(this.docId);
        if (!isNullOrUndefined(clinicDoc)) {
          this.removeSelectedTags(clinicDoc.tags);
          if (this.isNew === false) {
            this.setFormValues(clinicDoc);
          }
        }
      });
    });
  }

  private initForm() {
    this.form = this.fb.group({
      id: [this.docId],
      name: [null, Validators.required],
      filePath: [null],
      file: [null, Validators.required],
      tags: [null],
      notes: [null],
      uploadDate: [new Date()],
      modifiedDate: [new Date()],
      pdfToHtmlUrl: [null],
      eFormDefinition: [null],
    });
  }

  private setFormValues(clinicDoc) {
    this.prevTags = [...clinicDoc.tags];
    this.form.controls['name'].setValue(clinicDoc.name);
    this.form.controls['tags'].setValue(clinicDoc.tags);
    this.form.controls['filePath'].setValue(clinicDoc.filePath);
    this.form.controls['notes'].setValue(clinicDoc.notes);
    this.form.controls['uploadDate'].setValue(clinicDoc.uploadDate);
    this.form.controls['modifiedDate'].setValue(clinicDoc.modifiedDate);
    this.form.controls['pdfToHtmlUrl'].setValue(clinicDoc.pdfToHtmlUrl);
    this.form.controls['eFormDefinition'].setValue(clinicDoc.eFormDefinition);
  }

  removeSelectedTags(tags: Tag[]) {
    tags.forEach((initalTag) => {
      const tagIndex = this.tags.findIndex((tag) => tag.tagId === initalTag.tagId);
      if (tagIndex >= 0) {
        this.tags.splice(tagIndex, 1);
      }
    });
  }

  onSubmit() {
    this.loading = true;

    this.form.value.tags = this.form.value.tags ? this.processTags(this.form.value.tags) : this.form.value.tags;
    try {
      if (this.isNew) {
        this.formIoApiService.uploadPdf(this.form.value).subscribe((forioUrl) => {
          const doc = this.form.value as ClinicDocument;
          doc.pdfToHtmlUrl = forioUrl;
          this.clinicDocService
            .uploadHtmlPdfToStorage(doc)
            .pipe(take(1))
            .subscribe((storageUrl) => {
              this.formIoApiService.deletePdf(doc).subscribe();
              doc.pdfToHtmlUrl = storageUrl;
              this.clinicDocService.addDocument(this.form.value).subscribe((addedDoc) => {
                this.docId = addedDoc.id;
                this.isNew = false;
                this.createOrEdit = 'Edit';
                this.loading = false;
                this.router.navigate([
                  '/management/clinic-documents/list',
                  { outlets: { 'action-panel': ['edit-clinic-eform', this.docId] } },
                ]);
              });
            });
        });
      } else {
        this.clinicDocService.updateDocument(this.form.value).subscribe(() => {
          this.router.navigate([
            '/management/clinic-documents/eform-builder/' + this.docId,
            { outlets: { 'action-panel': null } },
          ]);
        });
      }
    } catch {
      this.loading = false;
    }
  }

  updateDocument() {
    this.form.value.tags = this.form.value.tags ? this.processTags(this.form.value.tags) : this.form.value.tags;
    this.clinicDocService.updateDocument(this.form.value).subscribe(() => {
      this.router.navigate(['/management/clinic-documents/list', { outlets: { 'action-panel': null } }]);
    });
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      this.form.patchValue({
        file: event.target.files[0],
      });
    }
  }

  cancelUpdate() {
    this.clinicDocService.restoreTags(this.docId, this.prevTags);
    this.clinicDocService.docChangeCancelled.next();
    this.router.navigate(['/management/clinic-documents/list', { outlets: { 'action-panel': null } }]);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  isFormDisabled(): boolean {
    return !this.form.valid && this.isNew;
  }

  processTags(tags: any[]): Tag[] {
    const tagList: Tag[] = [];
    tags.forEach((t) => {
      if (isNullOrUndefined(t.clinicDocumentId)) {
        const type = !isNullOrUndefined(t.type) ? t.type : TagType.custom;
        const title = !isNullOrUndefined(t.title) ? t.title : t.display;
        let tagRefId = '';
        if (t.type !== TagType.custom) {
          if (!isNullOrUndefined(t.tagId)) {
            tagRefId = t.tagId.split('-')[1];
          }
        }
        tagList.push(new Tag(t.id, t.tagId, title, type, tagRefId));
      } else {
        tagList.push(t);
      }
    });
    return tagList;
  }
}
