import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ServiceTemplatesService } from '@services/service-templates.service';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';

@Component({
  selector: 'app-apply-service',
  templateUrl: './apply-service.component.html',
  styleUrls: ['./apply-service.component.less'],
})
export class ApplyServiceComponent implements OnInit, OnDestroy {
  @Output() serviceTemplate: EventEmitter<ClinicServiceTemplate> = new EventEmitter();
  @Output() closeServiceTemplate: EventEmitter<string> = new EventEmitter();

  selectedServiceTemplate: ClinicServiceTemplate;
  serviceTemplates: ClinicServiceTemplate[] = [];

  unsub: Subject<void> = new Subject<void>();

  constructor(private serviceTemplatesService: ServiceTemplatesService) {}

  ngOnInit() {
    this.getServiceTemplates();
  }

  getServiceTemplates() {
    this.serviceTemplatesService
      .getServiceTemplates()
      .pipe(takeUntil(this.unsub))
      .subscribe((serviceTemplates: ClinicServiceTemplate[]) => {
        this.serviceTemplates = serviceTemplates.filter(
          (serviceTemplate: ClinicServiceTemplate) => serviceTemplate.status
        );
      });
  }

  applyServiceTemplate() {
    this.serviceTemplate.next(this.selectedServiceTemplate);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
