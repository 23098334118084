<div class="pr-2 pt-1 font-weight-bold">
  <ng-container *ngIf="editMode; else text">
    <span class="disable-input-number-spinner">
      <input
        #visitChargeDisplayedInput
        type="number"
        autofocus
        class="form-control text-right inline-input"
        [(ngModel)]="visitChargeDisplayed"
        (change)="onOverrideCharge()"
        (blur)="exitEditMode()"
        (keyup.enter)="exitEditMode()"
      />
    </span>
  </ng-container>
  <ng-template #text>
    <div (click)="editMode = true" [ngClass]="{ 'pastel-red-font': hasOverrideCharge() }">
      {{ visitChargeDisplayed | currency }}
    </div>
  </ng-template>
</div>
