<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>

<div [ngStyle]="{'height': 'calc(100dvh - 75px)'}">
  <kendo-grid
    [data]="gridView"
    [sort]="gridState.sort"
    [sortable]="true"
    [selectable]="true"
    [style.height.%]="100"  
    (cancel)="cancelHandler($event)"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)">
    <ng-template kendoGridToolbarTemplate>
      <button 
        type="button"
        class="btn kendo-btn kendo-btn-green em-btn mt-2" 
        icon="plus"
        (click)="onAddClick()">
          Add Tag
      </button>
    </ng-template> 
    <kendo-grid-column field="hexColour" title="" [width]="60" [class]="'icon-column'">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <span 
          [class]="'fas fa-child'" 
          [style.color]="'white'"
          [style.text-align]="'center'"
          [style.backgroundColor]="dataItem.hexColour ? dataItem.hexColour : 'transparent'"
          [style.width.px]="35"
          [style.padding.px]="5"
          [style.border-radius.px]="2">
        </span>
      </ng-template>
      
    </kendo-grid-column>
    <kendo-grid-column field="title" title="Title"></kendo-grid-column>
    <kendo-grid-column field="type" title="Type">
      <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem?.type ? getTagType(dataItem.type) : 'Unknown'}}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column title="Actions" min-width="125px" [width]="125" class="text-center">
      <ng-template kendoGridCellTemplate  let-dataItem    >
        <button kendoTooltip tooltipClass="em-tooltip" position="left" [disabled]="dataItem?.type == TagType.photoType || dataItem?.type == TagType.document" [title]="'Edit tag'" kendoGridEditCommand class="btn kendo-btn"><i class="fas fa-edit"></i></button>
        <button kendoTooltip tooltipClass="em-tooltip" position="left"   [disabled]="dataItem?.type == TagType.photoType || dataItem?.type == TagType.document" [title]="'Delete tag'" kendoGridRemoveCommand class="btn kendo-btn"><i class="fas fa-minus-circle"></i></button>
      </ng-template>
    </kendo-grid-command-column>

  </kendo-grid>
</div>
  
<app-actionpanel></app-actionpanel>