<div class="photo-filter-container p-1 d-flex flex-column">
  <div class="row">
    <div
      title="Toggle Admin Mode"
      *ngIf="!isSeriesMode"
      kendoTooltip
      tooltipClass="em-tooltip"
      position="top"
      title="Toggle consultation gallery images"
      class="h-100 filter-item special-filter-item white-font w-100 col-3 text-center"
      [ngClass]="{ 'selected-item': adminMode }"
      (click)="toggleAdminMode()"
    >
      <i class="far fa-portrait portrait-icon pr-1"></i>Consult
    </div>

    <div
      title="Toggle Favourite Mode"
      *ngIf="photos && photos.length > 0"
      kendoTooltip
      tooltipClass="em-tooltip"
      position="top"
      title="Toggle only showing favourite photos"
      class="h-100 filter-item white-font special-filter-item w-100 col-3 text-center"
      [ngClass]="{ 'selected-item': favouriteMode }"
      (click)="toggleFavouriteMode()"
    >
      <i class="far fa-heart portrait-icon pr-1"></i>Favourites
    </div>

    <div
      title="Toggle Coolsculpting Gallery"
      *ngIf="adminMode && photos && photos.length > 0"
      kendoTooltip
      tooltipClass="em-tooltip"
      position="top"
      title="Toggle only showing coolsculpting photos"
      class="h-100 filter-item white-font special-filter-item w-100 col-3 text-center"
      [ngClass]="{ 'selected-item': csMode }"
      (click)="toggleCoolsculptingMode()"
    >
      <i class="far fa-snowflake portrait-icon pr-1"></i>Coolsculpting
    </div>

    <div
      title="Toggle stock images in gallery"
      *ngIf="csMode && !isSeriesMode"
      kendoTooltip
      tooltipClass="em-tooltip"
      position="top"
      title="Toggle showing stock (Bodify) images"
      class="h-100 filter-item white-font special-filter-item w-100 col-3 text-center"
      [ngClass]="{ 'selected-item': showStockImages }"
      (click)="toggleStockImages()"
    >
      <i class="far fa-stocking portrait-icon pr-1"></i>Stock Images
    </div>

    <div class="col-3" [ngClass]="{}"></div>
  </div>
  <div class="row" *ngIf="photos && photos.length > 0">
    <div class="col text-center filter-header">Type</div>
    <div class="col text-center filter-header">Sharing</div>
    <div class="col text-center filter-header" *ngIf="allowServiceFilter && !adminMode">
      <mat-select
        #selectedVals1
        [disabled]="tags?.AutoGenerated?.length == 0"
        [(value)]="selectedTags.AutoGenerated"
        multiple
        placeholder="Patient"
        (selectionChange)="basicFilter()"
      >
        <mat-option *ngFor="let tag of tags.AutoGenerated" [value]="tag">{{ tag.title }}</mat-option>
        <mat-select-trigger style="color: black; font-weight: bold">
          Service
          <div *ngIf="selectedTags?.AutoGenerated?.length > 3" class="pl-1">
            (+{{ selectedTags?.AutoGenerated?.length - 3 }})
          </div>
        </mat-select-trigger>
      </mat-select>
    </div>

    <div class="col text-center filter-header" *ngIf="csMode">
      <mat-select
        #selectedGenderSelect
        [(value)]="selectedGender"
        placeholder="Gender"
        (selectionChange)="basicFilter()"
      >
      <mat-option  [value]="null">No Gender Filter</mat-option>
      <mat-option  [value]="'Male'">Male</mat-option>
      <mat-option  [value]="'Female'">Female</mat-option>
        
      </mat-select>
    </div>

    <div class="col text-center filter-header">
      <mat-select
        #selectedVals2
        [disabled]="tags?.BodyPart?.length == 0"
        [(value)]="selectedTags.BodyPart"
        multiple
        placeholder="Body"
        (selectionChange)="basicFilter()"
      >
        <mat-option *ngFor="let tag of tags.BodyPart" [value]="tag">{{ tag.title }}</mat-option>
        <mat-select-trigger style="color: black; font-weight: bold">
          Body
          <div *ngIf="selectedTags?.BodyPart?.length > 3" class="pl-1">(+{{ selectedTags?.BodyPart?.length - 3 }})</div>
        </mat-select-trigger>
      </mat-select>
    </div>

    <div class="col text-center filter-header" *ngIf="!csMode">
      <mat-select
        #selectedVals2
        [disabled]="tags?.Service?.length == 0"
        [(value)]="selectedTags.Service"
        multiple
        placeholder="Service Tag"
        (selectionChange)="basicFilter()"
      >
        <mat-option *ngFor="let tag of tags.Service" [value]="tag">{{ tag.title }}</mat-option>
        <mat-select-trigger style="color: black; font-weight: bold">
          Service
          <div *ngIf="selectedTags?.Service?.length > 3" class="pl-1">(+{{ selectedTags?.Service?.length - 3 }})</div>
        </mat-select-trigger>
      </mat-select>
    </div>


  </div>

  <div class="row align-items-start" *ngIf="photos && photos.length > 0">
    <div
      class="col text-center filter-item"
      [ngClass]="{ 'selected-item': typeFilters.single }"
      (click)="typeFilters.single = !typeFilters.single; basicFilter()"
    >
      Single
    </div>
    <div
      class="col text-center filter-item"
      #referenceButton
      [ngClass]="{ 'selected-item': shareFilters.none && !adminMode }"
      (click)="shareFilters.none = adminMode ? false : !shareFilters.none; adminMode ? null : basicFilter()"
    >
      None
    </div>
    <div
      *ngIf="selectedTags?.AutoGenerated?.length > 0; else blankCol1"
      class="col text-center filter-item selected-item"
      (click)="tagSelected(0, 'AutoGenerated')"
    >
      {{ selectedTags.AutoGenerated[0].title }}
    </div>
    <ng-template #blankCol1><div *ngIf="!adminMode" class="col grid-place"></div></ng-template>
    <div *ngIf="csMode" class="col grid-place"></div>
    <div
      *ngIf="selectedTags?.BodyPart?.length > 0; else blankCol1b"
      class="col text-center filter-item selected-item"
      (click)="tagSelected(0, 'BodyPart')"
    >
      {{ selectedTags.BodyPart[0].title }}
    </div>
    <ng-template #blankCol1b><div class="col grid-place"></div></ng-template>
    <div
      *ngIf="selectedTags?.Service?.length > 0; else blankCol1c"
      class="col text-center filter-item selected-item"
      (click)="tagSelected(0, 'Service')"
    >
      {{ selectedTags.Service[0].title }}
    </div>
    <ng-template #blankCol1c><div *ngIf="!csMode" class="col grid-place"></div></ng-template>
  </div>
  <div class="row align-items-start" *ngIf="photos && photos.length > 0">
    <div
      class="col text-center filter-item"
      [ngClass]="{ 'selected-item': typeFilters.ba }"
      (click)="typeFilters.ba = !typeFilters.ba; basicFilter()"
    >
      B&A
    </div>
    <div
      class="col text-center filter-item"
      [ngClass]="{ 'selected-item': shareFilters.internal || adminMode }"
      (click)="shareFilters.internal = adminMode || !shareFilters.internal; adminMode ? null : basicFilter()"
    >
      Internal
    </div>
    <div
      *ngIf="selectedTags?.AutoGenerated?.length > 1; else blankCol2"
      class="col text-center filter-item selected-item"
      (click)="tagSelected(1, 'AutoGenerated')"
    >
      {{ selectedTags?.AutoGenerated[1].title }}
    </div>
    <ng-template #blankCol2><div *ngIf="!adminMode" class="col grid-place"></div></ng-template>
    <div *ngIf="csMode" class="col grid-place"></div>
    <div
      *ngIf="selectedTags?.BodyPart?.length > 1; else blankCol2b"
      class="col text-center filter-item selected-item"
      (click)="tagSelected(1, 'BodyPart')"
    >
      {{ selectedTags.BodyPart[1].title }}
    </div>
    <ng-template #blankCol2b><div class="col grid-place"></div></ng-template>
    <div
      *ngIf="selectedTags?.Service?.length > 1; else blankCol2c"
      class="col text-center filter-item selected-item"
      (click)="tagSelected(1, 'Service')"
    >
      {{ selectedTags.Service[1].title }}
    </div>
    <ng-template #blankCol2c><div *ngIf="!csMode" class="col grid-place"></div></ng-template>
  </div>
  <div class="row align-items-start" *ngIf="photos && photos.length > 0">
    <div
      class="col text-center filter-item"
      [ngClass]="{ 'selected-item': typeFilters.series }"
      (click)="typeFilters.series = !typeFilters.series; basicFilter()"
    >
      Series
    </div>
    <div
      class="col text-center filter-item"
      [ngClass]="{ 'selected-item': shareFilters.external || adminMode }"
      (click)="shareFilters.external = adminMode || !shareFilters.external; adminMode ? null : basicFilter()"
    >
      External
    </div>
    <div
      *ngIf="selectedTags?.AutoGenerated?.length > 2; else blankCol3"
      class="col text-center filter-item selected-item"
      (click)="tagSelected(2, 'AutoGenerated')"
    >
      {{ selectedTags.AutoGenerated[2].title }}
    </div>
    <ng-template #blankCol3><div *ngIf="!adminMode" class="col grid-place"></div></ng-template>
    <div *ngIf="csMode" class="col grid-place"></div>
    <div
      *ngIf="selectedTags?.BodyPart?.length > 2; else blankCol3b"
      class="col text-center filter-item selected-item"
      (click)="tagSelected(2, 'BodyPart')"
    >
      {{ selectedTags.BodyPart[2].title }}
    </div>
    <ng-template #blankCol3b><div class="col grid-place"></div></ng-template>
    <div
      *ngIf="selectedTags?.Service?.length > 2; else blankCol3c"
      class="col text-center filter-item selected-item"
      (click)="tagSelected(2, 'Service')"
    >
      {{ selectedTags.Service[2].title }}
    </div>
    <ng-template #blankCol3c><div *ngIf="!csMode" class="col grid-place"></div></ng-template>
  </div>
</div>
