import { takeUntil, startWith, map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Package } from '@models/package';
import { ClinicProduct } from '@models/clinic-product';
import { ProductCategory } from '@models/product-category';
import { Service } from '@models/service/service';
import { ServiceCategory } from '@models/service/service-category';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete/confirm-delete.component';
import { MatDialog } from '@angular/material/dialog';

import { Observable, Subject } from 'rxjs';
import { ClinicProductsService } from '@services/clinic-products.service';
import { ServicesService } from '@services/services.service';
import { PackagesService } from '@services/packages.service';

@Component({
  selector: 'app-catalogue-packages',
  templateUrl: './catalogue-packages.component.html',
  styleUrls: ['./catalogue-packages.component.less']
})
export class CataloguePackagesComponent implements OnInit, OnDestroy {
  searchValue = '';
  unsub: Subject<void> = new Subject<void>();
  disableGrid = false;
  loading = false;
  packages: Package[] = [];
  products: ClinicProduct[] = [];
  services: Service[] = [];
  prodCats: ProductCategory[] = [];
  servCats: ServiceCategory[] = [];
  searchCtrl: FormControl;
  filteredPackages: Observable<Package[]>;
  productsDropDown: ClinicProduct[] = [];
  servicesDropDown: Service[] = [];
  productsByPackage: { [id: string]: ClinicProduct[] } = {};
  servicesByPackage: { [id: string]: Service[] } = {};

  gridView: GridDataResult;
  gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  formGroup: FormGroup;
  editedRowIndex: number;
  editedDataItem: Package;

  constructor(
    private productsService: ClinicProductsService,
    private servicesService: ServicesService,
    private packagesService: PackagesService,
    private router: Router,
    private catalogueUpdatesService: CatalogueUpdatesService,
    private deleteDialog: MatDialog
  ) {
    this.searchCtrl = new FormControl();
    this.filteredPackages = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      map(pack => this.filterPackages(pack))
    );
  }

  ngOnInit() {
    this.gridView = {
      data: [],
      total: 0
    };
    this.loading = true;
    this.catalogueUpdatesService.catalogueUpdated.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.disableGrid = false;
      if (this.catalogueUpdatesService.refreshRequired) {
        this.catalogueUpdatesService.refreshRequired = false;
        this.refreshData();
      }
    });
    this.getProductList();
    this.getServiceList();
    this.refreshData();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  getProductList() {
    this.prodCats = [];
    this.productsService.getProductCategories().subscribe(res => {
      res.forEach(doc => {
        const docData = doc;
        const pushItem: ProductCategory = {
          productCategoryId: docData.productCategoryId,
          name: docData.name
        };
        this.prodCats.push(pushItem);
      });
      this.prodCats.forEach(pc => {
        this.productsService.getProductByCategory(pc.productCategoryId).subscribe(result => {
          result.forEach(pcdoc => {
            const pcdocData = pcdoc;
            const pcpushItem = new ClinicProduct ({
              id: pcdocData.id,
              displayName: pcdocData.displayName,
              productCode: pcdocData.productCode,
              quantityInStock: pcdocData.quantityInStock,
              retailPrice: pcdocData.retailPrice,
              overrideRetailPrice: pcdocData.overrideRetailPrice,
              wholesalePrice: pcdocData.wholesalePrice,
              overrideWholesalePrice: pcdocData.overrideWholesalePrice,
              productCategoryId: pcdocData.productCategoryId,
              category: pcdocData.category,
              productTaxes: pcdocData.productTaxes,
              quantity: pcdocData.quantity,
              usageDuration: pcdocData.usageDuration,
              usageInstructions: pcdocData.usageInstructions
            });
            this.productsDropDown.push(pcpushItem);
          });
        });
      });
    });
  }

  getServiceList() {
    this.servCats = [];
    this.servicesService.getServiceCategories().subscribe(res => {
      res.forEach(doc => {
        const docData = doc;
        const pushItem: ServiceCategory = {
          serviceCategoryId: docData.serviceCategoryId,
          name: docData.name
        };
        this.servCats.push(pushItem);
      });
      this.servCats.forEach(sc => {
        this.servicesService.getServiceByCategory(sc).subscribe(result => {
          result.forEach(scdoc => {
            const scdocData = scdoc as Service;
            const scpushItem = this.initscpushItem(scdocData);

            this.servicesDropDown.push(scpushItem);
          });
        });
      });
    });
  }

  initscpushItem(scdocData: Service) {
    const scPushItem = new Service();

    scPushItem.setFullObject(
      scdocData.serviceId,
      scdocData.serviceName,
      scdocData.quantity,
      scdocData.serviceCategoryId,
      scdocData.category,
      scdocData.serviceRecProductsString,
      scdocData.resourcesString,
      scdocData.templateId,
      scdocData.serviceTemplate,
      scdocData.serviceDetailTemplateId,
      scdocData.status,
      scdocData.serviceAltName,
      scdocData.defaultDurationMinutes,
      scdocData.diagnosticCode,
      scdocData.templateIcon,
      scdocData.defaultPrice,
      null, // overrideDefaultPrice
      scdocData.attachedForms,
      scdocData.governmentBilling,
      scdocData.serviceTaxes,
      null, // userCategories
      scdocData.room,
      null, // serviceNotes
      null, //planned treatment note
      null, // servicePhotoPath
      null, // observations
      null, // signedTime
      null, // signedBy
      scdocData.equipment,
      false, // isLocked,
      scdocData.serviceBillingCodes // serviceBillingCodes
    );

    return scPushItem;
  }

  filterPackages(name: string) {
    let filterResults: Package[] = [];

    if (name !== '') {
      this.gridView = {
        data: this.packages
          .filter(pack => pack.name.toLowerCase().includes(name.toLowerCase())),
        total: this.packages.filter(pack => pack.name.toLowerCase().includes(name.toLowerCase())).length
      };
      filterResults = this.packages.filter(pack => pack.name.toLowerCase().includes(name.toLowerCase()));
    } else {
      this.gridView = {
        data: this.packages,
        total: this.packages.length
      };
      filterResults = [];
    }
    return filterResults;
  }

  refreshData() {
    this.loading = true;
    this.packages = [];
    this.packagesService.getPackages().subscribe(res => {
      res.forEach(doc => {
        const docData = doc;
        const pushItem: Package = {
          packageId: docData.packageId,
          name: docData.name,
          totalOfIndividualPrices: docData.totalOfIndividualPrices,
          retailPrice: docData.retailPrice,
          packageProducts: docData.packageProducts,
          packageServices: docData.packageServices,
          packageTaxes: docData.packageTaxes,
          packageProductsString: docData.packageProductsString
        };
        this.packages.push(pushItem);
      });
      this.loadItems();
      this.loading = false;
    });
  }

  addPackage(pack: Package) {
    this.packagesService.addPackage(pack);
  }

  onAddClick({ sender }) {
    this.disableGrid = true;
    this.router.navigate(['/management/catalogue/packages', { outlets: { 'action-panel': ['edit-package', '_'] } }]);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.disableGrid = true;
    this.router.navigate([
      '/management/catalogue/packages',
      { outlets: { 'action-panel': ['edit-package', dataItem.packageId] } }
    ]);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }) {
    const pack: Package = formGroup.value;
    if (isNew) {
      this.packagesService.addPackage(pack).subscribe(() => {
        this.refreshData();
      });
    } else {
      this.packagesService.updatePackage(pack).subscribe(() => {
        this.refreshData();
      });
    }
    sender.closeRow(rowIndex);
  }

  public removeHandler({ dataItem }) {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px'
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe(result => {
        if (result === 'delete') {
          const dataItemToRemove = {
            packageId: dataItem.packageId,
            name: dataItem.name,
            totalOfIndividualPrices: dataItem.totalOfIndividualPrices,
            retailPrice: dataItem.retailPrice,
            packageProducts: dataItem.packageProducts,
            packageProductQuantities: dataItem.packageProductQuantities,
            packageServices: dataItem.packageServices,
            packageServiceQuantities: dataItem.packageServiceQuantities,
            packageTaxes: dataItem.packageTaxes,
            packageProductsString: dataItem.packageProductsString
          };
          this.packagesService.removePackage(dataItemToRemove).subscribe(() => {
            this.refreshData();
          });
        }
      });
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  loadItems() {
    this.gridView = {
      data: this.packages,
      total: this.packages.length
    };
    // filter the products and services dropdowns based on their associated package
    this.gridView.data.forEach(function(element) {});
  }

  openDeleteDialog(): void {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
      data: { result: '' }
    });
  }

  normalizeArray<T>(array: Array<T>, indexKey: keyof T) {
    const normalizedObject: any = {};
    for (let i = 0; i < array.length; i++) {
      const key = array[i][indexKey];
      normalizedObject[key] = array[i];
    }
    return normalizedObject as { [key: string]: T };
  }
}
