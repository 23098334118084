<div class="edit-container position-absolute d-block z-index-3 white-background content">
  <ngx-loading [show]="loading"></ngx-loading>

  <div class="re-links-container">
    <button class="btn em-btn teal-background" (click)="cancelUpdate()">
      <i class="far fa-times" aria-hidden="true"></i>
    </button>
  </div>

  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0">
    <form [formGroup]="reminderSettingsForm">
      <div class="row mx-0 teal-background">
        <div class="col">
          <h6 class="white-font uppercase my-0 py-2">Send Test Visit Reminder {{ type }}</h6>
        </div>
      </div>

      <div *ngIf="type === 'email'" class="py-3">
        <div class="row mx-0">
          <div class="col">
            <mat-form-field class="w-100">
              <mat-label>Template</mat-label>
              <mat-select formControlName="emailTemplate">
                <mat-option *ngFor="let template of emailTemplates" [value]="template">
                  {{ template.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col">
            <mat-form-field class="w-100">
              <input matInput placeholder="Sender Name" formControlName="emailSenderName" />
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col">
            <mat-form-field class="w-100">
              <input matInput placeholder="Sender Email Address" formControlName="emailSenderEmail" />
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col">
            <mat-form-field class="w-100">
              <input matInput placeholder="Recipient Name" formControlName="emailRecipientName" />
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col">
            <mat-form-field class="w-100">
              <input matInput placeholder="Recipient Email Address" formControlName="emailRecipientEmail" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="type === 'sms'" class="py-3">
        <div class="row mx-0">
          <div class="col">
            <mat-form-field class="w-100">
              <textarea
                matInput
                placeholder="SMS Message Preview"
                formControlName="smsMessage"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="10"
                (keydown.enter)="$event.stopPropagation()"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col">
            <mat-form-field class="w-100">
              <input matInput placeholder="Recipient Number" formControlName="recipientNumber" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row mt-5 mx-0">
        <div class="col d-flex justify-content-end">
          <button class="btn em-btn em-btn-green mr-2" (click)="sendTest()" [disabled]="!reminderSettingsForm.valid">
            Send Test {{ type }}
          </button>

          <button class="btn em-btn cancel-btn" (click)="cancelUpdate()">Cancel</button>
        </div>
      </div>
    </form>
  </perfect-scrollbar>
</div>
