import { Component, OnInit, Input, AfterViewInit, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { Visit } from '@models/visit';
import { VisitService } from '@services/visit.service';

@Component({
  selector: 'app-visit-billing',
  templateUrl: './visit-billing.component.html',
  styleUrls: ['./visit-billing.component.less']
})
export class VisitBillingComponent implements OnInit {
  @Input() visitId: number;

  editMode = false;

  visit: Visit;
  visitChargeDisplayed: number;

  constructor(private visitService: VisitService) {}

  ngOnInit() {
    this.visitService.getVisitById(this.visitId).subscribe((visit: Visit) => {
      this.visit = new Visit(visit);
      this.visitChargeDisplayed = this.visit.getChargeAmount();
    });
  }

  onOverrideCharge(): void {
    this.visit.overrideChargeAmount = this.visitChargeDisplayed;
    this.visitChargeDisplayed = this.visit.getChargeAmount();

    this.visitService.updateVisit(this.visit).subscribe();
  }

  exitEditMode(): void {
    this.editMode = false;
  }

  hasOverrideCharge(): boolean {
    if (this.visit && this.visit.overrideChargeAmount) {
      return true;
    } else {
      return false;
    }
  }
}
