import { ServiceChartEntryNote } from './service-chart-entry-note';
import { ServiceBillingCode } from '@models/service/service-billing-code';
import { ServiceDetailTemplate } from '../service/service-detail-template';
import { PatientForm } from '@models/forms/patient-form';
import { ServiceForm } from '@models/forms/service-form';
import { PhotoDrawing } from '@models/photo/photo-drawing';
import { AppointmenteTreatmentForm } from '@models/etreatment-forms/appointment-etreatment-form';
import { PaymentStatus } from '@models/appointments/payment-status';
import { PreviousTreatmentDetail } from '@models/treatment-planning/previous-treatment-detail';

export class ChartAppointment {
  public appointmentId: number;
  public date: string;
  public serviceName: string;
  public serviceId: number;
  public serviceIdColour: string;
  public serviceIcon: string;
  public serviceTemplateId: number;
  public serviceProviderName: string;
  public paymentStatus: PaymentStatus;
  public isPrepaid: boolean;
  public price: number;
  public total: number;
  public invoiceId: number;
  public governmentBilling: boolean;
  public dxCode: string;
  public billingCodes: ServiceBillingCode[];
  public photoDrawings: PhotoDrawing[];
  public serviceNotes: ServiceChartEntryNote[];
  public signedByUser: string;
  public signedTime: Date;
  public isLocked: boolean;
  public serviceDetailTemplateId: ServiceDetailTemplate;
  public plannedTreatmentId?: number;
  public visitNotes: string;
  public appointmentForms: PatientForm[];
  public serviceForms: ServiceForm[];
  public appointmenteTreatmentForms: AppointmenteTreatmentForm[];
  public details?: PreviousTreatmentDetail[];
  public isPlannedTreatmentMultiple: boolean;
  public plannedTreatmentMultipleQuantity: number;
  public plannedTreatmentRank: number;
  public plannedTreatmentMultipleNotes: string;

  public constructor(init?: Partial<ChartAppointment>) {
    Object.assign(this, init);
  }
}
