import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, combineLatest } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';
import { ClinicDocument } from '@models/document/clinic-document';
import { DocumentType } from '@models/document/document-type';
import { PatientDocument } from '@models/document/patient-document';
import { ClinicDocumentsService } from '@services/clinic-documents.service';
import { PatientDocumentService } from '@services/patient-documents.service';
import { DocumentDetailsComponent } from '../document-details/document-details.component';

@Component({
  selector: 'app-assign-clinic-document',
  templateUrl: './assign-clinic-document.component.html',
  styleUrls: ['./assign-clinic-document.component.less']
})
export class AssignClinicDocumentComponent implements OnInit, OnDestroy {
  searchFormControl: FormControl;
  @Input() patientId: number;
  @Input() quickView = false;
  @Input() filterType: DocumentType;
  unsub: Subject<void>;
  clinicDocuments: ClinicDocument[];
  allClinicDocuments: ClinicDocument[];
  lastAddedClinicDocument: ClinicDocument;

  assignedClinicDocumentIdList: number[];

  loading = false;

  constructor(
    public activeModal: NgbActiveModal,
    private clinicDocumentService: ClinicDocumentsService,
    private patientDocumentService: PatientDocumentService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.unsub = new Subject();
    this.loading = true;

    this.assignedClinicDocumentIdList = [];

    // Search related setup
    this.searchFormControl = new FormControl('');

    combineLatest([
      this.clinicDocumentService.getDocuments().pipe(tap(() => { this.loading = false; })),
      this.searchFormControl.valueChanges.pipe(startWith(''))
    ])
      .pipe(
        map(([clinicDocuments, searchString]) =>
          searchString ? clinicDocuments.filter(this.filterClinicDocuments(searchString)) : clinicDocuments.slice()
        ),
        map(clinicDocuments => {
          this.allClinicDocuments = clinicDocuments;
          if (this.filterType){
            return clinicDocuments.filter(doc => doc.tags.map(tag => +tag.refId).indexOf(this.filterType.id) != -1)
          }
          else{
            return clinicDocuments;
          }
        }),
        takeUntil(this.unsub)
      )
      .subscribe(clinicDocuments => {
        this.clinicDocuments = clinicDocuments;
      }); // End of the search related setup

    this.patientDocumentService.documents$
      .pipe(takeUntil(this.unsub))
      .subscribe((patientClinicDocumentList) => {
        this.assignedClinicDocumentIdList = patientClinicDocumentList.filter(p => p.isClinicDocument).map(p => p.clinicDocumentId);
      });

      this.patientDocumentService.documentAssigned.pipe(takeUntil(this.unsub)).subscribe(patientClinicDocument => {
        this.loading = false;
        this.activeModal.close();
        if (this.quickView) {
          this.dialog.open(DocumentDetailsComponent, {
            panelClass: 'document-view-modal',
            disableClose: true
          });
          this.patientDocumentService.documentSelected.next(patientClinicDocument)
        }
      });
  }

  resetFilter(){
    this.filterType = null;
    this.clinicDocuments = this.allClinicDocuments;
  }

  alreadyAssigned(clinicDocumentId: number) {
    return this.assignedClinicDocumentIdList.includes(clinicDocumentId);
  }

  assignClinicDocument(clinicDocument: ClinicDocument) {
    const toAssign = new PatientDocument({
      id: 0,
      name: clinicDocument.name,
      filePath: clinicDocument.filePath,
      file: clinicDocument.file,
      patientId: this.patientId,
      documentTypeId: clinicDocument.documentTypeId,
      tags: clinicDocument.tags,
      uploadDate: new Date(),
      modifiedDate: new Date(),
      isClinicDocument: true,
      clinicDocumentId: clinicDocument.id,
      pdfToHtmlUrl: clinicDocument.pdfToHtmlUrl,
      eFormDefinition: clinicDocument.eFormDefinition,
    });

    toAssign.tags.forEach(t => {
        t.id = 0;
        t.refId = t.tagId.split('-')[1];
    });

    this.loading = true;
    this.patientDocumentService.assignPatientClinicDocuments(toAssign);

  }

  filterClinicDocuments = (searchString: string) => (clinicDocument: ClinicDocument) => {
    return (clinicDocument.name && clinicDocument.name.toLowerCase().includes(searchString.toLowerCase())) ||
      (clinicDocument.notes && clinicDocument.notes.toLowerCase().includes(searchString.toLowerCase()));
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
