import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { LoyaltyConverting } from '@models/finance/loyalty-convering';
import { PostInvoice } from '@models/invoice/invoice';
import { InvoiceType } from '@models/invoice/invoice-type';
import { Membership } from '@models/memberships/membership';
import { TabType } from '@models/tab-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FinanceService } from '@services/finance.service';
import { InvoicesService } from '@services/invoices.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MembershipSelectionComponent } from '../membership-selection/membership-selection.component';

export interface UpdateCreditDialogData {
  oldCreditAmount: number;
  newCreditAmount: number;
}

@Component({
  selector: 'app-account-overview',
  templateUrl: './account-overview.component.html',
  styleUrls: ['./account-overview.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class AccountOverviewComponent implements OnInit, OnDestroy {
  @ViewChild('returnModal') returnModal: TemplateRef<any>;
  @ViewChild('creditUpdateModal') creditUpdateModal: TemplateRef<any>;
  patientId: number;
  patientAvailableCredit: number = 0;
  patientNewAvailableCredit: number = 0;
  patientAvailableLoyaltyPoints: { value: number; dollarValue: number } = { value: 0, dollarValue: 0 };
  errors: any[] = [];
  unsub = new Subject<any>();
  loading = false;
  TabType = TabType;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private invoiceService: InvoicesService,
    private financeService: FinanceService,
    private modalService: NgbModal,
    public authService: AuthService,
    public updateCreditDialog: MatDialog,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getCurrentPatientId();
    this.getPatientAvailableCredit();
    this.detectInvoiceListUpdate();
    this.getPatientAvailableLoyaltyPoints();
    this.getPatientAvailableLoyaltyPointsDollarValue();
  }

  getCurrentPatientId() {
    this.patientId = this.route.snapshot.params.patId.split('_')[0];
  }

  async addNewInvoice(addCredit: boolean) {
    const blankInvoice: PostInvoice = {
      patientId: this.patientId,
      invoiceDate: new Date(),
      invoiceTypeId: InvoiceType.Regular,
    };

    this.loading = true;
    const result = await this.invoiceService
      .addInvoice(blankInvoice, false)
      .toPromise()
      .catch((err) => {
        throw err;
      });
    const invoice = result.model;
    if (addCredit) {
      await this.invoiceService.addCreditLineItemToInvoice(invoice);
    }
    this.router.navigate(['../invoice', invoice.id], { relativeTo: this.route });
    this.loading = false;
  }

  openMembershipInvoice() {
    const modalRef = this.modalService.open(MembershipSelectionComponent, { backdrop: 'static' });
    (modalRef.componentInstance as MembershipSelectionComponent).patientId = this.patientId;
    modalRef.closed.subscribe(async (memberships: Membership[]) => {
      if (memberships && memberships.length > 0) {
        this.loading = true;
        const membershipIds = memberships.map((m) => m.id);
        const invoice = await this.invoiceService
          .createInvoiceForMemberships(this.patientId, membershipIds)
          .toPromise()
          .catch((err) => {
            this.loading = false;
            throw err;
          });
        this.router.navigate(['../invoice', invoice.id], { relativeTo: this.route });
        this.loading = false;
      }
    });
  }

  getPatientAvailableCredit() {
    this.financeService
      .getPatientAvailableCredit(this.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((patientAvailableCredit: number) => (this.patientAvailableCredit = patientAvailableCredit));
  }

  detectInvoiceListUpdate() {
    this.invoiceService.invoicesListUpdated$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.getPatientAvailableLoyaltyPoints();
      this.getPatientAvailableLoyaltyPointsDollarValue();
      this.getPatientAvailableCredit();
    });
  }

  openReturnModal() {
    this.dialog.open(this.returnModal, { panelClass: 'custom-dialog-container' });
  }

  openCreditUpdateModal() {
    this.dialog.open(this.creditUpdateModal, { panelClass: 'custom-dialog-container' });
  }

  patientCreditUpdated(newPatientCreditAmount) {
    this.patientAvailableCredit = newPatientCreditAmount;
  }

  getPatientAvailableLoyaltyPoints() {
    this.financeService
      .getPatientAvailableLoyaltyPoints(this.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((value: number) => {
        this.patientAvailableLoyaltyPoints.value = value;
      });
  }

  getPatientAvailableLoyaltyPointsDollarValue() {
    this.financeService
      .getPatientAvailableLoyaltyPointsDollarValue(this.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((value: number) => {
        this.patientAvailableLoyaltyPoints.dollarValue = value;
      });
  }

  convertLoyaltyPointsToCredit() {
    const loyaltyConvertingModel: LoyaltyConverting = {
      patientId: this.patientId,
      amountToConvert: this.patientAvailableLoyaltyPoints.value,
    };
    this.financeService
      .convertLoyaltyPointsToCredit(loyaltyConvertingModel)
      .pipe(takeUntil(this.unsub))
      .subscribe(
        () => {
          this.patientAvailableCredit += this.patientAvailableLoyaltyPoints.dollarValue;
          this.patientAvailableLoyaltyPoints.value = 0;
          this.patientAvailableLoyaltyPoints.dollarValue = 0;
        },
        (errorResponse: HttpErrorResponse) => {
          this.errors = errorResponse.error.errors;
        }
      );
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
