import { LogLevel } from '@microsoft/signalr';

export const environment = {
  production: false,
  hmr: false,
  mixpanelToken: '5bba455aba5a226462dd40d9f1e669d3',
  baseUrl: document.getElementsByTagName('base')[0].href,
  signalRUrl: '',
  signalRLogLevel: LogLevel.Warning,
  jQuerySource: 'https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js',
  payWithConvergeSource: 'https://demo.convergepay.com/hosted-payments/PayWithConverge.js',
  squarePaymentSource: 'https://sandbox.web.squarecdn.com/v1/square.js',
};
