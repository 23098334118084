<h1 mat-dialog-title class="text-center mb-3">{{ title }}</h1>
<div mat-dialog-content class="text-center mb-3">
  <p *ngIf="content !== undefined">{{ content }}</p>
  <mat-form-field floatLabel="always" class="filter-date">
    <mat-label>Date</mat-label>
    <input
      matInput
      [placeholder]="inputLabel"
      [matDatepicker]="picker"
      [max]="maximumDate"
      [min]="minimumDate"
      [value]="date"
      (dateInput)="onCalendarDateChange($event)"
      (dateChange)="onCalendarDateChange($event)"
    />
    <mat-datepicker #picker></mat-datepicker>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  </mat-form-field>
  <kendo-timepicker
    [max]="getMaximumTime()"
    [min]="minimumDate"
    [fillMode]="'flat'"
    [placeholder]="inputLabel"
    [steps]="{ minute: minimumDuration }"
    [(value)]="date"
  >
  </kendo-timepicker>
</div>
<div mat-dialog-actions class="justify-content-center">
  <button mat-button [mat-dialog-close]="'cancel'">Cancel</button>
  <button mat-button [mat-dialog-close]="date" cdkFocusInitial>{{ confirmButtonText }}</button>
</div>
