import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Patient } from '@models/patient';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PatientService } from '@services/patient.service';
import { Subject, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-patient-search-modal',
  templateUrl: './patient-search-modal.component.html',
  styleUrls: ['./patient-search-modal.component.less'],
})
export class PatientSearchModalComponent implements OnInit, OnDestroy, AfterViewInit {
  loading = false;
  searchFormControl = new FormControl('');
  patients: Patient[] = null;
  unsub = new Subject<void>();
  @ViewChild('patientSearchInput') patientSearchInput: ElementRef<HTMLInputElement>;

  constructor(public activeModal: NgbActiveModal, private patientService: PatientService) {}

  ngOnInit(): void {
    this.searchFormControl.valueChanges
      .pipe(
        takeUntil(this.unsub),
        tap(() => (this.loading = true)),
        switchMap((searchString) =>
          searchString && searchString.trim() !== ''
            ? this.patientService.searchPatientByTerm(searchString.trim())
            : of(null)
        )
      )
      .subscribe((results) => {
        this.patients = results;
        this.loading = false;
      });
  }

  ngAfterViewInit(): void {
    this.patientSearchInput.nativeElement.focus();
  }

  getPatientName(patient: Patient) {
    return patient.firstName + ' ' + (patient.nickName ? `"${patient.nickName}" ` : '') + patient.lastName;
  }

  closeModal(patient: Patient) {
    this.activeModal.close(patient);
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }
}
