<div kendoTooltip filter="i[title]" position="top" tooltipClass="em-tooltip" class="h-100">
  <ngx-loading [show]="loadingMessages.length > 0"></ngx-loading>
  <div *ngIf="loadingMessages.length > 0" class="loading-info">
    <h3 class="px-4 py-1">{{ loadingMessages[0] }}</h3>
  </div>
  <app-fc-schedule-wrapper
    class="d-block w-100 h-100"
    #scheduleWrapper
    [clinic]="clinic"
    [appointments]="appointmentService.activeScheduleAppointments"
    [providers]="providersToDisplay"
    [date]="currentDate"
    [editable]="isScheduleEditable"
    (timeSlotSelected)="onTimeSlotSelected($event)"
    (resourceSelected)="onResourceSelected($event)"
    (eventDropped)="onEventDropped($event)"
    (eventDragStarted)="onEventDragStarted($event)"
    (eventResized)="onEventResized($event)"
    (eventHovered)="onEventHovered($event)"
    (eventClicked)="onEventClicked($event)"
    (rightClicked)="onRightClicked($event)"
  ></app-fc-schedule-wrapper>
</div>

<context-menu #contextMenu *ngIf="scheduleView === ScheduleView.Appointments && !eventsService?.blockedScheduleMode">
  <ng-template contextMenuItem>
    <div *ngIf="!rightClickAppointment?.cancelled" class="context-menu-btn" (click)="createNudge()">Create Nudge</div>
  </ng-template>
  <ng-template *ngIf="eventsService?.movingAppointment" contextMenuItem>
    <div class="context-menu-btn" (click)="cancelMove()">Cancel Move</div>
  </ng-template>
  <ng-template *appAuthGuard="appointmentsPolicy" contextMenuItem>
    <div class="context-menu-btn" (click)="cancelAppointment()">Cancel This Appointment</div>
  </ng-template>
  <ng-template *appAuthGuard="appointmentsPolicy" contextMenuItem>
    <!-- [enabled]="eventsService.scheduleMode !== ScheduleMode.AgendaWeek" -->
    <div class="context-menu-btn" (click)="cancelVisit()">Cancel All Appointments</div>
  </ng-template>
  <ng-template *appAuthGuard="appointmentsPolicy" contextMenuItem>
    <div class="context-menu-btn" (click)="markAppointmentForMove()">Move This Appointment</div>
  </ng-template>
  <ng-template contesxtMenuItem>
    <div class="context-menu-btn" (click)="markVisitForMove()">Move All Appointments</div>
  </ng-template>
  <!-- [enabled]="eventsService.scheduleMode !== ScheduleMode.AgendaWeek" -->
  <ng-template contextMenuItem [disabled]="!rightClickAppointment?.service?.requireCardOnFile">
    <div class="context-menu-btn" (click)="requestCreditCard()">Request Credit Card</div>
  </ng-template>
</context-menu>

<context-menu #contextMenu *ngIf="scheduleView === ScheduleView.StaffSchedules || eventsService.blockedScheduleMode">
  <ng-template contextMenuItem>
    <div class="context-menu-btn" (click)="deleteBlockedAppointment()">Delete Schedule</div>
  </ng-template>
</context-menu>

<div #providersFilterPanel id="providersFilterPanel">
  <div class="box">
    <div class="top-bar d-flex justify-content-end py-1">
      <button
        class="d-flex justify-content-center align-items-center px-0 py-0 btn pointer"
        (click)="closeProvidersPanel()"
      >
        <i class="fal fa-times"></i>
      </button>
    </div>
    <div
      *ngIf="scheduleMode === ScheduleMode.DayView && !mobileView"
      class="container no-action pb-0"
      cdkDropList
      (cdkDropListDropped)="onProviderDropped($event)"
    >
      <ngx-loading [show]="providerListLoading"></ngx-loading>
      <div class="row grey-four-border-bottom" *ngFor="let provider of allProviders; let i = index" cdkDrag>
        <div class="col-sm-10 col1 d-flex align-items-center">
          <div
            class="d-flex align-items-center justify-content-center item-number mr-2"
            (click)="providerSelected(provider)"
            [ngClass]="{ selected: provider.visible }"
          ></div>
          {{ provider.title }}
          &nbsp;<i class="far fa-clock smaller" *ngIf="isProviderScheduled(provider.id)"></i>
        </div>
        <div class="col-sm-2 col2 text-right d-flex align-items-center justify-items-center">
          <i
            class="fas fa-check-square selected provider-checkbox"
            *ngIf="provider.visible"
            (click)="providerSelected(provider)"
          ></i>
          <i class="fal fa-square provider-checkbox" *ngIf="!provider.visible" (click)="providerSelected(provider)"></i>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center">
        <div class="row align-items-center">
          Working Only
          <mat-slide-toggle [(ngModel)]="showOnlyWorkingProviders"> </mat-slide-toggle>
        </div>
        <div class="clear-all m-0 mr-2" (click)="selectAllVisibleProviders()">Select All</div>
        <div class="clear-all m-0 mr-2" (click)="clearAllVisibleProviders()">Clear All</div>
      </div>
    </div>
    <div *ngIf="scheduleMode === ScheduleMode.WeekView || mobileView" class="container pb-0">
      <ngx-loading [show]="providerListLoading"></ngx-loading>
      <mat-radio-group
        class="providers-radio-group"
        [labelPosition]="'before'"
        [(ngModel)]="selectedProviderId"
        (change)="selectedProviderChanged()"
      >
        <mat-radio-button
          class="grey-four-border-bottom provider-radio-button"
          *ngFor="let provider of allProviders"
          [value]="provider.id"
        >
          {{ provider.title }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>

<app-hover-panel
  *ngIf="scheduleView !== 'StaffSchedules' && !eventsService.blockedScheduleMode"
  [(hoveredAppointment)]="hoveredAppointment"
  (mouseleave)="hideHoverPanel()"
  (mouseenter)="stopHoverHide()"
></app-hover-panel>
