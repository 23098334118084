export const PatientData = {
  title: 'Patient Data Fields',
  weight: 0,
  default: true,
  components: {
    firstName: {
      title: 'First Name',
      key: 'firstName',
      schema: {
        label: 'First Name',
        type: 'textfield',
        key: 'firstName',
        input: true
      },
    },
    lastName: {
      title: 'Last Name',
      key: 'lastName',
      schema: {
        label: 'Last Name',
        type: 'textfield',
        key: 'lastName',
        input: true
      }
    },
    name: {
      title: 'Full Name',
      key: 'name',
      schema: {
        label: 'Full Patient Name',
        type: 'textfield',
        key: 'name',
        input: true
      }
    },
    gender: {
      title: 'Gender',
      key: 'genderString',
      schema: {
        label: 'Gender String',
        type: 'textfield',
        key: 'genderString',
        input: true
      }
    },
    genderMale: {
      title: 'Gender Male Checkbox',
      key: 'genderMale',
      schema: {
        label: 'Gender Male Checkbox',
        type: 'checkbox',
        key: 'genderMale',
        input: true
      }
    },
    genderFemale: {
      title: 'Gender Female Checkbox',
      key: 'genderFemale',
      schema: {
        label: 'Gender Female Checkbox',
        type: 'checkbox',
        key: 'genderFemale',
        input: true
      }
    },
    birthYear: {
      title: 'Birth Year',
      key: 'birthYear',
      schema: {
        label: 'Birth Year',
        type: 'datetime',
        datepickerMode: 'year',
        format: 'yyyy',
        enableTime: false,
        key: 'birthYear',
        input: true
      }
    },
    birthMonth: {
      title: 'Birth Month',
      key: 'birthMonth',
      schema: {
        label: 'Birth Month',
        type: 'datetime',
        datepickerMode: 'month',
        format: 'MM',
        enableTime: false,
        key: 'birthMonth',
        input: true
      }
    },
    birthDay: {
      title: 'Birth Day',
      key: 'birthDay',
      schema: {
        label: 'Birth Month',
        type: 'datetime',
        datepickerMode: 'day',
        format: 'dd',
        enableTime: false,
        key: 'birthDay',
        input: true
      }
    },
    email: {
      title: 'Email',
      key: 'patientEmail',
      schema: {
        label: 'Email',
        type: 'textfield',
        key: 'patientEmail',
        input: true
      }
    },
    phoneNumberMobile: {
      title: 'Mobile Phone',
      key: 'mobilePhone',
      schema: {
        label: 'Mobile Phone',
        type: 'textfield',
        key: 'mobilePhone',
        input: true
      }
    },
    phoneNumberHome: {
      title: 'Home Phone',
      key: 'homePhone',
      schema: {
        label: 'Home Phone',
        type: 'textfield',
        key: 'homePhone',
        input: true
      }
    },
    address1: {
      title: 'Address 1',
      key: 'address1',
      schema: {
        label: 'Address 1',
        type: 'textfield',
        key: 'address1',
        input: true
      }
    },
    address2: {
      title: 'Address 2',
      key: 'address2',
      schema: {
        label: 'Address 2',
        type: 'textfield',
        key: 'address2',
        input: true
      }
    },
    city: {
      title: 'City',
      key: 'city',
      schema: {
        label: 'City',
        type: 'textfield',
        key: 'city',
        input: true
      }
    },
    country: {
      title: 'Country',
      key: 'country',
      schema: {
        label: 'Country',
        type: 'textfield',
        key: 'country',
        input: true
      }
    },
    postalCode: {
      title: 'Postal Code',
      key: 'postalCode',
      schema: {
        label: 'Postal Code',
        type: 'textfield',
        key: 'postalCode',
        input: true
      }
    },
    province: {
      title: 'Province',
      key: 'province',
      schema: {
        label: 'Province',
        type: 'textfield',
        key: 'province',
        input: true
      }
    },
    healthCareNumber: {
      title: 'Health Care Number',
      key: 'healthCareNumber',
      schema: {
        label: 'Health Care Number',
        type: 'textfield',
        key: 'healthCareNumber',
        input: true
      }
    },
  }
};
