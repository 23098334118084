<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>

<div [ngStyle]="{ height: 'calc(100dvh - 75px)' }">
  <kendo-grid
    [data]="gridView"
    [pageSize]="gridState.take"
    [skip]="gridState.skip"
    [sort]="gridState.sort"
    [sortable]="true"
    [selectable]="true"
    [style.height.%]="100"
    (add)="onAddClick()"
    (cancel)="cancelHandler($event)"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <div class="d-flex">
        <span class="pl-4 search-box border border-radius">
          <span class="fa fa-search"></span>
          <mat-form-field class="w-75 p-3">
            <input
              matInput
              placeholder="Search..."
              aria-label="Search Documents"
              [(ngModel)]="searchValue"
              [formControl]="searchCtrl"
            />
            <button
              mat-button
              *ngIf="searchValue"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="searchValue = ''"
            >
              <mat-icon><i class="fa fa-times-circle"></i></mat-icon>
            </button>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let clinicDoc of filteredClinicDocuments | async" [value]="clinicDoc.name">
                <span>{{ clinicDoc.name }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </span>
      </div>
      <button kendoGridAddCommand class="btn kendo-btn kendo-btn-green em-btn" icon="folder-open">Add Document</button>
      <button
        class="btn kendo-btn kendo-btn-green em-btn k-button-icontext k-button"
        type="button"
        (click)="onEformAddClick()"
      >
        <i class="far fa-plus-circle"></i> Create eForm
      </button>
      <!--      <button class="btn kendo-btn kendo-btn-green em-btn mt-2" type="button" kendoGridExcelCommand icon="file-excel">
        Export to Excel
      </button> -->
    </ng-template>
    <kendo-grid-excel fileName="emily_clinic_documents.xlsx"></kendo-grid-excel>
    <kendo-grid-column field="name" title="Name" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="notes" title="Notes" [width]="150"></kendo-grid-column>

    <kendo-grid-command-column title="Actions" min-width="125px" [width]="125">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <button kendoGridEditCommand class="btn kendo-btn"><i class="fas fa-edit"></i></button>
        <button kendoGridRemoveCommand class="btn kendo-btn"><i class="fas fa-minus-circle"></i></button>
        <button class="btn kendo-btn" *ngIf="dataItem.filePath">
          <a class="fal fa-download" [href]="dataItem.filePath" target="_blank"></a>
        </button>
        <!-- <button class="btn kendo-btn" (click)="viewForm(dataItem.id)"><i class="fal fa-eye"></i></button> -->
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>

<app-actionpanel></app-actionpanel>
