<ngx-loading [show]="loading"></ngx-loading>

<div class="row" *ngIf="photosLoaded" kendoTooltip tooltipClass="em-tooltip" position="top">
  <div
    class="col-6 text-center"
    *ngFor="let treatmentPhoto of photos; let i = index"
    [ngClass]="{ 'pl-0': i === 0, 'pr-0': i === photos.length - 1 }"
  >
    <div class="panel-item-border w-100 d-inline-block position-relative">
      <ng-container *ngIf="editingIndex === i; else readonly">
        <span class="right-button position-absolute z-index-3">
          <button class="btn btn-sm em-btn em-btn-green mr-2" (click)="onRefresh(i)">
            <i class="fas fa-sync-alt" title="Reset"></i>
          </button>
          <button class="btn btn-sm em-btn em-btn-green mr-2" (click)="onSetPhoto(i)">
            <i class="far fa-camera" title="Add Photo"></i>
          </button>
        </span>
        <ng-container *ngIf="!loading">
          <!-- <image-drawing
            #drawTool
            [src]="treatmentPhoto.photo?.filePath"
            saveBtnText="Save"
            cancelBtnText="Cancel"
            [outputMimeType]="treatmentPhoto.photo?.imageType"
            outputQuality="1"
            (save)="onSave($event, i)"
            (cancel)="onCancel()"
          >
            >
          </image-drawing> -->
        </ng-container>
      </ng-container>
      <ng-template #readonly>
        <button class="btn btn-sm em-btn em-btn-green position-absolute right-button mr-2" (click)="onEdit(i)">
          <i class="far fa-pen-alt"></i>
        </button>
        <img
          *ngIf="!loading"
          #image
          [src]="treatmentPhoto.photo?.filePath"
          [alt]="treatmentPhoto.photo?.imageName"
          class="treatment-photo mw-100"
        />
      </ng-template>
    </div>
  </div>
</div>
