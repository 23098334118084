import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Patient } from '@models/patient';
import {
  ConnectionState,
  Conversation,
  Client as ConversationsClient,
  Message,
  SendMediaOptions,
} from '@twilio/conversations';
import { countryTuples } from 'country-region-data';
import { BehaviorSubject, Subject } from 'rxjs';
import { ClinicsService } from './clinics.service';
import { UsersService } from './users.service';
import { PatientService } from './patient.service';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js';

@Injectable({
  providedIn: 'root',
})
export class TwilioConversationsService {
  initialized = false;
  private clinicId: number;
  private client: ConversationsClient;

  private conversations = new BehaviorSubject<Conversation[]>([]);
  conversations$ = this.conversations.asObservable();

  private selectedConversationAndPatient = new BehaviorSubject<[Conversation, Patient]>([null, null]);
  selectedConversationAndPatient$ = this.selectedConversationAndPatient.asObservable();

  private conversationUpdated = new Subject<Conversation>();
  conversationUpdated$ = this.conversationUpdated.asObservable();

  private messageAdded = new Subject<Message>();
  messageAdded$ = this.messageAdded.asObservable();

  private totalUnreadCount = new BehaviorSubject<number>(0);
  totalUnreadCount$ = this.totalUnreadCount.asObservable();

  private connectionState = new BehaviorSubject<ConnectionState>(null);
  connectionState$ = this.connectionState.asObservable();

  constructor(
    private http: HttpClient,
    private clinicsService: ClinicsService,
    private usersService: UsersService,
    private patientService: PatientService
  ) {
    this.clinicsService.clinicIdSelected$.subscribe(async (clinicId) => {
      if (clinicId && this.clinicId != clinicId) {
        this.clinicId = clinicId;
        await this.initConversationsClient();
      }
    });
  }

  private getAccessToken() {
    return this.http.get(environment.baseUrl + 'api/Twilio', { responseType: 'text' });
  }

  sendOptInMessage(patient: Patient) {
    return this.http.post(environment.baseUrl + 'api/Twilio/SendOptInSms/' + patient.patientId, {});
  }

  private async initConversationsClient() {
    console.log('Patient Messaging - Initializing...');
    this.initialized = false;
    const token = await this.getAccessToken().toPromise();
    this.client = new ConversationsClient(token);
    this.listenToEvents();
  }

  private listenToEvents() {
    this.client.removeAllListeners();

    this.client.on('connectionStateChanged', (state) => {
      console.log(`Patient Messaging State: ${state}`);
      this.connectionState.next(state);
    });

    this.client.on('initFailed', (error: any) => {
      console.log('Patient Messaging - Initializing Failed.');
    });

    this.client.on('connectionError', (error: any) => {
      console.log('Patient Messaging - Connection Error.');
    });

    this.client.on('tokenAboutToExpire', async () => {
      const token = await this.getAccessToken().toPromise();
      this.client = await this.client.updateToken(token);
    });

    this.client.on('tokenExpired', () => {
      console.log('Patient Messaging - Token Expired, Reinitializing.');
      this.client.removeAllListeners();
      this.initConversationsClient();
    });

    this.client.on('initialized', async () => {
      await this.getClinicConversations();
      this.initialized = true;
      console.log('Patient Messaging - Initialized.');
    });

    this.client.on('conversationLeft', (conversation) => {
      this.conversations.next(this.conversations.value.filter((con) => con.sid != conversation.sid));
    });

    this.client.on('conversationJoined', async (conversation) => {
      if (this.initialized) {
        await conversation.setAllMessagesUnread();
        const conversations = this.sortConversations([conversation, ...this.conversations.value]);
        this.conversations.next(conversations);
      }
    });

    this.client.on('conversationUpdated', async (conversationUpdate) => {
      const updateConversation = conversationUpdate.conversation;
      const conversations = this.conversations.value;
      const [selectedConversation, _] = this.selectedConversationAndPatient.value;

      if (selectedConversation && selectedConversation.sid === updateConversation.sid) {
        await selectedConversation.setAllMessagesRead().catch((error) => {
          throw error;
        });
      }

      const conIndex = conversations.findIndex((con) => con.sid == updateConversation.sid);
      if (conIndex > -1) {
        conversations[conIndex] = conversationUpdate.conversation;
        this.conversations.next([...conversations]);
        await this.setTotalUnreadCount();
      }

      this.conversationUpdated.next(updateConversation);
    });

    this.client.on('messageAdded', async (message: Message) => {
      const messageConversation = message.conversation;
      const allConversations = this.conversations.value;
      const activeConversations = allConversations.filter((con) => !this.getConversationArchiveStatus(con));
      const [selectedConversation, _] = this.selectedConversationAndPatient.value;

      if (selectedConversation && selectedConversation.sid === messageConversation.sid) {
        await selectedConversation.setAllMessagesRead().catch((error) => {
          throw error;
        });
      }

      const inLoadedConversations = activeConversations.some((con) => con.sid == messageConversation.sid);
      if (inLoadedConversations) {
        const conversations = this.sortConversations(allConversations);
        this.conversations.next(conversations);
        await this.setTotalUnreadCount();
      }

      this.messageAdded.next(message);
    });
  }

  setSelectedConversationAndPatient(conversation: Conversation, patient: Patient) {
    this.selectedConversationAndPatient.next([conversation, patient]);
  }

  async getPatientConversation(patient: Patient) {
    try {
      const existingConversation = await this.findExistingConversation(patient.patientId);
      if (existingConversation) {
        const participants = await existingConversation.getParticipants();
        const clientParticipantExists = participants.some((p) => p.identity === this.client.user.identity);
        const smsParticipantExists = participants.some((p) => p.identity == null);
        if (!clientParticipantExists) {
          existingConversation.join();
        }
        if (!smsParticipantExists) {
          await this.addPatientToConversation(existingConversation, patient);
        }
        const archived = this.getConversationArchiveStatus(existingConversation);
        if (archived) {
          await this.unarchiveConversation(existingConversation, patient);
        }
        return existingConversation;
      }
      const newConversation = await this.createPatientConversation(patient);
      return newConversation;
    } catch (error) {
      // Invalid message binding address (mobile number)
      if (error.body?.code === 50407) {
        throw new Error('This patient does not have a valid mobile number set.');
      }
      // Participant and proxy address pair is already in use
      if (error.body?.code === 50416) {
        throw new Error('A conversation already exists for a patient with the same mobile number.');
      }
      throw error;
    }
  }

  async sendMessage(conversation: Conversation, message: string, image?: File): Promise<void> {
    if (conversation) {
      const user = this.usersService.loggedInUser;
      const attributes = {
        user: {
          id: user.id,
          fullName: user.fullName,
          firstName: user.firstName,
          lastName: user.lastName,
          avatar: user.avatar.split('?')[0],
        },
      };

      const messageBuilder = conversation.prepareMessage().setBody(message).setAttributes(attributes);
      if (image) {
        const sendMediaOptions: SendMediaOptions = {
          contentType: image.type,
          filename: image.name,
          media: image,
        };
        messageBuilder.addMedia(sendMediaOptions);
      }

      await messageBuilder.build().send();
    }
  }

  async setMessageReadBy(message: Message): Promise<void> {
    if (message) {
      const participant = await message.getParticipant().catch(() => {
        return null;
      });
      const attributes = message.attributes as any;
      if (participant && participant.type !== 'chat' && !attributes.readByUser) {
        const user = this.usersService.loggedInUser;
        attributes.readByUser = {
          id: user.id,
          fullName: user.fullName,
          firstName: user.firstName,
          lastName: user.lastName,
          avatar: user.avatar.split('?')[0],
        };
        attributes.readByDate = new Date();
        message = await message.updateAttributes(attributes).catch((error) => {
          throw error;
        });
      }
    }
  }

  getClientUserIdentity() {
    return this.client.user.identity;
  }

  parsePatientId(conversation: Conversation): number {
    const patientPart = conversation.uniqueName?.split('-')[1];
    const patientId = patientPart?.split('_')[1];
    return Number(patientId);
  }

  getConversationArchiveStatus(conversation: Conversation): boolean {
    return (conversation.attributes as any).archived ?? false;
  }

  async archiveConversation(conversation: Conversation) {
    const attributes = conversation.attributes as any;
    attributes.archived = true;
    await conversation.updateAttributes(attributes);
  }

  async unarchiveConversation(conversation: Conversation, patient: Patient) {
    const attributes = conversation.attributes as any;
    attributes.archived = false;
    await conversation.updateAttributes(attributes);
  }

  private async createPatientConversation(patient: Patient) {
    if (!patient) throw new Error('No patient provided for conversation.');

    const patientName = patient.firstName + ' ' + (patient.nickName ? `"${patient.nickName}" ` : '') + patient.lastName;
    let newConversation: Conversation;
    try {
      newConversation = await this.client.createConversation({
        friendlyName: patientName,
        uniqueName: this.generateUniqueName(patient.patientId),
      });
      await newConversation.join();
      await this.addPatientToConversation(newConversation, patient);
      if (patient.sendTwoWayMessages) {
        patient.sendTwoWayMessages = false;
        await this.patientService.updatePatient(patient).toPromise();
      }
      return newConversation;
    } catch (error) {
      if (newConversation) {
        try {
          await newConversation.delete();
        } catch (error) {
          throw error;
        }
      }
      throw error;
    }
  }

  private async addPatientToConversation(conversation: Conversation, patient: Patient) {
    if (!patient.mobileNumber) throw new Error('Patient does not have a mobile number set.');
    if (!patient.address?.country) throw new Error('Patient does not have a country set.');
    if (!this.clinicsService.clinic?.twilioFromNumber) throw new Error('Clinic does not have a from number set.');
    const clinicNumber = this.clinicsService.clinic?.twilioFromNumber;
    const countryCode = countryTuples.find(
      (value) => value[0].toLowerCase() === patient.address.country.toLowerCase()
    )[1];
    const formattedNumber = parsePhoneNumber(patient.mobileNumber, countryCode as CountryCode);
    await conversation.addNonChatParticipant(clinicNumber, formattedNumber.number);
  }

  public async setTotalUnreadCount() {
    let total = 0;
    const activeConversations = this.conversations.value.filter((c) => !this.getConversationArchiveStatus(c));
    for (const conversation of activeConversations) {
      const count = await conversation.getUnreadMessagesCount();
      total += count ?? 0;
    }
    this.totalUnreadCount.next(total);
  }

  async findExistingConversation(patientId: number) {
    if (this.client.connectionState !== 'connected') throw new Error('Not connected to Twilio');
    const uniqueName = this.generateUniqueName(patientId);
    try {
      return await this.client.getConversationByUniqueName(uniqueName);
    } catch (error) {
      // Conversation not found
      if (error.body?.code === 50350) {
        return null;
      } else {
        throw error;
      }
    }
  }

  private async getClinicConversations() {
    let conversationsPage = await this.client.getSubscribedConversations().catch((error) => {
      throw error;
    });
    let conversations = conversationsPage.items;
    while (conversationsPage.hasNextPage) {
      conversationsPage = await conversationsPage.nextPage();
      conversations.push(...conversationsPage.items);
    }
    conversations = this.sortConversations(conversations);
    this.conversations.next(conversations);
    await this.setTotalUnreadCount();
  }

  private sortConversations(conversations: Conversation[]): Conversation[] {
    return conversations.sort((a, b) => {
      let aComp = a.lastMessage ? a.lastMessage.dateCreated.getTime() : a.dateCreated.getTime();
      let bComp = b.lastMessage ? b.lastMessage.dateCreated.getTime() : b.dateCreated.getTime();
      return bComp - aComp;
    });
  }

  private generateUniqueName(patientId: number) {
    return `${this.client.user.identity}-patient_${patientId}`;
  }
}
