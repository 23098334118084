<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>

<div class="d-flex align-items-end container-fluid mb-2">
  <label class="switch mb-1">
    <input type="checkbox" [(ngModel)]="active" (change)="settingsChange()" />
    <span class="slider round"></span>
  </label>
  <span class="pl-2">Retention Campaigns {{ globalStatus }}</span>
  <button type="button" class="btn medium em-btn em-btn-green ml-auto" (click)="openRetentionPanel()">
    CREATE CAMPAIGN
  </button>
</div>

<!-- <div class="col mt-0">
      <mat-form-field>
        <input matInput placeholder="Sender Name" [(ngModel)]="emailSenderName" (change)="settingsChange()">
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field>
        <input matInput placeholder="Sender Email Address" [(ngModel)]="emailSenderEmail" (change)="settingsChange()">
      </mat-form-field>
    </div> -->

<div class="row mx-0 px-3 flex-grow-1 minh-0">
  <div class="col px-0 h-100">
    <kendo-grid
      class="emr-kendo-grid communications hide-default-sort large-header"
      [data]="displayData"
      [style.height.%]="100"
      [pageSize]="gridState.take"
      [pageable]="true"
      [skip]="gridState.skip"
      [sort]="gridState.sort"
      [sortable]="{
        allowUnsort: false,
        mode: 'single'
      }"
      filterable="menu"
      [filter]="state.filter"
      (pageChange)="pageChange($event)"
      (sortChange)="sortChange($event)"
      (dataStateChange)="dataStateChange($event)"
      (edit)="openRetentionPanel($event)"
      (remove)="removeCampaign($event?.dataItem)"
      #grid="kendoGrid"
    >
      <kendo-grid-column
        [width]="125"
        title="Actions"
        [style]="{ 'padding-left': '5px', 'padding-right': '5px', 'text-align': 'center' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          <div>
            <button kendoGridEditCommand class="btn kendo-btn-edit p-0 navy-font mr-2">
              <i class="fas fa-pencil" kendoTooltip tooltipClass="em-tooltip" position="top" title="Edit Campaign"></i>
            </button>
            <!--       <button class="btn kendo-btn" (click)="onPreview(dataItem)">
                  <i
                    class="far fa-user-check"
                    kendoTooltip
                    tooltipClass="em-tooltip"
                    position="top"
                    title="Preview Recipients"
                  ></i>
                </button> -->
            <button
              class="btn kendo-btn-edit pl-0"
              kendoTooltip
              tooltipClass="em-tooltip"
              position="top"
              [title]="'Copy Campaign'"
            >
              <i class="fas fa-copy" (click)="cloneCampaign(dataItem)"></i>
            </button>

            <button
              [attr.disabled]="dataItem.completed ? 'disabled' : null"
              kendoGridRemoveCommand
              class="btn kendo-btn p-0"
              kendoTooltip
              tooltipClass="em-tooltip"
              position="top"
              [title]="'Delete Campaign'"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="title" title="Title" [filterable]="false" [width]="250">
        <ng-template kendoGridHeaderTemplate let-column>
          {{ column.title }}
          <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          <strong>{{ dataItem.title }}</strong>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="campaignType" title="Campaign Type" [filterable]="true">
        <ng-template kendoGridHeaderTemplate let-column>
          {{ column.title }}
          <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          {{ dataItem.campaignType == 'EmailSms' ? 'Email/SMS' : dataItem.campaignType }}
        </ng-template>
        <ng-template kendoGridFilterCellTemplate> </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-column="column"
          let-filter="filter"
          let-filterService="filterService"
        >
          <app-multicheck-filter
            [isPrimitive]="false"
            [field]="column.field"
            [currentFilter]="filter"
            [filterService]="filterService"
            [data]="campaignTypes"
            textField="title"
            valueField="title"
          >
          </app-multicheck-filter>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="status" title="Status">
        <ng-template kendoGridHeaderTemplate let-column>
          {{ column.title }}
          <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          {{ !dataItem.completed ? dataItem.status : 'Completed' }}
        </ng-template>
        <ng-template kendoGridFilterCellTemplate> </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-column="column"
          let-filter="filter"
          let-filterService="filterService"
        >
          <app-multicheck-filter
            [isPrimitive]="false"
            [field]="column.field"
            [currentFilter]="filter"
            [filterService]="filterService"
            [data]="runStatuses"
            textField="title"
            valueField="title"
          >
          </app-multicheck-filter>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="startDate" title="Start Date" [filterable]="false">
        <ng-template kendoGridHeaderTemplate let-column>
          {{ column.title }}
          <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          {{ dataItem.startDate | date : 'yyyy-MM-dd HH:mm' }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="endDate" title="End Date" [filterable]="false">
        <ng-template kendoGridHeaderTemplate let-column>
          {{ column.title }}
          <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          {{
            dataItem.endDate
              ? (dataItem.endDate | date : 'yyyy-MM-dd HH:mm')
              : dataItem.isRecurring
              ? 'No End Date'
              : 'One Time Only'
          }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="lastSentDate" title="Last Sent" [filterable]="false">
        <ng-template kendoGridHeaderTemplate let-column>
          {{ column.title }}
          <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          {{ dataItem.lastSentDate ? (dataItem.lastSentDate | date : 'yyyy-MM-dd HH:mm') : 'Not Sent' }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="previousRecipients" title="Previous Recipients" [filterable]="false">
        <ng-template kendoGridHeaderTemplate let-column>
          {{ column.title }}
          <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>
            <span
              [ngClass]="{ 'pointer underline': haveRecipients(dataItem.previousRecipients) }"
              (click)="openCampaignHistoryModal(dataItem)"
            >
              {{ dataItem.previousRecipients }}
            </span>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="nextSendDate" title="Next Send" [filterable]="false">
        <ng-template kendoGridHeaderTemplate let-column>
          {{ column.title }}
          <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          <div class="d-inline pr-1">
            {{ dataItem.nextSendDate | date : 'yyyy-MM-dd HH:mm' }}
          </div>
          <i *ngIf="dataItem.isProcessing" class="fas fa-cog fa-spin fa-lg"></i>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [width]="164" field="nextRecipients" title="Next Recipients" [filterable]="false">
        <ng-template kendoGridHeaderTemplate let-column>
          {{ column.title }}
          <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          <div class="center">
            <button
              class="btn kendo-btn"
              [hidden]="hasNextSendDate(dataItem) || dataItem.status == 'Suspended'"
              (click)="openCampaignRecipientsModal(dataItem)"
            >
              <i
                class="far fa-user-check"
                kendoTooltip
                tooltipClass="em-tooltip"
                position="top"
                title="Preview Next Recipients"
              ></i>
            </button>
          </div>
        </ng-template>
      </kendo-grid-column>

      <!-- Uncomment the block below to move the clone to a separate action column if preferred -->
      <!--
          <kendo-grid-column title="Clone" [sortable]="false" [filterable]="false">
            <ng-template kendoGridHeaderTemplate let-column>
              Duplicate Campaign
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" >
              <div class="w-100 d-flex align-items-center justify-content-center">
                <button class="btn medium em-btn em-btn-green"><i class="fal fa-copy" (click)="cloneCampaign(dataItem)"></i></button>
              </div>
            </ng-template>
          </kendo-grid-column> -->
    </kendo-grid>
  </div>
</div>

<p-overlayPanel #hoverPanel id="hoverPanel" [dismissable]="false"></p-overlayPanel>
<app-actionpanel></app-actionpanel>

<ng-template #campaignRecipientsModal let-dialogRef="dialogRef">
  <app-panel [dialogRef]="dialogRef" class="campaign-recipients-modal" [isHuge]="true">
    <app-campaign-recipients
      [campaignId]="campaignId"
      [title]="selectedTitle"
      [recipientRequestType]="recipientRequestType"
    ></app-campaign-recipients>
  </app-panel>
</ng-template>
