import { PlatformModule } from '@angular/cdk/platform';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '@app/auth/auth.service';
import { FormioModule } from '@formio/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { InputsModule, SwitchModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import * as Sentry from '@sentry/angular-ivy';
import { AllergiesService } from '@services/allergies/allergies.service';
import { BookingServiceCategoryService } from '@services/booking-categories.service';
import { HybridStorageStrategy } from '@services/cache/hybrid-cache-strategy';
import { ClinicEmailTemplateService } from '@services/clinic-email-template.service';
import { CommunicationsService } from '@services/communications.service';
import { CryptoHelperService } from '@services/crypto/crypto-helper';
import { MspCardSwipeService } from '@services/msp-card-swipe.service';
import { PhotoSignalrService } from '@services/photo-signalr.service';
import { SignalrConnectionService } from '@services/signalr-connections.service';
import 'fullcalendar';
import 'fullcalendar-scheduler';
import 'hammer-timejs';
import 'hammerjs';
import 'jquery';
import 'moment';
import { InlineSVGModule } from 'ng-inline-svg';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { LoadingModule } from 'ngx-loading';
import { NgxMaskModule } from 'ngx-mask';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { NG_SCROLLBAR_OPTIONS, NgScrollbarModule, NgScrollbarOptions } from 'ngx-scrollbar';
import { NgScrollbarReachedModule } from 'ngx-scrollbar/reached-event';
import { ColorPickerModule } from 'primeng/colorpicker';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AppComponent } from './app.component';
import { Routing } from './app.routing';
import { AuthModule } from './auth/auth.module';
import { CalnavComponent } from './calendarnav/calnav.component';
import { CalnavnoheaderComponent } from './calendarnav/calnavnoheader.component';
import { ConvergeCardComponent } from './components/payments/converge-card/converge-card.component';
import { ConvergePaymentComponent } from './components/payments/converge-payment/converge-payment.component';
import { CurrentDateComponent } from './current-date/current-date.component';
import { EmilyComponent } from './emily/emily.component';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { EditDoctorComponent } from './management/address-book/actionpanel/edit-doctor/edit-doctor.component';
import { EditLabComponent } from './management/address-book/actionpanel/edit-lab/edit-lab.component';
import { EditPharmacyComponent } from './management/address-book/actionpanel/edit-pharmacy/edit-pharmacy.component';
import { QuickAddLabComponent } from './management/address-book/actionpanel/quick-add-lab/quick-add-lab.component';
import { QuickAddPharmacyComponent } from './management/address-book/actionpanel/quick-add-pharmacy/quick-add-pharmacy.component';
import { QuickAddPhysicianComponent } from './management/address-book/actionpanel/quick-add-physician/quick-add-physician.component';
import { AddressBookDoctorsComponent } from './management/address-book/address-book-doctors/address-book-doctors.component';
import { AddressBookLabsComponent } from './management/address-book/address-book-labs/address-book-labs.component';
import { AddressBookPharmaciesComponent } from './management/address-book/address-book-pharmacies/address-book-pharmacies.component';
import { AddressBookComponent } from './management/address-book/address-book.component';
import { SiteBillingModule } from './management/billing/site-billing.module';
import { EditEtreatmentFormComponent } from './management/catalogue/actionpanel/edit-etreatment-form/edit-etreatment-form.component';
import { AssignItemsToMembershipComponent } from './management/catalogue/actionpanel/edit-membership/dialogs/assign-items-to-membership/assign-items-to-membership.component';
import { EditMembershipComponent } from './management/catalogue/actionpanel/edit-membership/edit-membership.component';
import { EditPackageComponent } from './management/catalogue/actionpanel/edit-package/edit-package.component';
import { EditProductComponent } from './management/catalogue/actionpanel/edit-product/edit-product.component';
import { EditServiceComponent } from './management/catalogue/actionpanel/edit-service/edit-service.component';
import { EditSpecialComponent } from './management/catalogue/actionpanel/edit-special/edit-special.component';
import { EditTagComponent } from './management/catalogue/actionpanel/edit-tag/edit-tag.component';
import { EditTextTemplateComponent } from './management/catalogue/actionpanel/edit-text-template/edit-text-template.component';
import { ClinicSuppliesModule } from './management/catalogue/catalogue-clinic-supplies/clinic-supplies.module';
import { CatalogueDocumentTypesModule } from './management/catalogue/catalogue-document-types/catalogue-document-types.module';
import { CatalogueMembershipsComponent } from './management/catalogue/catalogue-memberships/catalogue-memberships.component';
import { CataloguePackagesComponent } from './management/catalogue/catalogue-packages/catalogue-packages.component';
import { CatalogueProductsComponent } from './management/catalogue/catalogue-products/catalogue-products.component';
import { CatalogueServicesComponent } from './management/catalogue/catalogue-services/catalogue-services.component';
import { CatalogueSpecialsComponent } from './management/catalogue/catalogue-specials/catalogue-specials.component';
import { CatalogueTagsComponent } from './management/catalogue/catalogue-tags/catalogue-tags.component';
import { CatalogueTextTemplatesComponent } from './management/catalogue/catalogue-text-templates/catalogue-text-templates.component';
import { CatalogueComponent } from './management/catalogue/catalogue.component';
import { ClinicDocumentsModule } from './management/clinic-documents/clinic-documents.module';
import { CommunicationSummaryComponent } from './management/communication-summary/communication-summary.component';
import { CommunicationWrapperComponent } from './management/communication-wrapper/communication-wrapper.component';
import { EditEmailVisitReminderComponent } from './management/communications/actionpanel/edit-email-visit-reminder/edit-email-visit-reminder.component';
import { EditVisitReminderSettingsComponent } from './management/communications/actionpanel/edit-visit-reminder-settings/edit-visit-reminder-settings.component';
import { SendTestVisitReminderComponent } from './management/communications/actionpanel/send-test-visit-reminder/send-test-visit-reminder.component';
import { CommunicationsComponent } from './management/communications/communications.component';
import { AddProductComponent } from './management/dialogs/add-product/add-product.component';
import { ApptListDialogComponent } from './management/dialogs/appt-list-dialog/appt-list-dialog.component';
import { ClinicSuppliesSelectorModalComponent } from './management/dialogs/clinic-supplies-selector-modal/clinic-supplies-selector-modal.component';
import { CommissionEditComponent } from './management/dialogs/commission-edit/commission-edit.component';
import { ConfirmCancelWithReasonDialogComponent } from './management/dialogs/confirm-cancel-with-reason/confirm-cancel-with-reason.component';
import { ConfirmChangeUserStatusComponent } from './management/dialogs/confirm-change-user-status/confirm-change-user-status.component';
import { ConfirmDeleteDialogComponent } from './management/dialogs/confirm-delete/confirm-delete.component';
import { ConfirmMergePatientComponent } from './management/dialogs/confirm-patient-merge/confirm-patient-merge.component';
import { ConfirmUnsavedChangesDialogComponent } from './management/dialogs/confirm-unsaved-changes/confirm-unsaved-changes.component';
import { ConfirmUpdateMethodDialogComponent } from './management/dialogs/confirm-update-method/confirm-update-method.component';
import { DateSelectDialogComponent } from './management/dialogs/date-select-dialog/date-select-dialog.component';
import { GenericDialogComponent } from './management/dialogs/generic-confirm/generic-confirm.component';
import { GenericInputComponent } from './management/dialogs/generic-input/generic-input.component';
import { GroupPricingObservationsDialogComponent } from './management/dialogs/group-pricing-observations/group-pricing-observations.component';
import { HoursOfOperationDialogComponent } from './management/dialogs/hours-of-operation/hours-of-operation.component';
import { CategoryItemComponent } from './management/dialogs/manage-categories/category-item/component';
import { ManageCategoriesDialogComponent } from './management/dialogs/manage-categories/manage-categories.component';
import { MoveAppointmentDialogComponent } from './management/dialogs/move-appointment/move-appointment.component';
import { SendTestEmailComponent } from './management/dialogs/send-test-email/send-test-email.component';
import { ServiceProviderSelectorModalComponent } from './management/dialogs/service-provider-selector-modal/service-provider-selector-modal.component';
import { EditEmailTemplatesComponent } from './management/email-templates/actionpanel/edit-email-templates/edit-email-templates.component';
import { EmailTemplatesComponent } from './management/email-templates/email-templates.component';
import { ImportJSONComponent } from './management/email-templates/import-json/import-json.component';
import { FormsModule } from './management/forms/forms.module';
import { GiftCardsOverviewComponent } from './management/gift-cards/gift-cards-overview/gift-cards-overview.component';
import { GiftCardsSettingsComponent } from './management/gift-cards/gift-cards-settings/gift-cards-settings.component';
import { GiftCardsComponent } from './management/gift-cards/gift-cards.component';
import { ManagementComponent } from './management/management.component';
import { EditCancellationReasonComponent } from './management/organization/actionpanel/edit-cancellation-reason/edit-cancellation-reason.component';
import { EditClinicComponent } from './management/organization/actionpanel/edit-clinic/edit-clinic.component';
import { EditMerchantDeviceComponent } from './management/organization/actionpanel/edit-merchant-device/edit-merchant-device.component';
import { EditOnlineBookingCategoryComponent } from './management/organization/actionpanel/edit-online-booking-category/edit-online-booking-category.component';
import { EditOnlineBookingProviderComponent } from './management/organization/actionpanel/edit-online-booking-provider/edit-online-booking-provider.component';
import { EditOnlineBookingServiceComponent } from './management/organization/actionpanel/edit-online-booking-service/edit-online-booking-service.component';
import { EditPaymentMethodComponent } from './management/organization/actionpanel/edit-payment-method/edit-payment-method.component';
import { EditResourceComponent } from './management/organization/actionpanel/edit-resource/edit-resource.component';
import { EditUserCommissionsComponent } from './management/organization/actionpanel/edit-user/edit-user-commissions/edit-user-commissions.component';
import { EditUserComponent } from './management/organization/actionpanel/edit-user/edit-user.component';
import { AccessLocationModule } from './management/organization/org-access-locations/access-location.module';
import { OrgCancellationReasonComponent } from './management/organization/org-cancellation-reason/org-cancellation-reason.component';
import { OrgClinicGeneralComponent } from './management/organization/org-clinic-general/org-clinic-general.component';
import { ClinicLogoComponent } from './management/organization/org-clinics/clinic-logo/clinic-logo.component';
import { OrgClinicsComponent } from './management/organization/org-clinics/org-clinics.component';
import { EditCompanyComponent } from './management/organization/org-company/org-company.component';
import { OrgInvoiceTextEditorComponent } from './management/organization/org-invoice-text-editor/org-invoice-text-editor.component';
import { OrgMerchantDevicesComponent } from './management/organization/org-merchant-devices/org-merchant-devices.component';
import { OrgOnlineBookingComponent } from './management/organization/org-online-booking/org-online-booking.component';
import { OrgOnlineCategoriesSettingsComponent } from './management/organization/org-online-booking/org-online-categories-settings/org-online-categories-settings.component';
import { OrgOnlineGeneralSettingsComponent } from './management/organization/org-online-booking/org-online-general-settings/org-online-general-settings.component';
import { OrgOnlineProvidersSettingsComponent } from './management/organization/org-online-booking/org-online-providers-settings/org-online-providers-settings.component';
import { OrgOnlineServiceSettingsComponent } from './management/organization/org-online-booking/org-online-service-settings/org-online-service-settings.component';
import { OrgPaymentMethodsComponent } from './management/organization/org-payment-methods/org-payment-methods.component';
import { OrgPaymentOverviewComponent } from './management/organization/org-payment-overview/org-payment-overview.component';
import { OrgPhotosComponent } from './management/organization/org-photos/org-photos.component';
import { OrgResourcesComponent } from './management/organization/org-resources/org-resources.component';
import { OrgRolePermissionsComponent } from './management/organization/org-role-permissions/org-role-permissions.component';
import { EditTaxComponent } from './management/organization/org-taxes/edit-tax/edit-tax.component';
import { OrgTaxesComponent } from './management/organization/org-taxes/org-taxes.component';
import { OrgUserPermissionsComponent } from './management/organization/org-user-permissions/org-user-permissions.component';
import { AvatarUploaderComponent } from './management/organization/org-users/avatar-uploader/avatar-uploader.component';
import { OrgUsersComponent } from './management/organization/org-users/org-users.component';
import { OrganizationComponent } from './management/organization/organization.component';
import { CampaignRecipientsGridComponent } from './management/retention-campaigns/actionpanel//preview-campaign-recipients/campaign-recipients-grid/campaign-recipients-grid.component';
import { EditRetentionCampaignComponent } from './management/retention-campaigns/actionpanel/edit-retention-campaign/edit-retention-campaign.component';
import { PreviewCampaignRecipientsComponent } from './management/retention-campaigns/actionpanel/preview-campaign-recipients/preview-campaign-recipients.component';
import { CampaignRecipientsComponent } from './management/retention-campaigns/campaign-recipients/campaign-recipients.component';
import { RetentionCampaignsComponent } from './management/retention-campaigns/retention-campaigns.component';
import { MessagingModule } from './messaging/messaging.module';
import { MessagingSignalrService } from './messaging/services/messaging-signalr.service';
import { NudgesModule } from './nudges/nudges.module';
import { NudgesSignalrService } from './nudges/services/nudges-signalr.service';
import { EditPatientContentComponent } from './patients/actionpanel/edit-patient-content/edit-patient-content.component';
import { EditPatientComponent } from './patients/actionpanel/edit-patient/edit-patient.component';
import { MergePatientsComponent } from './patients/merge-patients/merge-patients.component';
import { PatientProfileDetailComponent } from './patients/patient-profile-detail/patient-profile-detail.component';
import { AllergiesModule } from './patients/patient-tabs/allergies/allergies.module';
import { PatientAccountTabComponent } from './patients/patient-tabs/patient-account-tab/patient-account-tab.component';
import { PatientAccountModule } from './patients/patient-tabs/patient-account-tab/patient-account.module';
import { ChartOverviewModule } from './patients/patient-tabs/patient-chart-tab/chart-overview/chart-overview.module';
import { SelectProviderDialogComponent } from './patients/patient-tabs/patient-chart-tab/chart-overview/modals/select-provider/select-provider.component';
import { PatientChartTabComponent } from './patients/patient-tabs/patient-chart-tab/patient-chart-tab.component';
import { PatientCommunicationTabComponent } from './patients/patient-tabs/patient-communication-tab/patient-communication-tab.component';
import { CreateNudgesComponent } from './patients/patient-tabs/patient-nudges-tab/create-nudges/create-nudges.component';
import { DocumentPreviewComponent } from './patients/patient-tabs/patient-nudges-tab/document-preview/document-preview.component';
import { PatientPhotosConsentedTabComponent } from './patients/patient-tabs/patient-photos-consented-tab/patient-photos-consented-tab.component';
import { PatientPhotosConsentedModule } from './patients/patient-tabs/patient-photos-consented-tab/patient-photos-consented.module';
import { PatientPhotosTabComponent } from './patients/patient-tabs/patient-photos-tab/patient-photos-tab.component';
import { PatientPhotosModule } from './patients/patient-tabs/patient-photos-tab/patient-photos.module';
import { PatientConvergeOverviewComponent } from './patients/patient-tabs/patient-profile-tab/patient-converge-overview/patient-converge-overview.component';
import { PasscodeComponent } from './patients/patient-tabs/patient-profile-tab/patient-portal-overview/passcode/passcode.component';
import { PatientPortalOverviewComponent } from './patients/patient-tabs/patient-profile-tab/patient-portal-overview/patient-portal-overview.component';
import { PatientProfileTabComponent } from './patients/patient-tabs/patient-profile-tab/patient-profile-tab.component';
import { PatientSquareOverviewComponent } from './patients/patient-tabs/patient-profile-tab/patient-square-overview/patient-square-overview.component';
import { PatientTabsComponent } from './patients/patient-tabs/patient-tabs.component';
import { CreateTreatmentPlanPackageComponent } from './patients/patient-tabs/patient-treatment-plan-tab/create-treatment-plan-package/create-treatment-plan-package.component';
import { PatientTreatmentPlanTabComponent } from './patients/patient-tabs/patient-treatment-plan-tab/patient-treatment-plan-tab.component';
import { ServiceDetailModule } from './patients/patient-tabs/patient-treatment-plan-tab/service-detail/service-detail.module';
import { PatientDocumentsModule } from './patients/patient-tabs/patients-documents-tab/patient-documents.module';
import { SharedPhotosModule } from './patients/patient-tabs/shared-photos/shared-photos.module';
import { PatientComponent } from './patients/patient.component';
import { PhotosModule } from './photos/photos.module';
import { ReportsModule } from './reports/reports.module';
import { PatientReviewComponent } from './reviews/patient-review/patient-review.component';
import { ReviewsSettingsComponent } from './reviews/reviews-settings/reviews-settings.component';
import { ReviewsModule } from './reviews/reviews.module';
import { CancelVisitDialogComponent } from './schedule/appointments/actionpanel/cancel-visit-dialog/cancel-visit-dialog.component';
import { CancelVisitComponent } from './schedule/appointments/actionpanel/cancel-visit/cancel-visit.component';
import { CreateShiftComponent } from './schedule/appointments/actionpanel/create-shift/create-shift.component';
import { VisitAppointmentsComponent } from './schedule/appointments/actionpanel/visits/visit-appointments/visit-appointments.component';
import { VisitEditAppointmentComponent } from './schedule/appointments/actionpanel/visits/visit-edit-appointment/visit-edit-appointment.component';
import { VisitPatientInfoComponent } from './schedule/appointments/actionpanel/visits/visit-patient-info/visit-patient-info.component';
import { VisitsTreatmentPlanComponent } from './schedule/appointments/actionpanel/visits/visits-treatment-plan/visits-treatment-plan.component';
import { VisitsComponent } from './schedule/appointments/actionpanel/visits/visits.component';
import { AppointmentComponent } from './schedule/appointments/appointment/appointment.component';
import { AppointmentsComponent } from './schedule/appointments/appointments.component';
import { RoomsComponent } from './schedule/rooms/rooms.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { TasksComponent } from './schedule/tasks/tasks.component';
import { ActionPanelService, MasterOverlayService } from './services/actionpanel.service';
import { AddressService } from './services/address.service';
import { AppointmentSignalrService } from './services/appointment-signalr.service';
import { AppointmentService } from './services/appointments.service';
import { CancellationReasonService } from './services/cancellation-reason.service';
import { CatalogueUpdatesService } from './services/catalogueupdates.service';
import { CategoryService } from './services/category.service';
import { ClinicProductsService } from './services/clinic-products.service';
import { ClinicsService } from './services/clinics.service';
import { CompanyService } from './services/company.service';
import { CurrentDataService } from './services/currentData.service';
import { DoctorsService } from './services/doctors.service';
import { EventsService } from './services/events.service';
import { FavouriteServicesService } from './services/favourite-services.service';
import { FormatterService } from './services/formatter.service';
import { GeographyService } from './services/geography.service';
import { HoursOfOperationService } from './services/hoursofoperation.service';
import { ImageService } from './services/image.service';
import { LabsService } from './services/labs.service';
import { NavStateService } from './services/navstate.service';
import { ObservationListItemsService } from './services/observation-list-items.service';
import { ObservationTypesService } from './services/observation-types.service';
import { ObservationUnitsService } from './services/observation-units.service';
import { ObservationService } from './services/observation.service';
import { PackagesService } from './services/packages.service';
import { PatientSignalrService } from './services/patient-signalr.service';
import { PatientService } from './services/patient.service';
import { PharmaciesService } from './services/pharmacies.service';
import { PhotoDrawingService } from './services/photo-drawing.service';
import { ProductDefaultsService } from './services/product-defaults.service';
import { ResourcesService } from './services/resources.service';
import { ReviewSettingsService } from './services/review-settings.service';
import { RoomService } from './services/room.service';
import { ServiceDefaultsService } from './services/service-defaults.service';
import { InjectionFormService } from './services/service-detail/injection-form.service';
import { ServiceTemplatesSignalrService } from './services/service-templates-signalr.service';
import { ServiceTemplatesService } from './services/service-templates.service';
import { ServicesSignalrService } from './services/services-signalr.service';
import { ServicesService } from './services/services.service';
import { SpecialsService } from './services/specials.service';
import { TaxService } from './services/tax.service';
import { TextTemplatesService } from './services/text-templates.service';
import { UsersService } from './services/users.service';
import { ValidationService } from './services/validation.service';
import { VisitSignalrService } from './services/visit-signalr.service';
import { VisitService } from './services/visit.service';
import { SharedModule } from './shared/shared.module';
import { PatientsListComponent } from './sidenav/quick-links/patients-list/patients-list.component';
import { SidenavModule } from './sidenav/sidenav.module';
import { ViewerComponent } from './viewer/viewer.component';
import { VisitStatusDialogComponent } from './visit-status-dialog/visit-status-dialog.component';
import { EmailSettingsComponent } from './management/email-settings/email-settings.component';
import { HoverPanelComponent } from './schedule/appointments/hover-panel/hover-panel.component';
import { FcScheduleWrapperComponent } from './schedule/appointments/fc-schedule-wrapper/fc-schedule-wrapper.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MobileNavComponent } from './mobile-nav/mobile-nav.component';
import { OrgPaymentProcessorsComponent } from './management/organization/org-payment-processors/org-payment-processors.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true,
};

const DEFAULT_NG_SCROLLBAR_OPTIONS: NgScrollbarOptions = {
  appearance: 'standard',
};

@Injectable()
export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: { enable: false },
    rotate: { enable: false },
  };
}
@NgModule({
  declarations: [
    AppComponent,
    ScheduleComponent,
    PatientComponent,
    PatientsListComponent,
    PatientTabsComponent,
    MergePatientsComponent,
    PatientProfileDetailComponent,
    PatientAccountTabComponent,
    PatientTreatmentPlanTabComponent,
    PatientChartTabComponent,
    PatientCommunicationTabComponent,
    PatientPhotosTabComponent,
    PatientPhotosConsentedTabComponent,
    PatientProfileTabComponent,
    PatientPortalOverviewComponent,
    AddressBookDoctorsComponent,
    AddressBookPharmaciesComponent,
    AddressBookLabsComponent,
    ManagementComponent,
    CalnavComponent,
    CalnavnoheaderComponent,
    ViewerComponent,
    CatalogueComponent,
    CatalogueServicesComponent,
    AddressBookComponent,
    CommunicationsComponent,
    EditVisitReminderSettingsComponent,
    OrganizationComponent,
    TasksComponent,
    AppointmentsComponent,
    RoomsComponent,
    CatalogueProductsComponent,
    CataloguePackagesComponent,
    CatalogueSpecialsComponent,
    OrgResourcesComponent,
    OrgCancellationReasonComponent,
    OrgClinicGeneralComponent,
    OrgUsersComponent,
    OrgClinicsComponent,
    EditUserComponent,
    EditResourceComponent,
    EditCancellationReasonComponent,
    EditProductComponent,
    EditServiceComponent,
    EditSpecialComponent,
    EditPackageComponent,
    EditProductComponent,
    EditDoctorComponent,
    EditPharmacyComponent,
    EditLabComponent,
    EditCompanyComponent,
    EditClinicComponent,
    EditPatientComponent,
    EditPatientContentComponent,
    VisitsComponent,
    AppointmentComponent,
    EmilyComponent,
    CancelVisitDialogComponent,
    CancelVisitComponent,
    ConfirmDeleteDialogComponent,
    ConfirmMergePatientComponent,
    ConfirmChangeUserStatusComponent,
    ConfirmCancelWithReasonDialogComponent,
    ConfirmUnsavedChangesDialogComponent,
    ConfirmUpdateMethodDialogComponent,
    MoveAppointmentDialogComponent,
    HoursOfOperationDialogComponent,
    ClinicSuppliesSelectorModalComponent,
    CurrentDateComponent,
    ManageCategoriesDialogComponent,
    CreateShiftComponent,
    CategoryItemComponent,
    VisitStatusDialogComponent,
    ApptListDialogComponent,
    GenericDialogComponent,
    GenericInputComponent,
    AvatarUploaderComponent,
    ClinicLogoComponent,
    CatalogueTextTemplatesComponent,
    CatalogueTagsComponent,
    EditTextTemplateComponent,
    EditEtreatmentFormComponent,
    EditTagComponent,
    VisitsTreatmentPlanComponent,
    QuickAddLabComponent,
    QuickAddPhysicianComponent,
    QuickAddPharmacyComponent,
    OrgPaymentMethodsComponent,
    EditPaymentMethodComponent,
    CreateNudgesComponent,
    DocumentPreviewComponent,
    RetentionCampaignsComponent,
    EmailTemplatesComponent,
    EditRetentionCampaignComponent,
    ReviewsSettingsComponent,
    EditEmailTemplatesComponent,
    PreviewCampaignRecipientsComponent,
    CampaignRecipientsGridComponent,
    CampaignRecipientsComponent,
    SendTestVisitReminderComponent,
    SendTestEmailComponent,
    CommunicationWrapperComponent,
    CommunicationSummaryComponent,
    CreateTreatmentPlanPackageComponent,
    ConfirmChangeUserStatusComponent,
    SelectProviderDialogComponent,
    PatientReviewComponent,
    OrgTaxesComponent,
    EditTaxComponent,
    ImportJSONComponent,
    DateSelectDialogComponent,
    OrgOnlineBookingComponent,
    EditOnlineBookingProviderComponent,
    EditOnlineBookingCategoryComponent,
    EditOnlineBookingServiceComponent,
    OrgOnlineGeneralSettingsComponent,
    OrgOnlineProvidersSettingsComponent,
    OrgOnlineCategoriesSettingsComponent,
    OrgOnlineServiceSettingsComponent,
    PatientSquareOverviewComponent,
    EditUserCommissionsComponent,
    CommissionEditComponent,
    EditEmailVisitReminderComponent,
    GiftCardsComponent,
    GiftCardsOverviewComponent,
    GiftCardsSettingsComponent,
    OrgPhotosComponent,
    PasscodeComponent,
    AddProductComponent,
    OrgPaymentOverviewComponent,
    OrgInvoiceTextEditorComponent,
    ServiceProviderSelectorModalComponent,
    GroupPricingObservationsDialogComponent,
    OrgMerchantDevicesComponent,
    EditMerchantDeviceComponent,
    VisitAppointmentsComponent,
    VisitPatientInfoComponent,
    VisitEditAppointmentComponent,
    OrgUserPermissionsComponent,
    OrgRolePermissionsComponent,
    CatalogueMembershipsComponent,
    EditMembershipComponent,
    AssignItemsToMembershipComponent,
    EmailSettingsComponent,
    HoverPanelComponent,
    FcScheduleWrapperComponent,
    MobileNavComponent,
    OrgPaymentProcessorsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ButtonsModule,
    Routing,
    BrowserAnimationsModule,
    GridModule,
    ExcelModule,
    PDFModule,
    LoadingModule,
    InlineSVGModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    ColorPickerModule,
    ContextMenuModule,
    OverlayPanelModule,
    MatRadioModule,
    DateInputsModule,
    IntlModule,
    DropDownsModule,
    TooltipModule,
    TreeViewModule,
    MatExpansionModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatProgressBarModule,
    SwitchModule,
    InputsModule,
    MatSidenavModule,
    FileUploadModule,
    MatMenuModule,
    PerfectScrollbarModule,
    AuthModule,
    SharedModule,
    ServiceDetailModule,
    PatientPhotosModule,
    PatientPhotosConsentedModule,
    CatalogueDocumentTypesModule,
    PatientDocumentsModule,
    ClinicDocumentsModule,
    ClinicSuppliesModule,
    SidenavModule,
    ChartOverviewModule,
    MessagingModule,
    SharedPhotosModule,
    PatientAccountModule,
    TypeaheadModule.forRoot(),
    SiteBillingModule,
    AllergiesModule,
    NudgesModule,
    ReviewsModule,
    AccessLocationModule,
    ReportsModule,
    PdfViewerModule,
    FormioModule,
    EditorModule,
    FontAwesomeModule,
    MatButtonToggleModule,
    PhotosModule,
    PlatformModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    NgScrollbarModule,
    NgScrollbarReachedModule,
    SiteBillingModule,
    RouterModule,
    FullCalendarModule,
  ],
  providers: [
    CryptoHelperService,
    MspCardSwipeService,
    ReviewSettingsService,
    HybridStorageStrategy,
    CurrentDataService,
    ClinicProductsService,
    ProductDefaultsService,
    EventsService,
    ServicesService,
    ServiceTemplatesService,
    ServiceDefaultsService,
    ObservationService,
    ObservationTypesService,
    ObservationListItemsService,
    ObservationUnitsService,
    ActionPanelService,
    CatalogueUpdatesService,
    NavStateService,
    PatientService,
    NgModel,
    VisitService,
    VisitSignalrService,
    PatientSignalrService,
    ServicesSignalrService,
    ServiceTemplatesSignalrService,
    MessagingSignalrService,
    ClinicsService,
    LabsService,
    CompanyService,
    DoctorsService,
    PackagesService,
    PharmaciesService,
    ResourcesService,
    CancellationReasonService,
    SpecialsService,
    GeographyService,
    ValidationService,
    FormatterService,
    HoursOfOperationService,
    UsersService,
    MasterOverlayService,
    AppointmentService,
    AppointmentSignalrService,
    PhotoSignalrService,
    NudgesSignalrService,
    SignalrConnectionService,
    CategoryService,
    TaxService,
    RoomService,
    AddressService,
    ImageService,
    InjectionFormService,
    TextTemplatesService,
    AllergiesService,
    CommunicationsService,
    ClinicEmailTemplateService,
    BookingServiceCategoryService,
    FavouriteServicesService,
    PhotoDrawingService,
    {
      provide: MAT_DIALOG_DATA,
      useValue: {},
    },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService, Sentry.TraceService] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: DEFAULT_NG_SCROLLBAR_OPTIONS,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: CustomHammerConfig,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function appInitializer(authenticationService: AuthService) {
  return () =>
    new Promise<void>((resolve) => {
      var reg = new RegExp(/\/login$|\/giftcards\/|\/portal\/|\/portal\/login?$/gm);
      if (!reg.test(window.location.pathname)) {
        //not authenticating patient reviews atm
        // attempt to refresh token on app start up to auto authenticate
        authenticationService
          .refreshTokenRequest(window.location.pathname + window.location.search)
          .subscribe()
          .add(resolve);
      } else {
        // The following routes are unauthenticated
        // { path: 'PatientReview/:patientId', component: PatientReviewComponent },
        // { path: 'PatientReview/:patientId/:appointmentId', component: PatientReviewComponent },
        // { path: 'PatientReview/:patientId/Unsubscribe/Review', component: PatientReviewComponent },
        resolve();
      }
    });
}
