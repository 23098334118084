import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';

import { isNullOrUndefined } from '@app/shared/helpers';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map, startWith } from 'rxjs/operators';

import { PackagesService } from '@services/packages.service';
import { TaxService } from '@services/tax.service';
import { ClinicsService } from '@services/clinics.service';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { FormatterService } from '@services/formatter.service';
import { Tax } from '@models/tax';
import { Package, PackageTax } from '@models/package';
import { ClinicProduct, PackageProduct } from '@models/clinic-product';
import { Service, PackageService } from '@models/service/service';

@Component({
  selector: 'app-edit-package',
  templateUrl: './edit-package.component.html',
  styleUrls: ['./edit-package.component.less'],
})
export class EditPackageComponent implements OnInit, OnDestroy {
  name: FormControl;
  totalOfIndividualPrices: FormControl;
  retailPrice: FormControl;
  products: FormControl;
  services: FormControl;
  selectedProduct: FormControl = new FormControl();
  selectedService: FormControl = new FormControl();

  public addOrEdit = 'Add';
  public taxes: Tax[];
  public selectedTaxes: Tax[] = [];
  public filteredProducts: Observable<ClinicProduct[]>;
  public selectedProducts: PackageProduct[] = [];
  public filteredServices: Observable<Service[]>;
  public selectedServices: PackageService[] = [];
  public package: Package = {
    packageId: 0,
    name: '',
    totalOfIndividualPrices: '$0.00',
    retailPrice: null,
    packageProducts: [],
    packageServices: [],
    packageTaxes: [],
    packageProductsString: '',
  };

  private isNew = true;
  private packageIdParam = '0';
  private allAvailableProducts: ClinicProduct[] = [];
  private allAvailableServices: Service[] = [];

  private unsub: Subject<void> = new Subject<void>();

  constructor(
    private packagesService: PackagesService,
    private taxesService: TaxService,
    private clinicService: ClinicsService,
    private catalogueUpdatesService: CatalogueUpdatesService,
    private route: ActivatedRoute,
    private router: Router,
    public formatterService: FormatterService
  ) {
    this.name = new FormControl();
    this.totalOfIndividualPrices = new FormControl();
    this.retailPrice = new FormControl();
    this.products = new FormControl();
    this.services = new FormControl();
  }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      const id = params['packid'];
      this.taxes = [];

      if (id && id !== '_') {
        // TODO: add this to the condition: && isNumber(id) && id > 0
        this.packageIdParam = id;

        this.packagesService.getPackageForEdit(id).subscribe(
          (dto) => {
            if (dto.package) {
              this.package = dto.package;
              this.allAvailableProducts = dto.clinicProducts;
              this.allAvailableServices = dto.services.filter((e) => !e.serviceName.startsWith('_'));
              // this.taxes = dto.taxes;
              dto.package.packageProducts.forEach((pp) => {
                this.selectedProducts.push(pp);
              });

              dto.package.packageServices.forEach((ss) => {
                this.selectedServices.push(ss);
              });

              dto.package.packageTaxes.forEach((pt) => {
                this.selectedTaxes.push(pt.tax);
              });
              this.taxes = this.getClinicTaxes(0);
              this.isNew = false;
              this.addOrEdit = 'Edit';
            } else {
              // TODO: decide what to do if there is no more the package in the database
            }
          },
          (err) => {
            // TODO: decide what to do with err
          }
        );
      } else {
        this.packagesService.getListsForNew().subscribe(
          (dto) => {
            this.allAvailableProducts = dto.clinicProducts;
            this.allAvailableServices = dto.services.filter((e) => !e.serviceName.startsWith('_'));
            // this.taxes = dto.taxes;
            this.taxes = this.getClinicTaxes(0);
          },
          (err) => {
            // TODO: decide what to do with err
          }
        );
      }
    });

    this.filteredProducts = this.selectedProduct.valueChanges.pipe(
      startWith(''),
      map((val) => this.prodfilter(val))
    );
    this.filteredServices = this.selectedService.valueChanges.pipe(
      startWith(''),
      map((val) => this.servfilter(val))
    );
  }

  private prodfilter(val: any): ClinicProduct[] {
    if (val.name) {
      return this.allAvailableProducts.filter((option) =>
        option.displayName.toLowerCase().includes(val.name.toLowerCase())
      );
    } else {
      return this.allAvailableProducts.filter((option) => option.displayName.toLowerCase().includes(val.toLowerCase()));
    }
  }

  public productDisplayFn(user?: ClinicProduct): string | undefined {
    return user ? user.displayName : undefined;
  }

  private servfilter(val: any): Service[] {
    if (!val) return [];
    if (val.serviceName) {
      return this.allAvailableServices.filter((option) =>
        option.serviceName.toLowerCase().includes(val.serviceName.toLowerCase())
      );
    } else {
      return this.allAvailableServices.filter((option) => option.serviceName.toLowerCase().includes(val.toLowerCase()));
    }
  }

  public serviceDisplayFn(user?: Service): string | undefined {
    return user ? user.serviceName : undefined;
  }

  public addProductToPackage() {
    let matchFound = false;
    let productToAdd: ClinicProduct = null;
    if (typeof this.selectedProduct.value === 'object') {
      productToAdd = this.selectedProduct.value;
    }
    if (!isNullOrUndefined(productToAdd)) {
      this.selectedProducts.forEach((selectedProduct) => {
        if (selectedProduct.clinicProductId === productToAdd.id) {
          selectedProduct.productQuantity += 1;
          this.updatePackageProductQuantity(selectedProduct);
          matchFound = true;
        }
      });
      if (!matchFound) {
        const packageProduct: PackageProduct = {
          clinicProductId: productToAdd.id,
          clinicProduct: productToAdd,
          productQuantity: 1,
          packageId: Number(this.packageIdParam),
          package: null,
        };
        const packageProductDB: PackageProduct = {
          clinicProductId: productToAdd.id,
          clinicProduct: null,
          productQuantity: 1,
          packageId: Number(this.packageIdParam),
          package: null,
        };
        this.selectedProducts.push(packageProduct);
        this.package.packageProducts.push(packageProductDB);
        this.updateTotalOfPrices();
        this.selectedProduct.reset();
      }
    }
  }

  public updatePackageProductQuantity(product: PackageProduct) {
    this.package.packageProducts.forEach((pp) => {
      if (pp.clinicProductId === product.clinicProductId) {
        pp.productQuantity = product.productQuantity;
      }
    });
    this.selectedProducts.forEach((sp) => {
      if (sp.clinicProductId === product.clinicProductId) {
        sp.productQuantity = product.productQuantity;
      }
    });
    this.updateTotalOfPrices();
  }

  public removeProductFromPackage(productToRemove: ClinicProduct) {
    let productIndex = this.package.packageProducts.indexOf(
      this.package.packageProducts.find((pp) => pp.clinicProductId === productToRemove.id)
    );
    this.package.packageProducts.splice(productIndex, 1);

    productIndex = this.selectedProducts.indexOf(
      this.selectedProducts.find((sp) => sp.clinicProductId === productToRemove.id)
    );
    this.selectedProducts.splice(productIndex, 1);

    this.updateTotalOfPrices();
  }

  public addServiceToPackage() {
    let matchFound = false;
    let serviceToAdd: Service = null;
    if (typeof this.selectedService.value === 'object') {
      serviceToAdd = this.selectedService.value;
    }
    if (!isNullOrUndefined(serviceToAdd)) {
      serviceToAdd.quantity = 1;
      this.selectedServices.forEach((selectedService) => {
        if (selectedService.serviceId === serviceToAdd.serviceId) {
          selectedService.serviceQuantity += 1;
          this.updatePackageServiceQuantity(selectedService);
          matchFound = true;
        }
      });
      if (!matchFound) {
        const packageService: PackageService = {
          serviceId: serviceToAdd.serviceId,
          service: serviceToAdd,
          serviceQuantity: 1,
          packageId: Number(this.packageIdParam),
          package: null,
        };
        const packageServiceDB: PackageService = {
          serviceId: serviceToAdd.serviceId,
          service: null,
          serviceQuantity: 1,
          packageId: Number(this.packageIdParam),
          package: null,
        };
        this.selectedServices.push(packageService);
        this.package.packageServices.push(packageServiceDB);
        this.updateTotalOfPrices();
      }
    }
  }

  public removeServiceFromPackage(serviceToRemove: Service) {
    let serviceIndex = this.package.packageServices.indexOf(
      this.package.packageServices.find((pp) => pp.serviceId === serviceToRemove.serviceId)
    );
    this.package.packageServices.splice(serviceIndex, 1);

    serviceIndex = this.selectedServices.indexOf(
      this.selectedServices.find((sp) => sp.serviceId === serviceToRemove.serviceId)
    );
    this.selectedServices.splice(serviceIndex, 1);

    this.updateTotalOfPrices();
  }

  public updatePackageServiceQuantity(service: PackageService) {
    this.package.packageServices.forEach((pp) => {
      if (pp.serviceId === service.serviceId) {
        pp.serviceQuantity = service.serviceQuantity;
      }
    });
    this.selectedServices.forEach((ss) => {
      if (ss.serviceId === service.serviceId) {
        ss.serviceQuantity = service.serviceQuantity;
      }
    });
    this.updateTotalOfPrices();
  }

  private getClinicTaxes(clinicId: number): Tax[] {
    const clinicTaxes: Tax[] = [];
    this.clinicService.getClinics().subscribe((c) => {
      if (!isNullOrUndefined(c[0].clinicTaxes)) {
        if (c[0].clinicTaxes.length > 0) {
          c[0].clinicTaxes.forEach((ct) => {
            this.taxesService.getTaxes().subscribe((taxes) => {
              taxes.forEach((t) => {
                if (t.taxId === ct.taxId) {
                  clinicTaxes.push(t);
                }
              });
              return clinicTaxes;
            });
          });
        } else {
          return clinicTaxes;
        }
      } else {
        return clinicTaxes;
      }
    });
    return clinicTaxes;
  }

  public updateTotalOfPrices() {
    let totalPrices = 0;
    this.selectedProducts.forEach((sp) => {
      totalPrices = Number(totalPrices) + Number(sp.clinicProduct.retailPrice) * Number(sp.productQuantity);
    });
    this.selectedServices.forEach((ss) => {
      totalPrices =
        Number(totalPrices) +
        (ss.service.defaultPrice === null ? 0 : Number(ss.service.defaultPrice) * Number(ss.serviceQuantity));
    });
    // apply the taxes
    let thetaxtotal = 0;
    this.selectedTaxes.forEach((tax) => {
      thetaxtotal = thetaxtotal + totalPrices * tax.value;
    });
    totalPrices = totalPrices + thetaxtotal;
    this.package.totalOfIndividualPrices = totalPrices.toFixed(2);
  }

  public updatePackage() {
    const id = this.package.packageId;
    const selectedTaxes = this.selectedTaxes;
    const packageTaxes: PackageTax[] = [];

    selectedTaxes.forEach((t) => {
      packageTaxes.push({
        packageId: id,
        package: null,
        taxId: t.taxId,
        tax: null,
      });
    });
    this.package.packageTaxes = packageTaxes;

    this.package.packageProductsString = '';
    this.selectedProducts.forEach((sp) => {
      this.package.packageProductsString =
        this.package.packageProductsString +
        sp.clinicProduct.displayName +
        '(' +
        sp.productQuantity +
        ')-$' +
        sp.clinicProduct.retailPrice;
      this.package.packageProductsString = this.package.packageProductsString + ',';
    });
    this.selectedServices.forEach((ss) => {
      this.package.packageProductsString =
        this.package.packageProductsString +
        ss.service.serviceName +
        '(' +
        ss.serviceQuantity +
        ')-$' +
        ss.service.defaultPrice;
      this.package.packageProductsString = this.package.packageProductsString + ',';
    });
    // remove the trailing comma
    this.package.packageProductsString = this.package.packageProductsString.slice(0, -1);

    if (this.isNew) {
      this.packagesService.addPackage(this.package).subscribe(() => {
        this.catalogueUpdatesService.refreshRequired = true;
        this.catalogueUpdatesService.catalogueUpdateComplete();
        this.router.navigate(['/management/catalogue/packages', { outlets: { 'action-panel': null } }]);
      });
    } else {
      this.packagesService.updatePackage(this.package).subscribe(() => {
        this.catalogueUpdatesService.refreshRequired = true;
        this.catalogueUpdatesService.catalogueUpdateComplete();
        this.router.navigate(['/management/catalogue/packages', { outlets: { 'action-panel': null } }]);
      });
    }
  }

  public cancelUpdate() {
    this.catalogueUpdatesService.refreshRequired = false;
    this.catalogueUpdatesService.catalogueUpdateComplete();
    this.router.navigate(['/management/catalogue/packages', { outlets: { 'action-panel': null } }]);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
