<div class="modal-header border-0 px-2 py-1">
  <button type="button" class="btn white-font p-0 ml-auto" aria-label="Close" (click)="closeModal()">
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="p-4 d-flex flex-column align-items-center">
  <h6 class="mt-0 mb-3 navy-font">QUICK ADD LAB</h6>
  <form [formGroup]="labForm" class="w-100 mb-2">
    <div class="navy-font d-flex flex-column justify-content-center font-weight-bold form-field">
      Lab Name:
      <div class="flex-grow-1">
        <mat-form-field [floatLabel]="'never'" class="w-100">
          <input matInput formControlName="labName">
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="text-center">
    <button
      type="button"
      class="btn em-btn em-btn-green"
      [disabled]="!labForm.valid"
      (click)="labFormSubmit()">
        Add
    </button>
    <button
      type="button"
      class="btn em-btn em-btn-green ml-2"
      (click)="closeModal()">
        Cancel
    </button>
  </div>
</div>
      