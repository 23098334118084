import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormioAppConfig, FormBuilderComponent } from 'angular-formio-emilyemr';
import { ClinicDocumentsService } from '@services/clinic-documents.service';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClinicDocument } from '@models/document/clinic-document';
import { PatientData } from './eform-custom-data/patient-data';
import { PhysicianData } from './eform-custom-data/physician-data';
import { ClinicData } from './eform-custom-data/clinic-data';
@Component({
  selector: 'app-eform-builder',
  templateUrl: './eform-builder.component.html',
  styleUrls: ['./eform-builder.component.less']
})
export class EformBuilderComponent implements OnInit, OnDestroy {
  public form: any;
  public builderOptions: Object;

  unsub: Subject<void> = new Subject<void>();
  docId: number;
  clinicDoc: ClinicDocument;

  @ViewChild('builder') builder: FormBuilderComponent;

  constructor(
    private route: ActivatedRoute,
    private config: FormioAppConfig,
    private clinicDocService: ClinicDocumentsService
  ) {

    this.builderOptions = {
      builder: {
        basic: false,
        advanced: false,
        data: false,
        layout: false,
        pdf: false,
        customBasic: {
          title: 'Form Fields',
          default: true,
          weight: 0,
          components: {
            textfield: true,
            checkbox: true,
            datetime: true,
            textarea: true,
            signature: {
              title: 'Signature',
              key: 'signature',
              icon: 'fa fa-pencil',
              schema: {
                label: 'Signature',
                type: 'signature',
                key: 'signature',
                input: true,
                backgroundColor: 'transparent',
                width: '-webkit-fill-available',
                height: 'auto'
              }
            },
          }
        },
        patientData: PatientData,
        physicianData: PhysicianData,
        clinicData: ClinicData
      },
      editForm: {
        textfield: [
          {
            key: 'api',
            ignore: true
          }
        ]
      }
    };
  }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsub)).subscribe(params => {
      this.docId = +params['id'];
    });
    this.clinicDocService.getDocument(this.docId).subscribe(doc => {
      this.clinicDoc = doc;
      this.initBuilder();
    });
  }

  onChange(event) {
    this.clinicDoc.eFormDefinition = JSON.stringify(event.form, null, 4);
    this.clinicDocService.updateDocument(this.clinicDoc).pipe(takeUntil(this.unsub)).subscribe();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  private initBuilder() {
    if (this.clinicDoc.eFormDefinition) {
      this.form = JSON.parse(this.clinicDoc.eFormDefinition);
      this.form.settings.pdf.src = this.clinicDoc.pdfToHtmlUrl;
    } else {
      this.form = {
        display: 'pdf',
        components: [],
        settings: {
          pdf: {
            'src': this.clinicDoc.pdfToHtmlUrl
          }
        }
      };
    }
    this.builder.form = this.form;
    this.builder.buildForm(this.form);
  }
}
