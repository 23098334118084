<!-- <p class="header" [ngClass]="{ inactive: !sideNavExpanded }">
  Quick Links<span class="float-right pr-3"><i class="fas fa-cogs"></i></span>
</p> -->
<ul [ngClass]="{ 'text-center': !sideNavExpanded }">
  <li *appAuthGuard="addressBookPolicy" (click)="goToAddressBook()">
    <span
      class="menu-icon"
      [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
      kendoTooltip
      tooltipClass="em-tooltip"
      position="right"
    >
      <i class="fal fa-address-book fa-lg" aria-hidden="true" title="Address Book"></i>
    </span>
    <span [ngClass]="{ inactive: !sideNavExpanded }">Address Book</span>
  </li>
  <li routerLink="/messages">
    <span
      class="menu-icon position-relative"
      [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
      kendoTooltip
      tooltipClass="em-tooltip"
      position="right"
    >
      <div *ngIf="messagingNotificationsCounter" class="nav-link-badge position-absolute">
        {{ messagingNotificationsCounter }}
      </div>
      <i class="fal fa-envelope fa-lg" title="Clinic Messaging"></i>
    </span>
    <span [ngClass]="{ inactive: !sideNavExpanded }">Clinic Messaging</span>
  </li>
  <li routerLink="/reports" *appAuthGuard="reportsPolicy">
    <span
      class="menu-icon"
      [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
      kendoTooltip
      tooltipClass="em-tooltip"
      position="right"
    >
      <i class="fal fa-chart-bar fa-lg" aria-hidden="true" title="Reports"></i>
    </span>
    <span [ngClass]="{ inactive: !sideNavExpanded }">Reports</span>
  </li>
  <li routerLink="/epower-reports" *appAuthGuard="powerReportsPolicy">
    <span
      class="menu-icon"
      [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
      kendoTooltip
      tooltipClass="em-tooltip"
      position="right"
    >
      <div
        [inlineSVG]="'../../../../../../../assets/svg/analytics.svg'"
        title="ePower Reports"
        class="svg-icon"
      ></div>
    </span>
    <span [ngClass]="{ inactive: !sideNavExpanded }">ePower Reports</span>
  </li>
  <li routerLink="/patients-list" *appAuthGuard="patientListPolicy">
    <span
      class="menu-icon"
      [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
      kendoTooltip
      tooltipClass="em-tooltip"
      position="right"
    >
      <i class="fal fa-users fa-lg" aria-hidden="true" title="Patients"></i>
    </span>
    <span [ngClass]="{ inactive: !sideNavExpanded }">Patients</span>
  </li>
  <!-- <app-add-patient *ngIf="this.hasClinic" [sideNavExpanded]="sideNavExpanded"></app-add-patient> -->
  <!-- <li routerLink="/merge-patients" *appAuthGuard="patientMergePolicy">
    <span
      class="menu-icon"
      [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
      kendoTooltip
      tooltipClass="em-tooltip"
      position="right"
    >
      <i class="fal fa-user-friends fa-lg" aria-hidden="true" title="Merge Patients"></i>
    </span>
    <span [ngClass]="{ inactive: !sideNavExpanded }">Merge Patient</span>
  </li> -->
  <!-- <app-patient-check-in *appAuthGuard="appointmentPolicy" [sideNavExpanded]="sideNavExpanded"> </app-patient-check-in> -->
  <!-- This is a patient search in the nav bar, it is commented out as it is currently not needed -->
  <!-- <li
    *ngIf="patientPanelPermissions ? authService.userHasPermission(patientPanelPermissions) : false"
    (click)="searchPatient()"
  >
    <span
      [ngClass]="{ inactive: sideNavExpanded, 'menu-icon-expanded': sideNavExpanded }"
      kendoTooltip
      tooltipClass="em-tooltip"
      position="right"
    >
      <i class="fal fa-search menu-icon-small" aria-hidden="true" title="Search Patients"></i>
    </span>
    <app-search-patient [searchPatientClicked]="searchPatientClicked" [sideNavExpanded]="sideNavExpanded"></app-search-patient>
  </li> -->
</ul>
