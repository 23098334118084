<ngx-loading [show]="loading"></ngx-loading>

<div class="modal-header border-0 p-0">
  <button
    type="button"
    class="btn white-font p-0 ml-auto mr-2"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="container p-0 pb-3">
  <div class="search-container navy-background justify-content-center patients-container">
    <h6 class="modal-title text-uppercase white-font text-center mb-4">Search For Patient</h6>
    <div class="input-group pb-2">
      <div class="input-group-prepend">
        <div class="input-group-text border-radius grey-three-background border-0">
          <i class="fa fa-search"></i>
        </div>
      </div>
      <input
        #patientSearchInput
        autocomplete="off"
        [formControl]="searchFormControl"
        type="text"
        name="docSearch"
        class="form-control border-0 grey-three-background"
        placeholder="Search"
      />
    </div>
  </div>

  <div *ngIf="patients && patients.length !== 0; else noPatients" class="patients-container" style="height: 440px">
    <ng-scrollbar class="h-100">
      <ng-container *ngFor="let patient of patients">
        <div (click)="closeModal(patient)" class="patient-item">
          <div class="navy-font">{{ getPatientName(patient) | titlecase }}</div>
          <button class="btn-clean" (click)="closeModal(patient)">
            <i class="fas fa-plus-circle calendar-green-font bigger-icon"></i>
          </button>
        </div>
      </ng-container>
    </ng-scrollbar>
  </div>

  <ng-template #noPatients>
    <div class="p-2 no-patients-container">
      <span *ngIf="!patients" class="font-weight-bold"> Use the search bar above.</span>
      <span *ngIf="patients && patients.length === 0" class="font-weight-bold">No patients found.</span>
    </div>
  </ng-template>

  <div class="save-changes-container my-3 text-center">
    <button class="btn em-btn em-btn-green ml-2" (click)="activeModal.dismiss()">Cancel</button>
  </div>
</div>
