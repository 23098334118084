import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MailjetEmailTemplate } from '@models/communications/mailjet-email-template.model';
import { environment } from '@environments/environment';
import { Observable, Subject } from 'rxjs';
import { EmailNotificationsSettings } from '@models/communications/email-notifications-settings.model';
import { SmsNotificationsSettings } from '@models/communications/sms-notifications-settings.model';
import { CommunicationSettingType } from '@models/communications/notifications-settings-base.model';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { PagedDataResult } from '@models/paged-data-result.model';
import { CommunicationHistory } from '@models/communications/communication-history.model';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { RetentionCampaignsSettings } from '@models/communications/retention-campaigns-settings.model';
import { TestEmail } from '@models/communications/test-email.model';
import { TestSms } from '@models/communications/test-sms.model';
import { CommunicationHistorySummary } from '@models/communications/communicaton-history-summary.model';

@Injectable()
export class CommunicationsService {
  communicationsSettingsUpdated = new Subject();
  refreshRequired = false;

  constructor(private http: HttpClient) {}

  getLatestReviewRequest(patientId: number): Observable<CommunicationHistory | null> {
    return this.http.get<CommunicationHistory>(
      `${environment.baseUrl}api/Communications/GetLatestReviewRequest/${patientId}`
    );
  }

  getEmailTemplates(): Observable<MailjetEmailTemplate[]> {
    return this.http.get<MailjetEmailTemplate[]>(environment.baseUrl + 'api/Communications/GetAllEmailTemplates');
  }

  getEmailTemplateDetails(templateId: number): Observable<MailjetEmailTemplate> {
    return this.http
      .get<MailjetEmailTemplate>(environment.baseUrl + `api/Communications/GetEmailTemplateDetails/${templateId}`)
      .pipe(
        map((template) => {
          if (template && template.htmlTemplate && template.htmlTemplate.indexOf('</head>') != -1) {
            template.htmlTemplate = template.htmlTemplate.replace(
              '</head>',
              '<style type="text/css">::-webkit-scrollbar {width: 18px;}::-webkit-scrollbar-track {background-color: transparent;} ::-webkit-scrollbar-thumb {background-color: #d3d4d5; border-radius: 18px; border: 6px solid transparent; background-clip: content-box;} ::-webkit-scrollbar-thumb:hover{background-color: #b2b2b2;}</style></head>'
            );
          }
          return template;
        })
      );
  }

  getGlobalCommunicationSettings(): Observable<any> {
    return this.http.get<any>(environment.baseUrl + 'api/Communications/GetGlobalNotificationsSettings');
  }

  getEmailNotificationsSettings(): Observable<EmailNotificationsSettings[]> {
    return this.http
      .get<EmailNotificationsSettings>(environment.baseUrl + 'api/Communications/GetEmailNotificationsSettings')
      .pipe(map((_) => this.parseDurations(_)));
  }

  getEmailNotificationsSettingsByType(settingType: CommunicationSettingType): Observable<EmailNotificationsSettings> {
    return this.http
      .get<EmailNotificationsSettings>(
        environment.baseUrl + 'api/Communications/GetEmailNotificationsSettingsByType/' + settingType
      )
      .pipe(map((_) => this.parseDurations(_)));
  }

  getSmsNotificationsSettings(): Observable<SmsNotificationsSettings> {
    return this.http
      .get<SmsNotificationsSettings>(environment.baseUrl + 'api/Communications/GetSmsNotificationsSettings')
      .pipe(map((_) => this.parseDurations(_)));
  }

  getRetentionCampaignsSettings(): Observable<RetentionCampaignsSettings> {
    return this.http.get<RetentionCampaignsSettings>(
      environment.baseUrl + 'api/Communications/GetRetentionCampaignsSettings'
    );
  }

  updateGlobalNotificationsSettings(enabled: boolean) {
    return this.http.post(environment.baseUrl + 'api/Communications/UpdateGlobalNotificationsSettings', { enabled });
  }

  updateEmailNotificationsSettings(data: EmailNotificationsSettings) {
    return this.http.post(environment.baseUrl + 'api/Communications/UpdateEmailNotificationsSettings', data);
  }

  updateSmsNotificationsSettings(data: SmsNotificationsSettings) {
    return this.http.post(environment.baseUrl + 'api/Communications/UpdateSmsNotificationsSettings', data);
  }

  updateRetentionCampaignsSettings(data: RetentionCampaignsSettings) {
    return this.http.post(environment.baseUrl + 'api/Communications/UpdateRetentionCampaignsSettings', data);
  }

  getHistoryForPatient(
    patientId: number,
    state: DataSourceRequestState
  ): Observable<PagedDataResult<CommunicationHistory>> {
    const url = environment.baseUrl + `api/Communications/GetHistory`;
    const data = {
      patientId,
      state,
    };
    return this.http.post<PagedDataResult<CommunicationHistory>>(url, data);
  }

  getHistory(
    state: DataSourceRequestState,
    startFilterDate: Date,
    endFilterDate: Date,
    searchText: string,
    filter: { name: string; value: string } = null
  ): Observable<PagedDataResult<CommunicationHistorySummary>> {
    const url = environment.baseUrl + `api/Communications/GetHistorySummary`;
    const data = {
      state,
      endFilterDate,
      startFilterDate,
      searchText,
    };
    return this.http.post<PagedDataResult<CommunicationHistorySummary>>(url, data);

    // .pipe(
    //   map((history: any) => {
    //     //this section and filter property are for filtering type on client side
    //     //can be removed when the api has implemented back end filter, the logic is already in place on client
    //     //see filterChange method in communication-summary

    //     if (filter && filter.name) {
    //       let oldSize = history.data.length;
    //       history.data = history.data.filter(
    //         (h) =>
    //           h[filter.name] &&
    //           filter.value &&
    //           (h[filter.name].indexOf(filter.value) != -1 || filter.value == 'SMS,Email')
    //       );
    //       //this is temporary to get at least close to the correct number until filtering on back end returns actual correct number
    //       history.total =
    //         oldSize == history.data.length
    //           ? history.total
    //           : history.data.length == 0
    //           ? 0
    //           : history.data.length < 10
    //           ? history.data.length
    //           : history.total - history.data.length;
    //     }
    //     // let testPhone = new RegExp('^\\d{3}-\\d{3}-\\d{4}$');
    //     // history.data.forEach((e) => {
    //     //   if (e.type == 'RetentionCampaigns') {
    //     //     if (testPhone.test(e.patientContact)) {
    //     //       e.type = 'SMS';
    //     //     } else {
    //     //       e.type = 'Email';
    //     //     }
    //     //   }
    //     // });
    //     return history;
    //   })
    // );
  }

  sendTestEmail(data: TestEmail) {
    return this.http.post(environment.baseUrl + 'api/Communications/SendTestEmail', data);
  }

  sendTestEmailSes(data: TestEmail) {
    return this.http.post(environment.baseUrl + 'api/Communications/SendTestEmailSes', data);
  }

  sendTestSms(data: TestSms) {
    return this.http.post(environment.baseUrl + 'api/Communications/SendTestSms', data);
  }

  sendTestCampaignSms(data: TestSms) {
    return this.http.post(environment.baseUrl + 'api/Communications/SendTestCampaignSms', data);
  }

  private parseDurations(s: any): any {
    if (Array.isArray(s)) {
      s.forEach((el) => {
        el.workerStartTime = moment.duration(el.workerStartTime);
        el.workerEndTime = moment.duration(el.workerEndTime);
      });
    } else {
      s.workerStartTime = moment.duration(s.workerStartTime);
      s.workerEndTime = moment.duration(s.workerEndTime);
    }
    return s;
  }
}
