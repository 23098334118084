<div #exterior kendoTooltip tooltipClass="em-tooltip" position="left">
  <ngx-loading [show]="loading"></ngx-loading>

  <div class="container panel-item-border pt-2">
    <div #headerArea>
      <!-- Header -->
      <div class="row">
        <div class="col">
          <h5>Assessment</h5>
        </div>
      </div>

      <div class="coolsculpting-detail-wrapper d-flex pb-2 teal-font w-100">
        <div class="col-1 p-0"></div>
        <div class="col-5 p-0">Applicator & Treatment Area</div>
        <div class="col-2 px-1 text-right">Cycles</div>
        <div class="col-2 px-1 text-right">$/Cycle</div>
        <div class="col-2"></div>
      </div>
    </div>

    <perfect-scrollbar
      *ngIf="assessmentMap && assessmentMap.size > 0"
      class="scrollbar-container"
      style="margin-bottom: 5px; position: relative"
      [ngStyle]="{
        'max-height': maxScrollHeight == 0 ? '200px' : maxScrollHeight - headerArea.offsetHeight - 25 + 'px'
      }"
    >
      <mat-accordion>
        <div *ngFor="let assessment of assessmentMap | keyvalue">
          <mat-expansion-panel
            *ngFor="let obrsGroupMap of assessment.value | keyvalue"
            [expanded]="false"
            [disabled]="true"
            #panelList
          >
            <mat-expansion-panel-header class="px-0 py-0 pr-2" [collapsedHeight]="'36px'" [expandedHeight]="'36px'">
              <mat-panel-title>
                <div
                  class="observation-area"
                  [ngStyle]="{ filter: onGetAvaliableCycles(assessment.key, obrsGroupMap.key) == 0 ? 'opacity(50%)' : 'none' }"
                >
                  <div class="bigger p-0 col-1 justify-content-center d-flex">
                    <i [ngStyle]="{ color: onGetCircleColor(assessment.key, obrsGroupMap.key) }" class="fas fa-circle"></i>
                  </div>
                  <div class="col-5 p-0">
                    <h6 class="small-heading navy-font mb-0">
                      {{ onGetApplicatorDisplayName(obrsGroupMap.key) }} : {{ onGetAreaDisplayName(obrsGroupMap.key) }} :
                      {{ coolsculptingFormService.onGetPosition(obrsGroupMap.key) }}
                    </h6>
                  </div>
                  <div class="p-0 text-right" [ngClass]="{ 'col-2': !treatmentPlanning, 'col-4': treatmentPlanning }">
                    {{ onGetAvaliableCycles(assessment.key, obrsGroupMap.key) }} / {{ onGetCycles(assessment.key, obrsGroupMap.key) }} cycles
                    available
                  </div>
                  <div class="col-2 p-0">
                    <app-unit-billing
                      [service]="plannedTreatmentMap.get(assessment.key).service"
                      [key]="obrsGroupMap.key"
                      [observations]="onGetObservations(assessment.key, obrsGroupMap.key)"
                      [isLocked]="plannedTreatmentMap.get(assessment.key).service.isLocked || associatedPlannedTreatment?.paymentStatus == PaymentStatus.Paid"
                    >
                      >
                    </app-unit-billing>
                  </div>

                  <div class="col-2 p-0 d-flex justify-content-end" *ngIf="!treatmentPlanning">
                    <button
                      type="button"
                      [disabled]="onGetAvaliableCycles(assessment.key, obrsGroupMap.key) == 0"
                      [attr.data-test-id]="'assessment-area-plus-button'"
                      class="btn btn-sm em-btn em-btn-green d-flex align-items-center justify-content-center mr-1"
                      (click)="onApplyTreatment(assessment.key, obrsGroupMap.key)"
                    >
                      <i class="far fa-plus" title="Add Treatment Cycle"></i>
                    </button>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
        </div>
      </mat-accordion>
    </perfect-scrollbar>
  </div>
</div>
