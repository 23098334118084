import { InvoiceTransaction } from '@models/finance/invoice-transaction';
import { InvoiceLineItem } from './invoice-line-item';
import { InvoiceTax } from './invoice-tax';
import { InvoiceType } from './invoice-type';

export class Invoice {
  id: number;
  visitId?: number;
  invoiceNumber: string;
  invoiceDate: Date;
  createDate: Date;
  totalAmount: number;
  subTotalAmount: number;
  taxAmount: number;
  isPaid: boolean;
  invoiceTypeId?: number;
  invoiceLineItems: InvoiceLineItem[];
  invoiceTransactions?: InvoiceTransaction[] = [];
  invoiceTaxes: InvoiceTax[];
  patientCreditsAtTimePaid?: number;
  clinicInvoiceTextAtTimePaid?: string;

  constructor(init?: Partial<Invoice>) {
    Object.assign(this, init);
  }
}

export class PostInvoice
{
    patientId: number;
    invoiceDate: Date;
    invoiceTypeId: InvoiceType;
}
