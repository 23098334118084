import { Component, Input, OnInit } from '@angular/core';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { PhotoProcessingOperation } from '@models/photo/photo-processing';
import { PhotoshopService } from '@services/photoshop.service';
import { fabric } from 'fabric';

@Component({
  selector: 'app-image-processing',
  templateUrl: './image-processing.component.html',
  styleUrls: ['./image-processing.component.less'],
})
export class ImageProcessingComponent implements OnInit {
  @Input() photo: PhotoMetaData;
  @Input() set processSelection(selection: PhotoProcessingOperation[]) {
    console.log(selection);
  }
  loading = false;
  private canvas: fabric.Canvas;
  private imageElement: HTMLImageElement;
  private isPanning = false;
  private disablePanning = false;
  private zoomLevel: number;
  private lastPos: { x: number; y: number };

  constructor(private photoshopService: PhotoshopService) {}

  ngOnInit(): void {
    this.initCanvas();
  }

  private initCanvas() {
    const canvas = (this.canvas = new fabric.Canvas('canvas', {
      defaultCursor: 'move',
      selection: false,
    }));
    fabric.Object.prototype.selectable = false;

    canvas.backgroundColor = 'white';

    canvas.on('touch:gesture', (opt: any) => {
      const event = opt.e;
      event.preventDefault();

      if (event.touches?.length == 2) {
        this.disablePanning = true;
        if (event.self.state == 'start') {
          this.zoomLevel = this.canvas.getZoom();
        }
        const delta = this.zoomLevel * event.self.scale;
        this.zoomCanvas(delta, event.self.x, event.self.y);
        this.disablePanning = false;
      }
    });

    canvas.on('mouse:wheel', (opt) => {
      opt.e.preventDefault();
      const delta = opt.e.deltaY;
      this.zoomCanvas(delta, opt.e.offsetX, opt.e.offsetY);
    });

    canvas.on('mouse:down', (opt) => {
      const event = opt.e;
      event.preventDefault();
      this.isPanning = true;

      if (event instanceof MouseEvent)
        this.lastPos = {
          x: event.clientX,
          y: event.clientY,
        };

      if (event instanceof TouchEvent)
        this.lastPos = {
          x: event.touches[0].clientX,
          y: event.touches[0].clientY,
        };
    });

    canvas.on('mouse:move', (opt) => {
      const event = opt.e;
      event.preventDefault();

      if (event instanceof MouseEvent) this.panCanvas(event.clientX, event.clientY);

      if (event instanceof TouchEvent) this.panCanvas(event.touches[0].clientX, event.touches[0].clientY);
    });

    canvas.on('mouse:up', (opt) => {
      // on mouse up we want to recalculate new interaction
      // for all objects, so we call setViewportTransform
      this.canvas.setViewportTransform(this.canvas.viewportTransform);
      this.isPanning = false;
    });

    this.initImage();
  }

  private zoomCanvas(delta: number, offsetX: number, offsetY: number) {
    const prevZoom = this.canvas.getZoom();
    let zoom = prevZoom * 0.999 ** delta;

    if (zoom > 10) zoom = 10;
    if (zoom < 1) zoom = 1;

    this.canvas.zoomToPoint({ x: offsetX, y: offsetY }, zoom);

    const vpt = this.canvas.viewportTransform;
    const canvasWidth = this.canvas.getWidth();
    const canvasHeight = this.canvas.getHeight();

    if (vpt[4] >= 0) {
      vpt[4] = 0;
    } else if (vpt[4] < canvasWidth - canvasWidth * zoom) {
      vpt[4] = canvasWidth - canvasWidth * zoom;
    }

    if (vpt[5] >= 0) {
      vpt[5] = 0;
    } else if (vpt[5] < canvasHeight - canvasHeight * zoom) {
      vpt[5] = canvasHeight - canvasHeight * zoom;
    }
  }

  private panCanvas(toX: number, toY: number) {
    if (this.isPanning && !this.disablePanning) {
      const vpt = this.canvas.viewportTransform;
      vpt[4] += toX - this.lastPos.x;
      vpt[5] += toY - this.lastPos.y;
      this.canvas.requestRenderAll();
      this.lastPos = { x: toX, y: toY };
    }
  }

  private initImage() {
    this.loading = true;
    this.imageElement = new Image();
    this.imageElement.src = this.photo.filePath;
    this.imageElement.crossOrigin = 'Anonymous';
    this.imageElement.onload = () => {
      this.loading = false;
      this.setCanvasImage();
    };
  }

  private async setCanvasImage() {
    this.resetCanvasViewport();
    const bgImage = new fabric.Image(this.imageElement, {
      originX: 'left',
      originY: 'top',
      crossOrigin: 'anonymous',
    });

    const imageTextureSize = bgImage.width > bgImage.height ? bgImage.width : bgImage.height;
    if (imageTextureSize > fabric.textureSize) {
      fabric.textureSize = imageTextureSize;
    }

    const size = bgImage.getOriginalSize();
    this.canvas.setWidth(size.width);
    this.canvas.setHeight(size.height);

    const greyScaleFilter = new fabric.Image.filters.Grayscale();
    const saturationFilter = new fabric.Image.filters.Saturation({ saturation: -0.5 });
    // const customRedFilter = this.customRedFilter();
    // bgImage.filters.push(greyScaleFilter);
    // bgImage.applyFilters();
    this.canvas.setBackgroundImage(bgImage, this.canvas.renderAll.bind(this.canvas));

    // bgImage.clone((overlayImage: fabric.Image) => {
    //   const removeNotRedFilter = new fabric.Image.filters.RemoveColor({ color: 'rgb(0,255,255)', distance: 0.8 });
    //   overlayImage.filters = [removeNotRedFilter];
    //   overlayImage.applyFilters();
    //   this.canvas.setOverlayImage(overlayImage, this.canvas.renderAll.bind(this.canvas));
    // });

    // await this.photoshopService.applyRedFilter(this.photo.id).toPromise();
  }

  private getColourMatrixFilter() {
    const lumR = 0.3086;
    const lumG = 0.6094;
    const lumB = 0.082;

    const str = 1;
    const stg = 0;
    const stb = 0;
    const sr = (1 - str) * lumR;
    const sg = (1 - stg) * lumG;
    const sb = (1 - stb) * lumB;

    return new fabric.Image.filters.ColorMatrix({
      // prettier-ignore
      // matrix: [
      //   sr+str, sr, sr, 0, 0, // R
      //   sg, sg+stg, sg, 0, 0, // G
      //   sb, sb, sb+stb, 0, 0, // B
      //   0, 0, 0, 1, 0  // A
      // ],
      // prettier-ignore
      matrix: [
        1, 0, 0, 0, -100, // R
        0, 0, 0, 0, 0, // G
        0, 0, 0, 0, 0, // B
        0, 0, 0, 1, 0  // A
      ],
    });
  }

  // private customRedFilter() {
  //   const SwapColor = fabric.util.createClass(fabric.Image.filters.BaseFilter, {
  //     type: 'SwapColor',

  //     fragmentSource:
  //       'precision highp float;\n' +
  //       'uniform sampler2D uTexture;\n' +
  //       'uniform vec4 colorSource;\n' +
  //       'uniform vec4 colorDestination;\n' +
  //       'varying vec2 vTexCoord;\n' +
  //       'void main() {\n' +
  //       'vec4 color = texture2D(uTexture, vTexCoord);\n' +
  //       'vec3 delta = abs(colorSource.rgb - color.rgb);\n' +
  //       'gl_FragColor = length(delta) < 0.02 ? colorDestination.rgba : color;\n' +
  //       '}',

  //     colorSource: 'rgb(255, 0, 0)',

  //     colorDestination: 'rgb(0, 0, 255)',

  //     applyTo2d: function (options) {
  //       const imageData = options.imageData;
  //       const data = imageData.data;
  //       const source = new fabric.Color(this.colorSource).getSource();
  //       const destination = new fabric.Color(this.colorDestination).getSource();
  //       for (let i = 0; i < data.length; i += 4) {
  //         if (data[i] === source[0] && data[i + 1] === source[1] && data[i + 2] === source[2]) {
  //           data[i] = destination[0];
  //           data[i + 1] = destination[1];
  //           data[i + 2] = destination[2];
  //         }
  //       }
  //     },

  //     getUniformLocations: function (gl, program) {
  //       return {
  //         uColorSource: gl.getUniformLocation(program, 'colorSource'),
  //         uColorDestination: gl.getUniformLocation(program, 'colorDestination'),
  //       };
  //     },

  //     sendUniformData: function (gl, uniformLocations) {
  //       const source = new fabric.Color(this.colorSource).getSource();
  //       const destination = new fabric.Color(this.colorDestination).getSource();
  //       source[0] /= 255;
  //       source[1] /= 255;
  //       source[2] /= 255;
  //       destination[0] /= 255;
  //       destination[1] /= 255;
  //       destination[2] /= 255;
  //       gl.uniform4fv(uniformLocations.uColorSource, source);
  //       gl.uniform4fv(uniformLocations.uColorDestination, destination);
  //     },

  //     isNeutralState: function () {
  //       return this.colorSource === this.colorDestination;
  //     },

  //     toObject: function () {
  //       return fabric.util.object.extend(this.callSuper('toObject'), {
  //         colorSource: this.colorSource,
  //         colorDestination: this.colorDestination,
  //       });
  //     },
  //   });

  //   SwapColor.fromObject = fabric.Image.filters.BaseFilter.fromObject;

  //   return new SwapColor();
  // }

  private resetCanvasViewport() {
    this.canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
  }
}
