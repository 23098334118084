import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { CampaignPreviewRecipient } from '@models/retention-campaigns/campaign-preview-recipient';
import { RecipientRequestType } from '@models/retention-campaigns/recipient-request-types';
import { RetentionCampaignsService } from '@services/retention-campaigns.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-campaign-recipients',
  templateUrl: './campaign-recipients.component.html',
  styleUrls: ['./campaign-recipients.component.less'],
})
export class CampaignRecipientsComponent implements OnInit {
  unsub: Subject<void> = new Subject();
  @Input() campaignId: number;
  @Input() title: string;
  @Input() recipientRequestType: RecipientRequestType;

  skip = 0;
  loading = false;
  campaignRecipients: CampaignPreviewRecipient[];
  errors: any[] = [];
  totalRecipients: number = null;
  titleAndRecipients: string = '';

  constructor(private retentionCampaignsService: RetentionCampaignsService) {}

  ngOnInit() {
    this.getRecipients()
  }

  getRecipients() {
    if (!this.campaignId) return;
    // Clear out existing errors
    this.errors = [];
    this.campaignRecipients = [];
    this.titleAndRecipients = 'Loading Recipients...';
    this.totalRecipients = 0;
    this.loading = true;

    var getRecipientsFunction = this.retentionCampaignsService.getRecipientsForCampaignPreview(this.campaignId);
    if (this.recipientRequestType == RecipientRequestType.PreviousRun)
      getRecipientsFunction = this.retentionCampaignsService.getPreviousRecipientsForCampaign(this.campaignId);

    getRecipientsFunction.subscribe(
      (result) => {
        this.campaignRecipients = result;
        if (!this.totalRecipients || this.totalRecipients != result.length) {
          this.totalRecipients = this.campaignRecipients.length;
          if (this.title) this.titleAndRecipients = this.title + ` - ${this.totalRecipients.toLocaleString()} Total`;
          else this.titleAndRecipients = `${this.totalRecipients.toLocaleString()} Total Recipients`;
        }
        this.loading = false;
      },
      (errorResponse: HttpErrorResponse) => {
        this.errors = errorResponse.error.errors;
        this.loading = false;
      }
    );
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
