<div class="teal-font smaller">Add Text Template</div>
<mat-form-field 
  class="apply-text-field w-100"
  [floatLabel]="'never'">
    <mat-label>Select</mat-label>
    <mat-select [(ngModel)]="selectedTextTemplate">
      <mat-option *ngFor="let textTemplate of textTemplates" [value]="textTemplate">
        {{textTemplate.name}}
      </mat-option>
    </mat-select>
</mat-form-field>
<div class="text-right">
  <button 
    type="button" 
    class="btn em-btn-green white-font"
    [disabled]="!selectedTextTemplate"
    (click)="applyTextTemplate()">
      APPLY
  </button>
  <button 
    type="button" 
    class="btn em-btn-green white-font ml-2"
    (click)="closeTemplateContent.next()">
      CLOSE
  </button>
</div>