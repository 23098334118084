import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AuthModule } from '@app/auth/auth.module';
import { SidenavModule } from '@app/sidenav/sidenav.module';
import { ChatModule } from '@progress/kendo-angular-conversational-ui';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { EditorModule } from 'primeng/editor';
import { SharedModule } from '../shared/shared.module';
import { EditChannelComponent } from './dialogs/edit-channel/edit-channel.component';
import { EditUserComponent } from './dialogs/edit-user/edit-user.component';
import { MessageComposeComponent } from './messaging-panel/messaging-feed/message-compose/message-compose.component';
import { MessagingFeedComponent } from './messaging-panel/messaging-feed/messaging-feed.component';
import { MessagingHeaderSearchComponent } from './messaging-panel/messaging-header/messaging-header-search/messaging-header-search.component';
import { MessagingHeaderComponent } from './messaging-panel/messaging-header/messaging-header.component';
import { MessagingPanelComponent } from './messaging-panel/messaging-panel.component';
import { MessagingSidebarChannelsComponent } from './messaging-panel/messaging-sidebar/messaging-sidebar-channels/messaging-sidebar-channels.component';
import { MessagingSidebarConversationsComponent } from './messaging-panel/messaging-sidebar/messaging-sidebar-conversations/messaging-sidebar-conversations.component';
import { MessagingSidebarHeaderComponent } from './messaging-panel/messaging-sidebar/messaging-sidebar-header/messaging-sidebar-header.component';
import { MessagingSidebarComponent } from './messaging-panel/messaging-sidebar/messaging-sidebar.component';
import { ConversationsSidebarComponent } from './patient-messaging/conversations-sidebar/conversations-sidebar.component';
import { PatientMessagingComponent } from './patient-messaging/patient-messaging.component';
import { ChatChannelsService } from './services/chat-channels.service';
import { ChatMessagesService } from './services/chat-messages.service';
import { ChatUsersService } from './services/chat-users.service';
import { MessagingSignalrService } from './services/messaging-signalr.service';

@NgModule({
  declarations: [
    MessagingPanelComponent,
    MessagingSidebarComponent,
    MessagingSidebarHeaderComponent,
    MessagingSidebarChannelsComponent,
    MessagingSidebarConversationsComponent,
    MessagingHeaderComponent,
    MessagingHeaderSearchComponent,
    MessagingFeedComponent,
    MessageComposeComponent,
    EditChannelComponent,
    EditUserComponent,
    PatientMessagingComponent,
    ConversationsSidebarComponent,
  ],
  imports: [
    SharedModule,
    EditorModule,
    MatMenuModule,
    MatListModule,
    MatPaginatorModule,
    AuthModule,
    ChatModule,
    SidenavModule,
    InputsModule,
  ],
  exports: [MessagingPanelComponent, PatientMessagingComponent],
  providers: [ChatUsersService, ChatChannelsService, ChatMessagesService, MessagingSignalrService],
})
export class MessagingModule {}
