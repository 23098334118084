import { Directive, Input, ElementRef, AfterViewInit, AfterContentInit } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[autoFocus]'
})
export class AutofocusDirective implements AfterContentInit {

  @Input() public autoFocus: boolean;

  public constructor(private el: ElementRef) { }

  public ngAfterContentInit() {
    this.el.nativeElement.focus();
  }

}
