<div #toolbarArea class="row section-photo-toolbar justify-content-end">
  <div class="col-9">
    <div class="row navy-background photo-toolbar align-items-center py-2">
      <ngx-loading [show]="loading"></ngx-loading> 
      <div
        class="col-12 d-flex justify-content-between pr-0 text-white align-items-center"
        [ngClass]="{ 'justify-content-between': !seriesMode, 'justify-content-end': seriesMode }"
      >
      <div *ngIf="!currentPhoto" style="height:40px"></div>
        <div *ngIf="!seriesMode" class="col-auto pr-0 text-center">
          <div *ngIf="currentPhoto" class="row flex-nowrap w-100">
            <div class="col-6 px-0 d-flex align-items-center justify-items-start">
              <label class="m-0"><b>Name:</b></label>
              <input *ngIf="adminMode && patient"
                class="flex-nowrap editable-div w-100" 
                disabled="true"
                placeholder="Cannot Edit: Consent Gallery Mode"
              />
              <input *ngIf="!adminMode || !patient"
                class="flex-nowrap editable-div w-100"
                #imageNameInput
                [(ngModel)]="currentPhoto.imageName"
                placeholder="Enter image name..."
                (input)="changedData()"
              />
            </div>
            <div class="col-6 d-flex justify-content-between pr-0 text-white align-items-center">
              <kendo-datepicker [disabled]="adminMode && patient != null"
                *ngIf="currentPhoto.dateTaken"
                class="emr-datepicker kendo-date w-100"
                placeholder="Image Date"
                [format]="'yyyy-MM-dd'"
                [(value)]="photoDate"
                (valueChange)="photoDateChanged($event)"
              >
              </kendo-datepicker>
            </div>
          </div>
          <div *ngIf="currentPhoto" class="row flex-nowrap w-100">
            <label class="m-0"><b>Notes:</b></label>
            <input *ngIf="adminMode && patient"
            class="flex-nowrap editable-div   w-100" 
            disabled="true"
            placeholder="Cannot Edit: Consent Gallery Mode"
          />
            <input *ngIf="!adminMode || !patient"
              class="flex-nowrap editable-div w-100"
              [(ngModel)]="currentPhoto.notes"
              placeholder="Enter image notes..."
              (input)="changedData()"
            />
          </div>
        </div>
        <div class="col tags-container px-0">
          <div class="d-flex">
            <div class="">
              <mat-select
                class="select-header select-sharing"
                [(value)]="currentPhoto.photoConsentTypeId"
                placeholder="Sharing: None"
              >
                <mat-select-trigger>Sharing: {{CONSENT_TYPES[currentPhoto.photoConsentTypeId]}}</mat-select-trigger>
                <mat-option *ngFor="let consentType of consentMap" value={{consentType.value}}>{{consentType.title}}</mat-option>
              </mat-select>
            </div>
            <div class="ml-4 d-flex flex-nowrap">
              <mat-select
                class="select-header select-tags"
                multiple
                placeholder="Services"
              >
                <mat-select-trigger>Service</mat-select-trigger>
                <mat-option >Example</mat-option>
              </mat-select>
              <div class="tag-list-container">
                <span>Service 1</span>
                <span>Service Withalongname</span>
                <span>Service 3</span>
              </div>
            </div>
            <div class="ml-4 d-flex flex-nowrap">
              <mat-select
                class="select-header select-tags"
                multiple
                placeholder="Body Parts"
              >
                <mat-select-trigger>Body Part</mat-select-trigger>
                <mat-option >Example</mat-option>
              </mat-select>
              <div class="tag-list-container">
                <span>Body Part 1</span>
                <span>Body Part Withalongname</span>
                <span>Body Part 3</span>
              </div>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-auto ml-auto">
              <div class="d-inline-flex ml-auto justify-self-end">
                <div class="fake-pill">
                  CoolSculpting <i class="ml-1 fa fa-times"></i>
                </div>
                <div class="fake-pill">
                  Back <i class="ml-1 fa fa-times"></i>
                </div>
                <div class="fake-pill">
                  Cheeks <i class="ml-1 fa fa-times"></i>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <!-- <div *ngIf="!seriesMode" class="col d-flex justify-content-start flex-column">
          <div *ngIf="currentPhoto" class="flex-nowrap d-flex">
            <div
              class="truncate-ellisis"
              kendoTooltip
              tooltipClass="em-tooltip"
              position="top"
              [title]="currentPhoto?.tags?.length > 0 ? getCurrentPhotoTags(currentPhoto.tags) : 'No tags yet'"
            >
              <b>Tags:</b> {{ currentPhoto?.tags?.length > 0 ? getCurrentPhotoTags(currentPhoto.tags) : 'No tags yet' }}
            </div>
            <button
              type="button"
              *ngIf="!editingImageTags && (!adminMode || !patient)"
              class="em-btn btn-clean"
              style="line-height: normal"
              (click)="editingImageTags = true"
              title="Edit image tags..."
            >
              <i class="far fa-plus-circle"></i>
            </button>
          </div> 
        </div> -->
        <div class="col-auto d-flex pl-0 flex-nowrap justify-content-end"> 
          <ng-container *ngIf="!seriesMode; else saveButtons">
            <div ngbDropdown class="d-flex">
              <button
                kendoTooltip
                tooltipClass="em-tooltip"
                position="top"
                title="Add Patient Photo(s)"
                *ngIf="false"
                class="btn em-btn em-btn-green ml-2"
                (click)="uploadPhoto()"
              >
                <i class="far fa-plus"></i>
              </button>
              <!-- <button
                kendoTooltip
                tooltipClass="em-tooltip"
                position="top"
                *ngIf="patient"
                ngbDropdownToggle
                type="button"
                title="Create Series"
                class="btn em-btn btn-clean px-1"
                style="border: 1px solid white"
              ></button> -->
              <button
                kendoTooltip
                tooltipClass="em-tooltip"
                position="top"
                title="Create Before & After"
                *ngIf="false"
                class="btn em-btn em-btn-green ml-2"
                (click)="createBeforeAfter()"
              >
                <i class="far fa-images"></i>
              </button>
            </div>
          </ng-container>
          <ng-template #saveButtons>
            <div  *ngIf="!(adminMode && patient)">
              <button
              class="btn em-btn em-btn-green mr-2"
              kendoTooltip
              tooltipClass="em-tooltip"
              position="top"
              title="Save Series"
              [disabled]="photoSeriesList?.length < 2"
              (click)="onSave()"
            >
              <i class="far fa-save"></i>
            </button>
            <button
              kendoTooltip
              tooltipClass="em-tooltip"
              position="top"
              title="Cancel Series Creation"
              class="btn em-btn em-btn-green"
              (click)="onCancel()"
            >
              <i class="far fa-times"></i>
            </button>
            </div>
            
          </ng-template>

          <!-- <div ngbDropdown *ngIf="currentPhoto && !seriesMode && !(adminMode && patient)" >
            <button
              kendoTooltip
              tooltipClass="em-tooltip"
              position="top"
              ngbDropdownToggle
              type="button"
              title="Sharing Settings"
              class="btn em-btn em-btn-green ml-2 pr-1"
            >
              <i
                class="fas fa-user-check neg-icon-container"
                *ngIf="currentPhoto.photoConsentTypeId == CONSENT_TYPES.None"
              >
                <i class="fa fa-ban neg-icon-nested"></i>
              </i>
              <i *ngIf="currentPhoto.photoConsentTypeId == CONSENT_TYPES.Internal" class="fas fa-user-check"></i>
              <i
                *ngIf="currentPhoto.photoConsentTypeId == CONSENT_TYPES.InternalExternal"
                class="fas fa-check-double"
              ></i>
            </button>
            <div ngbDropdownMenu aria-labelledby="Sharing" class="p-1">
              <button
                kendoTooltip
                tooltipClass="em-tooltip"
                position="top"
                title="Do not share anywhere"
                (click)="
                  loading = true; currentPhoto.photoConsentTypeId = CONSENT_TYPES.None; updateMetaData().toPromise()
                "
                class="btn em-btn em-btn-green w-100 mb-1"
              >
                <i
                  class="fa{{
                    currentPhoto.photoConsentTypeId == CONSENT_TYPES.None ? 's' : 'r'
                  }} fa-user-check mr-1 neg-icon-container"
                >
                  <i class="fa fa-ban neg-icon-nested"></i>
                </i>
                No Sharing
              </button>
              <button
                kendoTooltip
                tooltipClass="em-tooltip"
                position="top"
                title="Share within EmilyEMR"
                (click)="
                  loading = true; currentPhoto.photoConsentTypeId = CONSENT_TYPES.Internal; updateMetaData().toPromise()
                "
                class="btn em-btn em-btn-green w-100 mb-1"
              >
                <i
                  class="fa{{
                    currentPhoto.photoConsentTypeId == CONSENT_TYPES.Internal ? 's' : 'r'
                  }} fa-user-check mr-1"
                ></i
                >Internal Sharing
              </button>
              <button
                kendoTooltip
                tooltipClass="em-tooltip"
                position="top"
                title="Share anywhere"
                (click)="
                  loading = true;
                  currentPhoto.photoConsentTypeId = CONSENT_TYPES.InternalExternal;
                  updateMetaData().toPromise()
                "
                class="btn em-btn em-btn-green w-100 mb-1"
              >
                <i
                  class="fa{{
                    currentPhoto.photoConsentTypeId == CONSENT_TYPES.InternalExternal ? 's' : 'r'
                  }} fa-check-double mr-1"
                ></i
                >External Sharing
              </button>
            </div>
          </div> -->

          <button
            *ngIf="currentPhoto && !seriesMode"
            kendoTooltip
            tooltipClass="em-tooltip"
            position="top"
            title="Download Photo"
            class="btn em-btn em-btn-green ml-2 hide-on-small"
            (click)="downloadPhoto(currentPhoto)"
          >
            <a type="button" style="color: white">
              <i class="fal fa-download"></i>
            </a>
          </button>

          <div ngbDropdown *ngIf="currentPhoto && !seriesMode" #emailDropdown>
            <button
              *ngIf="currentPhoto && !seriesMode"
              ngbDropdownToggle
              type="button"
              kendoTooltip
              tooltipClass="em-tooltip"
              position="top"
              title="Email Photo"
              class="btn em-btn em-btn-green ml-2 email-photo-button"
            >
              <a type="button" style="color: white">
                <i class="fal fa-envelope"></i>
              </a>
            </button>
            <div ngbDropdownMenu aria-labelledby="Email" class="p-1">

              <div class="d-flex flex-nowrap grey-two-background" *ngIf="showEmailMessage">
                Email sent...
              </div>
              <div class="d-flex flex-nowrap" *ngIf="!showEmailMessage">
                <input placeholder="example@email.com" #emailInput class="w-100" [value]="patient?.email" />
                <button
                  [disabled]="!emailInput || !emailInput?.value || emailInput?.value == ''"
                  (click)="emailPhoto(currentPhoto, emailInput.value)"
                  kendoTooltip
                  tooltipClass="em-tooltip"
                  position="top"
                  title="Send as email"
                  class="btn em-btn em-btn-green smaller ml-1"
                >
                  <i class="fas fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>

          <button
            kendoTooltip
            tooltipClass="em-tooltip"
            position="top"
            [title]="adminMode && patient ? 'Cannot delete in consult mode' : 'Delete Photo'"
            *ngIf="currentPhoto && patient && !seriesMode"
            class="btn em-btn em-btn-green ml-2"
            [disabled]="adminMode && patient"
            (click)="deletePhoto(currentPhoto)"
          >
            <i class="far fa-trash-alt"></i>
          </button>
          <!-- <button
            *ngIf="currentPhoto && !seriesMode"
            kendoTooltip tooltipClass="em-tooltip" position="top" title="Favourite Photo"
            class="btn em-btn em-btn-green ml-2 hide-on-small" 
            (click)="favouriteToggle(currentPhoto)"
          >
            <a type="button"  style="color:white"   >
              <i   
              [ngClass]="{'fal' : !currentPhoto.isFavourite, 'fas' : currentPhoto.isFavourite, 'fa-heart': true, 'calendar-red-font' : currentPhoto.isFavourite }"
            ></i>
            </a> 
          </button> -->
        </div>
      </div>
    </div>
  </div>
</div>
<div
  #tagEditor
  class="image-tag-editor"
  *ngIf="editingImageTags && currentPhoto"
  [ngStyle]="{ top: toolbarArea?.style?.height + 'px', width: getSidenavWidth() + 'px' }"
>
  <div class="d-flex flex-nowrap justify-content-between p-2">
    <div class="align-self-center">
      <tag-input
        (onAdd)="updateMetaData().toPromise()"
        (onRemove)="updateMetaData().toPromise()"
        [(ngModel)]="currentPhoto.tags"
        name="tags"
        [inputClass]="'tag-input'"
        #input
        [displayBy]="'title'"
        [onlyFromAutocomplete]="true"
      >
        <ng-template let-item="item" let-index="index">
          <div class="tag-pill" [ngStyle]="{ 'border-color': pillColours[item.type] }">
            <ng-container *ngIf="item.title; else text">
              {{ item.title }}
            </ng-container>
            <ng-template #text>
              {{ item.display }}
            </ng-template>
            <delete-icon
              *ngIf="
                item.tagId.indexOf('Consent-') == -1 &&
                item.tagId.indexOf('Avatar-0') == -1 &&
                !currentPhoto.isStockPhoto &&
                (!currentPhoto?.isSeries || item.type != PhotoTypeTag)
              "
              (click)="input.removeItem(item, index)"
            ></delete-icon>
          </div>
        </ng-template>
        <tag-input-dropdown
          [focusFirstElement]="true"
          [autocompleteItems]="filteredTagList"
          [zIndex]="99999"
          [displayBy]="'title'"
          [identifyBy]="'tagId'"
          [showDropdownIfEmpty]="true"
          [keepOpen]="false"
        >
        </tag-input-dropdown>
      </tag-input>
    </div>
    <button
      class="btn em-btn em-btn-green btn-sm align-self-start"
      style="height: 2em; width: 2em"
      (click)="editingImageTags = false"
    >
      <i class="far fa-times"></i>
    </button>
  </div>
</div>
<div class="overlay-button-container pt-2"
     [ngStyle]="{ top: toolbarArea?.style?.height + 'px', width: getSidenavWidth() + 'px' }"
>
  <button
    *ngIf="currentPhoto && !seriesMode"
    kendoTooltip tooltipClass="em-tooltip" position="top" title="Favourite Photo"
    class="btn em-btn em-btn-green ml-2" 
    (click)="favouriteToggle()"
  >
    <a type="button"  style="color:white"   >
      <i   
      [ngClass]="{'fal' : !currentPhoto.isFavourite, 'fas' : currentPhoto.isFavourite, 'fa-heart': true, 'calendar-red-font' : currentPhoto.isFavourite }"
    ></i>
    </a> 
  </button>
</div>
