import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';

import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { PhotoEditingService } from '@services/photo-editing.service';
import { PhotoConsentType, PhotoConsentTitle } from '@models/photo/photo-consent-type';
import { TagService } from '@app/services/tag.service';

@Component({
  selector: 'app-photo-details',
  templateUrl: './photo-details.component.html',
  styleUrls: ['./photo-details.component.less']
})
export class PhotoDetailsComponent implements OnInit {
  @Input() photoSubscription: Subscription;
  @Input() editMode: boolean;
  @Input() showDelete = true;
  @Input() isGallery = false;
  @Input() photoCropped: boolean;
  @Input() photo: PhotoMetaData;
  @Input() editedPhoto: File;
  @Input() undoPhotoEdit: Subject<void>;
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() revert: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() initialized: EventEmitter<any> = new EventEmitter();

  loading = false;
  showDetails: boolean;
  editPhotoDetails: FormGroup;
  tagList: any[];
  pillColours = this.tagService.pillColours;
  photoConsentOptions: {title: PhotoConsentTitle, value: PhotoConsentType}[] = [];
  uplodaDateTime: Date;

  constructor(
    private photoEditingService: PhotoEditingService,
    private fb: FormBuilder,
    private tagService: TagService
  ) {
    this.photoCropped = false;
  }

  ngOnInit() {
    this.tagService.getAllTags().subscribe(tags => {
      this.tagList = tags;
      this.initialized.emit(true);
    });
    this.photoConsentOptions = Object.keys(PhotoConsentTitle).map(
      e => ({title: PhotoConsentTitle[e], value: PhotoConsentType[e]})
    );
  }

  initForm() {
    this.editPhotoDetails = this.fb.group({
      imageName: [this.photo.imageName, Validators.required],
      notes: [this.photo.notes],
      photoConsentTypeId: [this.photo.photoConsentTypeId],
      tags: [this.photo.tags],
      uploadDate: [this.photo.uploadDate],
    });

    if (this.isGallery) {
      this.editPhotoDetails.addControl('isGlobalSharedImage', new FormControl(this.photo.isGlobalSharedImage ? this.photo.isGlobalSharedImage : false));
    }

    // if there are pre-existing tags we need to remove them from the tag list, otherwise there will be duplicates and saving will error out
    this.photo.tags.forEach(t => {
      const tagIndex = this.tagList.findIndex(tag => tag.tagId === t.tagId);
      if (tagIndex >= 0) {
        this.tagList.splice(tagIndex, 1);
      }
    });
  }

  editPhoto() {
    this.initForm();
    this.uplodaDateTime = new Date(String(this.photo.uploadDate));
    this.editMode = true;
    this.photoEditingService.editPhoto(this.photo);
  }

  revertPhoto() {
    this.revert.emit();
  }

  saveChanges() {
    this.editPhotoDetails.controls['uploadDate'].patchValue(this.uplodaDateTime);
    this.save.emit(this.editPhotoDetails);
  }

  cancelChanges() {
    this.editMode = false;
    this.photoCropped = false;
    this.photoEditingService.cancelEdit();
  }

  undoImageChanges() {
    this.photoCropped = false;
    this.photoEditingService.undoPhotoChanges();
  }

  setPhotoConsent() {
    this.editPhotoDetails.value.photoConsentTypeId = (document.getElementById('photoConsentSelect') as any).value;
  }

  deletePhoto() {
    this.delete.emit(this.photo.id);
  }

  changeValue(event: Event) {
    const value = (event.target as HTMLInputElement).checked;
    this.editPhotoDetails.controls['isGlobalSharedImage'].patchValue(value);
  }
}
