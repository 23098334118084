import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { AddSquareCardComponent } from '@app/management/dialogs/add-square-card/add-square-card.component';
import { Patient } from '@models/patient';
import { Appointment } from '@models/appointments/appointment';
import { EmilySquareInfo } from '@models/square/emily-square-info';
import { SquareCard } from '@models/square/square-card';
import { SquareCardInfoRequest } from '@models/square/square-card-info-request';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SquareService } from '@services/square.service';
import { Subject } from 'rxjs';
import { FinanceService } from '@services/finance.service';

@Component({
  selector: 'app-square-card',
  templateUrl: './square-card.component.html',
  styleUrls: ['./square-card.component.less'],
})
export class SquareCardComponent implements OnInit, OnDestroy {
  // appId: string; // = 'sandbox-sq0idb-Efwo4_GFd3cS7tAeGNPPcg';
  // locationId: string; // = 'LHC0SRJ7A5AJS';
  emilySquareInfo: EmilySquareInfo;

  private _patient: Patient;
  @Input() set patient(patient: Patient) {
    this._patient = patient;
    this.ngOnInit();
  }
  get patient() {
    return this._patient;
  }
  @Input() minimumExpiryDate: Date = new Date(Date.now());
  @Input() selectedSquareCardId: string;
  @Input() requestCreditCard: boolean = false;
  @Input() showRequestCreditCardButton: boolean = false;
  @Input() appointment: Appointment;

  @Output() cardSelectedEvent: EventEmitter<SquareCard> = new EventEmitter<SquareCard>();
  @Output() cardAddedEvent: EventEmitter<SquareCardInfoRequest> = new EventEmitter<SquareCardInfoRequest>();
  @Output() requestCreditCardChangedEvent: EventEmitter<any> = new EventEmitter<any>();

  private card: any;
  squareCards: SquareCard[];
  selectedSquareCard: SquareCard;

  showAllCards: boolean = true;

  private unsub = new Subject<any>();
  loading = false;
  isDisabled: boolean = false;

  constructor(
    private squareService: SquareService,
    private modalService: NgbModal,
    private financeService: FinanceService
  ) {}

  async ngOnInit() {
    if (this.patient != null) {
      this.loading = true;
      this.squareService.getCardsOnFile(this.patient?.patientId).subscribe((cards) => {
        this.squareCards = cards;
        if (this.selectedSquareCardId) {
          this.selectedSquareCard = this.squareCards.find((s) => s.id === this.selectedSquareCardId);
          this.cardSelectedEvent.emit(this.selectedSquareCard);
        }
        this.loading = false;
      });
    }
  }

  ngOnDestroy() {
    this.unsub.next(true);
    this.unsub.complete();
  }

  onCardSelected(event: MatRadioChange) {
    this.requestCreditCard = false;
    this.requestCreditCardChangedEvent.emit(this.requestCreditCard);
    this.cardSelectedEvent.emit(event.value);
    // Clear out the manual card entry
    if (this.card) this.card.clear();
    this.cardAddedEvent.emit(null);
  }

  onRequestCreditCardChangedEvent(event) {
    this.requestCreditCard = !this.requestCreditCard;
    if (this.requestCreditCard) {
      this.selectedSquareCard = null;
      this.selectedSquareCardId = null;
    }
    this.requestCreditCardChangedEvent.emit(this.requestCreditCard);
  }

  compareExpiryToMinimum(card: SquareCard) {
    return (
      card.exp_year < this.minimumExpiryDate.getUTCFullYear() ||
      (card.exp_year == this.minimumExpiryDate.getUTCFullYear() &&
        card.exp_month < this.minimumExpiryDate.getUTCMonth() + 1)
    );
  }

  openAddNewCardModal() {
    const modalRef = this.modalService.open(AddSquareCardComponent, { centered: true });
    modalRef.componentInstance.patient = this.patient;
    modalRef.result.then((newCard) => {
      // save the new card
      if (this.squareCards) this.squareCards.push(newCard);
      else this.squareCards = [newCard];

      // Check if entered card has valid expiry for appointment
      if (!this.compareExpiryToMinimum(newCard)) this.selectedSquareCard = newCard;
      this.cardSelectedEvent.emit(newCard);
    });
  }
  openRequestNewCardModal() {
    this.financeService.requestCreditCardModal(this.patient?.patientId, this.appointment?.appointmentId);
  }
}
