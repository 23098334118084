import { MasterOverlayService } from '@services/actionpanel.service';
 
import { PhotoFilterComponent } from './../../shared-photos/photo-filter/photo-filter.component';
import { ImageService } from '@services/image.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { Subject, Subscription, throwError } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';

import { PhotoEditingService } from '@app/services/photo-editing.service';
import { PhotoEventType } from '@app/models/photo/photo-event-type';
import { PhotoMetaData } from '@app/models/photo/photo-meta-data';
import { ConfirmUnsavedChangesDialogComponent } from '@app/management/dialogs/confirm-unsaved-changes/confirm-unsaved-changes.component';
import * as moment from 'moment';
import { PhotoConsentType } from '@models/photo/photo-consent-type';
import { PhotoSeriesService } from '@services/photo-series.service';
import { SeriesType } from '@models/photo/series-type';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';

@Component({
  selector: 'app-photo-gallery-consented',
  templateUrl: './photo-gallery-consented.component.html',
  styleUrls: ['./photo-gallery-consented.component.less'], 
})
export class PhotoGalleryConsentedComponent implements OnInit, OnDestroy {
  _consentedImages: PhotoMetaData[];
  patientPhotos: any[];
  unsub: Subject<void> = new Subject<void>();
  photoEditingSubscription: Subscription;
  editingPhoto: boolean;
  routedPhotoId: number;
  selectedPhoto: PhotoMetaData;
  selectedSeriesPhotos = {};
  photosSeriesSet = new Set();
  patientId: number;
  SeriesType = SeriesType;
  @Input() isNewPhotoSeries: boolean;
  _seriesMode: boolean = false;


  @Input() showStockImages = false; //in prep for using this in non-consultation gallery as i expect we might
  @Input() set seriesMode(seriesMode: boolean){
    this.selectedSeriesPhotos = {};
    this.photosSeriesSet = new Set();
    this._seriesMode = seriesMode;

  } 
  @Input() filterComponent: PhotoFilterComponent;

  get seriesMode(){
    return this._seriesMode;
  }


  @Input() photoSeriesList: PhotoMetaData[];
  @Input() newItemId: number;
  @Input() seriesType: SeriesType;
  @Input()
  set consentedImages(consentedImages: PhotoMetaData[]) {
    this._consentedImages = consentedImages;
    if (this._consentedImages.length) {
      this.patientPhotos = [];
      this.transformPhotoData();
      this.updateSelectedPhoto();
      if (this.lazyLoadedImages && this.lazyLoadedImages.length > 0){
        this.lazyLoadedImages.forEach((image) => {
          this.imageObserver.unobserve(image);
        });
      }
      setTimeout(() => {
        this.lazyLoadedImages = Array.from(document.querySelectorAll(".lazy"));
        this.lazyLoadedImages.forEach((image) => {
          this.imageObserver.observe(image);
        });
      });
    } else {
      if (!this.seriesMode) {
        this.photoEditingService.loadPhoto(null);
      }
    }
  }

  get consentedImages() {
    return this._consentedImages;
  }

  lazyLoadedImages = [];
  imageObserver = null;
  constructor(
    private photoEditingService: PhotoEditingService,
    private confirmDialog: MatDialog,
    private photoSeriesService: PhotoSeriesService,
    private router: Router,
    private imageService: ImageService,
    private masterOverlayService: MasterOverlayService
  ) {
    this.editingPhoto = false;
    if ("IntersectionObserver" in window) {
      this.imageObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) =>{
          if (entry.isIntersecting) {
            var image = entry.target;
            image.classList.remove("lazy");
            this.imageObserver.unobserve(image);
          }
        });
      });
  
  
    }
  }



  ngOnInit() { 
    this.patientPhotos = [];

    this.photoEditingSubscription = this.photoEditingService.getPhotoSource()
    .pipe(takeUntil(this.unsub))
    .subscribe(photoEvent => {
      if (photoEvent.event === PhotoEventType.EditPhoto) {
        this.editingPhoto = true;
      } else if (photoEvent.event === PhotoEventType.CancelEditPhoto) {
        this.editingPhoto = false;
      }
      else if (photoEvent.event === PhotoEventType.LoadPhoto){
        this.selectedPhoto = photoEvent.photo;
      }
    });

    this.imageService.metadataUpdated$.pipe(takeUntil(this.unsub)).subscribe(image => {
      let indexToUpdate = this.consentedImages.findIndex(p => p.id === image.id && !p.isSeries);
      if (indexToUpdate > -1) this.consentedImages[indexToUpdate] = image;   
      this.selectedPhoto = image;
      this.transformPhotoData();
    });

    this.photoSeriesService.metadataUpdated$.pipe(takeUntil(this.unsub)).subscribe(image => {
      let indexToUpdate = this.consentedImages.findIndex(p => p.id === image.id && !p.isSeries);
      if (indexToUpdate > -1) this.consentedImages[indexToUpdate] = image;  
      this.selectedPhoto = image;
      this.transformPhotoData();
    });
  }

  private transformPhotoData() {
    const groups = this.consentedImages.reduce((groups, photo) => {
      const userName = photo.patientFullName;
      if (!groups[userName]) {
        groups[userName] = [];
      }
      groups[userName].push(photo);
      return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((userName) => {
      return {
        userName,
        photos: groups[userName]
      };
    });

    this.patientPhotos = groupArrays;
  }

  private updateSelectedPhoto(item?: PhotoMetaData) {
    this.patientId = 0;

    if (this.editingPhoto) {
      this.photosSeriesSet.clear();
      this.selectedSeriesPhotos = {};
      if (this.photoSeriesList && this.photoSeriesList.length) {
        this.patientId = this.photoSeriesList[0].patientId;
        this.consentedImages.map(photo => {
          this.photoSeriesList.map(selectedPhoto => {
            if (photo.id === selectedPhoto.id) {
              this.photosSeriesSet.add(photo);
              this.selectedSeriesPhotos[photo.id] = true;
            }
          });
        });
      }

      return;
    }

    if (this.newItemId) {
      const selectedPhoto = this.consentedImages.find(e => e.id === this.newItemId && e.isSeries);

      this.selectedPhoto = selectedPhoto;
      this.photoEditingService.loadPhoto(selectedPhoto);

      return;
    }

    if (this.isNewPhotoSeries) {
      this.selectedSeriesPhotos = {};

      if (!this.photoSeriesList.length) {
        this.photosSeriesSet.clear();
      } else {
        this.consentedImages.map(photo => {
          this.photoSeriesList.map(selectedPhoto => {
            if (photo.id === selectedPhoto.id) {
              this.selectedSeriesPhotos[photo.id] = true;
            }
          });
        });
        return;
      }

      const photo = new PhotoMetaData({
        isOriginal: true,
        uploadDate: moment(),
        modifiedDate: moment(),
        notes: '',
        photoConsentTypeId: PhotoConsentType.Internal,
        seriesType: this.seriesType,
        isSeries: true
      });

      this.selectedPhoto = photo;
      this.photoEditingService.loadPhoto(photo);

      return;
    }

    if (item) {
      this.photoEditingService.loadPhoto(item);
      this.selectedPhoto = item;
    } else {
      if (this.selectedPhoto) {
        const photoIndex = this.consentedImages.findIndex(i => i.id === this.selectedPhoto.id && i.isSeries === this.selectedPhoto.isSeries);
        this.selectedPhoto = photoIndex >= 0 ? this.consentedImages[photoIndex] : this.consentedImages[0];
        this.photoEditingService.loadPhoto(this.selectedPhoto);
      } else {
        this.photoEditingService.loadPhoto(this.patientPhotos[0].photos[0]);
        this.selectedPhoto = this.patientPhotos[0].photos[0];
      }
    }
  }

  public imageClicked(item: PhotoMetaData) {
    
    this.selectedPhoto = item;
    this.updateSelectedPhoto(item);
  }

  private showUnsavedChangesModal(item: PhotoMetaData) {
    const dialogRef = this.confirmDialog.open(ConfirmUnsavedChangesDialogComponent, {
        width: '250px'
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsub))
        .subscribe(result => {
          if (result === 'confirm') {
            this.editingPhoto = false;
            this.photoEditingService.cancelEdit();
            this.selectedPhoto = item;
            this.photoEditingService.loadPhoto(item);
          }
        });
  }
 
  page = 1;
  pageSize =10;
  onChangePage(addPage: number){
    this.page += addPage;
    const maxPage = Math.ceil(this.patientPhotos.length / this.pageSize);
    if (this.page <= 0)
      this.page = maxPage;
    else if (this.page > maxPage)
      this.page = 1;
  }

  public getUploadDatestr(photo: PhotoMetaData) {
    return moment(photo.uploadDate).format('YYYY-MM-DD');
  }

  public getDateTakenStr(photo: PhotoMetaData): string {
    return moment(photo.dateTaken).format('YYYY-MM-DD');
  }

  public imageSeriesClicked(item: PhotoMetaData) {
    if (this.isNewPhotoSeries && !this.photosSeriesSet.size) {
      this.patientId = 0;
    }

    if (this.patientId) {
      if (this.patientId !== item.patientId) {
        const dialogRef = this.confirmDialog.open(GenericDialogComponent, {
          width: '250px',
          data: {
            title: 'Warning',
            content: 'You can not add photo from another patient',
            confirmButtonText: 'Ok',
            showCancel: false
          }
        });

        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.unsub))
          .subscribe(result => {
            if (result === 'confirm') {
            }
          });

        return;
      }
    } else {
      this.patientId = item.patientId;
    }

    if (this.photosSeriesSet.has(item)) {
      this.photosSeriesSet.delete(item);
      this.selectedSeriesPhotos[item.id] = false;
    } else {
      if (this.seriesType === SeriesType.BeforeAfter && this.photosSeriesSet.size < 2) {
        this.selectedSeriesPhotos[item.id] = true;
        this.photosSeriesSet.add(item);
      }
      if (this.seriesType === SeriesType.Multi) {
        this.selectedSeriesPhotos[item.id] = true;
        this.photosSeriesSet.add(item);
      }
    }

    this.photoSeriesService.addPhotoToSeries(Array.from(this.photosSeriesSet));
  }

  public navigateToPatientPhotoTab(items: PhotoMetaData[]) {
    const patientId = items[0].patientId;
    this.router.navigate(['/gallery', { outlets: { 'action-panel': ['patient', patientId + '_patientprofiletab', 'patienttabs', 'patientphotostab'] } }]);
    this.masterOverlayService.masterOverlayEnabled.pipe(takeUntil(this.unsub)).subscribe(ovr => {
      if (!ovr){  //panel closed
        this.filterComponent.basicFilter();
      }
    });
  }

  patientShouldShow(patient: any){ //in prep for using this in non-consultation gallery as i expect we might
    return this.showStockImages || (patient.photos && patient.photos.findIndex(p => !p.isStockPhoto) != -1)
  }

  ngOnDestroy() {
    this.lazyLoadedImages.forEach((image) => {
      this.imageObserver.unobserve(image);
    });
    this.unsub.next();
    this.unsub.complete();
  }
}
