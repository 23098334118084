export class ConvergePayment {
  id: number;
  type: ConvergePaymentType;
  status: ConvergePaymentStatus;
  patientTokenId: number;
  amount: number;
  finalTotal?: number;
  response?: string;
}

export enum ConvergePaymentType {
  Payment = 0,
  Cancellation = 1,
}

export enum ConvergePaymentStatus {
  Reserved = 0,
  Pending = 1,
  Failed = 2,
  Completed = 3,
}
