<mat-expansion-panel [expanded]="true" class="print-element" [ngClass]="{ 'mobile-view': mobileView }">
  <ngx-loading [show]="isLoading"></ngx-loading>
  <div class="service-chart-color" [ngStyle]="{ background: chartEntry?.serviceIdColour }"></div>
  <mat-expansion-panel-header
    [collapsedHeight]="'auto'"
    [expandedHeight]="'auto'"
    [ngClass]="{ 'p-0': mobileView }"
    #panelHeader
    (click)="panelHeader._toggle()"
  >
    <mat-panel-title [ngClass]="{ 'w-100': mobileView }">
      <div class="d-flex flex-column panel-box px-3 pt-2 pb-2">
        <!-- Appointment Template -->
        <ng-container>
          <!-- Header row of sc entry -->
          <div class="d-flex flex-wrap mb-2">
            <div class="mr-3">
              <span *ngIf="chartEntry" class="font-weight-normal medium">
                {{ chartEntry?.date | convertUTC : true }}
              </span>
            </div>
            <div class="service-name mr-3">
              <span *ngIf="chartEntry">
                {{ chartEntry?.serviceName }}
              </span>
            </div>
            <div class="provider-name">
              <span *ngIf="chartEntry" class="font-weight-normal medium">
                {{ chartEntry?.serviceProviderName ? chartEntry?.serviceProviderName : chartEntry?.signedByUser }}
              </span>
            </div>
            <div class="d-flex ml-auto pl-3 entry-buttons">
              <ng-container *ngIf="chartEntry">
                <button
                  *ngIf="!mobileView && chartEntry?.appointmenteTreatmentForms?.length > 0"
                  (click)="openeTreatmentForm()"
                  class="btn btn-sm em-btn em-btn-green btn-clean mr-2 bigger chart-entry-button"
                  [ngStyle]="{ background: chartEntry?.serviceIdColour }"
                  type="button"
                >
                  <ng-container *ngIf="chartEntry?.serviceIcon; else noIcon">
                    <i [class]="chartEntry?.serviceIcon" title="Service Form"></i>
                  </ng-container>
                  <ng-template #noIcon>
                    <i class="far fa-calendar-plus" title="Service Form"></i>
                  </ng-template>
                </button>

                <button
                  *ngIf="
                    !mobileView &&
                    chartEntry?.serviceDetailTemplateId !== serviceDetailTemplate.None &&
                    chartEntry?.serviceIdColour
                  "
                  (click)="goToTheServiceDetail()"
                  class="btn btn-sm em-btn em-btn-green btn-clean mr-2 bigger chart-entry-button"
                  [ngStyle]="{ background: chartEntry?.serviceIdColour }"
                  type="button"
                  [attr.data-test-id]="'service-chart-details'"
                  [attr.data-test-value]="chartEntry?.appointmentId"
                >
                  <ng-container *ngIf="chartEntry?.serviceIcon; else noIcon">
                    <i [class]="chartEntry?.serviceIcon" title="Service Detail"></i>
                  </ng-container>
                  <ng-template #noIcon>
                    <i class="far fa-calendar-plus" title="Service Detail"></i>
                  </ng-template>
                </button>

                <button
                  [title]="showServiceDetail ? 'Collapse Text' : 'Expand Text'"
                  [disabled]="parseInt(existingNote.style.height, 10) <= 64"
                  type="button"
                  class="btn btn-sm em-btn em-btn-green btn-clean mr-2 bigger chart-entry-button"
                  (click)="showServiceDetail = !showServiceDetail; toggleTextArea()"
                >
                  <i style="font-size: 18px" [class]="showServiceDetail ? 'fas fa-caret-up' : 'fas fa-caret-down'"></i>
                </button>

                <ng-container *ngIf="!mobileView">
                  <button
                    *appAuthGuard="appointmentsPolicy"
                    type="button"
                    title="Reschedule appointment"
                    [attr.data-test-id]="'chart-schedule-button'"
                    [attr.data-test-value]="'pt-id-' + chartEntry?.appointmentId"
                    class="btn btn-sm em-btn em-btn-green btn-clean chart-entry-button mr-2 pt-id-{{
                      chartEntry?.appointmentId
                    }}"
                    (click)="clickRescheduleService()"
                  >
                    <i class="far fa-calendar"></i>
                  </button>
                  <button
                    [title]="!chartEntry?.isLocked ? 'Cancel Appointment' : 'Service Locked - Cannot Cancel'"
                    [disabled]="chartEntry?.isLocked"
                    type="button"
                    class="btn btn-sm em-btn em-btn-green btn-clean mr-2 bigger chart-entry-button"
                    (click)="cancelAppointment()"
                  >
                    <i class="far fa-ban"></i>
                  </button>
                </ng-container>

                <button
                  *ngIf="!chartEntry?.isLocked"
                  title="Entry Unlocked"
                  [disabled]="true"
                  type="button"
                  class="btn btn-sm em-btn em-btn-green btn-clean bigger chart-entry-button lock-btn"
                >
                  <i class="far fa-lock" title="Entry Unlocked"></i>
                </button>

                <button
                  *ngIf="chartEntry?.isLocked"
                  title="Unlock Entry"
                  (click)="onUnlockClick()"
                  type="button"
                  [attr.data-test-id]="'service-chart-unlock'"
                  [attr.data-test-value]="chartEntry?.appointmentId"
                  class="btn btn-sm em-btn em-btn-green btn-clean bigger chart-entry-button lock-btn"
                >
                  <i class="fas fa-unlock text-dark" title="Entry Locked"></i>
                </button>
              </ng-container>
            </div>
          </div>

          <!--Visit notes-->
          <ng-container *ngIf="chartEntry?.visitNotes">
            <div class="w-100">
              <span style="font-size: 0.7em">Visit Notes:</span><br />
              <div class="font-weight-normal px-2 pb-2 medium textarea-wrapper visit-notes grey-two-background w-100">
                {{ chartEntry?.visitNotes }}
              </div>
              <span style="font-size: 0.7em">Chart Notes:</span><br />
            </div>
          </ng-container>

          <!-- Main text area for sc entry -->
          <div class="d-flex flex-wrap negative-right-margin">
            <div
              class="d-flex align-items-center flex-column flex-grow-1 font-weight-normal mr-2 mb-2 medium textarea-wrapper"
              [ngClass]="{ 'w-100': mobileView }"
              (click)="onTextareaFocus()"
            >
              <textarea
                class="service-chart-input px-2 mx-2 w-100"
                [ngClass]="{
                  'collapsed-area': !showServiceDetail,
                  'mask-fade pointer-cursor': !showServiceDetail && parseInt(existingNote.style.height, 10) > 64,
                  'd-none': !chartEntry?.serviceNotes[0]?.note
                }"
                [value]="chartEntry?.serviceNotes[0]?.note ?? ''"
                [readonly]="true"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                [cdkAutosizeMinRows]="1"
                #existingNote
              ></textarea>
              <textarea
                class="service-chart-input active px-2 pb-2 mx-2 w-100"
                [placeholder]="'Add a note'"
                [ngClass]="{
                  'collapsed-area': !showServiceDetail && chartEntry?.isLocked,
                  'empty-chart-note': serviceNotesLength() === 0,
                  'mt-2': serviceNotesLength() > 0,
                  'd-none': chartEntry?.isLocked
                }"
                [value]="chartEntry?.serviceNotes[0]?.draft ?? ''"
                (keydown)="onTextareaKeyDown(); $event.stopPropagation()"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                [cdkAutosizeMinRows]="4"
                #newNote
              ></textarea>
            </div>
            <ng-container *ngFor="let photoDrawing of chartEntry?.photoDrawings">
              <img
                *ngIf="photoDrawing['delete'] !== true"
                class="img-thumb mr-2 mb-2"
                [src]="photoDrawing.photo.filePathThumb | safeResource"
                [alt]="photoDrawing.photo.imageName"
                (click)="openPhotoDrawing(photoDrawing)"
                role="button"
              />
            </ng-container>
          </div>
        </ng-container>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <!-- Observations sub-footer -->
  <div *ngIf="chartEntry?.details && chartEntry?.details.length" class="grey-two-background px-3 medium">
    <strong>Details</strong>
    <div class="opacity-60" *ngFor="let item of chartEntry?.details">
      {{ item.area }} {{ item.detail }} {{ item.unitDetail }}
    </div>
  </div>

  <!-- eForms -->
  <div *ngIf="chartEntry?.serviceForms && chartEntry?.serviceForms.length > 0" class="grey-two-background px-3 medium">
    <strong>eForms</strong>
    <div class="d-flex flex-column">
      <ng-container *ngFor="let serviceForm of chartEntry?.serviceForms; let index = index">
        <div class="smaller mr-3">
          <mat-checkbox
            (change)="
              currentFormsSelectionModel.toggle(serviceForm?.clinicForm?.id);
              onChangeCurrentFormsSelectionModel($event, serviceForm)
            "
            [checked]="isChecked(serviceForm)"
            [disabled]="(isChecked(serviceForm) && isSigned(serviceForm)) || mobileView"
            >{{ serviceForm?.clinicForm?.name }}&nbsp;&nbsp;<i
              class="far bigger-icon form-icon"
              [ngClass]="isSigned(serviceForm) ? 'fa-check spring-green-font' : 'fa-times calendar-red-font'"
            ></i>
          </mat-checkbox>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Footer of sc entry -->
  <div class="service-chart-detail px-3 py-2 grey-two-background d-flex align-items-center">
    <!--  Price stuff in bottom-left of sc entry ministry -->
    <div *ngIf="chartEntry?.governmentBilling && chartEntry?.dxCode" class="medium mr-5">
      <strong>Dx Code</strong>
      <span class="ml-2 opacity-60">{{ chartEntry?.dxCode }}</span>
    </div>
    <div *ngIf="chartEntry?.governmentBilling && chartEntry?.billingCodes?.length > 0" class="medium">
      <div *ngFor="let code of chartEntry?.billingCodes" class="d-flex billing-code">
        <strong>Billing Code</strong>
        <span class="ml-2 opacity-60">{{ code.billingCode }} x {{ code.serviceUnits }}</span>
      </div>
    </div>

    <!-- Price stuff in bottom-left of sc entry non-ministry -->
    <ng-container *ngIf="!chartEntry?.governmentBilling">
      <ng-container
        *ngIf="
          chartEntry?.serviceDetailTemplateId == serviceDetailTemplate.None &&
            chartEntry?.paymentStatus !== PaymentStatus.Paid &&
            chartEntry?.isLocked == false;
          else simpleServicePrice
        "
      >
        <div
          class="panel-box-column service-chart-price font-weight-bold py-1 mr-2 white-background"
          [ngClass]="{ 'border-danger': chartEntry?.price === null }"
        >
          <span class="font-weight-bold">$</span>
          <input
            #servicePriceInput="ngModel"
            type="text"
            size="6"
            [attr.data-test-id]="'appointment-price-' + chartEntry?.appointmentId"
            [attr.data-test-value]="chartEntry?.price"
            onlyNumber
            numericType="decimal"
            class="service-chart-input font-weight-bold navy-font"
            placeholder="Add price"
            [(ngModel)]="chartEntry.price"
          />
        </div>
        <button
          type="button"
          title="Save Service Price"
          [hidden]="!servicePriceInput.dirty"
          class="btn btn-sm em-btn em-btn-green btn-clean mr-2 bigger save-draft-button chart-entry-button"
          (click)="updateServicePrice(); servicePriceInput.reset(servicePriceInput.value)"
        >
          <i class="fas fa-save"></i>
        </button>
      </ng-container>
      <!--Show only the total price when the service is already paid.-->
      <ng-template #simpleServicePrice>
        <div
          class="panel-box-column font-weight-bold py-1"
          [attr.data-test-id]="'appointment-price-' + chartEntry?.appointmentId"
          [attr.data-test-value]="chartEntry?.total"
        >
          {{ chartEntry?.total | currency }}
        </div>
      </ng-template>
      <!-- Show the multiples info if its a multiple. -->
      <ng-container *ngIf="chartEntry?.isPlannedTreatmentMultiple">
        <div class="panel-box-column font-weight-bold py-1">
          <span>({{ chartEntry?.plannedTreatmentRank }} of {{ chartEntry?.plannedTreatmentMultipleQuantity }}) </span>
          <span *ngIf="chartEntry?.plannedTreatmentMultipleNotes != ''">
            - {{ chartEntry?.plannedTreatmentMultipleNotes }}</span
          >
        </div>
      </ng-container>
    </ng-container>

    <!-- Save, Sign, and Sign+Close buttons -->
    <div *ngIf="chartEntry && !chartEntry.isLocked" class="btn-group emr-btn-group note-btn draft-btn">
      <button
        type="button"
        title="Save Draft"
        [disabled]="submitButtons.draft == false"
        class="btn btn-sm em-btn em-btn-green btn-clean mr-2 bigger save-draft-button chart-entry-button"
        (click)="updateChart(false); submitButtons.draft = false; submitButtons.save = true"
      >
        <i class="fab fa-firstdraft"></i>
      </button>
      <button
        type="button"
        title="Sign & Save"
        [attr.data-test-id]="'service-chart-sign-and-save'"
        [attr.data-test-value]="chartEntry?.appointmentId"
        class="btn btn-sm em-btn em-btn-green btn-clean mr-2 bigger save-draft-button chart-entry-button"
        (click)="updateChart(true); submitButtons.save = false; submitButtons.draft = false"
        ngbDropdownItem
      >
        <i class="fas fa-signature"></i>
      </button>
      <button
        *ngIf="!mobileView"
        type="button"
        title="Sign, Save, & Exit"
        class="btn btn-sm em-btn em-btn-green btn-clean bigger save-draft-button chart-entry-button"
        (click)="signSaveExit()"
        ngbDropdownItem
      >
        <i class="fas fa-share-square"></i>
      </button>
    </div>

    <!-- Pay icon on bottom-right of chart entry -->
    <button
      *ngIf="chartEntry && chartEntry.governmentBilling && !mobileView"
      type="button"
      class="panel-box-button-icon {{ chartEntry?.isLocked ? 'ml-auto' : 'ml-3' }} bigger"
      [ngStyle]="!patientAccountPolicySatisfied && { 'pointer-events': 'none' }"
      [ngClass]="chartEntry?.paymentStatus == PaymentStatus.Paid ? 'calendar-green-font' : 'calendar-red-font'"
      [title]="
        chartEntry?.paymentStatus == PaymentStatus.Paid
          ? 'Government Payment Received'
          : 'Government Payment Outstanding'
      "
      (click)="
        patientAccountPolicySatisfied && chartSelected.emit(chartEntry);
        patientAccountPolicySatisfied && openMinistryModel()
      "
    >
      <i *ngIf="chartEntry?.paymentStatus !== PaymentStatus.Paid" class="fal fa-long-arrow-right"></i>
      <i class="fas fa-university"></i>
    </button>

    <button
      *ngIf="chartEntry && !chartEntry?.governmentBilling"
      type="button"
      class="panel-box-button-icon  {{ chartEntry?.isLocked ? 'ml-auto' : 'ml-3' }}  bigger"
      [ngStyle]="!patientAccountPolicySatisfied && { 'pointer-events': 'none' }"
      [ngClass]="{
        'calendar-green-font': chartEntry?.paymentStatus == PaymentStatus.Paid,
        'calendar-red-font': chartEntry?.paymentStatus == PaymentStatus.Unpaid,
        'purple-font': chartEntry?.paymentStatus == PaymentStatus.Refund
      }"
      [title]="
        chartEntry?.paymentStatus == PaymentStatus.Paid
          ? 'Payment Received'
          : chartEntry?.paymentStatus == PaymentStatus.Unpaid
          ? 'Payment Outstanding'
          : 'Payment Refunded'
      "
      (click)="patientAccountPolicySatisfied && !mobileView && selectedChartForInvoice()"
    >
      <i
        [class]="chartEntry?.paymentStatus == PaymentStatus.Refund ? 'fas fa-sad-tear' : 'fas fa-hand-holding-usd'"
      ></i>
    </button>
  </div>
</mat-expansion-panel>
