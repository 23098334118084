<div class="modal-header border-0 p-0">
  <button
    type="button"
    class="btn white-font p-0 ml-auto mr-2"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')">
    <i class="fal fa-times"></i>
  </button>
</div>

<div class="modal-body p-0">
  <div class="add-document-container grey-two-background">
    <h1 class="modal-title text-uppercase navy-font text-center" class="mat-dialog-title">Patient Signature</h1>
  </div>

  <div id="form-div">
    <formio 
      #formView
      [form]="form"
      (submit)="onFormSubmit($event)"
      (change)="onFormChange($event)"
      [submission]="submission">
    </formio>
  </div>

  <div class="row py-3">
    <div class="col text-center pb-2">
      <button (click)="onSubmit($event)"
        class="btn em-btn em-btn-green">
        Sign
      </button>
      <button
        type="submit"
        class="btn em-btn em-btn-green ml-2"
        (click)="activeModal.dismiss('Cross click')">
        Cancel
      </button>
    </div>
  </div>

</div>