import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { ClinicDocument } from '@models/document/clinic-document';
import { ClinicDocumentsService } from '@services/clinic-documents.service';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete/confirm-delete.component';
import { ClinicDocumentsComponent } from '../clinic-documents.component';

@Component({
  selector: 'app-clinic-documents-list',
  templateUrl: './clinic-documents-list.component.html',
  styleUrls: ['./clinic-documents-list.component.less']
})
export class ClinicDocumentsListComponent implements OnInit, OnDestroy {
  searchValue = '';
  searchCtrl: FormControl;
  filteredClinicDocuments: Observable<ClinicDocument[]>;

  unsub: Subject<void> = new Subject<void>();
  disableGrid = false;
  loading = false;

  clinicDocs: ClinicDocument[];
  public gridView: GridDataResult;

  gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  editedRowIndex: number;
  editedDataItem: ClinicDocument;

  constructor(
    private clinicDocService: ClinicDocumentsService,
    private deleteDialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.loading = true;
    this.disableGrid = false;

    this.searchCtrl = new FormControl();

    this.loadData();
    this.initDocsChangedSubscription();
    this.initClinicDocChangeCancelledSubscription();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  loadData() {
    this.clinicDocService.getDocuments().subscribe(docs => {
      this.clinicDocs = docs;

      this.gridView = {
        data: docs.slice(),
        total: docs.length
      };

      this.filteredClinicDocuments = this.searchCtrl.valueChanges.pipe(
        startWith(''),
        map(doc => this.filterClinicDocuments(doc))
      );

      this.loading = false;
      this.disableGrid = false;
    });
  }

  initDocsChangedSubscription() {
    this.clinicDocService.docsChanged.pipe(takeUntil(this.unsub)).subscribe(docs => {
      this.clinicDocs = docs;

      this.gridView = {
        data: docs.slice(),
        total: docs.length
      };
      this.loading = false;
      this.disableGrid = false;
    });
  }

  initClinicDocChangeCancelledSubscription() {
    this.clinicDocService.docChangeCancelled.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.loading = false;
      this.disableGrid = false;
    });
  }

  public editHandler({ dataItem }) {
    this.disableGrid = true;
    if ((dataItem as ClinicDocument).pdfToHtmlUrl) {
      this.router.navigate([
        '/management/clinic-documents/list',
        { outlets: { 'action-panel': ['edit-clinic-eform', dataItem.id] } }
      ]);
    } else {
      this.router.navigate([
        '/management/clinic-documents/list',
        { outlets: { 'action-panel': ['edit-clinic-document', dataItem.id] } }
      ]);
    }
  }

  onAddClick() {
    this.disableGrid = true;
    this.router.navigate([
      '/management/clinic-documents/list',
      {
        outlets: {
          'action-panel': ['edit-clinic-document', this.clinicDocService.defaultId]
        }
      }
    ]);
  }

  onEformAddClick() {
    this.disableGrid = true;
    this.router.navigate([
      '/management/clinic-documents/list',
      {
        outlets: {
          'action-panel': ['edit-clinic-eform', this.clinicDocService.defaultId]
        }
      }
    ]);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  filterClinicDocuments(name: string) {
    let filterResults: ClinicDocument[] = [];

    if (name !== '') {
      this.gridView = {
        data: this.clinicDocService.clinicDocs
          .filter(doc => doc.name.toLowerCase().includes(name.toLowerCase())),
        total: this.clinicDocService.clinicDocs.filter(doc => doc.name.toLowerCase().includes(name.toLowerCase()))
          .length
      };
      filterResults = this.clinicDocService.clinicDocs.filter(doc =>
        doc.name.toLowerCase().includes(name.toLowerCase())
      );
    } else {
      this.gridView = {
        data: this.clinicDocService.clinicDocs,
        total: this.clinicDocService.clinicDocs.length
      };
      filterResults = [];
    }
    return filterResults;
  }

  public removeHandler({ dataItem }) {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px'
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe(result => {
        if (result === 'delete') {
          this.clinicDocService.deleteDocument(dataItem.id).subscribe();
        }
      });
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
  }

  viewForm(docId: number) {
    this.router.navigate(['/management/clinic-documents/eform-viewer/' + docId]);
  }
}
