<ngx-loading [show]="loading"></ngx-loading>
<kendo-grid
  class="emr-kendo-grid"
  [data]="gridView"
  [style.maxHeight.%]="100"
  [pageSize]="gridState.take"
  [pageable]="true"
  [skip]="gridState.skip"
  [sort]="gridState.sort"
  [sortable]="{
    allowUnsort: false,
    mode: 'single'
  }"
  (dataStateChange)="pageChange($event)"
  (excelExport)="onExcelExport($event)"
  #grid="kendoGrid"
>
  <kendo-grid-column field="patientName" title="Name">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <button
        type="button"
        class="btn-clean px-0 text-left underline"
        (click)="navigateToPatientAccountTab(dataItem.patientId)"
      >
        {{ dataItem.patientName }}
      </button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="bdPoints" title="BD Points ($)" format="{0:c}">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="invoiceDate" title="Transaction Date">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{ dataItem.invoiceDate | date : 'yyyy-MM-dd h:mma' }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="invoiceId" title="Invoice #">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <button
        type="button"
        class="btn-clean px-0 text-left underline"
        (click)="navigateToPatientInvoice(dataItem.patientId, dataItem.invoiceId)"
      >
        {{ dataItem.invoiceId }}
      </button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="invoiceTotal" title="Invoice Total" format="{0:c}">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-excel fileName="patients_report.xlsx" [fetchData]="allData"></kendo-grid-excel>
</kendo-grid>
