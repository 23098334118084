<div class="content modal-outer">
  <div class="appointment_heading">Cancel Visit</div>
  <p class="confirmation_message">Are you sure you want to cancel visit?</p>

  <div class="visit-form">
    <div class="buttons-outer">
      <button mat-button (click)="close()" class="btn btn-cancel">Cancel</button>
      <button mat-button (click)="cancelVisit()" class="btn btn-cancel-red">Cancel Visit</button>
    </div>
  </div>
</div>