import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VisitService } from '@services/visit.service';
import { Appointment } from '@models/appointments/appointment';
import * as moment from 'moment';
import { PatientService } from '@services/patient.service';
import { Subject } from 'rxjs';
import { CurrentDataService } from '@services/currentData.service';
import { AppointmentService } from '@services/appointments.service';

@Component({
  selector: 'app-appt-list-dialog',
  templateUrl: './appt-list-dialog.component.html',
  styleUrls: ['./appt-list-dialog.component.less']
})
export class ApptListDialogComponent implements OnInit, OnDestroy {
  appts: Appointment[] = [];
  unsub: Subject<void> = new Subject<void>();
  date: Date;
  patientId: number;
  serviceType: string;
  selectedApptId: number;

  constructor(
    public visitService: VisitService,
    public patientService: PatientService,
    public currentDataService: CurrentDataService,
    private appointmentService: AppointmentService,
    public dialogRef: MatDialogRef<ApptListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.data.selectedApptId = null;
  }

  onSelectClick(): void {
    this.data.selectedApptId = this.selectedApptId;
  }

  ngOnInit() {
    this.date = this.data.date;
    this.patientId = this.data.patientId;
    this.serviceType = this.data.serviceType;
    this.appointmentService.getAppointmentsByPatientIdAndDate(this.patientId, moment().toDate()).subscribe(appts => {
      this.appts = appts.filter(a => a.service.serviceName === this.serviceType);
      if (this.appts.length === 1) {
        // since there is only 1 appointment, just automatically return it's id
        this.data.selectedApptId = this.appts[0].appointmentId;
        this.dialogRef.close(this.data);
      }
    });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  apptClicked(appt) {
    this.selectedApptId = appt.appointmentId;
  }

  getReadableDate() {
    return moment(this.date).format('YYYY-MM-DD');
  }

  getAppointmentTime(appt) {
    const startIndex = 11;
    let endIndex = appt.start.indexOf('.');
    let thetime: string = appt.start.slice(startIndex, endIndex);
    endIndex = thetime.indexOf(':');
    let hours = +thetime.slice(0, endIndex);
    thetime = thetime.replace(thetime.slice(0, endIndex), '');
    let ampm = 'AM';
    if (hours > 12) {
      hours = hours - 12;
      ampm = 'PM';
    }
    thetime = hours.toString() + thetime + ' ' + ampm;
    return thetime;
  }
}
