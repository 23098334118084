<ngx-loading [show]="loading" [config]="{ fullScreenBackdrop: true }"></ngx-loading>

<div class="w-100 h-100 d-flex flex-column" #container>
  <div #header>
    <div *ngIf="insideDialog" class="modal-header border-0 px-2 py-1">
      <h5 class="white-font ml-4 mt-1 text-uppercase">Merge Patients</h5>
      <button type="button" class="btn white-font p-0 ml-auto" aria-label="Close" (click)="closeModal()">
        <i class="fal fa-times"></i>
      </button>
    </div>
    <div *ngIf="!insideDialog" class="merge-header container-fluid">
      <div class="merge-toolbar row py-4 px-2 navy-background">
        <div class="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center py-1">
          <h5 class="white-font text-uppercase m-0 mr-2">Merge Patients</h5>
        </div>
      </div>
    </div>
  </div>
  <ng-scrollbar class="flex-grow-1">
    <div class="patient-edit container">
      <div class="row">
        <div class="col-md-5">
          <div class="mt-4">
            <div class="column-header">Primary Patient Record</div>
            <app-patient-typehead
              typeheadPlaceholder="Patient Search"
              searchType="primary"
              [asyncSelected]="patientName"
              (typeaheadOnSelectEmitter)="typeaheadOnChange($event)"
              [patientStatuses]="[PatientStatus.Active, PatientStatus.Merged]"
            >
            </app-patient-typehead>
          </div>
        </div>
        <div class="col-md-2">
          <button class="btn em-btn em-btn-green btn-block" style="margin-top: 72px" (click)="swapValue()">
            <i class="far fa-exchange" style="font-size: 24px"></i>
          </button>
        </div>
        <div class="col-md-5">
          <div class="mt-4">
            <div class="column-header">Duplicate Patient Record</div>
            <app-patient-typehead
              searchType="duplicate"
              [asyncSelected]="duplicatePatientName"
              (typeaheadOnSelectEmitter)="typeaheadOnChange($event)"
            >
            </app-patient-typehead>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-5">
          <ng-container *ngIf="primaryPatient">
            <ng-container
              *ngTemplateOutlet="patientEdit; context: { patient: primaryPatient, patientId: primaryPatient.patientId }"
            ></ng-container>
          </ng-container>
        </div>
        <div class="col-md-2">
          <div class="merge-divider" *ngIf="primaryPatient || duplicatePatient"></div>
        </div>
        <div class="col-md-5">
          <ng-container *ngIf="duplicatePatient">
            <ng-container
              *ngTemplateOutlet="
                patientEdit;
                context: { patient: duplicatePatient, patientId: duplicatePatient.patientId }
              "
            ></ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="primaryPatient && duplicatePatient">
      <div class="offset-md-4 col-md-4">
        <div class="mb-4">
          <button
            class="btn em-btn em-btn-green btn-block"
            style="margin-top: 72px"
            (click)="mergePatient()"
            [disabled]="isMerged"
          >
            Merge Patients
          </button>
        </div>
      </div>
    </div>
  </ng-scrollbar>
</div>
<app-actionpanel></app-actionpanel>

<ng-template #patientEdit let-patient="patient" , let-patientId="patientId">
  <div class="border">
    <div class="patient-photo d-flex">
      <div class="position-absolute"></div>
      <img [attr.src]="onGetAvatarURI(patient.avatar)" class="w-100 h-auto" />
    </div>
    <app-edit-patient-content [viewModeDefault]="true" [currentPatient]="patient"></app-edit-patient-content>
  </div>

  <hr class="merge-patient-divider" />

  <app-document-filter-list
    class="merge-patient-component border"
    [patientId]="patientId"
    [showFilters]="false"
  ></app-document-filter-list>

  <hr class="merge-patient-divider" />

  <div class="merge-patient-photos border">
    <div class="column-header p-3">Photos</div>
    <ng-scrollbar class="d-block flex-grow-1">
      <app-photo-gallery [patientId]="patientId" [seriesMode]="true"> </app-photo-gallery>
    </ng-scrollbar>
  </div>

  <hr class="merge-patient-divider" />

  <div class="merge-patient-photos border">
    <div class="column-header p-3">Messages</div>
    <ng-container *ngIf="patientConversations.get(patientId) as patientConversation">
      <app-patient-messaging
        [displayOnly]="true"
        [selectedConversation]="patientConversation"
        [selectedConversationPatient]="patient"
      >
      </app-patient-messaging>
    </ng-container>
  </div>

  <hr class="merge-patient-divider" />

  <app-patient-appointments-list
    [patientId]="patientId"
    [startDate]="todaysDate"
    [endDate]="endDate"
    [autoHide]="false"
    class="merge-patient-component border"
  >
  </app-patient-appointments-list>

  <hr class="merge-patient-divider" />

  <app-patient-appointments-list [patientId]="patientId" [autoHide]="false" class="merge-patient-component border">
  </app-patient-appointments-list>

  <hr class="merge-patient-divider" />

  <app-treatment-plan-overview
    [patientId]="patientId"
    [showDetailButton]="false"
    [isLocked]="false"
    [showProducts]="true"
    class="merge-patient-component border"
  ></app-treatment-plan-overview>

  <hr class="merge-patient-divider" />

  <app-prescriptions-widget
    [patientId]="patientId"
    [showprescriptions]="false"
    class="merge-patient-component border"
  ></app-prescriptions-widget>

  <hr class="merge-patient-divider" />

  <app-product-purschase-history
    [patientId]="patientId"
    [isInvoice]="false"
    class="merge-patient-component border"
  ></app-product-purschase-history>

  <hr class="merge-patient-divider" />

  <app-social-history [patientId]="patientId"></app-social-history>

  <hr class="merge-patient-divider" />

  <app-external-prescriptions [patientId]="patientId"></app-external-prescriptions>
</ng-template>
