import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { Appointment } from '@models/appointments/appointment';
import { Visit } from '@models/visit';
import { MasterOverlayService } from '@services/actionpanel.service';
import { AppointmentService } from '@services/appointments.service';
import { PatientService } from '@services/patient.service';
import { VisitService } from '@services/visit.service';
import * as moment from 'moment';

@Component({
  selector: 'app-hover-panel',
  templateUrl: './hover-panel.component.html',
  styleUrls: ['./hover-panel.component.less'],
})
export class HoverPanelComponent implements OnInit {
  @Input() hoveredAppointment: Appointment;
  @Output() hoveredAppointmentChange = new EventEmitter();
  @HostBinding('class.show-panel') display: boolean;
  @HostBinding('style.top') positionTop: string;
  @HostBinding('style.left') positionLeft: string;

  patientProfilePolicy = Policies.patientProfile;
  patientChartPolicy = Policies.patientChart;
  patientAccountPolicy = Policies.patientAccount;
  appointmentsPolicy = Policies.appointments;

  get hoverAppointmentVisit(): Visit {
    if (!this.hoveredAppointment) return null;
    return this.visitService.activeScheduleVisits?.get(this.hoveredAppointment.visitId);
  }

  constructor(
    private visitService: VisitService,
    private appointmentService: AppointmentService,
    private patientService: PatientService,
    private masterOverlayService: MasterOverlayService,
    private router: Router,
    private el: ElementRef
  ) {}

  ngOnInit(): void {}

  getBoundingClientRect() {
    return this.el.nativeElement.getBoundingClientRect();
  }

  closeHoverPanel() {
    this.display = false;
    this.hoveredAppointmentChange.emit(null);
  }

  hexToTranslucentRgbA(opacity: string): string {
    const hex = this.hoveredAppointment?.service?.serviceTemplate?.serviceIDColour;
    if (!hex) {
      return '';
    }
    let color: string[] | string = hex.substring(1).split('');
    if (color.length === 3) {
      color = [color[0], color[0], color[1], color[1], color[2], color[2]];
    }
    color = '0x' + color.join('');
    // tslint:disable-next-line: no-bitwise
    return 'rgba(' + [(+color >> 16) & 255, (+color >> 8) & 255, +color & 255].join(',') + ',' + opacity + ')';
  }

  getGender() {
    const patient = this.hoveredAppointment?.patient;
    if (patient) {
      if (patient.gender === 'Female' || patient.gender === 'F') {
        return 'F';
      } else if (patient.gender === 'Male' || patient.gender === 'M') {
        return 'M';
      } else {
        return 'X';
      }
    } else {
      return 'U';
    }
  }

  patientProfileClick() {
    this.navigateFromAppointment('patientprofiletab');
    this.patientService.getPatientById(this.hoveredAppointment.patientId).subscribe((patient) => {
      if (patient) {
        this.patientService.patientPanelPatient = patient;
        this.masterOverlayService.masterOverlayState(true);
      }
    });
  }

  patientChartClick() {
    this.navigateFromAppointment('patientcharttab');
    this.patientService.getPatientById(this.hoveredAppointment.patientId).subscribe((patient) => {
      if (patient) {
        this.patientService.patientPanelPatient = patient;
        this.masterOverlayService.masterOverlayState(true);
      }
    });
  }

  patientAccountClick() {
    this.navigateFromAppointment('patientaccounttab');
  }

  private navigateFromAppointment(tabName: string) {
    this.patientService.getPatientById(this.hoveredAppointment.patientId).subscribe((patient) => {
      if (patient) {
        this.patientService.patientPanelPatient = patient;
        this.masterOverlayService.masterOverlayState(true);
        // this.actionPanelOpened = false;
        const patientId = patient.patientId;
        switch (tabName) {
          case 'patientprofiletab':
            this.router.navigate([
              '/schedule',
              { outlets: { 'action-panel': ['patient', patientId + '_patientprofiletab'] } },
            ]);
            break;
          case 'patientcharttab':
            this.router.navigate([
              '/schedule',
              {
                outlets: {
                  'action-panel': [
                    'patient',
                    patientId + '_patientcharttab',
                    'patienttabs',
                    'patientcharttab',
                    'overview',
                  ],
                },
              },
            ]);
            break;
          case 'patientaccounttab':
            this.router.navigate([
              '/schedule',
              {
                outlets: {
                  'action-panel': [
                    'patient',
                    patientId + '_patientaccounttab',
                    'patienttabs',
                    'patientaccounttab',
                    'overview',
                  ],
                },
              },
            ]);
            break;
          default:
            break;
        }
      }
    });
  }

  getVisitStatus() {
    return this.visitService.getVisitStatus(this.hoverAppointmentVisit);
  }

  hoverCheckInClick() {
    const visit = this.visitService.activeScheduleVisits.get(this.hoveredAppointment.visitId);
    if (!visit) throw new Error('Visit not found in active appointment visits');
    this.visitService.updateVisitStatus(visit).subscribe();
  }

  getStartTime() {
    return moment(this.hoveredAppointment?.start).format('H:mm A');
  }

  getBirthday() {
    const patient = this.hoveredAppointment?.patient;
    if (patient && patient.birthDate) {
      return moment(patient.birthDate).format('MMMM Do, YYYY');
    } else {
      return 'N/A';
    }
  }

  getConfirmationStatus() {
    return this.appointmentService.getConfirmationStatus(this.hoverAppointmentVisit, this.hoveredAppointment);
  }

  getHoverPanelVisitNoteContent() {
    const visit = this.hoverAppointmentVisit;
    if (visit && visit.visitNotes) {
      return visit.visitNotes;
    } else {
      return 'No notes for this visit.';
    }
  }

  getHoverPatientNoteContent() {
    const patient = this.hoveredAppointment?.patient;
    if (patient && patient.notesAndAlerts && patient.notesAndAlerts.replace(/\s/g, '').length) {
      return patient.notesAndAlerts;
    } else {
      return 'No notes for this patient.';
    }
  }
}
