export const globalIncludes = `<meta name="emily-custom" content="start">
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.1.1/jspdf.umd.min.js"></script>
<script src="https://html2canvas.hertzen.com/dist/html2canvas.min.js"></script>
<style>@media print{@page{margin:0}html{margin:0}body{margin:0;-webkit-print-color-adjust:exact}#sidebar{display:none}.pf{margin:0!important}#page-container{zoom:100%!important;overflow:visible!important}}</style>
<script>window.addEventListener("message",function(a){"printMe"==a.data.data&&window.print(),a.data&&a.data.data&&-1!=a.data.data.indexOf("downloadMe")&&downloadMe(a.data.data.split("downloadMe::")[1])});</script>
<script>function downloadMe(e){var t=document.body;let o=t.style.overflow;t.style.overflow="visible";let a=document.getElementById("page-container").style.zoom,n=document.getElementById("page-container").style.overflow;document.getElementById("page-container").style.zoom="100%",document.getElementById("page-container").style.overflow="visible",document.getElementsByClassName("pf").forEach(e=>e.style.margin="0"),setTimeout(()=>{var l=t;let d=document.getElementById("pf1").scrollWidth;html2canvas(l,{width:d,scale:.9,backgroundColor:null}).then(l=>{let s=l.toDataURL();parent.postMessage({printImgData:s,printImgWidth:d,printImgHeight:l.height},"*"),console.log(s);var i=new window.jspdf.jsPDF("p","px",[d,l.height]);let m=i.getImageProperties(s);i.addImage(s,"PNG",0,0,d,m.height),i.save(e),setTimeout(()=>{t.style.overflow=o,document.getElementById("page-container").style.zoom=a,document.getElementById("page-container").style.overflow=n,document.getElementsByClassName("pf").forEach(e=>e.style.margin="13px auto")},150)})})}</script>
<style>.form-control,.form-group{font-family:arial!important}</style>
<style>.form-control,.form-group{font-size:18px!important}.formio-component-checkbox{font-size:20px!important}</style>
<style>.formio-signature-button > .btn{font-size:100%!important}}</style>
<style>textarea.form-control{line-height:initial!important}</style>
<style>.formio-component-checkbox>.pretty{position:initial!important}</style>`
