import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-photo-series-item',
  templateUrl: './photo-series-item.component.html',
  styleUrls: ['./photo-series-item.component.less']
})
export class PhotoSeriesItemComponent implements OnInit {
  @Input() photo;

  constructor() { }

  ngOnInit() {
  }

}
