import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clinic-documents',
  templateUrl: './clinic-documents.component.html',
  styleUrls: ['./clinic-documents.component.less']
})
export class ClinicDocumentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
