import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { Appointment } from '@models/appointments/appointment';
import { Patient } from '@models/patient';
import { PhotoDrawing } from '@models/photo/photo-drawing';
import { ChartAppointment } from '@models/service-chart/chart-appointment';
import { ChartEntry } from '@models/service-chart/chart-entry';
import { ServiceProvider } from '@models/service-provider';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { Service } from '@models/service/service';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { TextTemplate } from '@models/text-template';
import { PlannedTreatment } from '@models/treatment-planning/planned-treatment';
import { TreatmentPlan } from '@models/treatment-planning/treatment-plan';
import { TreatmentState } from '@models/treatment-planning/treatment-state';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { exportPDF } from '@progress/kendo-drawing';
import { AppointmentSignalrService } from '@services/appointment-signalr.service';
import { AppointmentService } from '@services/appointments.service';
import { BreakpointService } from '@services/breakpoint.service';
import { CurrentDataService } from '@services/currentData.service';
import { PatientService } from '@services/patient.service';
import { ServiceProviderService } from '@services/service-provider.service';
import { ServiceTemplatesService } from '@services/service-templates.service';
import { TextTemplatesService } from '@services/text-templates.service';
import { TreatmentPlanFormService } from '@services/treatment-planning/treatment-plan-form.service';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import * as moment from 'moment';
import { NgScrollbar } from 'ngx-scrollbar';
import printJS from 'print-js';
import { BehaviorSubject, Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { ServiceChartDrawToolComponent } from '../modals/service-chart-draw-tool/service-chart-draw-tool.component';

@Component({
  selector: 'app-service-charts',
  templateUrl: './service-charts.component.html',
  styleUrls: ['./service-charts.component.less'],
  providers: [],
  encapsulation: ViewEncapsulation.None,
})
export class ServiceChartsComponent implements OnInit, OnDestroy {
  @ViewChild('scrollbar', { static: true }) scrollbar: NgScrollbar;
  @ViewChild('chartInput') chartInput: NgbTypeahead;
  @ViewChild('pdf') pdfContainer: PDFExportComponent;
  @ViewChild('ministryBillingModal') ministryBillingModal: TemplateRef<any>;
  @Output() chartEdited = new EventEmitter<boolean>();
  searchFocus$ = new Subject<string>();
  applyNote$ = new Subject<string>();
  applyPhoto$ = new Subject<PhotoDrawing>();
  unsub: Subject<void> = new Subject<void>();

  focusedIndex: number;
  expandAll = false;
  selectedChart: ChartAppointment;
  patientId: number;
  patient: Patient;
  serviceTemplates: ClinicServiceTemplate[] = [];
  textTemplates: TextTemplate[] = [];
  defaultPatientNoteIndex: number;
  isLoading = false;
  mobileView = true;
  serviceChartPriceUpdate: { serviceId: number; price: number } = null;
  isFiltered: boolean;
  serviceDetailTemplate = ServiceDetailTemplate;
  refreshNeeded: boolean = false;
  isCurrentUserServiceProvider = false;
  serviceProviders: ServiceProvider[] = [];
  currentFormsSelectionModel: SelectionModel<number> = new SelectionModel<number>();
  appointmentPatientForms: any[];
  patientChartDownloadPolicy = Policies.patientChartDownload;
  appointmentsPolicy = Policies.appointments;
  loadedChartEntries: ChartEntry[] = [];
  private allChartEntries: ChartEntry[] = [];
  private defaultLimit = 10;
  private chartEntryLimit$ = new BehaviorSubject<number>(this.defaultLimit);

  constructor(
    private appointmentSignalrService: AppointmentSignalrService,
    private appointmentService: AppointmentService,
    private patientService: PatientService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private serviceTemplatesService: ServiceTemplatesService,
    private textTemplatesService: TextTemplatesService,
    private treatmentPlanService: TreatmentPlanService,
    private treatmentPlanFormService: TreatmentPlanFormService,
    private currentDataService: CurrentDataService,
    private serviceProviderService: ServiceProviderService,
    private modalService: NgbModal,
    private breakpointService: BreakpointService
  ) {}

  async ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const serviceId = +params['toServiceId'];
      this.scrollToServiceId(serviceId);
    });

    this.chartEntryLimit$
      .pipe(takeUntil(this.unsub))
      .subscribe((limit) => (this.loadedChartEntries = this.allChartEntries.slice(0, limit)));

    this.appointmentService.serviceTemplateIdSource$
      .pipe(takeUntil(this.unsub))
      .subscribe((serviceTemplateId) => this.getChartEntries(serviceTemplateId));

    this.treatmentPlanService.treatmentPlanScheduled$
      .pipe(takeUntil(this.unsub))
      .subscribe(() => this.getChartEntries());

    this.currentDataService.currentReplayDataUpdated$.pipe(takeUntil(this.unsub)).subscribe((data: any) => {
      if (data && data.serviceChartPriceUpdate) {
        this.serviceChartPriceUpdate = {
          serviceId: (<Service>data.serviceChartPriceUpdate).serviceId,
          price: (<Service>data.serviceChartPriceUpdate).getChargeAmount(),
        };
        this.currentDataService.resetReplayDataSubject();
      }
    });

    this.appointmentSignalrService.apptAdded$
      .pipe(
        takeUntil(this.unsub),
        filter((appointment) => appointment && appointment.patientId === this.patientId)
      )
      .subscribe((appointment: Appointment) => {
        this.refreshNeeded = true;
      });

    this.appointmentSignalrService.apptDeleted$
      .pipe(
        takeUntil(this.unsub),
        filter((appointment) => appointment && appointment.patientId === this.patientId)
      )
      .subscribe((appointment: Appointment) => {
        this.refreshNeeded = true;
      });

    this.breakpointService.mobileBreakpoint$.pipe(takeUntil(this.unsub)).subscribe((mobileView) => {
      this.mobileView = mobileView;
    });

    this.prepareServiceProviders();
    this.getPatientId();
    this.getServiceTemplates();
    this.getTextTemplates();
    await this.getChartEntries();
    setTimeout(() => this.scrollToToday());
  }

  private prepareServiceProviders() {
    this.serviceProviderService.getServiceProviderByDate(new Date()).subscribe((sps) => {
      this.serviceProviders = sps;
    });
  }

  private getPatientId() {
    this.patientId = this.route.snapshot.params.patId.split('_')[0];
    this.patientService
      .getPatientById(this.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((patient) => {
        this.patient = patient;
      });
  }

  private getServiceTemplates() {
    this.serviceTemplatesService
      .getServiceTemplateList()
      .pipe(takeUntil(this.unsub))
      .subscribe((serviceTemplates: ClinicServiceTemplate[]) => {
        this.serviceTemplates = serviceTemplates;
      });
  }

  private getTextTemplates() {
    this.textTemplatesService
      .getAllTextTemplates()
      .pipe(takeUntil(this.unsub))
      .subscribe((textTemplates: TextTemplate[]) => (this.textTemplates = textTemplates));
  }

  async getChartEntries(filterTemplateId: number = null) {
    this.isFiltered = filterTemplateId ? true : false;
    this.isLoading = true;
    let chartEntries = await this.appointmentService
      .getPatientChartEntries(this.patientId)
      .toPromise()
      .catch((err) => {
        this.isLoading = false;
        throw err;
      });
    this.isLoading = false;
    if (filterTemplateId) {
      chartEntries = chartEntries.filter((entry) => entry.serviceTemplateId === filterTemplateId);
    }
    this.allChartEntries = chartEntries;
    this.chartEntryLimit$.next(this.defaultLimit);
    this.setDefaultPatientNote();
  }

  private scrollToToday() {
    const indexToScroll = this.allChartEntries.findIndex((entry) => {
      const startEntry = moment(entry.date).startOf('day');
      const startToday = moment().startOf('day');
      return !startEntry.isAfter(startToday);
    });
    this.scrollToChartIndex(indexToScroll);
  }

  private scrollToServiceId(serviceId: number) {
    const indexToScroll = this.allChartEntries.findIndex((entry) => entry.serviceId === serviceId);
    setTimeout(() => this.scrollToChartIndex(indexToScroll));
  }

  private scrollToChartIndex(index: number) {
    if (index && index >= 0) {
      if (index >= this.loadedChartEntries.length) {
        this.loadedChartEntries = this.allChartEntries.slice(0, index + this.defaultLimit);
        this.chartEntryLimit$.next(index + this.defaultLimit);
      }
      const element = document.getElementById('scrollEntry' + index);
      this.scrollbar.scrollToElement(element);
    }
  }

  searchServiceTemplates = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.searchFocus$;
    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        term === ''
          ? this.serviceTemplates
          : this.serviceTemplates.filter((v) => v.serviceName.toLowerCase().indexOf(term.toLowerCase()) > -1)
      )
    );
  };

  serviceTemplatesInputFormatter = (x: { serviceName: string }) => null;

  serviceTemplatesResultFormatter = (x: { serviceName: string }) => x.serviceName;

  toggleAll() {
    this.expandAll = !this.expandAll;
  }

  setDefaultPatientNote() {
    this.defaultPatientNoteIndex = this.allChartEntries.findIndex(
      (entry) =>
        entry.patientNoteId && !moment(entry.date).startOf('day').isBefore(moment().startOf('day')) && !entry.isLocked
    );
  }

  isLockedAllNotes() {
    return !this.allChartEntries.some((entry: ChartEntry) => entry.patientNoteId != null && !entry.isLocked);
  }

  addPatientNote() {
    const chartNote = new ChartEntry();
    chartNote.patientNoteId = 0;
    chartNote.date = new Date();
    chartNote.isLocked = false;
    this.allChartEntries.unshift(chartNote);
    this.chartEntryLimit$.next(this.chartEntryLimit$.value + 1);
    this.focusedIndex = 0;
    this.scrollToChartIndex(0);
  }

  addPhotoDrawing() {
    const addIndex = this.focusedIndex;
    if (this.focusedIndex === null) {
      this.dialog.open(GenericDialogComponent, {
        width: '250px',
        data: {
          showCancel: false,
          title: 'Warning',
          content: 'You must select a chart entry',
          confirmButtonText: 'Ok',
        },
      });
    } else if (this.allChartEntries[addIndex]?.isLocked) {
      this.dialog.open(GenericDialogComponent, {
        width: '250px',
        data: {
          showCancel: false,
          title: 'Warning',
          content: 'You must select an unsigned chart entry',
          confirmButtonText: 'Ok',
        },
      });
    } else {
      const modalRef = this.modalService.open(ServiceChartDrawToolComponent, {
        centered: true,
        backdrop: 'static',
        windowClass: 'draw-tool-modal',
      });
      modalRef.componentInstance.patient = this.patient;
      modalRef.result.then((result) => {
        this.scrollToChartIndex(addIndex);
        this.focusedIndex = null;
        if (result && result !== 'delete') {
          let [data, photoDrawing]: [Blob, PhotoDrawing] = result;
          const serviceChart = this.allChartEntries[addIndex];
          photoDrawing.photo.filePath = photoDrawing.photo.filePathThumb = URL.createObjectURL(data);
          photoDrawing.photo.imageName += 'Drawing';
          photoDrawing.photo.patientId = this.patient.patientId;
          photoDrawing.photo.patientContainerFolderName = this.patient.patientId.toString();
          photoDrawing.serviceId = serviceChart.serviceId;
          photoDrawing.patientNoteId = serviceChart.patientNoteId;
          this.applyPhoto$.next(photoDrawing);
        }
      });
    }
  }

  removeEntry(index: number) {
    this.allChartEntries.splice(index, 1);
    this.chartEntryLimit$.next(this.chartEntryLimit$.value - 1);
  }

  applyTextTemplate(event: string) {
    if (this.focusedIndex == null) {
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        width: '250px',
        data: {
          showCancel: false,
          title: 'Warning',
          content: 'You must select a service chart entry',
          confirmButtonText: 'Ok',
        },
      });
      dialogRef.afterClosed().subscribe();
    } else {
      this.applyNote$.next(event);
    }
  }

  async printChart() {
    this.isLoading = true;
    const group = await this.pdfContainer.export();
    const dataUri = await exportPDF(group, { title: this.getFileName() });
    const base64 = dataUri.replace('data:application/pdf;base64,', '');
    printJS({ printable: base64, type: 'pdf', base64: true });
    this.isLoading = false;
  }

  private getFileName() {
    const patientName = `${this.patient.firstName}_${this.patient.lastName}`;
    const currentDate = new Date();
    const dateString = moment().format('YYYYMMDD');
    return patientName + '_' + dateString + '_CHART' + '.pdf';
  }

  private verifyNewServiceFromTemplate(): Observable<boolean> {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '300px',
      data: {
        title: 'Warning: Are you sure you want to add this service?',
        content:
          'Please confirm if you want to add this service and ignore the existing service in the treatment plan.',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancel: true,
      },
    });

    return dialogRef.afterClosed().pipe(
      takeUntil(this.unsub),
      map((result) => {
        if (result === 'confirm') {
          return true;
        }
        return false;
      })
    );
  }

  async applyService(serviceTemplate: ClinicServiceTemplate) {
    this.isLoading = true;
    const patient = this.patientService.patientPanelPatient;
    this.chartInput.dismissPopup();
    const plannedTreatments = await this.getUnplannedTreatments().toPromise();
    const treatmentPlanScheduled = plannedTreatments.find(
      (treatment) =>
        treatment.service.serviceName == serviceTemplate.serviceName &&
        treatment.treatmentState == TreatmentState.Unplanned
    );
    if (treatmentPlanScheduled && !(await this.verifyNewServiceFromTemplate().toPromise())) {
      this.isLoading = false;
      return;
    }

    await this.appointmentService.createAppointmentFromTemplate(serviceTemplate, patient);
    this.getChartEntries();
  }

  private getUnplannedTreatments(): Observable<Array<PlannedTreatment>> {
    return this.treatmentPlanService.getUnplannedTreatmentPlanByPatientId(this.patientId).pipe(
      map((tp) => {
        let tpc: TreatmentPlan = this.treatmentPlanFormService.onCleanTreatmentPlan(tp);
        return tpc.plannedTreatments;
      })
    );
  }

  public clearFilter() {
    this.appointmentService.shareServiceTemplateId(null);
  }

  revokeObjectURL(url: string) {
    URL.revokeObjectURL(url);
  }

  getAge(date: Date) {
    return moment(new Date(Date.now())).diff(moment(date), 'years');
  }

  openMinistryModel() {
    if (Boolean(this.patient.clientId) == false) {
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        width: '250px',
        data: {
          showCancel: false,
          title: 'Missing Patient Info',
          content: 'Patient does not have a valid MSP number. Please update patient details to submit for MSP billing.',
          confirmButtonText: 'Ok',
        },
      });
      dialogRef.afterClosed().subscribe((result) => {});
    } else {
      this.dialog.open(this.ministryBillingModal, {
        panelClass: 'custom-dialog-container',
      });
    }
  }

  selectChartForMinistry(entry: ChartAppointment) {
    this.selectedChart = entry;
  }

  onBottomReached() {
    if (this.chartEntryLimit$.value < this.allChartEntries.length)
      this.chartEntryLimit$.next(this.chartEntryLimit$.value + this.defaultLimit);
  }

  chartEntryEdited(edited: boolean) {
    this.chartEdited.emit(edited);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
    this.searchFocus$.complete();
    this.applyNote$.complete();
    this.applyPhoto$.complete();
  }
}
