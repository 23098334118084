<form [formGroup]="plannedTreatmentsForm">
  <div class="form-row grey-four-background negative-row-margin">
    <div [ngClass]="isPlannedTreatmentMultiple ? 'col-3' : 'col-1'" class="text-center navy-font font-weight-bold p-2">
      Scheduled
    </div>
    <div
      [ngClass]="isPlannedTreatmentMultiple ? 'col-5' : 'col-3'"
      class="navy-font font-weight-bold grey-six-border-left p-2"
    >
      Service
    </div>
    <div *ngIf="!isPlannedTreatmentMultiple" class="col-4 navy-font font-weight-bold grey-six-border-left p-2">
      Summary
    </div>
    <div
      *ngIf="!isPlannedTreatmentMultiple"
      class="col-1 text-center navy-font font-weight-bold grey-six-border-left p-2"
    >
      Pricing
    </div>
    <div
      *ngIf="!isPlannedTreatmentMultiple"
      class="col-1 text-center navy-font font-weight-bold grey-six-border-left p-2"
    >
      Due Date
    </div>
    <div
      *ngIf="!isPlannedTreatmentMultiple"
      class="col-1 text-center navy-font font-weight-bold grey-six-border-left p-2"
    >
      Creation Date
    </div>
    <div
      *ngIf="!isPlannedTreatmentMultiple"
      class="col-1 text-center navy-font font-weight-bold grey-six-border-left p-2"
    >
      Action
    </div>
  </div>
  <ng-container formArrayName="plannedTreatments">
    <ng-container *ngFor="let plannedTreatment of getPlannedTreatmentControls(); let i = index" [formGroupName]="i">
      <div
        class="form-row negative-row-margin grey-six-border-bottom"
        kendoTooltip
        tooltipClass="em-tooltip"
        position="top"
      >
        <div [ngClass]="isPlannedTreatmentMultiple ? 'col-3' : 'col-1'" class="p-2 text-center">
          <div *ngIf="!isPlannedTreatmentMultiple" class="form-row">
            <div class="col = d-flex justify-content-center align-items-center">
              <input
                type="text"
                class="form-control d-inline w-25 text-center grey-two-border"
                formControlName="rank"
              />
              <i
                [ngClass]="{
                  'fa-calendar-times': plannedTreatment.value.treatmentState == treatmentState.Unplanned,
                  'fa-calendar': plannedTreatment.value.treatmentState == treatmentState.Scheduled
                }"
                class="pl-3 far"
                style="font-size: 1.25rem"
                [title]="
                  plannedTreatment.value.treatmentState == treatmentState.Unplanned
                    ? 'Unplanned'
                    : plannedTreatment.value.treatmentState == treatmentState.Scheduled
                    ? 'Scheduled'
                    : ''
                "
              ></i>
            </div>
          </div>
          <div *ngIf="plannedTreatment.value.treatmentState == treatmentState.Scheduled" class="form-row pt-1">
            <div class="col">
              {{ plannedTreatment.value.plannedDate | convertUTC : true }}
            </div>
          </div>
        </div>
        <div
          [ngClass]="isPlannedTreatmentMultiple ? 'col-5' : 'col-3'"
          class="grey-two-background grey-six-border-left p-2"
        >
          <div class="form-row">
            <div class="col">
              {{ plannedTreatment.value.service.serviceName }}
            </div>
          </div>
          <div class="form-row pt-1">
            <div class="col">
              <label class="d-block mb-0">Concern/Notes</label>
              <input type="text" class="form-control grey-two-border w-100 px-1" formControlName="notes" />
            </div>
          </div>
        </div>
        <div *ngIf="!isPlannedTreatmentMultiple" class="col-4 p-2 grey-six-border-left">
          <ng-container
            *ngIf="plannedTreatment.value.service.serviceDetailTemplateId === serviceDetailTemplate.Injections"
          >
            <app-item-detail-display
              [service]="serviceList[i]"
              [observationType]="observationTypes.Injectables"
              [isLocked]="true"
              [itemType]="serviceListType.history"
            >
            </app-item-detail-display>
          </ng-container>
          <ng-container
            *ngIf="plannedTreatment.value.service.serviceDetailTemplateId === serviceDetailTemplate.Coolsculpting"
          >
            <app-item-detail-display
              [service]="serviceList[i]"
              [observationType]="observationTypes.Coolsculpting"
              [isLocked]="true"
              [itemType]="serviceListType.history"
            >
            </app-item-detail-display>
          </ng-container>
        </div>
        <div *ngIf="!isPlannedTreatmentMultiple" class="col-1 text-center grey-two-background grey-six-border-left p-2">
          <!-- Handle the governement billing scenario, we don't show a price -->
          <ng-container *ngIf="plannedTreatment.value.service.governmentBilling; else privatePay"> - </ng-container>
          <ng-template #privatePay>
            <!-- If the Service doesn't have a Template -->
            <ng-container
              *ngIf="
                plannedTreatment.value.service.serviceDetailTemplateId == serviceDetailTemplate.None;
                else serviceDetail
              "
            >
              <!-- If the service doesn't have a Template, and it's paid, we just show the default price  -->
              <ng-container *ngIf="plannedTreatment.value.paymentStatus == PaymentStatus.Paid; else notPaid">
                <span class="spring-green-font"
                  >{{ plannedTreatment.value.service.defaultPrice | currency : '$' }}
                  <div>
                    {{
                      plannedTreatment.value.treatmentState == treatmentState.Scheduled ||
                      plannedTreatment.value.treatmentState == treatmentState.Unplanned
                        ? 'Prepaid'
                        : 'Paid'
                    }}
                  </div>
                </span>
              </ng-container>
              <!-- If it's not paid, we can change the total -->
              <ng-template #notPaid>
                <span class="w-25"
                  >$<input
                    type="number"
                    [ngClass]="{
                      'red-border': plannedTreatment.value.treatmentState == treatmentState.Scheduled,
                      'grey-two-border': plannedTreatment.value.treatmentState == treatmentState.Unplanned
                    }"
                    class="ml-1 text-center form-control small d-inline price-input"
                    formControlName="price"
                  />
                  <div [ngClass]="{ 'purple-font': plannedTreatment.value.paymentStatus == PaymentStatus.Refund }">
                    {{ plannedTreatment.value.paymentStatus == PaymentStatus.Refund ? 'Refunded' : 'Unpaid' }}
                  </div>
                </span>
              </ng-template>
            </ng-container>

            <!-- And if the Service does have a Service Template, it has service details to take into account -->
            <ng-template #serviceDetail>
              <ng-container
                *ngIf="
                  plannedTreatment.value.service.serviceDetailTemplateId != serviceDetailTemplate.None &&
                    plannedTreatment.value.service.chargeAmount;
                  else noPrice
                "
              >
                <span
                  [ngClass]="{
                    'spring-green-font': plannedTreatment.value.paymentStatus == PaymentStatus.Paid,
                    'purple-font': plannedTreatment.value.paymentStatus == PaymentStatus.Refund,
                    'calendar-red-font':
                      plannedTreatment.value.paymentStatus == PaymentStatus.Unpaid &&
                      plannedTreatment.value.treatmentState == treatmentState.Scheduled
                  }"
                >
                  {{ plannedTreatment.value.service.chargeAmount | currency : '$' }}
                  <div>
                    {{
                      plannedTreatment.value.paymentStatus == PaymentStatus.Paid
                        ? plannedTreatment.value.treatmentState == treatmentState.Scheduled ||
                          plannedTreatment.value.treatmentState == treatmentState.Unplanned
                          ? 'Prepaid'
                          : 'Paid'
                        : plannedTreatment.value.paymentStatus == PaymentStatus.Refund
                        ? 'Refunded'
                        : 'Unpaid'
                    }}
                  </div>
                </span>
              </ng-container>
            </ng-template>
            <ng-template #noPrice> - </ng-template>
          </ng-template>
        </div>

        <div *ngIf="!isPlannedTreatmentMultiple" class="col-1 text-center grey-two-background grey-six-border-left p-2">
          <input
            [matDatepicker]="dueDatePicker"
            (focus)="dueDatePicker.open()"
            formControlName="dueDate"
            class="grey-four-border-bottom text-center w-100 form-control grey-two-border small"
          />
          <mat-datepicker #dueDatePicker></mat-datepicker>
        </div>
        <div *ngIf="!isPlannedTreatmentMultiple" class="col-1 text-center grey-six-border-left p-2">
          {{ plannedTreatment.value.createdDate | date : 'yyyy-MM-dd' }}
        </div>
        <div *ngIf="!isPlannedTreatmentMultiple" class="col-1 text-center grey-six-border-left p-2">
          <ng-container
            *ngIf="
              plannedTreatment.value.service.serviceDetailTemplateId !== serviceDetailTemplate.None;
              else hiddenBtn
            "
          >
            <button class="btn btn-sm em-btn em-btn-green mr-2" (click)="onViewServiceDetail(plannedTreatment.value)">
              <ng-container *ngIf="plannedTreatment.value.service.templateIcon; else noIcon">
                <i
                  [class]="plannedTreatment.value.service.templateIcon"
                  [title]="
                    plannedTreatment.value.paymentStatus == PaymentStatus.Paid
                      ? 'Prepaid: No Editing'
                      : 'Service Details'
                  "
                ></i>
              </ng-container>
              <ng-template #noIcon>
                <i
                  class="far fa-file-alt small"
                  [title]="
                    plannedTreatment.value.paymentStatus == PaymentStatus.Paid
                      ? 'Prepaid: No Editing'
                      : 'Service Details'
                  "
                ></i>
              </ng-template>
            </button>
          </ng-container>
          <ng-template #hiddenBtn>
            <button class="btn opacity-0 no-pointer"><i class="far fa-file-alt small"></i></button>
          </ng-template>
          <button
            *ngIf="isPlannedTreatmentMultiple"
            [disabled]="plannedTreatment.value.paymentStatus == PaymentStatus.Paid"
            class="btn btn-sm em-btn em-btn-green"
            (click)="onEditService(i, plannedTreatment.value)"
          >
            <i class="far fa-file-alt small" title="Save"></i>
          </button>
          <button
            *ngIf="!isPlannedTreatmentMultiple"
            [disabled]="plannedTreatment.value.paymentStatus == PaymentStatus.Paid"
            class="btn btn-sm em-btn em-btn-green"
            (click)="onDeleteService(i, plannedTreatment.value)"
          >
            <i class="far fa-trash-alt small" title="Delete"></i>
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</form>
