
<div
*ngIf="seriesViewMode && seriesType === SeriesType.BeforeAfter"
 
>
<div class="d-flex w-100 h-100">
  <div class="col-6 h-100 p-0" *ngFor="let photo of photoSeriesItems; index as i; first as isFirst">
       <div class="d-flex align-items-center h-100 justify-content-center">
        <img  
        appDetermineMaxScrollHeight 
        [itemsToWatch]="[photo]"
        style="cursor: pointer;"
        class="mw-100 h-auto"
          src={{photo.filePath}}
          alt="{{photo?.imageName}}"
          (click)="photoClicked(i)">

         
      </div>
      <div class="photo-badge-caption white-font navy-background p-2 uppercase">
        {{isFirst ? 'before' : 'after'}}
      </div>
    
  </div>
</div>
</div>
<div
  *ngIf="seriesViewMode  && seriesType === SeriesType.Multi"
   class="series-wrapper"> 
  <div class="row flex-nowrap"  [style.transform]="'translateX(' + translateValue + ')'">
    <!-- NOTE: Uncomment below to make the series line up on top row -->
    <!-- <div class="col h-100" *ngFor="let photo of photoSeriesItems; first as isFirst; let i = index">
      <div class="d-flex align-items-center h-100 justify-content-center">
        <img
          appDetermineMaxScrollHeight
          [itemsToWatch]="[photo]"
          class="mw-100 h-auto"
          src="{{ photo.filePath }}"
          alt="{{ photo?.imageName }}"
          (click)="photoClicked()"
        />
      </div>

      <div class="photo-badge-caption white-font navy-background p-2 uppercase">
        {{ photo?.imageName }}
      </div>
    </div> -->

    <!-- NOTE: Uncomment below to make the series line up on bottom window edge -->
    <div class="col h-100 p-0" *ngFor="let photo of photoSeriesItems; first as isFirst; index as i">
      <div class="photo-badge h-100 d-flex flex-column">
        <div  appDetermineMaxScrollHeight [itemsToWatch]="[photo, photo?.filePath]" (click)="photoClicked(i)"  [applyToHeight]="true"  [addHeight]="-40" style="background-repeat: no-repeat; background-position: bottom; background-size: contain;cursor: pointer;" [ngStyle]="{'background-image': 'url(' + photo?.filePath + ')'}"> </div>
        <div class="photo-badge-caption white-font navy-background p-2 uppercase">
          {{ photo?.imageName }}
        </div>
      </div>  
    </div>
  </div>
  <!-- <div class="d-flex pt-2 pb-2">
    <button class="btn navy-font p-0" [disabled]="!currentState" (click)="incrementDecrecmentTransform(false)">
      <i class="far fa-arrow-left"></i>
    </button>
    <button class="btn navy-font p-0 ml-auto" [disabled]="currentState === maxTranslateValue" (click)="incrementDecrecmentTransform(true)">
      <i class="far fa-arrow-right"></i>
    </button>
  </div> -->
</div>
