<ngx-loading [show]="loading"></ngx-loading>
<div class="modal-header border-0 p-0">
  <button
    type="button"
    class="btn white-font p-0 ml-auto mr-2"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <i class="fal fa-times"></i>
  </button>
</div>
<div class="px-4 py-4 d-flex flex-column align-items-center content">
  <form [formGroup]="form" class="w-100">
    <h6 class="mt-0 mb-3 navy-font text-center">SEND TEST {{ type | uppercase }}</h6>
    <div *ngIf="type == 'email'; else smsControls">
      <div class="row mx-0">
        <div class="col">
          <mat-form-field class="w-100">
            <input matInput placeholder="Sender Name" formControlName="emailSenderName" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col">
          <mat-form-field class="w-100">
            <input matInput placeholder="Reply-To Email Address" formControlName="emailReplyToEmail" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col">
          <mat-form-field class="w-100">
            <input matInput placeholder="Recipient First Name" formControlName="emailRecipientFirstName" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col">
          <mat-form-field class="w-100">
            <input matInput placeholder="Recipient Last Name" formControlName="emailRecipientLastName" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col">
          <mat-form-field class="w-100">
            <input
              matInput
              placeholder="Recipient Email Address"
              formControlName="emailRecipientEmail"
              type="email"
              name="email"
              autocomplete="email"
            />
          </mat-form-field>
        </div>
      </div>
    </div>

    <ng-template #smsControls>
      <div class="row mx-0">
        <div class="col">
          <mat-form-field class="w-100">
            <textarea
              matInput
              placeholder="SMS Message"
              formControlName="smsMessage"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              [cdkAutosizeMinRows]="3"
              [cdkAutosizeMaxRows]="6"
            >
            </textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col">
          <mat-form-field class="w-100">
            <input matInput placeholder="Recipient Phone Number" formControlName="smsRecipientNumber" />
          </mat-form-field>
        </div>
      </div>
    </ng-template>

    <div class="row mx-0 mt-3">
      <div class="col d-flex justify-content-end">
        <button class="btn em-btn em-btn-green mr-2" type="submit" (click)="sendTest()" [disabled]="!form.valid">
          Send Test {{ type == 'email' ? (type | titlecase) : (type | uppercase) }}
        </button>

        <button class="btn em-btn cancel-btn" (click)="cancelUpdate()">Cancel</button>
      </div>
    </div>
  </form>
</div>
