import { ClinicTaxDetailsComponent } from './reports/clinic-reports/clinic-tax-details/clinic-tax-details.component';
import { CategorySalesDetailsComponent } from './reports/clinic-reports/category-sales-details/category-sales-details.component';
import { NgModule } from '@angular/core';
import { ReportsComponent } from './reports/reports.component';
import { SharedModule } from '@app/shared/shared.module';
import { ClinicReportsComponent } from './reports/clinic-reports/clinic-reports.component';
import { PatientReportsComponent } from './reports/patient-reports/patient-reports.component';
import { ListPatientReportsComponent } from './reports/listpatient-reports/list-patient-reports.component';
import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { ProductReportsComponent } from './reports/product-reports/product-reports.component';
import { ServiceReportsComponent } from './reports/service-reports/service-reports.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ProviderReportsComponent } from './reports/provider-reports/provider-reports.component';
import { ReferralReportsComponent } from './reports/referral-reports/referral-reports.component';
import { BDPointsReportsComponent } from './reports/bd-points-reports/bd-points-reports.component';
import { LiabilitiesReportComponent } from './reports/liabilities-report/liabilities-report.component';
import { InvoicesReportsComponent } from './reports/invoices-reports/invoices-reports.component';
import { ProviderDetailsComponent } from './reports/provider-reports/provider-details/provider-details.component';
import { PaymentTypeDetailsComponent } from './reports/clinic-reports/payment-type-details/payment-type-details.component';
import { SalesSummaryDetailsComponent } from './reports/clinic-reports/sales-summary-details/sales-summary-details.component';
import { LiabilitiesReportDetailsComponent } from './reports/liabilities-report/liabilities-report-details/liabilities-credit-report-details.component';
import { SuppliesReportsComponent } from './reports/supplies-reports/supplies-reports.component';
import { CommissionsReportsComponent } from './reports/commissions-reports/commissions-reports.component';
import { ServiceDetailComponent } from './reports/service-reports/service-detail/service-detail.component';
import { SupplyDetailsComponent } from './reports/supplies-reports/supply-details/supply-details.component';
import { PowerBiReportsComponent } from './reports/power-bi-reports/power-bi-reports.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { AuthModule } from '@app/auth/auth.module';
import { MembershipsReportsComponent } from './reports/memberships-reports/memberships-reports.component';
import { MembershipDetailReportComponent } from './reports/memberships-reports/membership-detail-report/membership-detail-report.component';

@NgModule({
  declarations: [
    ReportsComponent,
    ClinicReportsComponent,
    PatientReportsComponent,
    ProductReportsComponent,
    ServiceReportsComponent,
    ProviderReportsComponent,
    ProviderDetailsComponent,
    PaymentTypeDetailsComponent,
    LiabilitiesReportDetailsComponent,
    CategorySalesDetailsComponent,
    SalesSummaryDetailsComponent,
    ClinicTaxDetailsComponent,
    ReferralReportsComponent,
    ListPatientReportsComponent,
    BDPointsReportsComponent,
    LiabilitiesReportComponent,
    InvoicesReportsComponent,
    SuppliesReportsComponent,
    CommissionsReportsComponent,
    ServiceDetailComponent,
    SupplyDetailsComponent,
    PowerBiReportsComponent,
    MembershipsReportsComponent,
    MembershipDetailReportComponent,
  ],
  imports: [
    AuthModule,
    SharedModule,
    MatCheckboxModule,
    GridModule,
    PDFModule,
    ExcelModule,
    PowerBIEmbedModule,
  ],
  exports: [
    ReportsComponent,
    ClinicReportsComponent,
    PatientReportsComponent,
    ProductReportsComponent,
    ListPatientReportsComponent,
    InvoicesReportsComponent,
    ProviderDetailsComponent,
    PaymentTypeDetailsComponent,
    LiabilitiesReportDetailsComponent,
    CategorySalesDetailsComponent,
    SalesSummaryDetailsComponent,
    ClinicTaxDetailsComponent,
  ],
})
export class ReportsModule {}
