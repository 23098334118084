<div class="edit-container position-absolute d-block z-index-3 white-background">
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0;">
    <div class="row mx-0 teal-background">
      <div class="col px-2">
        <h6 class="white-font uppercase p-2 mb-0">
          {{addOrEdit}} Special
        </h6>
      </div>
    </div>
    <div class="row pt-2 mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input matInput required placeholder="Special Name" class="capitalized" aria-label="Special Name"
            (change)="formatterService.textCapitalize(name)" [(ngModel)]="special.name" [formControl]="name">
        </mat-form-field>
      </div>
      <div class="col px-2">
        <mat-form-field>
          <input matInput placeholder="Diagnostic Code" aria-label="Diagnostic Code" [(ngModel)]="special.code"
            [formControl]="code">
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input type="number" matInput placeholder="Total of Prices" aria-label="Total of Prices"
            [(ngModel)]="special.totalOfIndividualPrices" [formControl]="totalOfIndividualPrices">
        </mat-form-field>
      </div>
    </div>
    <div class="row mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input type="number" matInput placeholder="Retail Price" aria-label="Retail Price" [(ngModel)]="special.retailPrice"
            [formControl]="retailPrice">
        </mat-form-field>
      </div>
      <div class="col px-2">
        <kendo-multiselect class="panel-flex-row-item kendo-multiselect" placeholder="Taxes" aria-label="Taxes"
          [data]="taxes" [valuePrimitive]="false" [(ngModel)]="selectedTaxes" textField="name" valueField="value"
          (valueChange)="updateTotalOfPrices()">
        </kendo-multiselect>
      </div>
    </div>

    <div class="row mx-0 teal-background">
      <div class="col px-2">
        <h6 class="white-font uppercase p-2 mb-0">
          Products
        </h6>
      </div>
    </div>
    <div class="row pt-2 mx-0">
      <div class="col px-2">
        <mat-form-field>
          <input id="productsInput" type="text" matInput [formControl]="selectedProduct"
            placeholder="Search for a product" [matAutocomplete]="products" />
        </mat-form-field>
        <mat-autocomplete #products="matAutocomplete" [displayWith]="productDisplayFn">
          <mat-option *ngFor="let product of filteredProducts | async" [value]="product">
            {{ product.displayName }}
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="col px-2">
        <button class="btn em-btn em-btn-green" (click)="addProductToSpecial()">Add Product</button>
      </div>
    </div>
    <div class="row mx-0" *ngFor="let product of selectedProducts">
      <div class="col px-2">
        {{product.clinicProduct.displayName}}
      </div>
      <div class="col px-2">
        ${{product.clinicProduct.retailPrice}}
      </div>
      <div class="col px-2">
        <input
          class="no-spin"
          type="number"
          [(ngModel)]="product.productQuantity"
          (change)="updateSpecialProductQuantity(product)">
      </div>
      <div class="col px-2">
        <button class="btn p-0" (click)="removeProductFromSpecial(product)">
          <i class="fa fa-times-circle"></i>
        </button>
      </div>
    </div>
    <div class="row mt-2 mx-0">
      <div class="col text-center px-2">
        <button class="btn em-btn em-btn-green" [disabled]="special.name === ''"
          (click)="updateSpecial()">{{addOrEdit == 'Add' ? 'Add' : 'Update'}}
          Special</button>
      </div>
      <div class="col text-center px-2">
        <button class="btn em-btn em-btn-green" (click)="cancelUpdate()">Cancel</button>
      </div>
    </div>
  </perfect-scrollbar>
</div>
