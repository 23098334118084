import { Component, ElementRef, Input, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { Policies } from '@app/auth/auth-policies';
import { ErrorMessageType } from '@app/shared/components/error/error.component';
import { Patient } from '@models/patient';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExecuteActionEvent, Message, User } from '@progress/kendo-angular-conversational-ui';
import { TextAreaComponent } from '@progress/kendo-angular-inputs';
import { BlobService } from '@services/blob.service';
import { PatientSignalrService } from '@services/patient-signalr.service';
import { PatientService } from '@services/patient.service';
import { TwilioConversationsService } from '@services/twilio-conversations.service';
import { Conversation, Message as TwilioMessage } from '@twilio/conversations';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-patient-messaging',
  templateUrl: './patient-messaging.component.html',
  styleUrls: ['./patient-messaging.component.less'],
})
export class PatientMessagingComponent implements OnInit, OnDestroy {
  private unsub = new Subject<void>();
  loading = false;
  statusMessage = '';
  statusMessageType: ErrorMessageType;
  optInMessage = '';
  optInMessageType = ErrorMessageType.Info;
  clientUser: User = null;
  users: User[] = [];
  messages: Message[] = [];
  sendPolicy = Policies.patientMessagingSend;

  @Input() selectedConversation: Conversation = null;
  @Input() selectedConversationPatient: Patient = null;
  @Input() displaySelection = true;
  @Input() displayOnly = false;

  @ViewChild('messageBoxInput', { static: false })
  messageBoxInput: TextAreaComponent;

  @ViewChild('uploadInput', { static: false }) inputFile: ElementRef;
  innerInputFocus = false;
  imagePreview: string;

  get connected() {
    return this.twilioService.initialized;
  }

  private _selectedImage: File;
  get selectedImage() {
    return this._selectedImage;
  }
  set selectedImage(file: File) {
    this._selectedImage = file;
    this.imagePreview = '';

    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagePreview = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  constructor(
    private twilioService: TwilioConversationsService,
    private blobService: BlobService,
    private patientSignalRService: PatientSignalrService,
    private patientService: PatientService,
    @Optional() public activeModal?: NgbActiveModal
  ) {}

  async ngOnInit() {
    this.loading = true;

    if (this.displayOnly) {
      this.displaySelection = false;
    }

    this.twilioService.connectionState$
      .pipe(
        takeUntil(this.unsub),
        filter((state) => state != null)
      )
      .subscribe((state) => {
        switch (state) {
          case 'connecting':
            this.statusMessage = '';
            break;
          case 'connected':
            this.statusMessage = '';
            if (this.displaySelection) this.loading = false;
            break;
          case 'disconnecting':
            this.statusMessage = 'Disconnecting';
            this.statusMessageType = ErrorMessageType.Info;
            if (this.displaySelection) this.loading = false;
            break;
          case 'disconnected':
            this.statusMessage = 'Disconnected';
            this.statusMessageType = ErrorMessageType.Info;
            if (this.displaySelection) this.loading = false;
            break;
          case 'denied':
            this.statusMessage = 'Connection denied.';
            this.statusMessageType = ErrorMessageType.Error;
            if (this.displaySelection) this.loading = false;
            break;
          default:
            this.statusMessage = 'Unknown Error';
            this.statusMessageType = ErrorMessageType.Error;
            if (this.displaySelection) this.loading = false;
            break;
        }
      });

    // If conversation and patient inputs are set then use them and not the selected conversation observable
    if (this.selectedConversation && this.selectedConversationPatient) {
      this.setSelectedConversationPatient(this.selectedConversationPatient);
      try {
        this.loading = true;
        await this.setClientUser(this.selectedConversation);
        await this.setMessages(this.selectedConversation);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.statusMessage = error.message;
        this.statusMessageType = ErrorMessageType.Error;
        throw error;
      }
    } else {
      this.twilioService.selectedConversationAndPatient$
        .pipe(takeUntil(this.unsub))
        .subscribe(async ([conversation, patient]) => {
          this.selectedConversation = conversation;
          this.setSelectedConversationPatient(patient);
          try {
            this.loading = true;
            await conversation?.setAllMessagesRead();
            await this.setClientUser(conversation);
            await this.setMessages(conversation);
            this.loading = false;
          } catch (error) {
            this.loading = false;
            this.statusMessage = error.message;
            this.statusMessageType = ErrorMessageType.Error;
            throw error;
          }
        });
    }

    this.twilioService.messageAdded$.pipe(takeUntil(this.unsub)).subscribe(async (message) => {
      if (!this.selectedConversation) return;
      if (message.conversation.sid !== this.selectedConversation.sid) return;
      await this.appendMessage(message);
    });

    this.patientSignalRService.patientUpdated$.pipe(takeUntil(this.unsub)).subscribe((patientId) => {
      if (this.selectedConversationPatient && this.selectedConversationPatient.patientId === patientId) {
        this.patientService.getPatientById(patientId).subscribe((patient) => {
          this.setSelectedConversationPatient(patient);
        });
      }
    });
  }

  private async setClientUser(conversation: Conversation) {
    if (!conversation) {
      this.clientUser = null;
      return;
    }
    const userIdentity = this.twilioService.getClientUserIdentity();
    const userParticipant = await conversation.getParticipantByIdentity(userIdentity).catch((error) => {
      this.loading = false;
      throw error;
    });
    const userId = userParticipant.sid;
    this.clientUser = { id: userId };
  }

  private async setMessages(conversation: Conversation) {
    if (conversation) {
      let messagePage = await conversation.getMessages().catch((error) => {
        throw error;
      });
      const messages = messagePage.items;
      while (messagePage.hasNextPage) {
        messagePage = await messagePage.nextPage().catch((error) => {
          throw error;
        });
        messages.push(...messagePage.items);
      }

      if (messages && messages.length >= 0) {
        await Promise.all(messages.map(async (message) => await this.twilioService.setMessageReadBy(message)));
        const kendoMessages = await Promise.all(
          messages.map(async (message) => await this.mapTwilioMessageToKendo(message))
        );
        this.messages = kendoMessages;
        return;
      }
    }
    this.messages = [];
  }

  private setSelectedConversationPatient(patient: Patient) {
    this.selectedConversationPatient = patient;
    if (!patient) {
      this.optInMessage = '';
    } else if (patient.sendTwoWayMessages) {
      this.optInMessage = '';
    } else if (patient.unsubscribedDateTwoWayMessages) {
      this.optInMessage = `${patient.firstName} ${patient.lastName} unsubscribed from SMS messaging on ${moment(
        patient.unsubscribedDateTwoWayMessages
      ).format('MM/DD/YY h:mm A')}`;
    } else {
      this.optInMessage = `${patient.firstName} ${patient.lastName} has not yet opted-in to SMS messaging`;
    }
  }

  private async appendMessage(message: TwilioMessage): Promise<void> {
    if (message) {
      await this.twilioService.setMessageReadBy(message);
      const kendoMessage = await this.mapTwilioMessageToKendo(message);
      this.messages = [...this.messages, kendoMessage];
    }
  }

  private async mapTwilioMessageToKendo(message: TwilioMessage): Promise<Message> {
    const attachments = await Promise.all(
      message.attachedMedia?.map(async (media) => ({
        contentType: media.contentType,
        content: await media.getContentTemporaryUrl(),
      }))
    );

    const user = (message.attributes as any).user;
    const readByUser = (message.attributes as any).readByUser;
    const readByDate = (message.attributes as any).readByDate as Date;
    const sas = this.blobService.getReadOnlySAS();

    let author: any;
    if (user) {
      author = {
        id: message.participantSid,
        name: user.fullName,
        avatarUrl: user.avatar + '?' + sas,
      };
    } else if (readByUser) {
      author = {
        id: message.participantSid,
        name: `Read By: ${readByUser.fullName} ${moment(readByDate).format('MM/DD/YY, h:mm A')}`,
      };
    } else {
      author = {
        id: message.participantSid,
        name: 'System',
        avatarUrl: '../../../assets/Avatars/avatar-placeholder.png',
      };
    }

    const kendoMessage: Message = {
      author: author,
      status: author.name,
      timestamp: message.dateCreated,
      text: message.body,
      attachmentLayout: 'list',
      attachments: attachments,
    };
    return kendoMessage;
  }

  async sendMessage(): Promise<void> {
    const selectedPatientId = this.twilioService.parsePatientId(this.selectedConversation);
    if (
      this.selectedConversationPatient &&
      this.selectedConversationPatient.sendTwoWayMessages &&
      this.selectedConversationPatient.patientId === selectedPatientId
    ) {
      const messageBox = this.messageBoxInput;
      const newMessage = messageBox.value;
      const selectedImage = this.selectedImage;
      if (!newMessage) {
        return;
      }

      messageBox.value = null;
      messageBox.focus();
      this.selectedImage = null;
      this.twilioService.sendMessage(this.selectedConversation, newMessage, selectedImage);
    }
  }

  sendOptInMessage(): void {
    this.loading = true;
    this.twilioService.sendOptInMessage(this.selectedConversationPatient).subscribe(
      () => (this.loading = false),
      (error) => {
        this.loading = false;
        this.statusMessage = error.message ?? 'Unknown Error';
        this.statusMessageType = ErrorMessageType.Error;
      }
    );
  }

  onInputFocus(): void {
    this.innerInputFocus = true;
  }

  onInputBlur(): void {
    this.innerInputFocus = false;
  }

  clearValue(): void {
    if (!this.innerInputFocus) {
      this.messageBoxInput.value = null;
      this.messageBoxInput.focus();
      this.innerInputFocus = true;
    }
  }

  onFileSelect(event: Event): void {
    this.selectedImage = (event.target as HTMLInputElement).files[0];
    (event.target as HTMLInputElement).value = null;
    this.messageBoxInput.focus();
    this.innerInputFocus = true;
  }

  onAction(e: ExecuteActionEvent): void {
    if (e.action.type === 'reply') {
      this.inputFile.nativeElement.click();
    }
    this.messageBoxInput.focus();
  }

  ngOnDestroy(): void {
    this.twilioService.setSelectedConversationAndPatient(null, null);
    this.unsub.next();
    this.unsub.complete();
  }
}
