import { GenericDialogComponent } from '../generic-confirm/generic-confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject, forkJoin } from 'rxjs';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MailjetEmailTemplate } from '@models/communications/mailjet-email-template.model';
import { Router, ActivatedRoute } from '@angular/router';
import { CommunicationsService } from '@services/communications.service';
import { takeUntil } from 'rxjs/operators';
import { EmailNotificationsSettings } from '@models/communications/email-notifications-settings.model';
import { SmsNotificationsSettings } from '@models/communications/sms-notifications-settings.model';
import { TestEmail } from '@models/communications/test-email.model';
import { RetentionCampaignsService } from '@services/retention-campaigns.service';
import { UsersService } from '@services/users.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TestSms } from '@models/communications/test-sms.model';
import { ValidationService } from '@services/validation.service';
import { FormatterService } from '@services/formatter.service';
import { EmailTemplateType } from '@models/clinic-email-template';
import { CommunicationSettingType } from '@models/communications/notifications-settings-base.model';

@Component({
  selector: 'app-send-test-email',
  templateUrl: './send-test-email.component.html',
  styleUrls: ['./send-test-email.component.less'],
})
export class SendTestEmailComponent implements OnInit, OnDestroy {
  @Input() campaignId: number;
  @Input() quickView = false;
  @Input() type: string;
  @Input() emailTemplateType: EmailTemplateType;
  @Input() emailHtml: string;
  @Input() emailSubject: string;

  private unsub: Subject<void> = new Subject<void>();
  loading: boolean;
  form: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private communicationsService: CommunicationsService,
    private retentionCampaignsService: RetentionCampaignsService,
    private userService: UsersService,
    private validationService: ValidationService,
    public formatterService: FormatterService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.initForm();

    if (this.emailTemplateType === EmailTemplateType.RetentionCampaign) this.populateCampaignValues(this.campaignId);
    else if (this.emailTemplateType === EmailTemplateType.AppointmentReminder)
      this.populateReminderValues(this.campaignId);
    else this.populateCampaignValues(this.campaignId);
  }

  initForm() {
    if (this.type == 'email') {
      this.form = new FormGroup({
        emailSenderName: new FormControl('', Validators.required),
        emailReplyToEmail: new FormControl('', [Validators.email, , Validators.required]),
        emailRecipientFirstName: new FormControl('', Validators.required),
        emailRecipientLastName: new FormControl('', Validators.required),
        emailRecipientEmail: new FormControl('', [Validators.email, , Validators.required]),
      });
    } else if (this.type == 'sms') {
      this.form = new FormGroup({
        smsMessage: new FormControl({ value: '', disabled: true }, Validators.required),
        smsRecipientNumber: new FormControl('', [this.validationService.validatePhoneNumber, Validators.required]),
      });
      const numberControl = this.form.get('smsRecipientNumber');
      numberControl.valueChanges.subscribe({
        next: (ignored) => this.formatterService.formatPhoneNumber(numberControl),
      });
    }
  }

  conditionalValidator(condition: () => boolean, validator: ValidatorFn): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!condition()) {
        return null;
      }
      return validator(control);
    };
  }

  emailFormData() {
    const data = new TestEmail();
    data.emailTemplateType = this.emailTemplateType;
    data.senderName = this.form.controls['emailSenderName'].value;
    data.replyToEmail = this.form.controls['emailReplyToEmail'].value;
    data.recipientFirstName = this.form.controls['emailRecipientFirstName'].value;
    data.recipientLastName = this.form.controls['emailRecipientLastName'].value;
    data.recipientEmail = this.form.controls['emailRecipientEmail'].value;
    data.html = this.emailHtml;
    data.subject = this.emailSubject;
    return data;
  }

  private smsFormData() {
    const data: TestSms = {
      userId: this.userService.loggedInUser.id,
      message: this.form.controls['smsMessage'].value,
      recipientNumber: this.form.controls['smsRecipientNumber'].value,
    };
    return data;
  }

  cancelUpdate() {
    this.retentionCampaignsService.refreshRequired = false;
    this.retentionCampaignsService.retentionCampaignSettingsUpdated.next();
    this.activeModal.close();
  }

  populateCampaignValues(campaignId: number) {
    this.loading = true;

    if (this.type == 'email') {
      forkJoin([
        this.retentionCampaignsService.getCampaign(campaignId),
        this.communicationsService.getEmailTemplates(),
        this.communicationsService.getRetentionCampaignsSettings(),
      ])
        .pipe(takeUntil(this.unsub))
        .subscribe(([campaign, emailTemplates, settings]) => {
          const userEmail =
            localStorage.getItem('testEmail') == null
              ? this.userService.loggedInUser.email
              : localStorage.getItem('testEmail');
          this.emailHtml = campaign.emailHTML;
          this.emailSubject = campaign.emailSubjectLine;
          this.form.controls['emailSenderName'].setValue(settings.senderName);
          this.form.controls['emailReplyToEmail'].setValue(settings.senderEmail);
          this.form.controls['emailRecipientFirstName'].setValue(this.userService.loggedInUser.firstName);
          this.form.controls['emailRecipientLastName'].setValue(this.userService.loggedInUser.lastName);
          this.form.controls['emailRecipientEmail'].setValue(userEmail);

          this.loading = false;
        });
    } else if (this.type == 'sms') {
      this.retentionCampaignsService
        .getCampaign(campaignId)
        .pipe(takeUntil(this.unsub))
        .subscribe({
          next: (campaign) => {
            const userPhoneNumber =
              localStorage.getItem('testSMS') == null
                ? this.userService.loggedInUser.phoneNumber
                : localStorage.getItem('testSMS');
            this.form.controls['smsMessage'].setValue(campaign.smsCampaignText);
            this.form.controls['smsRecipientNumber'].setValue(userPhoneNumber);

            this.loading = false;
          },
        });
    }
  }

  populateReminderValues(campaignId: number) {
    this.loading = true;

    if (this.type == 'email') {
      forkJoin([this.communicationsService.getEmailNotificationsSettings()])
        .pipe(takeUntil(this.unsub))
        .subscribe(([allSettings]) => {
          let settings = allSettings.find((es) => es.communicationSettingType === CommunicationSettingType.Reminder);
          const userEmail =
            localStorage.getItem('testEmail') == null
              ? this.userService.loggedInUser.email
              : localStorage.getItem('testEmail');
          this.emailHtml = settings.emailHtml;
          this.emailSubject = settings.emailSubjectLine;
          this.form.controls['emailSenderName'].setValue(settings.senderName);
          this.form.controls['emailReplyToEmail'].setValue(settings.senderEmail);
          this.form.controls['emailRecipientFirstName'].setValue(this.userService.loggedInUser.firstName);
          this.form.controls['emailRecipientLastName'].setValue(this.userService.loggedInUser.lastName);
          this.form.controls['emailRecipientEmail'].setValue(userEmail);

          this.loading = false;
        });
    } else if (this.type == 'sms') {
      this.retentionCampaignsService
        .getCampaign(campaignId)
        .pipe(takeUntil(this.unsub))
        .subscribe({
          next: (campaign) => {
            const userPhoneNumber =
              localStorage.getItem('testSMS') == null
                ? this.userService.loggedInUser.phoneNumber
                : localStorage.getItem('testSMS');
            this.form.controls['smsMessage'].setValue(campaign.smsCampaignText);
            this.form.controls['smsRecipientNumber'].setValue(userPhoneNumber);

            this.loading = false;
          },
        });
    }
  }

  sendTest() {
    const typeString = this.type == 'sms' ? 'SMS' : 'email';
    const dialogData = {
      width: '250px',
      data: {
        showCancel: false,
        title: 'Complete',
        content: `Test ${typeString} sent successfully...`,
        confirmButtonText: 'Ok',
      },
    };
    const failureDialogData = {
      width: '250px',
      data: {
        showCancel: false,
        title: 'Error',
        content: `Test ${typeString} was not sent. Please report this issue.`,
        confirmButtonText: 'Ok',
      },
    };

    if (this.type == 'email') {
      this.communicationsService.sendTestEmailSes(this.emailFormData()).subscribe(
        () => {
          this.dialog.open(GenericDialogComponent, dialogData);
        },
        () => {
          this.dialog.open(GenericDialogComponent, failureDialogData);
        }
      );
      // Save entered email as preferred test email
      localStorage.setItem('testEmail', this.form.get('emailRecipientEmail').value);
    } else if (this.type == 'sms') {
      this.communicationsService.sendTestCampaignSms(this.smsFormData()).subscribe(
        () => {
          this.dialog.open(GenericDialogComponent, dialogData);
        },
        () => {
          this.dialog.open(GenericDialogComponent, failureDialogData);
        }
      );
      // Save entered phone numbers as preferred test number
      localStorage.setItem('testSMS', this.form.get('smsRecipientNumber').value);
    }
    this.cancelUpdate();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
