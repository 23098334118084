import { Injectable } from '@angular/core';
import { Policies } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';
import { Visit } from '@models/visit';
import { AppointmentService } from '@services/appointments.service';
import { SignalrBase } from './signalr-base';
import { SignalrHttpClientService } from './signalr-http-client.service';
import { VisitService } from './visit.service';

@Injectable({
  providedIn: 'root',
})
export class VisitSignalrService extends SignalrBase<Visit> {
  constructor(
    authService: AuthService,

    private appointmentService: AppointmentService,
    private visitService: VisitService,
    private signalrHttpClientService: SignalrHttpClientService
  ) {
    super('Visits', Policies.appointments, authService, signalrHttpClientService);
    this.registerServerEvents();
  }

  private registerServerEvents(): void {
    super.registerServerEvent('UpdateVisit', (data: Visit) => {
      this.visitService.updateAppointmentVisit(data);
    });

    super.registerServerEvent('AddVisit', (data: Visit) => {
      // this.appointmentService.updateScheduleAppointments().subscribe();
    });

    super.registerServerEvent('DeleteVisit', (data: Visit) => {
      // this.appointmentService.updateScheduleAppointments().subscribe();
    });
  }
}
