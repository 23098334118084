import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription, Subject } from 'rxjs';

import { PhotoEditingService } from '@services/photo-editing.service';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { ImageTransform } from '../photo-rotation/photo-rotation.component';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.less']
})
export class PhotoComponent implements OnInit, OnChanges {
  @Input() photoSubscription: Subscription;
  @Input() editMode: boolean;
  @Input() photo: PhotoMetaData;
  @Input() undoPhotoEdit: Subject<void>;
  @Input() initialized = false;
  @Input() photoEditorConfig: any;
  @Output() photoClickedData: EventEmitter<any> = new EventEmitter();
  loading = false;
  isTallPhoto(imageEl){
    if (imageEl && imageEl.style && imageEl.style.height){
      return imageEl.style.height > imageEl.style.width;
    }
    return true;
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  // rotation = 0;
  scale = 0;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  imageURL: any;
  loaded = false;

  constructor(
    private photoEditingService: PhotoEditingService,
  ) {
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editMode']) {
      if (changes['editMode'].currentValue) {
        this.loaded = false;

        this.toDataURL(this.photo.filePath)
          .then(dataUrl => {
            this.loaded = true;
            this.imageURL = dataUrl;
          });
      } else {
        this.loading
        this.canvasRotation = 0;
        this.imageURL = null;
        this.loaded = false;
      }
    }
    else if (changes['photo'] && changes['photo'].currentValue && changes['photo'].currentValue.id && this.photo && this.photo.id != changes['photo'].currentValue.id){
      this.loading = true;
    }
   }

  toDataURL = url => {
     return fetch(url)
     .then(response => response.blob())
     .then(blob => new Promise((resolve, reject) => {
       const reader = new FileReader()
       reader.onloadend = () => resolve(reader.result);
       reader.onerror = reject;
       reader.readAsDataURL(blob);
     }));
  }

  public photoClicked() {
    this.photoClickedData.emit();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event) {
    this.photoEditingService.photoCropped();
    this.croppedImage = event.base64;
    const file = this.dataURLtoFile(event.base64, 'default.png');
    this.photoEditingService.fileChanged(file);
  }

  public imageLoaded() {
    this.showCropper = true;
  }

  public cropperReady(sourceImageDimensions) {
  }

  public loadImageFailed() {
  }

  public rotateLeft() {
      this.canvasRotation--;
      this.flipAfterRotate();
  }

  public rotateRight() {
      this.canvasRotation++;
      this.flipAfterRotate();
  }

  private flipAfterRotate() {
      const flippedH = this.transform.flipH;
      const flippedV = this.transform.flipV;
      this.transform = {
          ...this.transform,
          flipH: flippedV,
          flipV: flippedH
      };
  }

  dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
  }
}
