import { Component, OnInit, OnDestroy, ElementRef, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { TreatmentPlan } from '@models/treatment-planning/treatment-plan';
import { Patient } from '@models/patient';
import { Subject } from 'rxjs';
import { PatientService } from '@services/patient.service';
import { takeUntil } from 'rxjs/operators';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { TreatmentPlanFormService } from '@services/treatment-planning/treatment-plan-form.service';
import { TreatmentPlanPhoto } from '@models/treatment-planning/treatment-plan-photo';
import { isNullOrUndefined } from '@app/shared/helpers';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmUnsavedChangesDialogComponent } from '../../../../../../management/dialogs/confirm-unsaved-changes/confirm-unsaved-changes.component';
import { ImageService } from '@services/image.service';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddPhotoComponent } from '../../../../patient-photos-tab/add-photo/add-photo.component';
//Temporarily commented out until we reinstate treatment plan photos #1162
@Component({
  selector: 'app-treatment-plan-draw-tool',
  templateUrl: './treatment-plan-draw-tool.component.html',
  styleUrls: ['./treatment-plan-draw-tool.component.less'],
})
export class TreatmentPlanDrawToolComponent implements OnInit, OnDestroy {
  patient: Patient;
  treatmentPlan: TreatmentPlan;
  unsub = new Subject<any>();
  loading = false;
  photosLoaded = false;
  photos: TreatmentPlanPhoto[];
  editingIndex: number;
  maxPhotoHeight: number;
  @ViewChildren('image') images: QueryList<ElementRef>;
  @ViewChildren('drawTool') drawTools: QueryList<ElementRef>;

  constructor(
    private patientService: PatientService,
    private treatmentPlanService: TreatmentPlanService,
    private treatmentPlanFormService: TreatmentPlanFormService,
    private confirmDialog: MatDialog,
    private imageService: ImageService,
    private modalService: NgbModal
  ) {
    this.patient = this.patientService.patientPanelPatient;
    // By default the user it not annotating on any photos
    this.editingIndex = -1;
    this.maxPhotoHeight = 475;
  }

  ngOnInit() {
    this.treatmentPlanService.getTreatmentPlanByPatientId(this.patient.patientId).subscribe((tp: TreatmentPlan) => {
      this.treatmentPlan = tp;
      if (this.treatmentPlan) {
        // if (this.treatmentPlan.photos.length === 0) {
        //   // This is a new treatment plan so there are no photos
        //   // Add the default photos and save them
        //   // turned off in #802/#794
        //   this.treatmentPlanService.addTreatmentPlanPhotos(this.treatmentPlan, this.patient);
        // } else {
        this.photos = this.treatmentPlan.photos;
        this.onUpdateLoadingStatus();
      }
      //}
    });

    this.patientService.thePatientUpdated$.pipe(takeUntil(this.unsub)).subscribe((patient) => {
      this.patient = patient;
      this.getTreatmentPlan();
    });

    this.treatmentPlanService.treatmentPlanUpdated$.pipe(takeUntil(this.unsub)).subscribe((tp) => {
      this.treatmentPlan = tp;
      this.photos = this.treatmentPlan.photos;
      this.editingIndex = -1;
      this.onUpdateLoadingStatus();
    });

    this.treatmentPlanFormService.saveTreatmentPlanPhotos$.pipe(takeUntil(this.unsub)).subscribe(() => {
      // Trigger image save
      this.drawTools.forEach((drawTool) => {
        // @ts-ignore
        drawTool.saveImage();
      });
    });
  }

  onSave(data: Blob, index: number, newPhoto = false) {
    const oldPhoto = this.photos[index].photo;
    // Get the height of the exported photo
    this.loading = true;
    const metadata: FilePropertyBag = {
      type: data.type,
    };
    const file = new File([data], oldPhoto.imageName, metadata);
    oldPhoto.fileId = null;
    oldPhoto.filePath = null;
    oldPhoto.filePathThumb = null;
    oldPhoto.imageHeight = 0;
    if (newPhoto) {
      oldPhoto.id = 0;
      oldPhoto.isTreatmentPlanOriginal = true;
      oldPhoto.isOriginal = true;
    }
    this.imageService.uploadPhoto(file, oldPhoto).subscribe((photo: PhotoMetaData) => {
      this.onUpdateTreatmentPlanPhoto(photo, index);
    });
  }

  onUpdateTreatmentPlanPhoto(photo: PhotoMetaData, index: number) {
    this.loading = true;
    const newPhoto = new TreatmentPlanPhoto({
      id: this.photos[index].id,
      photo: photo,
      treatmentPlanId: this.treatmentPlan.id,
    });

    this.photos[index] = newPhoto;

    this.treatmentPlan.photos = this.photos;

    this.treatmentPlanFormService.updateTreatmentPlanPhotos(this.treatmentPlan.photos);
    this.treatmentPlanFormService.editingPhoto = false;
    // this.treatmentPlanFormService.saveTreatmentPlan();
  }

  onCancel() {
    this.editingIndex = -1;
    this.treatmentPlanFormService.treatmentPlanDirty = false;
    this.treatmentPlanFormService.editingPhoto = false;
  }

  onCalculateImageScale(index: number) {
    const photo = this.photos[index].photo;
    if (isNullOrUndefined(photo) || isNullOrUndefined(photo.imageHeight)) {
      return 1;
    }
    const currHeight = photo.imageHeight;
    const result = Math.round((this.maxPhotoHeight / currHeight) * 10) / 10;
    return result < 0.1 ? 0.1 : result;
  }

  onEdit(index: number) {
    if (this.editingIndex >= 0) {
      // Actively editing
      const dialogRef = this.confirmDialog.open(ConfirmUnsavedChangesDialogComponent, {
        width: '250px',
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsub))
        .subscribe((result) => {
          if (result === 'confirm') {
            this.onDetermineHeight(index);
            this.editingIndex = index;
            this.treatmentPlanFormService.editingPhoto = true;
            this.treatmentPlanFormService.treatmentPlanDirty = true;
          }
        });
    } else {
      this.editingIndex = index;
      this.onDetermineHeight(index);
      this.treatmentPlanFormService.editingPhoto = true;
      this.treatmentPlanFormService.treatmentPlanDirty = true;
    }
  }

  onDetermineHeight(index: number) {
    if (isNullOrUndefined(this.photos[index].photo.imageHeight) || this.photos[index].photo.imageHeight === 0) {
      this.photos[index].photo.imageHeight = this.images.toArray()[index].nativeElement.naturalHeight;
    }
  }

  async onRefresh(index: number) {
    this.loading = true;
    let filePath: string;
    if (index === 0) {
      // Face
      filePath =
        this.patient.gender.toLowerCase() === 'female'
          ? '../../../../../../../assets/service-detail/FemaleInjectables.jpg'
          : '../../../../../../../assets/service-detail/MaleInjectables.jpg';
    } else {
      // Body
      filePath =
        this.patient.gender.toLowerCase() === 'female'
          ? '../../../../../../../assets/service-detail/FemaleCoolsculpting.jpg'
          : '../../../../../../../assets/service-detail/MaleCoolsculpting.jpg';
    }

    //   await this.treatmentPlanService.createFile(filePath).then((file) => this.onSave(file, index, true));
  }

  onSetPhoto(index: number) {
    const modalRef = this.modalService.open(AddPhotoComponent, { windowClass: 'add-photo-modal', centered: true });
    (modalRef.componentInstance as AddPhotoComponent).singleImageSelect = true;
    (modalRef.componentInstance as AddPhotoComponent).showGallery = true;
    modalRef.result
      .then((photoMetaData) => {
        //this.treatmentPlanService.createFile(photoMetaData.filePath).then((file) => this.onSave(file, index, true));
      })
      .catch(() => {
        // user exited the modal without selecting a photo
      });
  }

  onUpdateLoadingStatus() {
    if (!isNullOrUndefined(this.photos) && this.photos.length > 0) {
      this.photosLoaded = true;
      this.loading = false;
    }
  }

  private getTreatmentPlan() {
    this.treatmentPlanService.getTreatmentPlanByPatientId(this.patient.patientId).subscribe((tp) => {
      this.treatmentPlan = tp;
      this.photos = this.treatmentPlan.photos;
      this.onUpdateLoadingStatus();
    });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
