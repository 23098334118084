<div class="em-panel" [ngClass]="{ 'em-panel--huge': isHuge, 'em-panel--medium': isMedium }">
  <div class="white-background">
    <div class="px-2 py-1 spring-green-background d-flex align-items-center">
      <button type="button" class="ml-auto white-font btn-clean bigger" (click)="close()">
        <i class="far fa-times"></i>
      </button>
    </div>
    <div class="d-flex flex-column" style="max-height: 90dvh">
      <ng-scrollbar class="flex-grow-1" [autoHeightDisabled]="false">
        <ng-content></ng-content>
      </ng-scrollbar>
    </div>
  </div>
</div>
