import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ReportsService } from '@services/reports.service';
import { GridDataResult, DataStateChangeEvent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { PatientReport } from '@models/reports/patient/patient-reports';
import { Subject, Observable, pipe } from 'rxjs';
import { takeUntil, map, take } from 'rxjs/operators';
import { IReportComponent } from '@models/reports/ireport-component';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { DataSourceRequestState, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Router } from '@angular/router';
import { PatientService } from '@services/patient.service';

@Component({
  selector: 'app-bd-points-reports',
  templateUrl: './bd-points-reports.component.html',
  styleUrls: ['./bd-points-reports.component.less']
})
export class BDPointsReportsComponent implements OnInit, OnDestroy, IReportComponent {
  @ViewChild('grid') grid;
  gridView: GridDataResult;
  gridState: DataSourceRequestState;
  pageable: any;
  loading: boolean = false;
  unsub: Subject<void> = new Subject<void>();

  constructor(
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private reportsService: ReportsService,
    private patientService: PatientService,
    private router: Router
  ) { }

  ngOnInit() {
    this.initDefaultValue();
    this.getReportData();
    this.allData = this.allData.bind(this);
  }

  initDefaultValue() {
    this.gridState = {
      skip: 0,
      take: 100,
      sort: [{field: 'invoiceDate', dir: 'asc'}]
    };

    this.gridView = {
      data: [],
      total: 0
    };
  }

  getReportData() {
    this.loading = true;
    this.reportsService.getBDPointsReportEntities(this.gridState)
      .pipe(takeUntil(this.unsub))
      .subscribe((patientReports: any) => { 
        this.gridView = {
          data: patientReports.data,
          total: patientReports.total || patientReports.data.length
        };
        this.loading = false;
    });
  }

  navigateToPatientAccountTab(patientId: number) {
    this.patientService.getPatientById(patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((patient: any) => {
        this.patientService.patientPanelPatient = patient;
        this.router.navigate(['/reports', { outlets: { 'action-panel': ['patient', patientId + '_patientprofiletab', 'patienttabs', 'patientaccounttab', 'overview'] } }]);
      });
  }

  navigateToPatientInvoice(patientId: number, invoiceId: number) {
    this.patientService.getPatientById(patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((patient: any) => {
        this.patientService.patientPanelPatient = patient;
        this.router.navigate(['/reports', { outlets: { 'action-panel': ['patient', patientId + '_patientprofiletab', 'patienttabs', 'patientaccounttab', 'invoice', invoiceId] } }]);
      });
  }

  exportToExcel() {
    this.grid.saveAsExcel();
  }

  onExcelExport(e: any) {
    this.reportsService.onExcelExport(e, 'Patients Report');
  }

  pageChange(event: DataStateChangeEvent) {
    this.gridState = event;
    this.getReportData();
  }

  sortChange(sort: SortDescriptor[]) {
    this.gridState.sort = sort;
    this.gridView.data = orderBy(this.gridView.data, this.gridState.sort);
  }

  sortIconClass(column: any) {
    return this.gridState.sort.length && (this.gridState.sort[0].field === column.field && this.gridState.sort[0].dir === 'asc') ? 'fad fa-sort-up'
           : this.gridState.sort.length && (this.gridState.sort[0].field === column.field && this.gridState.sort[0].dir === 'desc') ? 'fad fa-sort-down'
           : 'fas fa-sort';
  }

  allData = (): Observable<any> => {
    const gridState: DataSourceRequestState = {};
    return this.reportsService.getPatientReportEntities(gridState)
             .pipe(map((patientReports: any) => {
                return {
                  data: patientReports.data.map((item: PatientReport) => ({...item, patientLastName: item.patientName, invoiceDate: this.datePipe.transform(item.invoiceDate,  'yyyy-MM-dd'), salesAmount: this.currencyPipe.transform(item.salesAmount)})),
                  total: patientReports.total,
                };
              }));
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
