import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CampaignPreviewRecipient } from '@models/retention-campaigns/campaign-preview-recipient';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { FormatterService } from '@services/formatter.service';
import { PatientService } from '@services/patient.service';
import { RetentionCampaignsService } from '@services/retention-campaigns.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RetentionCampaignType } from '../../../../../models/retention-campaigns/retention-campaign-types';

@Component({
  selector: 'app-campaign-recipients-grid',
  templateUrl: './campaign-recipients-grid.component.html',
  styleUrls: ['./campaign-recipients-grid.component.less'],
})
export class CampaignRecipientsGridComponent implements OnInit {
  loading: boolean;
  unsub = new Subject<any>();

  @Input() gridState: DataSourceRequestState;

  @Input()
  get details(): CampaignPreviewRecipient[] {
    return this._details;
  }
  set details(values: CampaignPreviewRecipient[]) {
    this._details = values;
    this.loadItems();
  }

  private _details: CampaignPreviewRecipient[] = [];
  public gridView: GridDataResult;
  public pageSize = 100;
  @Input() public skip = 0;
  public info = true;

  public communicationType = RetentionCampaignType;

  constructor(
    private router: Router,
    public formatterService: FormatterService,
    private retentionCampaignsService: RetentionCampaignsService,
    private patientService: PatientService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {}

  navigateToPatientProfile(patientId: number) {
    this.patientService
      .getPatientById(patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((patient: any) => {
        this.loading = true;
        this.patientService.patientPanelPatient = patient;

        this.retentionCampaignsService.refreshRequired = false;
        this.retentionCampaignsService.retentionCampaignSettingsUpdated.next();

        // Close existing
        this.dialog.closeAll();
        this.router.navigate(['/management/communication/retention-campaigns/', { outlets: { 'action-panel': null } }]);
        // Open patient panel
        this.router.navigate([
          '/management/communication/retention-campaigns',
          { outlets: { 'action-panel': ['patient', patient.patientId + '_patientprofiletab'] } },
        ]);
        this.loading = false;
      });
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }

  private loadItems(): void {
    if (this.details) {
      this.gridView = {
        data: this.details.slice(this.skip, this.skip + this.pageSize),
        total: this.details.length,
      };
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  getCommunicationType(ct: RetentionCampaignType) {
    let typeString = '';
    switch (ct) {
      case RetentionCampaignType.Email:
        typeString = 'Email';
        break;
      case RetentionCampaignType.SMS:
        typeString = 'SMS';
        break;
      case RetentionCampaignType.EmailSms:
        typeString = 'Email/SMS';
        break;
    }
    return typeString;
  }
}
