<ngx-loading [show]="loading"></ngx-loading>
<kendo-grid
  class="emr-kendo-grid"
  [data]="gridView"
  [style.maxHeight.%]="100"
  [pageSize]="gridState.take"
  [pageable]="true"
  [skip]="gridState.skip"
  [sort]="gridState.sort"
  [sortable]="{
    allowUnsort: false,
    mode: 'single'
  }"
  (pageChange)="pageChange($event)"
  (sortChange)="sortChange($event)"
  (excelExport)="onExcelExport($event)"
  #grid="kendoGrid"
>
  <kendo-grid-column field="patientLastName" title="Name">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <button type="button" class="btn-clean px-0 text-left" (click)="navigateToPatientChartTab(dataItem.patientId)">
        {{ dataItem.patientName }}
      </button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="dateOfBirth" title="Date of Birth">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{ dataItem.dateOfBirth | date : 'yyyy-MM-dd' }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="homePhoneNumber" title="Home Phone">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="mobilePhoneNumber" title="Mobile Phone">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="email" title="Email">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="fullAddress" title="Address">
    <ng-template kendoGridHeaderTemplate let-column>
      {{ column.title }}
      <i class="ml-1" [ngClass]="sortIconClass(column)"></i>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-excel fileName="list_patients_report.xlsx" [fetchData]="allData"></kendo-grid-excel>
</kendo-grid>
