export class SquareCard {
	version: number;
	bin: string;
	prepaid_type: string;
	card_type: string
	enabled: boolean;
	reference_id: string;
	merchant_id: string;
	customer_id: string;
	fingerprint: string;
	billing_address: any;
	cardholder_name: string;
	exp_year: number;
	exp_month: number;
	last_4: string;
	card_brand: string;
	id: string;
	card_co_brand: string;
}