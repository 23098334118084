<div class="edit-container position-absolute d-block z-index-3 white-background">
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0;">
    <form [formGroup]="tagsForm" (ngSubmit)="onSubmit()">
      <div class="row mx-0 teal-background">
        <div class="col px-2">
          <h6 class="white-font uppercase p-2 mb-0">
            {{actionType}} Tag
          </h6>
        </div>
      </div>
      <div class="row mx-0 mt-2">
        <div class="col px-2">
          <mat-form-field class="w-100">
            <input 
              matInput
              formControlName="title" 
              placeholder="Tag Title" 
              aria-label="Tag Title" 
              required/>
          </mat-form-field>
        </div>
      </div> 
      <div class="row mx-0 mt-2">
        <div class="col px-2"> 
          <mat-form-field   class="w-100" >
            <mat-select     
              name="type" 
              required
              formControlName="type" >
                <mat-option *ngFor="let tagType of tagTypes" [value]="tagType">
                  {{tagService.getTagTypeString(tagType)}} 
                </mat-option>
            </mat-select>
           
            <mat-placeholder class="placeholder">Tag Type</mat-placeholder>
          </mat-form-field>
        </div>
      </div> 
      <div class="row mx-0 mt-2">
        <div class="col px-2">
          <mat-form-field >
            <mat-select
              formControlName="hexColour" 
              id="tagColourInput"
              placeholder="Tag Colour"
              aria-label="Tag Colour"
              [ngStyle]="getSelectedOptionStyles()" 
              >

              <mat-option
                class="color-option"
                *ngFor="let color of serviceColours | keyvalue; let i = index"
                [value]="color.key" 
                [ngStyle]="getOptionStyles(color)"
                 >
                {{ color.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div> 
        
      <div class="row mt-2 mx-0">
        <div class="col px-2 text-center">
          <button 
            type="submit" 
            class="btn em-btn em-btn-green" 
            [disabled]="!tagsForm.valid">
              {{actionType}}
            </button>
        </div>
        <div class="col px-2 text-center">
          <button 
            class="btn em-btn em-btn-green" 
            (click)="cancelUpdate()">
              Cancel
          </button>
        </div>
      </div>
    </form>
  </perfect-scrollbar>
</div>
  