import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ReportsService } from '@services/reports.service';
import { GridDataResult, DataStateChangeEvent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ListPatientReport } from '@models/reports/listpatient/list-patient-reports';
import { Subject, Observable, pipe } from 'rxjs';
import { takeUntil, map, take } from 'rxjs/operators';
import { IReportComponent } from '@models/reports/ireport-component';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { DataSourceRequestState, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Router } from '@angular/router';
import { PatientService } from '@services/patient.service';
/**
 * MARK FOR DELETION ListPatientReportsComponent
 */
@Component({
  selector: 'app-list-patient-reports',
  templateUrl: './list-patient-reports.component.html',
  styleUrls: ['./list-patient-reports.component.less']
})
export class ListPatientReportsComponent implements OnInit, OnDestroy, IReportComponent {
  @ViewChild('grid') grid;
  gridView: GridDataResult;
  gridState: DataSourceRequestState;
  pageable: any;
  loading: boolean = false;
  unsub: Subject<void> = new Subject<void>();

  constructor(
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private reportsService: ReportsService,
    private patientService: PatientService,
    private router: Router
  ) { }

  ngOnInit() {
    this.initDefaultValue();
    this.getReportData();
    this.allData = this.allData.bind(this);
  }

  initDefaultValue() {
    this.gridState = {
      skip: 0,
      take: 100,
      sort: [{field: 'patientLastName', dir: 'asc'}]
    };

    this.gridView = {
      data: [],
      total: 0
    };
  }

  getReportData() {
    this.loading = true;
      this.reportsService.getListPatientReportEntities(this.gridState)
      .pipe(takeUntil(this.unsub))
      .subscribe((patientReports: any) => {
        this.gridState.sort = [{field: 'patientName', dir: 'asc'}];
        this.gridView = {
          data: patientReports.data,
          total: patientReports.total || patientReports.data.length
        };
        this.loading = false;
    });
  }

  navigateToPatientChartTab(patientId: number) {
    this.patientService.getPatientById(patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((patient: any) => {
        this.patientService.patientPanelPatient = patient;
        this.router.navigate(['../../schedule', { outlets: { 'action-panel': ['patient', patientId + '_patientcharttab', 'patienttabs', 'patientcharttab', 'overview'] } }]);
      });
  }

  exportToExcel() {
    this.grid.saveAsExcel();
  }

  onExcelExport(e: any) {
    this.reportsService.onExcelExport(e, 'Patient List Report');
  }

  pageChange(event: PageChangeEvent) {
    this.gridState.skip = event.skip;
    this.getReportData();
  }

  sortChange(sort: SortDescriptor[]) {
    this.gridState.sort = sort;
    this.gridView.data = orderBy(this.gridView.data, this.gridState.sort);
  }

  sortIconClass(column: any) {
    return this.gridState.sort.length && (this.gridState.sort[0].field === column.field && this.gridState.sort[0].dir === 'asc') ? 'fad fa-sort-up'
           : this.gridState.sort.length && (this.gridState.sort[0].field === column.field && this.gridState.sort[0].dir === 'desc') ? 'fad fa-sort-down'
           : 'fas fa-sort';
  }

  allData = (): Observable<any> => {
    const gridState: DataSourceRequestState = {};
      return this.reportsService.getListPatientReportEntities(gridState)
             .pipe(map((patientReports: any) => {
                return {
                    data: patientReports.data.map((item: ListPatientReport) => ({
                        ...item,
                        patientLastName: item.patientName,
                        dateOfBirth: this.datePipe.transform(item.dateOfBirth, 'yyyy-MM-dd'),
                        homePhoneNumber: item.homePhoneNumber,
                        mobilePhoneNumber: item.mobilePhoneNumber,
                        email: item.email,
                        fullAddress: item.fullAddress
                    })),
                  total: patientReports.total,
                };
              }));
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
