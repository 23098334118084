import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { TagType } from '@models/tag/tag-type';
import { Tag } from '@models/tag/tag';
import { TagService } from '@services/tag.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, of, throwError } from 'rxjs';
import { FormControl } from '@angular/forms';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { takeUntil, startWith, map, catchError } from 'rxjs/operators';
import { ConfirmDeleteDialogComponent } from '@app/management/dialogs/confirm-delete/confirm-delete.component';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { isNullOrUndefined } from '@app/shared/helpers';

@Component({
  selector: 'app-catalogue-tags',
  templateUrl: './catalogue-tags.component.html',
  styleUrls: ['./catalogue-tags.component.less'],
})
export class CatalogueTagsComponent implements OnInit, OnDestroy {
  searchCtrl: FormControl;
  filteredTags: Observable<Tag[]>;

  unsub: Subject<void> = new Subject<void>();
  disableGrid = false;
  loading = false;

  tags: Tag[];
  public gridView: GridDataResult;

  gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  editedRowIndex: number;
  editedDataItem: Tag;

  constructor(
    private tagService: TagService,
    private catalogueUpdatesService: CatalogueUpdatesService,
    private matDialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.initDefaultValue();
    this.initData();
    this.initSearchControl();
    this.refreshData();
  }

  initDefaultValue() {
    this.gridView = {
      data: [],
      total: 0,
    };
    this.loading = true;
    this.disableGrid = false;
    this.searchCtrl = new FormControl();
  }

  initData() {
    this.tagService
      .getAllPhotoTags()
      .pipe(takeUntil(this.unsub))
      .subscribe((textTemplates: Tag[]) => {
        this.tags = textTemplates.filter((tt) => tt.type != TagType.photoType);

        this.gridView = {
          data: textTemplates.slice(),
          total: textTemplates.length,
        };

        this.loading = false;
        this.disableGrid = false;
      });
  }

  initSearchControl() {
    this.filteredTags = this.searchCtrl.valueChanges.pipe(map((textTempltate) => this.filterTags(textTempltate)));
  }

  refreshData() {
    this.catalogueUpdatesService.catalogueUpdated.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.disableGrid = false;
      if (this.catalogueUpdatesService.refreshRequired) {
        this.catalogueUpdatesService.refreshRequired = false;
        this.initData();
      }
    });
  }

  filterTags(name: string) {
    let filterResults: Tag[] = [];
    if (!isNullOrUndefined(name) && name !== '') {
      this.gridView = {
        data: this.tags.filter((textTempltate) => textTempltate.title.toLowerCase().includes(name.toLowerCase())),
        total: this.tags.filter((textTempltate) => textTempltate.title.toLowerCase().indexOf(name.toLowerCase()))
          .length,
      };
      filterResults = this.tags.filter((textTempltate) =>
        textTempltate.title.toLowerCase().includes(name.toLowerCase())
      );
    } else {
      this.gridView = {
        data: this.tags,
        total: this.tags.length,
      };
      filterResults = [];
    }
    return filterResults;
  }

  public editHandler({ dataItem }) {
    this.disableGrid = true;
    this.router.navigate([
      '/management/catalogue/custom-tags',
      { outlets: { 'action-panel': ['edit-tag', dataItem.tagId] } },
    ]);
  }

  onAddClick() {
    this.disableGrid = true;
    this.router.navigate(['/management/catalogue/custom-tags', { outlets: { 'action-panel': ['edit-tag', '_'] } }]);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public removeHandler({ dataItem }) {
    const dialogRef = this.matDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'delete') {
          this.tagService
            .removeCustomTag(dataItem)
            .pipe(
              takeUntil(this.unsub),
              catchError((err) => {
                if (err && err.error == 'photos_associated') {
                  //let the user know
                  this.matDialog.open(GenericDialogComponent, {
                    width: '250px',
                    data: {
                      showCancel: false,
                      title: 'Cannot Delete',
                      content: 'This tag is being used by active photos...',
                      confirmButtonText: 'Ok',
                    },
                  });
                  return of();
                } else return throwError(err);
              })
            )
            .subscribe(() => {
              this.catalogueUpdatesService.refreshRequired = true;
              this.catalogueUpdatesService.catalogueUpdateComplete();
            });
        }
      });
  }

  TagType = TagType;
  getTagType(tagType: number) {
    return this.tagService.getTagTypeString(tagType);
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
