<div class="container h-100"> 
  <div class="row h-75 px-1 pt-1 flex-column flex-nowrap" style="border-top:1px solid grey" *ngIf="consentedImages.length">
    <div class="d-flex flex-norwap align-items-center justify-content-between mx-2">
      <button class="w-100" style="border-radius: 20px;" (click)="onChangePage(-1)">Previous</button>
      <div style="text-decoration: underline;" class="d-flex flex-nowrap w-100 justify-content-center">Page {{page}} of {{patientPhotos.length / pageSize | number: '1.0-0'}}</div>
      <button class="w-100" style="border-radius: 20px;" (click)="onChangePage(1)">Next</button>
    </div>
    <div class="photo-gallery-wrapper mt-1" *ngFor="let item of patientPhotos | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
      <div *ngIf="patientShouldShow(item)">
        <h6 class="sub-heading navy-font">
          <a class="pointer" (click)="navigateToPatientPhotoTab(item.photos)">{{ item.userName }}</a>
          </h6>
        <div class="photo-wrapper d-flex flex-wrap">
          <ng-container *ngIf="!seriesMode">
            <div
              *ngFor="let photo of item.photos; let i = index">
              <div *ngIf="showStockImages || !photo.isStockPhoto" 
              class="photo-container d-flex rounded-2 position-relative"
              (click)="imageClicked(photo)"
              [ngClass]="{'selected-photo': selectedPhoto?.id === photo.id && selectedPhoto?.isSeries === photo.isSeries}">
                <ul *ngIf="photo.isSeries  && photo.seriesPhotos?.length > 0; else regThumb" class="series-thumbnail">
                  <li *ngFor="let p of photo.seriesPhotos;let i = index;"  [ngStyle]="{'width':100 / photo?.seriesPhotos?.length +  '%' }">
                    <img [src]="p.filePathThumb"  class="series-thumb-img" [ngStyle]="{'margin-left': i != photo?.seriesPhotos?.length - 1 ? (-1 * (100 / photo?.seriesPhotos?.length)) + '%' : '0'}">
                  </li> 
                </ul>
                <ng-template #regThumb>
                  <img [src]="photo.filePathThumb"  class="gallery-photo"  >
                </ng-template>
                <div class="photo-overlay d-flex white-font position-absolute">
                  {{ getDateTakenStr(photo) }}
                  <i *ngIf="photo.isFavourite" class="fas fa-heart  ml-auto d-flex align-items-center" title="Favourite"></i>
                </div> 
              </div>
              <!-- <ngx-loading [show]="photo?.loading"></ngx-loading>
              <a type="button" *ngIf="photo?.isSeries"   class="close em-btn btn-clean download-gallery-button"   aria-label="Close"  (click)="$event.stopPropagation();downloadSeries(photo)">
                <i class="fal fa-download"></i>
              </a>
              <a type="button" *ngIf="!photo?.isSeries" download class="close em-btn btn-clean download-gallery-button"   aria-label="Close" [href]="photo.filePath">
                <i class="fal fa-download"></i>
              </a> -->
  
             
            </div>
          </ng-container>
          <ng-container *ngIf="seriesMode">
            <div
              class="photo-container d-flex rounded-2 position-relative"
              (click)="imageSeriesClicked(photo)"
              [ngClass]="{'selected-photo': selectedSeriesPhotos[photo.id] }"
              *ngFor="let photo of item.photos">
              <img [src]="photo.filePathThumb"  class="gallery-photo"  >
              <div class="photo-overlay d-flex white-font position-absolute">
                {{ getDateTakenStr(photo) }}
              </div>
              <div *ngIf="photo.isGlobalSharedImage" class="photo-star d-flex white-font position-absolute">
                <i class="fas fa-star p-1"></i>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
     
    </div>
  </div>
</div>
