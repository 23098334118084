import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { DataSourceRequestState, SortDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { CommunicationsService } from '@services/communications.service';
import { takeUntil } from 'rxjs/operators';
import { isNullOrUndefined } from '@app/shared/helpers';
import { PatientService } from '@services/patient.service';
import { MasterOverlayService } from '@services/actionpanel.service';
import { BlobService } from '@services/blob.service';
import { MatDialog } from '@angular/material/dialog';
import { PhotoLightboxComponent } from '@app/patients/patient-tabs/shared-photos/photo-lightbox/photo-lightbox.component';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-communication-summary',
  templateUrl: './communication-summary.component.html',
  styleUrls: ['./communication-summary.component.less'],
})
export class CommunicationSummaryComponent implements OnInit, AfterViewInit {
  @ViewChild('grid') grid;

  patientId: number;
  gridView: GridDataResult;
  gridState: DataSourceRequestState;
  pageable: any;
  loading = false;
  unsub: Subject<void> = new Subject<void>();
  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  activeDateFilter: string = '';
  activeTypeFilter: string = 'SMS,Email';
  searchValue = '';
  activeFilter;
  @ViewChild('emailFilterToggle') emailToggle: ElementRef;
  @ViewChild('smsFilterToggle') smsToggle: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private communicationsService: CommunicationsService,
    private patientService: PatientService,
    private router: Router,
    private masterOverlayService: MasterOverlayService,
    private blobService: BlobService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.initDefaultValue();
    this.filterByDate('TODAY');
  }

  ngAfterViewInit() {
    this.dateRange.valueChanges.subscribe(() => this.filterByDateRange());
  }

  search(searchValue: string) {
    this.searchValue = searchValue;
    this.gridState.skip = 0;
    this.gridState.take = 50;
    this.getCommunicationData(false);
  }

  clearFilter() {
    this.activeDateFilter = '';
    this.activeTypeFilter = 'SMS,Email';
    this.dateRange.reset();
    this.searchValue = null;
    this.filterByDate('TODAY');
  }

  initDefaultValue() {
    this.gridState = {
      skip: 0,
      take: 50,
      sort: [{ dir: 'desc', field: 'date' }],
      filter: {
        logic: 'or',
        filters: [],
      },
    };

    this.gridView = {
      data: [],
      total: 0,
    };
  }

  filterByDateRange() {
    this.activeDateFilter = 'DATE RANGE';
    this.gridState.skip = 0;
    this.gridState.take = 50;
    this.getCommunicationData();
  }

  getCommunicationData(loading = true) {
    this.loading = loading;
    const startDate = this.dateRange.get('start').value as Date;
    const endDate = this.dateRange.get('end').value as Date;
    this.communicationsService
      .getHistory(
        this.gridState,
        new Date(startDate.toDateString()),
        new Date(endDate.toDateString()),
        this.searchValue
        // { name: 'type', value: this.activeTypeFilter }
      )
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        this.gridView = {
          data: result.data,
          total: result.total || result.data.length,
        };
        this.loading = false;
      });
  }

  filterByType(event) {
    let amalgamatedKey = '';

    var emailToggleIn = this.emailToggle.nativeElement.checked;
    var smsToggleIn = this.smsToggle.nativeElement.checked;

    //console.log(event.srcElement.name);
    if (event.srcElement.name == 'smsFilter') {
      if (!emailToggleIn) {
        this.emailToggle.nativeElement.checked = true;
        emailToggleIn = true;
      }
    } else if (event.srcElement.name == 'emailFilter') {
      if (!smsToggleIn) {
        this.smsToggle.nativeElement.checked = true;
        smsToggleIn = true;
      }
    }

    if (smsToggleIn && emailToggleIn) {
      amalgamatedKey = 'SMS,Email';
    } else if (smsToggleIn) {
      amalgamatedKey = 'SMS';
    } else if (emailToggleIn) {
      amalgamatedKey = 'Email';
    }

    this.activeTypeFilter = amalgamatedKey;
    if (amalgamatedKey == 'SMS,Email') {
      this.gridState.filter = {
        logic: 'or',
        filters: [],
      };
    } else {
      this.gridState.filter = {
        logic: 'or',
        filters: [{ field: 'type', operator: 'eq', value: amalgamatedKey }],
      };
    }
    this.gridState.skip = 0;
    this.getCommunicationData();
  }

  filterByDate(key: string) {
    this.activeDateFilter = key;
    const now = new Date();
    this.dateRange = null;
    this.gridState.skip = 0;
    this.gridState.take = 50;

    switch (key) {
      case 'TODAY':
        this.dateRange.setValue({ start: now, end: now });
        break;

      case 'YESTERDAY':
        const yesterday = new Date(now.setDate(now.getDate() - 1));
        this.dateRange.setValue({ start: yesterday, end: yesterday });
        break;

      case '3DAYS':
        const threeDays = new Date(now.setDate(now.getDate() - 3));
        this.dateRange.setValue({ start: threeDays, end: new Date() });
        break;

      case 'WEEK':
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const week = new Date(today.setDate(today.getDate() - today.getDay()));
        this.dateRange.setValue({ start: week, end: new Date() });
        break;

      case 'MONTH':
        const month = new Date(now.getFullYear(), now.getMonth(), 1);
        this.dateRange.setValue({ start: month, end: new Date() });
        break;

      case 'YTD':
        const ytd = new Date(now.getFullYear(), 0);
        this.dateRange.setValue({ start: ytd, end: new Date() });
        break;
    }
  }

  pageChange(event: PageChangeEvent) {
    this.gridState.skip = event.skip;
    this.getCommunicationData();
  }

  sortChange(sort: SortDescriptor[]) {
    this.gridState.sort = sort;
    this.getCommunicationData();
  }

  filterChange(filter: CompositeFilterDescriptor) {
    this.gridState.filter = filter;
    this.getCommunicationData();
  }

  sortIconClass(column: any) {
    return this.gridState.sort.length &&
      this.gridState.sort[0].field === column.field &&
      this.gridState.sort[0].dir === 'asc'
      ? 'fad fa-sort-up'
      : this.gridState.sort.length &&
        this.gridState.sort[0].field === column.field &&
        this.gridState.sort[0].dir === 'desc'
      ? 'fad fa-sort-down'
      : 'fas fa-sort';
  }

  goToPatient(dataItem) {
    this.patientService.getPatientById(dataItem.patientId).subscribe((pat) => {
      if (!isNullOrUndefined(pat)) {
        this.patientService.patientPanelPatient = pat;
        this.masterOverlayService.masterOverlayState(true);
        this.router.navigate([
          '/management/communication/summary',
          { outlets: { 'action-panel': ['patient', pat.patientId + '_' + 'patientprofiletab'] } },
        ]);
      }
    });
  }

  navigateToPatientInvoice(patientId: number, reason: string) {
    const invoiceId = parseInt(reason);
    this.patientService
      .getPatientById(patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((patient: any) => {
        this.patientService.patientPanelPatient = patient;
        this.router.navigate([
          '/management/communication/summary',
          {
            outlets: {
              'action-panel': [
                'patient',
                patientId + '_patientprofiletab',
                'patienttabs',
                'patientaccounttab',
                'invoice',
                invoiceId,
              ],
            },
          },
        ]);
      });
  }

  openPhoto(url: string): void {
    this.dialog.open(PhotoLightboxComponent, {
      panelClass: 'lightbox-dialog',
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      data: {
        photoURL: this.applySaS(url),
      },
    });
  }

  applySaS(baseUrl: string): string {
    const readOnlySAS = this.blobService.getReadOnlySAS();
    return baseUrl + readOnlySAS;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
