<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>

<div class="edit-container edit-document position-absolute d-block z-index-3 white-background">
  <perfect-scrollbar style="max-width: 100%; top: 0; left: 0;">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">

      <div class="row mx-0 teal-background">
        <div class="col px-2">
          <h6 class="white-font uppercase p-2 mb-0">
            {{createOrEdit}} eForm
          </h6>
        </div>
      </div>

      <!-- Clinic Document Name-->
      <div class="row mx-0 mt-2">
        <div class="col">
          <mat-form-field class="w-100">
            <input 
              matInput 
              placeholder="eForm Title" 
              formControlName="name"
              autofocus />
          </mat-form-field>
        </div>
      </div>

      <div class="row mx-0 pt-1 pb-3">
        <div class="col">
          <label class="teal-font m-0">Add New Tag</label>
          <tag-input formControlName="tags" name="tags" [inputClass]="'tag-input'" #input [onlyFromAutocomplete]="true">
            <ng-template let-item="item" let-index="index">
              <div class="tag-pill" [ngStyle]="{ 'border-color': pillColours[item.type] }">
                <ng-container *ngIf="item.title; else text">
                  {{ item.title }}
                </ng-container>
                <ng-template #text>
                  {{ item.display }}
                </ng-template>
                <delete-icon (click)="input.removeItem(item, index)"></delete-icon>
              </div>
            </ng-template>
            <tag-input-dropdown 
              [focusFirstElement]="true" 
              [autocompleteItems]="tags" 
              [zIndex]="99999"
              [displayBy]="'title'" 
              [identifyBy]="'tagId'" 
              [showDropdownIfEmpty]="true" 
              [keepOpen]="false">
            </tag-input-dropdown>
          </tag-input>
        </div>
      </div>
      
      <div *ngIf="!form.value.filePath" class="row mx-0 pt-4 pb-3 grey-two-background">
        <div class="col px-2 mx-2">
          <label class="teal-font d-block m-0">Upload PDF</label>
          <label class="d-block file-label" [ngClass]="{'grey-five-font': form?.value?.file === null}">
            <ng-container *ngIf="form.value.file !== null; else showLabel">
              {{ form.value.file.name }}
            </ng-container>
            <ng-template #showLabel>
              Choose file
            </ng-template>
          </label>
          
          <label class="text-right d-block">
            <input type="file" class="invisible" (change)="onFileChange($event)" />
            <div class="btn em-btn em-btn-green">Browse</div>
          </label>
        </div>
      </div>
      <!-- Notes -->
      <!-- <div class="row mx-0 mt-2">
        <div class="col px-2">
          <mat-form-field class="w-100">
            <input matInput placeholder="Notes" formControlName="notes" />
          </mat-form-field>
        </div>
      </div> -->
      <!-- Submit Buttons-->
      <div class="row mx-4 mt-3">
        <button 
          type="submit" 
          class="btn em-btn em-btn-green ml-auto mr-2" 
          [disabled]="isFormDisabled()">
            {{ createOrEdit }}
        </button>
        <button 
          *ngIf="!isNew"
          type="button" 
          class="btn em-btn em-btn-green mr-2" 
          (click)="updateDocument()"
          [disabled]="isFormDisabled()">
            UPDATE
        </button>
        <button 
          class="btn em-btn em-btn-green" 
          (click)="cancelUpdate()">
            Cancel
        </button>
      </div>
    </form>
  </perfect-scrollbar>
</div>
