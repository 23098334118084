import { delay } from 'rxjs/operators';
import { PhotoEditingService } from '@services/photo-editing.service';
import { PhotoEventType } from './../../../../models/photo/photo-event-type';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BlobService } from '@services/blob.service';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { SeriesType } from '@models/photo/series-type';

@Component({
  selector: 'app-photo-series',
  templateUrl: './photo-series.component.html',
  styleUrls: ['./photo-series.component.less']
})
export class PhotoSeriesComponent implements OnInit {
  _photoSeriesList: PhotoMetaData[];
  readOnlySas: string;
  photoSeriesItems: PhotoMetaData[];
  SeriesType = SeriesType;
  translateValue = '0px';
  currentState = 0;
  maxTranslateValue: any;
  step = 50;
  @Input() seriesViewMode: boolean;
  @Input() seriesMode: boolean;
  @Input() seriesType: SeriesType;
  @Input()
  set photoSeriesList(photoSeriesList: PhotoMetaData[]) {
    this._photoSeriesList = photoSeriesList;
    this.formatData();
  }

  get photoSeriesList() {
    return this._photoSeriesList;
  }
  @Output() photoClickedData: EventEmitter<any> = new EventEmitter();

  constructor(
    private blobService: BlobService,
    private photoEditingService: PhotoEditingService
  ) { }

  ngOnInit() {
    const extionsMap = {
 
      [PhotoEventType.LoadPhoto]: photoEvent => {
     
        let currentPhoto = !photoEvent ? null : photoEvent.photo ? Object.assign({}, photoEvent.photo) : photoEvent.photo;
        if (currentPhoto&& currentPhoto.isSeries) { 
          this.photoSeriesList = currentPhoto.seriesPhotos ? currentPhoto.seriesPhotos : []; 
        }
      }, 
      [PhotoEventType.ClearPhoto]: photoEvent => {
        this.photoSeriesItems = [];
        this.photoSeriesList = [];
      }, 
 
    };

    this.photoEditingService.getPhotoSource()
      .pipe(delay(0))
      .subscribe(photoEvent => extionsMap[photoEvent.event](photoEvent));
  }

  formatData() {
    this.readOnlySas = this.blobService.getReadOnlySAS();
    if (this.photoSeriesList ) {
      this.photoSeriesItems = this.photoSeriesList.map(i => {
        const obj = Object.assign({}, i);

        if (i.filePath.indexOf('?') < 0) {
          i.filePath = i.filePath + this.readOnlySas;
        }
        if (obj.filePath.indexOf('?') < 0) {
          obj.filePath = obj.filePath + this.readOnlySas;
        }

        return obj;
      });
    }

    this.translateValue = '0px';
    this.currentState = 0;
    this.maxTranslateValue = (this.photoSeriesList.length - 2) * this.step;
  }

  incrementDecrecmentTransform(state: boolean) {
    if (state) {
      this.currentState = this.currentState + this.step;
      this.translateValue = `-${this.currentState}%`;
    } else {
      this.currentState = this.currentState - this.step;
      this.translateValue = `-${this.currentState}%`;
    }
  }

  public photoClicked(index: number) {
    this.photoClickedData.emit(index);
  }
}
