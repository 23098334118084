import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { TextTemplatesService } from '@services/text-templates.service';
import { TextTemplate } from '@models/text-template';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-apply-text-template',
  templateUrl: './apply-text-template.component.html',
  styleUrls: ['./apply-text-template.component.less']
})
export class ApplyTextTemplateComponent implements OnInit, OnDestroy {
  @Output() textTemplateContent: EventEmitter<string> = new EventEmitter();
  @Output() closeTemplateContent: EventEmitter<string> = new EventEmitter();

  selectedTextTemplate: TextTemplate;
  textTemplates: TextTemplate[] = [];

  unsub: Subject<void> = new Subject<void>();

  constructor(
    private textTemplatesService: TextTemplatesService
  ) { }

  ngOnInit() {
    this.getTextTemplates();
  }

  getTextTemplates() {
    this.textTemplatesService.getAllTextTemplates()
      .pipe(takeUntil(this.unsub))
      .subscribe((textTemplates: TextTemplate[]) => this.textTemplates = textTemplates);
  }

  applyTextTemplate() {
    this.textTemplateContent.next(this.selectedTextTemplate.text);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
