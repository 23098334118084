<div class="textarea-widget grey-two-background position-relative">
  <div
    class="d-flex flex-column white-background p-2"
    [ngClass]="{ 'bg-danger eggshell-font font-weight-bold': isDanger }"
  >
    <textarea
      [ngClass]="{ 'bg-danger eggshell-font font-weight-bold': isDanger }"
      matInput
      [(ngModel)]="textValue"
      (ngModelChange)="textValueChange($event)"
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      [cdkAutosizeMinRows]="minRows"
      [cdkAutosizeMaxRows]="maxRows"
    >
    </textarea>
    <button
      [style.visibility]="isEdited ? 'visible' : 'hidden'"
      class="save-btn btn em-btn-green position-absolute white-font mt-1 ml-auto"
      (click)="emitText.emit(textValue); isEdited = false"
    >
      SAVE
    </button>
  </div>
</div>
