
import { SeriesType } from '@models/photo/series-type';
import { PhotoMetaData } from '@models/photo/photo-meta-data';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ITagTypes, TagService } from '@services/tag.service';
import { PhotosFilterService } from '@services/photos-filter.service';
import { Tag } from '@models/tag/tag';
import { PhotoConsentType } from '@models/photo/photo-consent-type';


@Component({
  selector: 'app-photo-filter',
  templateUrl: './photo-filter.component.html',
  styleUrls: ['./photo-filter.component.less']
})
export class PhotoFilterComponent implements OnInit {
  @Output() changeState: EventEmitter<any> = new EventEmitter();
  allowAllShare = true;
  allowAllType = true;
  public get isFiltered(){
    return !this.allowAllShare || !this.allowAllType || this.favouriteMode || this.csMode ||  this.selectedTags.AutoGenerated.length > 0 || this.selectedTags.BodyPart.length > 0   || this.selectedTags.Service.length > 0   ;
  }
  showBalloons = false;

  selectedGender: "Male"|"Female" = null;

  @Input() allowServiceFilter = false;
  @Output() photosFiltered = new EventEmitter<PhotoMetaData[]>();
  _photos: PhotoMetaData[] = [];
  _filteredPhotos: PhotoMetaData[] = [];
  @Input() set photos(photos: PhotoMetaData[]) {
    if (!this.isFiltered ){
      this._photos = photos;
      this._filteredPhotos = photos;
      this.getTags();

    }
  }
  get photos(){
    return this._photos;
  }

  _favouriteMode: boolean = false;
  @Input() set favouriteMode(favouriteMode: boolean) {
    this._favouriteMode = favouriteMode;
  }
  get favouriteMode(){
    return this._favouriteMode;
  }

  @Output() showStockImagesChange: EventEmitter<any> = new EventEmitter();
  _showStockImages: boolean = false;
  @Input() set showStockImages(showStockImages: boolean) {
    this._showStockImages = showStockImages;
  }
  get showStockImages(){
    return this._showStockImages;
  }


  _csMode: boolean = false;
  @Input() set csMode(csMode: boolean) {
    this._csMode = csMode;
    this.selectedGender = null;
  }
  get csMode(){
    return this._csMode;
  }
  _adminMode: boolean = false;
  @Input() set adminMode(adminMode: boolean) {
    this._adminMode = adminMode;
    this.clearTags = true;
  }
  get adminMode(){
    return this._adminMode;
  }
  @Input() isFilterMode: boolean = false;
  @Input() isSeriesMode: boolean = false;
  _clearTags: boolean;
  @Input() set clearTags(clearTags: boolean) {
    this._clearTags = clearTags;
    if (clearTags) {
      this.selectedTags = {
        Service:   [],
        BodyPart:    [],
        PhotoType:   [],
        Supply: [],
        AutoGenerated: [],
      };

      this.typeFilters = {
        single: false,
        ba: false,
        series: false,
      }

      this.shareFilters = {
        none: false,
        internal: this.adminMode,
        external: this.adminMode
      }
      this._clearTags = false;
      this.getTags();
    }
  }


  get clearTags() {
    return this._clearTags;
  }

  tags: ITagTypes = {
    Service:   [],
    BodyPart:    [],
    PhotoType:   [],
    Supply: [],
    AutoGenerated: [],
  };
  _selectedTags: ITagTypes = {
    Service:   [],
    BodyPart:    [],
    PhotoType:   [],
    Supply: [],
    AutoGenerated: [],
  };

  set selectedTags(selectedTags){
    this._selectedTags = selectedTags;
  }

  get selectedTags(){
    return this._selectedTags;
  }

  typeFilters = {
    single: false,
    ba: false,
    series: false,
  }

  shareFilters = {
    none: false,
    internal: false,
    external: false
  }


  constructor(
    private tagService: TagService,
    private photosFilterService: PhotosFilterService
  ) { }

  ngOnInit() {
    this.getTags();
  }


  getTags(){

    this.tagService.getPhotoTagsByType()
    .subscribe(tags => {
      Object.keys(tags).forEach(tagKey => {
        if (this.photos){
          tags[tagKey] = tags[tagKey]
            .filter(tag => this.photos.find(p =>
              (p.tags.find(tag2 => tag.tagId == tag2.tagId) != null) ||
              (tagKey == "AutoGenerated" && p.relatedServiceTemplateIds  && p.relatedServiceTemplateIds.find(tag2 => tag.tagId == tag2) != null)
            ));   //only show relevent tags based on photo list present
        }
      })
      this.tags = tags;
    });
  }

  tagSelected(tagIndex: number, tagType: string){
    this.selectedTags[tagType].splice(tagIndex,1);
    this.basicFilter();
  }

  basicFilter( ){
    this.allowAllShare = (this.shareFilters.none && this.shareFilters.internal && this.shareFilters.external) ||
    (!this.shareFilters.none && !this.shareFilters.internal && !this.shareFilters.external); //allow all (no filter) if none or all are selected
    this.allowAllType = (this.typeFilters.single && this.typeFilters.ba && this.typeFilters.series) ||
        (!this.typeFilters.single && !this.typeFilters.ba && !this.typeFilters.series); //allow all (no filter) if none or all are selected

    this._filteredPhotos = this.photos
    // .filter(p => !this.csMode || (p.tags && p.tags.find(tag => tag.type == 1 && tag.title.toLowerCase().indexOf('coolsculpting') != -1)) != null) //for favourite filtering
    .filter(p => !this.favouriteMode || p.isFavourite ) //for favourite filtering
    .filter(p => {
      return  (this.shareFilters.none ? p.photoConsentTypeId == PhotoConsentType.None : false || this.allowAllShare) ||
      ( this.shareFilters.internal ? p.photoConsentTypeId == PhotoConsentType.Internal : false || this.allowAllShare) ||
      ( this.shareFilters.external ? p.photoConsentTypeId == PhotoConsentType.InternalExternal : false || this.allowAllShare)
    }).
    filter(p => {
      return  (this.typeFilters.single ? !p.isSeries : false || this.allowAllType) ||
      ( this.typeFilters.ba ? (p.isSeries && p.seriesType == SeriesType.BeforeAfter || p.tags.findIndex(tag => tag.tagId == Tag.typeTag.ba.tagId) != -1) : false || this.allowAllType) ||
      ( this.typeFilters.series ? (p.isSeries && p.seriesType == SeriesType.Multi || p.tags.findIndex(tag => tag.tagId == Tag.typeTag.multi.tagId) != -1) :false || this.allowAllType)
    })
    .filter(p => {
      return  this.selectedTags.AutoGenerated.length == 0 || this.selectedTags.AutoGenerated.some(item => p.relatedServiceTemplateIds.includes(+item.tagId));

    })
    .filter(p => !this.selectedGender || (p.patientGender == this.selectedGender)) //for favourite filtering
    .filter(p => {


      // let flatTags = [].concat.apply([],  Array.from(Object.keys(this.selectedTags)).map(a => tagsWithoutServices[a])).map(t => t.tagId); //this has been completely changed such that the only tags filtered this way will be body parts now
      let flatTags = [];
      flatTags =  flatTags.concat(this.selectedTags.BodyPart.map(bp => bp.tagId));
      flatTags =  flatTags.concat(this.selectedTags.Service.map(bp => bp.tagId));
      if (flatTags.length == 0) return true;
      return p.tags.some(tag => {
        return flatTags.indexOf(tag.tagId) != -1
      });
    }) ;
    this.photosFiltered.emit(this._filteredPhotos);
  }

  toggleAdminMode(){
    this.adminMode = !this.adminMode;
    if (this.csMode)  this.toggleCoolsculptingMode();
    this.changeState.emit(this.adminMode);
  }

  toggleFavouriteMode(){
    this.favouriteMode = !this.favouriteMode;

    this.basicFilter();
  }

  toggleCoolsculptingMode(){

    this.csMode = !this.csMode;
    if (!this.csMode && this.showStockImages) this.toggleStockImages();

    this.basicFilter();
  }

  toggleStockImages(){
    this.showStockImages = !this.showStockImages;
    this.showStockImagesChange.emit(this.showStockImages);
  }

  // addTag(tag: Tag) {
  //   if (!this.selectedTags.has(tag)) {
  //     this.selectedTags.add(tag);
  //     this.photosFilterService.addTags(Array.from(this.selectedTags) as Tag[]);
  //   } else {
  //     return;
  //   }
  // }

  // removeTag(tag: Tag) {
  //   let arr =  Array.from(this.selectedTags);
  //   let index =arr.findIndex(t => tag.tagId == t.tagId);
  //   arr.splice(index,1);

  //   this.selectedTags = new Set<Tag>(arr);
  //   this.photosFilterService.addTags(arr as Tag[]);
  // }

  // tagSelected(tag: Tag){
  //   return Array.from(this.selectedTags).some(t => tag.tagId == t.tagId);
  // }

  // clearSelectedTags() {
  //   this.selectedTags.clear();
  //   this.photosFilterService.addTags(Array.from(this.selectedTags) as Tag[]);
  // }

  // public tagSelectedFromSearch(tagStr: string){
  //   var tag: Tag = null;
  //   Object.keys(this.tags).some(tags =>{
  //     tag = this.tags[tags].find(t => t.title == tagStr)
  //     if (tag) return true;
  //     return false;
  //   })
  //   if (tag) {
  //     this.isFilterMode = true;
  //     this.addTag(tag);
  //   }
  // }
}
